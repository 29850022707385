import FusePageSimple from "@fuse/core/FusePageSimple";
import CONFIG from "app/config";
import { styled } from "@mui/material/styles";
import * as React from "react";

import { useState, useRef, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
    Card,
    TextField,
    Button,
    Item,
    Typography,
    Container,
    Dialog,
    Alert,
    Stack,
} from "@mui/material";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//intiialize
import PublicHeader from "app/main/apps/public/public-header/PublicHeader";
import FuseScrollbars from "@fuse/core/FuseScrollbars/FuseScrollbars";
import { Box, GlobalStyles, height } from "@mui/system";
import PublicSidebarLeftLogo from "../public-sidebar-left/PublicSidebarLeftLogo";
import PublicSidebarLeft from "../public-sidebar-left/PublicSidebarLeft";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { Map, Marker, InfoWindow, GoogleApiWrapper } from "google-maps-react";
import InfoWindowEx from "./infoWindowEx";
//Form
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { showMessage } from "app/store/fuse/messageSlice";
import { useDispatch } from "react-redux";
import { createMission, checkEcommerceUsername, joinedMission } from "./store/missionSlice"; //from store
import { useNavigate, generatePath } from "react-router-dom";
import Geocode from "react-geocode";
import MissionImages from "./modal/MissionImages";

import Provider from "react-redux/es/components/Provider";
import store from "app/store";
import AttachmentUploader from "app/services/AttachmentUploader";
import randomString from "app/services/randomString";
import { deletePublicAttachment } from "app/services/store/PublicUploadsSlice";
import Icon from "react-multi-date-picker/components/icon";
import ReactPlayer from "react-player";



Geocode.setApiKey(CONFIG.MAPS);
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

const defaultValues = {
    title: "",
    description: "",
    mission_briefing: "",
    address: "",
    lattitude: "",
    longtitude: "",
    content_marker: "",
    featuredImageId: "",
    images: [],
};

const Root = styled(FusePageSimple)(() => ({
    minHeight: "unset",
    "& .FusePageSimple-header.FusePageSimple-topBg": {
        display: "none",
    },
    "& .flex.flex-auto.flex-col.container.z-10.h-full": {
        width: "100%",
        maxWidth: "unset",
    },
    "& .FusePageSimple-header": {
        background: "transparent",
        backgroundSize: "cover",

        height: 152.99,
    },
    "& .FusePageSimple-wrapper": {
        background: "transparent",
        height: "unset",
        width: "100%",
    },
    "& .MuiDrawer-root.MuiDrawer-docked": {
        "& .MuiPaper-root": {
            width: "17.708333333333332vw",
            minWidth: "290px"
        }
    },
    "& .max-h-screen-content-100 .ps__rail-y": {
        display: "none !important",
    },
    "& .FusePageSimple-sidebarContent": {
        height: "calc(100vh - 7.6rem)",
    },
    "& .FusePageSimple-toolbar": {
        height: "76px",
    },
    "& .MuiFormControl-root": {
        marginBottom: "14px",
    },
    "& p": {
        fontFamily: "'Nunito Sans', sans-serif"
    }
}));

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        backgroundColor: "#ffff",
        border: "1px solid #E9E9E9",
        fontSize: '16px',
        borderRadius: '8px',
        [theme.breakpoints.up("lg")]: {
            height: 'unset',
            fontSize: '18px',
        },
    },
    "& .MuiInputLabel-root": {
        fontSize: '16px',
        [theme.breakpoints.up("lg")]: {
            fontSize: '16px',
        },
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
};
class PinIcon extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className="mr-3" width="24" height="24" viewBox="0 0 24 24"><g fill="#141f2c" stroke="#314159" strokeWidth="1" opacity="0"><rect width="24" height="24" stroke="none" /><rect x="0.5" y="0.5" width="23" height="23" fill="none" /></g><path d="M11.5,9a2.5,2.5,0,0,1,4.87-.81A2.746,2.746,0,0,1,16.5,9a2.5,2.5,0,0,1-4.86.84A2.6,2.6,0,0,1,11.5,9M5,9c0,4.5,5.08,10.66,6,11.81L10,22S3,14.25,3,9A7.005,7.005,0,0,1,8,2.29,8.982,8.982,0,0,0,5,9m9-7a7,7,0,0,1,7,7c0,5.25-7,13-7,13S7,14.25,7,9a7,7,0,0,1,7-7m0,2A5,5,0,0,0,9,9c0,1,0,3,5,9.71C19,12,19,10,19,9A5,5,0,0,0,14,4Z" fill="#314159" /></svg>
        )
    }
}

function PublicJoinMission(props) {

    const location = useLocation()
    const { data } = location.state
    const token = AgentJwtService.getDecodedAccessToken();
    const navigate = useNavigate(); //redirect after duspatch
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);  // visibility state

    console.log("Data", data)
    //form submission and validation
    const onSubmit = (data, e) => console.log(data, e);
    const onError = (errors, e) => console.log(errors, e);

    const [ishowingInfoWindow, setshowingInfoWindow] = useState(true);
    const [isDragable, setDragable] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);


    // const [markerPosition, setLattitude] = useState(null); 
    const [markerPosition, setMarkerPosition] = useState(null);
    const [activeMarker, setActiveMarker] = useState(null);
    const [showInfoWindow, setInfoWindowFlag] = useState(true);
    const [address, setAddress] = useState("");
    const [pinContentData, setpinContentData] = useState([]);
    const [imagesData, setImagesData] = useState([]);

    const [attachmentTempId, setAttachmentTempId] = useState(randomString())
    const [attachmentData, setAttachmentData] = useState([])
    const [attachmentEditData, setAttachmentEditData] = useState([])
    const [attachmentLoader, setAttachmentLoader] = useState(true)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const mapOptions = (maps) => {
        return {
            mapTypeId: maps.MapTypeId.HYBRID,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.TOP_CENTER,
            },
            zoomControl: true,
            zoomControlOptions: {
                position: maps.ControlPosition.LEFT_CENTER,
            },
            scaleControl: true,
            streetViewControl: true,
            streetViewControlOptions: {
                position: maps.ControlPosition.LEFT_TOP,
            },
            fullscreenControl: false,
        };
    };

    const schema = yup.object().shape({
        title: yup.string().required("Field is required").min(8).max(32),
        description: yup.string().required("Field is required"),
        mission_briefing: yup.string(),
        address: yup.string(),
        lattitude: yup.string(),
        longtitude: yup.string(),
        content_marker: yup.string(),
    });


    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState,
        handleSubmit,
        reset,
        getValues,
        setValue,
        watch,
        trigger
    } = methods;

    const { isValid, dirtyFields, errors } = formState;

    const handleMarkerMouseOver = (props, marker, e) => {
        setInfoWindowFlag(showInfoWindow);
        setActiveMarker(marker);
    };
    const handleMarkerDragend = (props, marker, e) => {
        const clickedLatLng = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        };

        setValue("lattitude", e.latLng.lat(), { shouldDirty: true });
        setValue("longtitude", e.latLng.lng(), { shouldDirty: true });

        setInfoWindowFlag(showInfoWindow);
        setActiveMarker(marker);

        //get address
        Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
            (response) => {
                const address = response.results[0].formatted_address;

                setValue("address", "address", { shouldDirty: true });
                setAddress(address);
                console.log(address);
            },
            (error) => {
                console.error(error);
            }
        );
        setMarkerPosition(clickedLatLng);
    };

    const onMarkerClick = (props, marker, e) => {

        setValue("title", data.title, { shouldDirty: true });
        setValue("description", data.description, { shouldDirty: true });
        setInfoWindowFlag(showInfoWindow);
        setActiveMarker(marker);

    }

    const onMapClick = (props, marker, e) => {
        const clickedLatLng = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        };

        setValue("lattitude", e.latLng.lat(), { shouldDirty: true });
        setValue("longtitude", e.latLng.lng(), { shouldDirty: true });

        //get address
        Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
            (response) => {
                const address = response.results[0].formatted_address;
                setValue("address", address, { shouldDirty: true });
                setAddress(address);
                console.log(address);
            },
            (error) => {
                // console.error(error);
            }
        );
        setMarkerPosition(clickedLatLng);
    };

    function saveImages(data) {
        setImagesData(data)
    }
    async function handleSave() {

        const result = await trigger([
            "title",
            "description",
            "mission_briefing",
            "address",
            "lattitude",
            "longtitude",
        ]);

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                    variant: "error",
                })
            );
        }

        var data_mission = getValues();
        data_mission.agent = token.id
        data_mission.fileId = attachmentTempId;
        data_mission.mission_id = data.id;

        dispatch(joinedMission(data_mission)).then(function (action) {
            reset(defaultValues);
            const id = data.id;
            navigate(generatePath("/public/mission-details/:id", { id }));
        },);

    }

    const handleDeleteAttachment = (id) => {
        dispatch(deletePublicAttachment({ id: id })).then(() => {
            const updatedAttachments = attachmentData.filter(item => item.id !== id);
            setAttachmentData(updatedAttachments);
        })
    };

    const handleDeleteAttachmentEdit = (id) => {
        dispatch(deletePublicAttachment({ id: id })).then(() => {
            const updatedAttachments = attachmentEditData.filter(item => item.id !== id);
            setAttachmentEditData(updatedAttachments);
        })
    };



    return (

        <>

            <GlobalStyles
                styles={(theme) => ({
                    "#fuse-main": {
                        height: "100% !important",
                    },
                    " .FusePageSimple-sidebar, .FusePageSimple-sidebarHeader":
                    {
                        background: "white !important",
                    },
                    " .absolute-important":
                    {
                        position: "absolute !important",
                    },
                    " div.FusePageSimple-sidebarHeader.permanent": {
                        minHeight: 0,
                        height: "unset"
                    }
                })}
            />

            <Root

                content={
                    <FuseScrollbars className=" h-full max-h-screen-content-100 overflow-auto overscroll-auto relative">
                        <div style={{ width: "100%", height: "calc(100vh - 76px)" }}>
                            <Map

                                onClick={onMapClick}
                                options={mapOptions}
                                containerStyle={containerStyle}
                                google={window.google}
                                zoom={14}
                                initialCenter={{ lat: 40.7128, lng: -74.006 }}
                                fullscreenControl={false}
                            >
                                {markerPosition && (
                                    <Marker
                                        onClick={onMarkerClick}
                                        position={markerPosition}
                                        draggable={true}
                                        onDragend={handleMarkerDragend}
                                        icon={{
                                            url: "assets/images/pin-background.png",
                                        }}
                                    ></Marker>
                                )}

                                <InfoWindowEx
                                    className="gwapo"
                                    options={{ gestureHandling: "greedy" }}
                                    disableAutoPan={true}
                                    marker={activeMarker}
                                    visible={showInfoWindow}
                                >

                                    <FormProvider {...methods}>

                                        <div className=" !p-16">
                                            <Typography className="!text-18 !font-bold" sx={{ mb: '13px' }}> Create Mission</Typography>
                                            <Controller
                                                name="title"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextFieldStyled
                                                        {...field}
                                                        className=" w-full"
                                                        error={!!errors.title}
                                                        required
                                                        helperText={errors?.title?.message}
                                                        label="Mission Title"
                                                        id="title"
                                                        variant="filled"
                                                        InputLabelProps={{
                                                            sx: {
                                                                color: "#48525C",
                                                                fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                                [`&.${inputLabelClasses.shrink}`]: {
                                                                    fontSize: '14px',
                                                                    color: !!errors.username ? "red" : "#141F2C"
                                                                }
                                                            }
                                                        }}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                        }}
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                            <Controller
                                                name="description"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextFieldStyled
                                                        {...field}
                                                        className="mt-16 w-full"
                                                        error={!!errors.description}
                                                        required
                                                        helperText={errors?.description?.message}
                                                        label="Description"
                                                        id="description"
                                                        variant="filled"
                                                        InputLabelProps={{
                                                            sx: {
                                                                color: "#48525C",
                                                                fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                                [`&.${inputLabelClasses.shrink}`]: {
                                                                    fontSize: '14px',
                                                                    color: !!errors.username ? "red" : "#141F2C"
                                                                }
                                                            }
                                                        }}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                        }}
                                                        fullWidth
                                                        multiline
                                                        rows={3}
                                                    />
                                                )}
                                            />

                                            {visible &&
                                                <Controller
                                                    name="address"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            className="mt-16 w-full"
                                                            error={!!errors.address}
                                                            helperText={errors?.address?.message}
                                                            label="Address"
                                                            readOnly
                                                            id="address"
                                                            variant="filled"
                                                            InputLabelProps={{
                                                                sx: {
                                                                    color: "#48525C",
                                                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                                        fontSize: '14px',
                                                                        color: !!errors.username ? "red" : "#141F2C"
                                                                    }
                                                                }
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                            }}
                                                            fullWidth
                                                        />
                                                    )}
                                                />}
                                            {visible &&
                                                <Controller
                                                    name="lattitude"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            className="mt-16 w-full"
                                                            error={!!errors.lattitude}
                                                            // required
                                                            helperText={errors?.lattitude?.message}
                                                            label="Latitude"
                                                            id="lattitude"
                                                            variant="filled"
                                                            InputLabelProps={{
                                                                sx: {
                                                                    color: "#48525C",
                                                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                                        fontSize: '14px',
                                                                        color: !!errors.username ? "red" : "#141F2C"
                                                                    }
                                                                }
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                            }}
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            }
                                            {visible &&
                                                <Controller
                                                    name="longtitude"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            className="mt-16 w-full"
                                                            error={!!errors.longtitude}
                                                            helperText={errors?.longtitude?.message}
                                                            label="Longtitude"
                                                            id="longtitude"
                                                            variant="filled"
                                                            InputLabelProps={{
                                                                sx: {
                                                                    color: "#48525C",
                                                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                                        fontSize: '14px',
                                                                        color: !!errors.username ? "red" : "#141F2C"
                                                                    }
                                                                }
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                            }}
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            }
                                            <Provider store={store}>
                                                <AttachmentUploader module="missions" id={attachmentTempId} attachmentData={attachmentData} setAttachmentData={setAttachmentData} />
                                                <Stack direction={'row'} gap={1} className="mt-10 ml-52" flexWrap={'wrap'}>
                                                    {
                                                        attachmentData.length > 0 &&
                                                        attachmentData.map((item) => (
                                                            <Stack direction={'row'} gap={1.5} flexWrap={'wrap'}>
                                                                <div
                                                                    style={{ width: 80, height: 80 }}
                                                                    className="overflow-hidden bg-white rounded-lg border-1 border-pi-light-grey relative"
                                                                >

                                                                    <Icon className='text-red-600 cursor-pointer absolute top-0 right-5 z-20'
                                                                        onClick={() => { handleDeleteAttachment(item.id) }}
                                                                    >delete</Icon>
                                                                    {
                                                                        (() => {
                                                                            switch (item.switchType) {
                                                                                case "youtube":
                                                                                    return <div className="image-container">
                                                                                        <DisabledPlayer>
                                                                                            <div className=" z-10 disable"></div>
                                                                                            <ReactPlayer
                                                                                                light={true}
                                                                                                playing={false}
                                                                                                className="react-player rounded-lg overflow-hidden "
                                                                                                url={item.srcUrl}
                                                                                                height={80}
                                                                                                width={80}
                                                                                            />
                                                                                        </DisabledPlayer>
                                                                                    </div>;
                                                                                case "video":
                                                                                    return <div className="image-container">
                                                                                        <video autoPlay={false} className="blog">
                                                                                            <source
                                                                                                type={item.fileType}
                                                                                                src={item.srcUrl}
                                                                                            />B
                                                                                        </video>
                                                                                    </div>;
                                                                                default:
                                                                                    return <div className="image-container">
                                                                                        <img

                                                                                            className="max-w-none w-full h-full object-cover"
                                                                                            src={item.srcUrl}
                                                                                        />
                                                                                    </div>;
                                                                            }
                                                                        })()
                                                                    }
                                                                </div>
                                                            </Stack>
                                                        ))
                                                    }
                                                </Stack>
                                            </Provider>
                                            <Box className="w-full mt-16 text-center">
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    className=" mx-auto rounded-md h-40"
                                                    sx={{ background: "#F0D060", py: "8px", color: "#314159", fontSize: "16px", borderRadius: "100px", mb: '8px', "&:hover": { background: "#FECB00", } }}
                                                    aria-label="pin"
                                                    value="legacy"
                                                    onClick={handleSave}
                                                >
                                                    < PinIcon /> PIN NOW
                                                </Button>
                                            </Box>
                                        </div>

                                    </FormProvider>
                                </InfoWindowEx>
                            </Map>
                        </div>
                        <Alert severity="info" sx={{ '& svg': { color: '#ffff' } }} className="absolute w-max left-0 right-0 text-16 bg-pi-blue-1 text-white rounded-md bottom-10 m-auto">To create the mission, you need to tap the map where your mission is located. You can move the map by dragging it.</Alert>
                    </FuseScrollbars>
                }


            />
            <ToastContainer />
        </>
    );
}

export default GoogleApiWrapper({
    apiKey: CONFIG.MAPS,
})(PublicJoinMission);
