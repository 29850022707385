import * as React from "react";
import { useMemo, useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Container, Grid, Paper, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import MissionDetailContent from "./content/MissionDetailContent";
// import MapContent from "../content/MapContent";
import MissionDetailJoinersContent from "./content/MissionDetailJoinersContent";
import MissionDetailMapContent from "./content/MissionDetailMapContent";
import Comments from "app/services/comments/Comments";
import { updateViews } from "app/main/apps/public/blog/store/PublicBlogSlice";
import { getMarkersByMissionId } from "app/main/apps/public/mission/store/missionDetailsSlice";
import { useDispatch } from "react-redux";

function MissionDetails() {
    const { id } = useParams();
    const [activeMission, setActiveMission] = useState({});
    const [markerPosition, setMarkerPosition] = useState({ lat: 40.7128, lng: -74.006 });
    const [isDisabled, setIsDisabled] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    const scrollToRef = (id) => {
        setIsDisabled(true)
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
        const timeout = setTimeout(() => {
            setIsDisabled(false)
        }, 5000);
        return () => clearTimeout(timeout);
    };

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(rData =>
                dispatch(updateViews({ ipAddress: rData.ip, module: "mission", moduleId: id })).then((action) => {
                    console.log('SHOWWWWWWWWWWW', action.payload, rData);
                }))
            .catch(error => console.log(error))
    }, [])

    useEffect(() => {
        dispatch(getMarkersByMissionId({ id: id, type: 'markers' })).then((action) => {
            setData((action.payload).filter(item => item.agent != null))
            setLoading(false)
        })
    }, [dispatch]);

    const handleItemCenter = (itemId) => {
        if (activeMission.id != itemId) {
            if (!isDisabled) {
                const mission = data.find(item => item.id == itemId);
                setActiveMission(mission)
                setMarkerPosition({ lat: mission.lat, lng: mission.lng });
            }
        }
    };

    const activeKingPin = () => {
        dispatch(getMarkersByMissionId({ id: id, type: 'missions' })).then((action) => {
            const mission = action.payload[0];
            setActiveMission(mission);
            setMarkerPosition({ lat: mission.lat, lng: mission.lng });
        })
    }

    return (
        <Container className="p-0 max-w-full font-nunito">
            <Stack className="w-full" direction={'row'} flexWrap={' wrap-reverse'}>
                <MissionDetailContent mission_id={id} activeMission={activeMission} setActiveMission={setActiveMission} markerPosition={markerPosition} setMarkerPosition={setMarkerPosition} isDisabled={isDisabled} activeKingPin={activeKingPin} />
            </Stack>
            <Stack className="w-full" direction={'row'} flexWrap={'wrap-reverse'}>
                <div className=" h-fit flex-grow basis-xs" style={{ alignSelf: "start", maxWidth: "639px" }}>
                    <div className='p-16 pb-0' sx={{ '& .MuiPaper-root': { marginButton: "0px" } }}>
                        <Comments moduleType="missions" moduleId={id} />
                    </div>
                    <MissionDetailJoinersContent mission_id={id} activeMission={activeMission} setActiveMission={setActiveMission} markerPosition={markerPosition} setMarkerPosition={setMarkerPosition} isDisabled={isDisabled} handleItemCenter={handleItemCenter} data={data} loading={loading} />
                </div>
                <div className=" flex-grow basis-xs sticky">
                    <div className="map-sticky">
                        <MissionDetailMapContent mission_id={id} activeMission={activeMission} setActiveMission={setActiveMission} markerPosition={markerPosition} setMarkerPosition={setMarkerPosition} scrollToRef={scrollToRef} />
                    </div>
                </div>
            </Stack>
        </Container>
    )
}

export default MissionDetails;