const mutation = {
    updateSearchHistory: `
        mutation updateSearchHistory($data: JSONString!){
            updateSearchHistory(data: $data){
                status,
                res,
                message
            }
        }
    `,
}

const query = {
    getSearchById: `
        query getSearchById($agentId: String){
            getSearchById(agentId: $agentId){
                userId
                searchHistory
                fullName
            }
        }
    `,
    getSearchList: `
        query getSearchList($agentId: String, $searchKey: String){
            getSearchList(agentId: $agentId, searchKey: $searchKey){
                agents{
                    userId
                    name
                    username
                    firstName
                    about
                    profilePicPath
                    missions{
                        title
                        description
                        dateCreated
                        id
                        missionPhotoPath
                        filesId
                        pinned
                    }
                }
                blogs{
                    id
                    title
                }
                missions{
                    id
                    title
                    description
                    dateCreated
                    filesId
                    pinned
                    agent{
                        id
                        email
                        name
                        userId
                        username
                        firstName
                        lastName
                        middleName
                        gender
                        birthdate
                        status
                        profilePicPath
                    }
                }
            }
        }
    `,
}
export { mutation, query };