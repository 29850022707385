
import FuseScrollbars from "@fuse/core/FuseScrollbars";
import _ from "@lodash";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import format from 'date-fns/format';
import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import withRouter from "@fuse/core/withRouter";
import FuseLoading from "@fuse/core/FuseLoading";
import { styled, alpha } from '@mui/material/styles';
import { Avatar, CardHeader, Divider, Icon, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Stack, Switch } from "@mui/material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import MissionManagerStatusModal from "../../mission-manager/content/modal/MissionManagerStatusModal";
import MissionManagerEditModal from "../../mission-manager/content/modal/MissionManagerEditModal";
import MissionManagerDeleteModal from "../../mission-manager/content/modal/MissionManagerDeleteModal";
import MissionEditModal from "./modal/MissionEditModal";
import MissionDeleteModal from "./modal/MissionDeleteModal";
import { updateMissionByType } from "app/main/apps/public/mission-manager-v2/store/missionManagerSlice"
import { blue } from "@mui/material/colors";
import PhotoGridView from "app/services/PhotoGridView";
import { useNavigate } from "react-router-dom";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd):not(.Mui-selected):not(:hover)': {
        backgroundColor: theme.palette.background.default,
    },
    '&:hover': {
        backgroundColor: theme.palette.background.hover,
    },
    // hide last border
    '& td, & th': {
        border: 0,
    },
}));

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: blue[400],
        '&:hover': {
            backgroundColor: alpha(blue[400], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: blue[400],
    },
}));

function PublicMissionManagerListTable(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { data, loadData, loading, setData } = props;

    if (loading) {
        return <FuseLoading />;
    }

    if (data.length === 0) {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                className="flex flex-1 items-center justify-center h-full"
            >
                <Typography color="textSecondary" variant="h5">
                    There are no Mission!
                </Typography>
            </motion.div>
        );
    }

    function toggleReplace(itemData) {

        var value = itemData.status == '1' ? '0' : '1';

        const newData = [...data];

        const index = newData.findIndex(item => item.id === itemData.id);

        if (index !== -1) {

            const updatedData = { ...newData[index], status: value }
            newData[index] = updatedData;
            setData(newData);

            updatedData.type = "status"

            dispatch(updateMissionByType(updatedData))
        }
    };

    return (
        <>

            {data.map((n) => (

                <div
                    key={n.id}
                >
                    <CardHeader

                        className="p-0 my-20"
                        sx={{ '& .MuiCardHeader-action': { alignSelf: "self-start" } }}
                        avatar={
                            <div style={{ cursor: 'pointer' }} onClick={() => navigate('/public/mission-details/' + n.missionId)}>
                                {/* //for future */}
                                <div style={{ width: 100, height: 100 }} cols={1} className="overflow-hidden mr-10 border-1 rounded-md">
                                    <PhotoGridView fileId={n.filesId} single={true} isDisabledModal={true} />
                                </div>
                            </div>
                        }
                        title={
                            <div style={{ cursor: 'pointer' }} onClick={() => navigate('/public/mission-details/' + n.missionId)}>
                                < Typography className=" font-extrabold text-18 text-pi-dark-blue-text line-clamp-1 leading-tight" >
                                    {n.title}
                                </Typography >
                                < Typography className=" text-14 text-pi-grey " >
                                    {format(new Date(n.dateCreated), 'MM/dd/yyyy hh:mm a')}
                                </Typography >
                                < Typography className=" text-16 line-clamp-2 leading-snug" >
                                    {n.description}
                                </Typography >
                            </div>
                        }
                        subheader={
                            <Stack style={{ cursor: 'pointer' }} onClick={() => navigate('/public/mission-details/' + n.missionId)} direction="row" alignItems="center">
                                <></>
                                < Typography className="text-14 text-pi-grey" >
                                </Typography >
                                {n.missionViews} views {n.pinned} reach
                            </Stack>
                        }
                        action={
                            <>
                                <PopupState variant="popover" popupId="demo-popup-popover" key={n.id}>
                                    {(popupState) => (
                                        <>
                                            <IconButton  {...bindTrigger(popupState)} edge={false}>
                                                <Icon>more_vert</Icon>
                                            </IconButton>
                                            <Popover
                                                {...bindPopover(popupState)}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                            >
                                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', }}>
                                                    <ListItem>
                                                        <ListItemText>Status</ListItemText>
                                                        <GreenSwitch edge="end" color="secondary" checked={n.status == '1'} onChange={() => { toggleReplace(n); popupState.close(); }} />
                                                        {/* <MissionManagerStatusModal item={n} module="status" loadData={loadData} /> */}

                                                    </ListItem>
                                                    <Divider />
                                                    {/* <MissionManagerEditModal item={n} module="edit" loadData={loadData} /> */}
                                                    <MissionEditModal item={n} loadData={loadData} popupState={popupState} />
                                                    <MissionDeleteModal item={n} loadData={loadData} popupState={popupState} />
                                                </List>
                                            </Popover>
                                        </>
                                    )}
                                </PopupState>
                            </>
                        }
                    />

                </div>
            ))}
        </>

    );
}
export default PublicMissionManagerListTable;