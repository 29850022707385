import Webcam from "react-webcam";
import * as React from "react";
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LoadingButton from "@mui/lab/LoadingButton";
import { randomString } from "app/services/services";
import FuseUtils from "@fuse/utils/FuseUtils";

function TakePhoto({ openDialog, setOpenDialog, setFile }) {

    const videoConstraints = {
        width: 720,
        height: 720
    };

    const webcamRef = React.useRef(null);

    function handleOpenDialog() {
        setOpenDialog(true);
    }

    function handleCloseDialog() {
        setOpenDialog(false);
    }

    async function handleCapture() {
        const imageSrc = webcamRef.current.getScreenshot();
        var blob = await fetch(imageSrc).then((res) => res.blob());
        blob.filename = randomString();
        blob.name = randomString();

        setFile({
            id: FuseUtils.generateGUID(),
            url: imageSrc,
            type: 'image',
            file: blob,
            status: "new"
        });

        handleCloseDialog();
    }

    return (

        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="form-dialog-title"
            scroll="body"
        >
            <AppBar position="static" elevation={0}>
                <Toolbar className="flex w-full">
                    <Typography variant="subtitle1" color="inherit">
                        Take a Photo
                    </Typography>
                </Toolbar>
            </AppBar>

            <form noValidate className="flex flex-col">

                <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/png"
                        videoConstraints={videoConstraints}
                    />
                </DialogContent>

                <DialogActions className="justify-center px-8 py-16">
                    <div className="px-16">

                        <LoadingButton
                            className="rounded-md mr-5"
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={handleCapture}
                        >
                            Capture
                        </LoadingButton>
                        <Button
                            className="rounded-md ml-5"
                            variant="contained"
                            type="button"
                            color="secondary"
                            onClick={handleCloseDialog}
                        >
                            Close
                        </Button>
                    </div>
                </DialogActions>
            </form>
        </Dialog>
    )

}

export default TakePhoto