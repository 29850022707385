import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import NotificationIcon from './NotificationIcon';
import parse from 'html-react-parser';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

function NotificationCard(props) {
  const { item, className } = props;
  const { variant } = item.options ? item.options : 'info';

  const handleClose = () => {
    if (props.onClose) {
      props.onClose(item.id);
    }
  };

  return (
    <Card
      className={clsx(
        'items-center relative w-full rounded-16 p-20 min-h-64 shadow',
        variant === 'success' && 'bg-green-600 text-white',
        variant === 'info' && 'bg-blue-700 text-white',
        variant === 'error' && 'bg-red-600 text-white',
        variant === 'warning' && 'bg-orange-600 text-white',
        className
      )}
      elevation={0}
    >
      <NotificationIcon value={variant} />
      <Typography component="div">
        {parse(item.message)}
      </Typography>
      <Typography component="div" className='mt-10'>
        {formatDistanceToNow(new Date(item.dateCreated), { addSuffix: true })}
      </Typography>
    </Card>
  );
}

export default NotificationCard;
