const mutation = {

    unFollowAgent: `
        mutation unfollowAgent($data: JSONString) {
           unfollowAgent (data: $data) {
                status,
                res,
                message
            }
        }
    `,

    followAgent: `
        mutation followAgent($data: JSONString) {
           followAgent (data: $data) {
                status,
                res,
                message
            }
        }
    `,

    muteAgent: `
        mutation muteAgent($data: JSONString) {
           muteAgent (data: $data) {
                status,
                res,
                message
            }
        }
    `,

    unmuteAgent: `
        mutation unmuteAgent($data: JSONString) {
           unmuteAgent (data: $data) {
                status,
                res,
                message
            }
        }
    `,


};

const query = {

    followerList: `
         query followerList($agentId: String, $keyword: String) {
            followerList(agentId: $agentId, keyword: $keyword) {
                id
                followedInfo{
                    userId
                    displayName
                    profilePicPath
                    allFollowerInfo{
                        agent
                        follower{
                            agentidFollower
                        }
                    }
                }
                dateCreated
                agentidFollowed
                notification
                name
            }
        }
    `,
    friendList: `
         query friendList($agentId: String) {
            friendList(agentId: $agentId) {
                notification
                followerInfo{
                    userId
                    displayName
                }
            }
        }
    `,
};

export { mutation, query };
