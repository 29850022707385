import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from 'app/config';
import { query, mutation } from "../graphql/agent_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import format from 'date-fns/format';
import JwtService from "app/services/jwtService";
import { isEmpty } from "lodash";

export const agentById = createAsyncThunk(
    "agentApp/info/agentById",
    async (agentId) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.agentById,
            variables: {
                agentId: agentId
            }
        });

        if (response.data.data.agentById) {
            const data = response.data.data.agentById;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

const agentInfoSlice = createSlice({
    name: 'agentApp/info',
    initialState: null,
    reducers: null,
    extraReducers: {
        [agentById.fulfilled]: (state, action) => action.payload,
    },
});

export default agentInfoSlice.reducer;