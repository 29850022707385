import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const ContactsApp = lazy(() => import('./ContactsApp'));

const ContactsAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
  routes: [
    {
      path: 'apps/contacts/:id',
      element: <ContactsApp />,
    },
    {
      path: 'apps/contacts',
      element: <Navigate to="/apps/contacts/all" />,
    },
  ],
};

export default ContactsAppConfig;
