import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "@fuse/core/withRouter";
import FuseLoading from "@fuse/core/FuseLoading";
import { styled } from '@mui/material/styles';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { TextField, Button, Item, CardHeader } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import {
    Avatar,
    Box,
    CardActionArea,
    Divider,
    Icon,
    IconButton,
    Link,
    InputAdornment,
} from "@mui/material";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { faComments, faEye } from "@fortawesome/free-regular-svg-icons";
import CardActions from "@mui/material/CardActions";
import Stack from "@mui/material/Stack";
import PublicBlogDetailsModal from "../modal/PublicBlogDetailsModal";
import TextLimit from "app/services/TextLimit";
import PhotoGridView from "app/services/PhotoGridView";
import AttachmentSvs from "app/services/AttachmentSvs";
import SharerSvs from "app/services/SharerSvs";
import { format } from "date-fns";

function PublicBlogContentList(props) {

    const dispatch = useDispatch();
    const { loading, data, keyword, id } = props;
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogData, setDialogData] = useState({});

    function handleOpenDialog(data) {

        setDialogData(data)
        setOpenDialog(true);
    }

    useEffect(() => {
        if (id) {
            const filteredObj = Object.fromEntries(
                Object.entries(data).filter(([key, value]) => value.id == id));
            handleOpenDialog(filteredObj[0]);
        }
    }, [id]);

    if (loading) {
        return <FuseLoading />;
    }

    var modData = data ? data : [];

    if (!loading && modData.length === 0) {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                className="flex flex-1 items-center justify-center h-full"
            >
                <Typography color="textSecondary" variant="h5">
                    {keyword != '' ? 'No Results Found' : 'There are no Blogs!'}
                </Typography>
            </motion.div>
        );
    }

    return (
        <div>
            {modData.map((val, key) => {
                return (
                    <Card className="p-16 pb-8 rounded-lg mb-16 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0} key={key}>
                        <CardHeader
                            className="p-0"
                            avatar={
                                <AttachmentSvs module={"at-3"} path={val.authorPhotoPath} data={val} />
                            }
                            title={
                                <Typography className=" font-extrabold text-18">
                                    {val.authorName}
                                    {val.featured == 1 && (<FontAwesomeIcon className="ml-5 text-14" icon={faCrown} />)}
                                </Typography>
                            }
                            subheader={
                                <Typography className=" line-clamp-1 font-500 text-15 text-pi-grey">
                                    {val.dateCreated && (format(new Date(val.dateCreated), 'MMMM d, yyyy'))}
                                </Typography>
                            }
                            action={
                                <IconButton aria-label="settings" className="hidden" edge={false}>
                                    <MoreVertIcon />
                                </IconButton>
                            }
                        />
                        <CardContent className="p-0">
                            <Typography
                                component="div"
                                className="font-bold mt-5 text-18 mb-0 text-pi-black line-clamp-2"
                            >
                                {val.metaTitle}
                            </Typography>
                            <Box className="flex items-end mb-6 relative">
                                <div className="text-16 mb-3">
                                    <TextLimit text={val.content} limit={200} />
                                </div>
                            </Box>
                        </CardContent>
                        <PhotoGridView fileId={val.fileId} />

                        <CardActions className="flex-col pt-8 pb-0  px-0">
                            <Stack
                                direction="row"
                                className="justify-between w-full items-center"
                            >
                                <Stack direction="row" spacing={2}>
                                    <Typography className=" text-pi-grey-back font-medium self-center pt-2">
                                        {val.views} views
                                    </Typography>
                                    <Link
                                        underline="none"
                                        component={"button"}
                                        onClick={() => handleOpenDialog(val)}
                                        className="  text-pi-grey-back font-medium hover:text-pi-primary"
                                    >
                                        {val.totalComments} comments
                                    </Link>
                                </Stack>
                                <SharerSvs link={`/public/blog?id=${val.id}`} title={val.metaTitle} description={val.content} />
                            </Stack>
                        </CardActions>
                    </Card>
                )
            })}
            <PublicBlogDetailsModal openDialog={openDialog} setOpenDialog={setOpenDialog} data={dialogData} />
        </div>
    );
}

export default PublicBlogContentList;
