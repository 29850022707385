// Updated By   : ICO
// Date         : 07-20-2023
// Branch       : design-101
// Task #72
// Task Link    : https://app.activecollab.com/187150/projects/7?modal=Task-54738-7
// Details      : Apply Design from Adobe Xd to Web Design Development
//                Responsive From Desktop to mobile size

// NOTE! 
// TextFieldStyled dapat naka variant="filled"
// add this code on Inside of TextField
// InputLabelProps={{
//     sx: {
//       color: "#48525C",
//       fontSize:{xl:'16px',lg:'16px', md:'14px'},
//       [`&.${inputLabelClasses.shrink}`]: {
//       fontSize:'14px',
//       color: !!errors.username?"red":"#141F2C"
//       }
//     }
//   }}
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { submitLogin } from "app/agent/store/agentLoginSlice";
import * as yup from "yup";
import _ from "@lodash";
import Typography from '@mui/material/Typography';
import AgentJwtService from "app/services/agent/AgentJwtService";
import { useNavigate } from 'react-router-dom';
import { Divider, FormHelperText, Stack, styled } from "@mui/material";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        backgroundColor: "#ffff",
        border: "1px solid #E9E9E9",
        height: 48,
        fontSize: '16px',
        [theme.breakpoints.up("lg")]: {
            height: 57,
            fontSize: '18px',
        },
        // borderRadius:"8px"
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
}));

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    email: yup.string().required("Please enter your username."),
    password: yup
        .string()
        .required("Please enter your password.")
        .min(4, "Password is too short - should be 4 chars minimum."),
});

const defaultValues = {
    email: "",
    password: "",
};

function JWTLoginTab(props) {
    const dispatch = useDispatch();
    const agentlogin = useSelector(({ agent }) => agent.agentlogin);

    const [openDialogForgotPassword, setOpenDialogForgotPassword] = useState(false);

    function handleDialogForgotPasswordOpen() {
        setOpenDialogForgotPassword(true);
    }

    function handleDialogForgotPasswordClose() {
        setOpenDialogForgotPassword(false);
    }

    const {
        control,
        setValue,
        formState,
        handleSubmit,
        reset,
        trigger,
        setError,
    } = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { isValid, dirtyFields, errors } = formState;

    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        agentlogin.errors.forEach((error) => {
            setError('password', {
                type: "manual",
                message: error.message,
            });
        });
    }, [agentlogin.errors, setError]);
    const navigate = useNavigate();

    useEffect(() => {
        const token = AgentJwtService.getDecodedAccessToken();
        if (token && token.id) {
            if (!token.firstName || token.firstName == '') {
                setTimeout(() => {
                    navigate("/agentprofile/edit");
                });
            } else {
                setTimeout(() => {
                    navigate("/");
                });
            }
        }
    }, [agentlogin.success]);

    function onSubmit(model) {
        dispatch(submitLogin(model)).then((action) => {
            setErrorMessage(action[0]);
        });
    }

    return (
        <div className="w-full max-w-pie-382px">
            {errorMessage.message && (
                <Stack sx={{ width: '100%', paddingBottom: '27px' }} spacing={2}>
                    <Alert sx={{ padding: '10px' }} severity="error">{errorMessage?.message}</Alert>
                </Stack>
            )}

            <form
                className="flex flex-col justify-center w-full"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <TextFieldStyled
                            label="Username"
                            placeholder="Enter your username"
                            variant="filled"
                            InputLabelProps={{
                                sx: {
                                    color: "#48525C",
                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        fontSize: '14px',
                                        color: !!errors.email ? "red" : "#141F2C"
                                    }
                                }
                            }}
                            {...field}
                            className="mb-24"
                            type="text"
                            error={!!errors.email}
                            helperText={errors?.email?.message}
                        />
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <TextFieldStyled
                            label="Password"
                            placeholder="******"
                            variant="filled"
                            InputLabelProps={{
                                sx: {
                                    color: "#48525C",
                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        fontSize: '14px',
                                        color: !!errors.password ? "red" : "#141F2C"
                                    }
                                }
                            }}
                            {...field}
                            className="mb-24"
                            type="password"
                            error={!!errors.password}
                            helperText={errors?.password?.message}
                            InputProps={{
                                className: "pr-2",
                                type: showPassword ? "text" : "password",
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            size="large"
                                            edge={false}
                                        >
                                            <Icon className="text-20" color="action">
                                                {showPassword ? "visibility" : "visibility_off"}
                                            </Icon>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            required
                        />
                    )}
                />
                <Button
                    type="submit"
                    variant="contained"
                    className="w-full mx-auto mt-0 font-bold text-14 lg:text-16 bg-pi-yellow-green uppercase"
                    aria-label="LOG IN"
                    disabled={_.isEmpty(dirtyFields) || !isValid}
                    value="legacy"
                    size="large"
                >
                    Login
                </Button>
            </form>
            <div className=" mt-44 flex items-center justify-center">
                <Divider className="w-32 border-1" />
                <span className="mx-8 font-semibold">OR</span>
                <Divider className="w-32 border-1" />
            </div>

            <div className="flex flex-col items-center justify-center pt-32 pb-24 w-full">
                <span className="font-normal text-14 lg:text-16 pb-14">Don't have an account yet?
                </span>
                <Button component={Link} to="/public/register" className=' rounded-md w-full font-bold text-14 lg:text-16' size='large' variant='outlined' style={{ borderColor: "#F0D060", color: '#F0D060', background: "#FFFF" }}>CREATE NEW ACCOUNT</Button>
            </div>
        </div>
    );
}

export default JWTLoginTab;
