import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import * as React from "react";
import Typography from "@mui/material/Typography";
import PublicHeader from "app/main/apps/public/public-header/PublicHeader";
import { GlobalStyles, height } from "@mui/system";
import FuseScrollbars from "@fuse/core/FuseScrollbars/FuseScrollbars";
import { Badge, Card, CardHeader, Stack, Avatar, useMediaQuery, Box, Button, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider, ListSubheader, } from "@mui/material";
import { Background, Parallax } from "react-parallax";
import HomeContent from "./content/HomeContent";
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { Link } from "react-router-dom";
const Root = styled(FusePageSimple)(() => ({
    minHeight: "unset",
    "& .FusePageSimple-header.FusePageSimple-topBg": {
        display: "none",
    },
    "& .flex.flex-auto.flex-col.container.z-10.h-full": {
        width: "100%",
        maxWidth: "unset",
    },
    "& .FusePageSimple-header": {
        background: "transparent",
        backgroundSize: "cover",

        height: 152.99,
    },
    "& .FusePageSimple-wrapper": {
        background: "transparent",
        height: "unset",
        width: "100%",
    },
    "& .MuiDrawer-root.MuiDrawer-docked": {
        "& .MuiPaper-root": {
            width: "17.708333333333332vw",
            minWidth: "290px"
        }
    },
    "& .max-h-screen-content-100 .ps__rail-y": {
        display: "none !important",
    },
    "& .FusePageSimple-sidebarContent": {
        height: "calc(100vh - 7.6rem)",
    },
}));

function PublicHome(props) {
    const token = AgentJwtService.getDecodedAccessToken();
    localStorage.setItem('active_menu', 'Home');
    return (
        <>
            <GlobalStyles
                styles={(theme) => ({
                    "#fuse-main": {
                        height: "100% !important",
                    },
                    " .FusePageSimple-sidebar, .FusePageSimple-sidebarHeader":
                    {
                        background: "white !important",
                    },
                    " .absolute-important":
                    {
                        position: "absolute !important",
                    },
                    " div.FusePageSimple-sidebarHeader.permanent": {
                        minHeight: 0,
                        height: "unset"
                    }
                })}
            />

            <Root

                content={
                    <>
                        <Root
                            content={
                                <FuseScrollbars className=" h-full max-h-screen-content-100 overflow-auto overscroll-auto">
                                    {token == "" ? (
                                        <Parallax className="relative lg:h-20-vw" contentClassName="absolute-important top-0 w-full 460px-vw md:h-345px-vw smMax:bg-pi-blue-2 bg-transparent" strength={0} bgImageStyle={{ objectFit: "contain", position: "unset", transform: "unset", left: "unset" }} disabled={true} bgImage="assets/images/pi-images/banner-2.png">
                                            <Box className=" sm:px-36 py-48px-vw px-48px-vw  ">
                                                <Typography variant="h3" className="smMax:text-18  md:mt-0 mt-12px-vw font-extrabold text-60px-vw max1200px:text-42px-vw text-pi-dark-blue-text">New to Planet Impossible?</Typography>
                                                <Typography className="smMax:text-14 text-28px-vw max1200px:text-24px-vw mt-8px-vw text-pi-dark-blue-text  smMax:contents">Join the Planet Impossible Network. Create Your Own Mission.
                                                    <span className="smMax:text-14 sm:block text-28px-vw max1200px:text-24px-vw mt-8px-vw text-pi-dark-blue-text ">We have <b className=" text-pi-red">200 Agents Joined</b>, <b className="text-pi-red">300 Created Missions</b>, and <b className="text-pi-red">300 pins dropped</b>.</span>
                                                    <span className="smMax:text-14 sm:block text-28px-vw max1200px:text-24px-vw mt-8px-vw text-pi-dark-blue-text ">Together, Let's Reach Impossible</span></Typography>
                                                <Link style={{ textDecoration: 'none' }} to="/public/create-mission">
                                                    <Button
                                                        variant="contained"
                                                        className="block smMax:text-12 tracking-wider py-12px-vw px-40px-vw uppercase mt-30px-vw bg-pi-yellow-green text-28px-vw max1200px:text-18px-vw font-extrabold hover:bg-pi-hover-yellow text-pi-dark-blue-text min-w-fit rounded-md">
                                                        Accept Mission
                                                    </Button>
                                                </Link>
                                            </Box>
                                        </Parallax >
                                    ) : <div className="pt-24" />}
                                    <HomeContent module={"home"} type={"featured"} initial={8} />
                                    <HomeContent module={"home"} type={"recent"} initial={8} />
                                    <HomeContent module={"home"} type={"popular"} initial={8} />
                                </FuseScrollbars>
                            }
                        />
                    </>
                }

            />
        </>
    );
}

export default PublicHome;
