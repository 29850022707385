import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import { mutation as mutationUpload } from "app/graphql/upload_graphql";
import axios from 'axios';
import CONFIG from 'app/config';
import { query, mutation } from "../graphql/settings_graphql";
import { showMessage } from "app/store/fuse/messageSlice";

export const maintenanceSwitch = createAsyncThunk(
    "settingsApp/maintenanceSwitch",
    async (params, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.maintenanceSwitch,
            variables: {
                data: JSON.stringify(params)
            },
        });
        dispatch(
            showMessage({
                message: response.data.data.maintenanceSwitch["message"],
                autoHideDuration: 5000, //ms
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
                variant: response.data.data.maintenanceSwitch["status"],
            })
        );
    }
);

export const updateSite = createAsyncThunk(
    "agentApp/info/updateSite",
    async (params, { dispatch, getState }) => {

        return new Promise(async (resolve, reject) => {

            saveImages(params).then(async (params) => {
                const response = await axios.post(CONFIG.API + "/api/", {
                    query: mutation.updateSite,
                    variables: { data: JSON.stringify(params) },
                });

                if (response.data.data.updateSite) {
                    dispatch(
                        showMessage({
                            message: "Site has been successfully saved.",
                            autoHideDuration: 5000, //ms
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            variant: "success",
                        })
                    );
                    resolve(true);
                } else {
                    dispatch(
                        showMessage({
                            message: "Something went wrong. Please try again later.",
                            autoHideDuration: 5000, //ms
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            variant: "error",
                        })
                    );
                    resolve(true);
                }
            });
        })
    }
);

const saveImages = (params) => {

    return new Promise(async (resolve, reject) => {
        if (params.images.length) {

            var newImages = [];

            params.images.map(async (image, index) => {

                if (image.status === "new") {
                    newImages.push({
                        index: index,
                        image: image
                    })
                } else {
                    if (params.featuredImageId == image.fileId) {
                        params.featuredImagePath = image.path;
                    }
                }

            });

            if (newImages.length) {
                var count = 0;
                newImages.map(async (image, index) => {

                    await uploadSvc({
                        file: image.image.file,
                        destination_file_name: "useraccount/user/user-" + randomString() + "-" + (image.image.file ? image.image.file.name : image.image.fileName)
                    }).then(async (result) => {

                        if (result.status === 200 && result.data.status === "success") {

                            var uploadData = result.data.uploadData
                            uploadData['module'] = 'user-account';
                            uploadData['file_id'] = image.image.id;
                            uploadData['imageType'] = image.image.imageType;

                            const response = await axios.post(CONFIG.API + "/api/", {
                                query: mutationUpload.create,
                                variables: {
                                    data: JSON.stringify(uploadData),
                                }
                            });

                            if (response.data.data.createUpload.status == "success") {
                                count++;
                                var upload = response.data.data.createUpload.upload;

                                if (params.featuredImageId == image.image.id) {
                                    params.featuredImagePath = upload.path;
                                }
                                params.imageType = image.image.imageType;

                                params.images[image.index].upload_id = upload.id;
                                params.images[image.index].file_name = upload.fileName;
                                params.images[image.index].file_id = upload.fileId;
                                params.images[image.index].file_type = upload.fileType;
                                params.images[image.index].path = upload.path;
                                params.images[image.index].module = upload.module;

                                if (newImages.length == count) {
                                    resolve(params);
                                }

                            }

                        }

                    });

                });

            } else {
                resolve(params)
            }


        } else {

            resolve(params);

        }

    });

}

export const getSettings = createAsyncThunk(
    "settingsApp/getSettings",
    async (keyword) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getSettings,
            variables: {
                keyword: keyword ? keyword : null
            },
        });

        if (response.data.data.getSettings) {
            const data = response.data.data.getSettings;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const forceLogoutUser = createAsyncThunk(
    "settingsApp/forceLogoutUser",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.forceLogoutUser,
            variables: {
                agentId: params.agentId ? params.agentId : null,
                userType: params.userType ? params.userType : null
            },
        });

        return response.data.data.forceLogoutUser;
    }
);

export const updateChecker = createAsyncThunk(
    "settingsApp/updateChecker",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.updateChecker,
            variables: {
                agentId: params.agentId ? params.agentId : null,
                userType: params.userType ? params.userType : null,
                updateFrom: 'mobile'
            },
        });

        return response.data.data.updateChecker;
    }
);

const settingsSlice = createSlice({
    name: 'settingsApp/settings',
    initialState: null,
    reducers: null,
    extraReducers: {
        [getSettings.fulfilled]: (state, action) => action.payload,
        [maintenanceSwitch.fulfilled]: (state, action) => action.payload,
    },
});

export default settingsSlice.reducer;
