const mutation = {
    createPage: `
        mutation createPage($data: JSONString!) {
            createPage (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    updatePage: `
        mutation updatePage($data: JSONString!) {
            updatePage (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    deletePage: `
        mutation deletePage($id: String!) {
            deletePage (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
    checkTypeExistence: `
        mutation checkTypeExistence($data: JSONString!) {
            checkTypeExistence (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    pageToggleSave: `
        mutation pageToggleSave($data: JSONString!) {
            pageToggleSave (data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    getPageInfo: `
        query getPageInfo($id: String, $slugs: String) {
            getPageInfo (id: $id, slugs: $slugs) {
                id
                title
                slugs
                shortDescription
                metaTitle
                metaDescription
                metaKeyword
                bodyContent
                status
                dateCreated
                dateUpdated
            }
        }
    `,
    getPagesList: `
        query getPagesList {
            getPagesList {
                id
                title
                slugs
                shortDescription
                metaTitle
                metaDescription
                metaKeyword
                bodyContent
                status
                dateCreated
                dateUpdated
            }
        }
    `,
};
export { mutation, query };