import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from 'app/config';
import { query, mutation } from "../graphql/public_uploads_graphql";
import { query as sQuery } from "app/graphql/store/services_graphql";
import { showMessage } from "app/store/fuse/messageSlice";

export const getSignedUrlPath = createAsyncThunk(
    "serviceApp/getSignedUrl",
    async (path) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getSignedUrl,
            variables: {
                path: path ? path : null,
            }
        });

        if (response.data.data.getSignedUrl) {
            const data = response.data.data.getSignedUrl;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const isJoined = createAsyncThunk(
    "serviceApp/isJoined",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: sQuery.isJoined,
            variables: params
        });

        return response.data.data.isJoined;
    }
);

const serviceSlice = createSlice({
    name: 'serviceApp',
    initialState: null,
    reducers: null,
    extraReducers: {
        [getSignedUrlPath.fulfilled]: (state, action) => action.payload,
        [isJoined.fulfilled]: (state, action) => action.payload,
    },
});

export default serviceSlice.reducer;
