const mutation = {
    updateAgent: `
        mutation updateAgent($data: JSONString!) {
            updateAgent (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    deleteAgent: `
        mutation deleteAgent($id: String!) {
            deleteAgent (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
    toggleSave: `
        mutation toggleSave($data: JSONString!) {
            toggleSave (data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    getAgentsList: `
        query getAgentsList {
            getAgentsList {
                id
                userId
                city
                email
                about
                country
                state
                status
                gender
                name
                username
                firstName
                middleName
                lastName
                birthdate
                agentName
                dateCreated
                dateFormatted
                images {
                    id
                    userId
                    uploadId
                    fileName
                    fileType
                    fileId
                    module
                    path
                    url
                    imageType
                    dateCreated
                    dateUpdated
                }
                bannerImages {
                    id
                    userId
                    uploadId
                    fileName
                    fileType
                    fileId
                    module
                    path
                    url
                    imageType
                    dateCreated
                    dateUpdated
                }
            }
        }
    `,
};
export { mutation, query };