const mutation = {
    updateViews: `
        mutation updateViews($data: JSONString) {
           updateViews(data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    getBlogs: `
        query getBlogs($id: String, $author: String, $category: String, $keyword: String, $start: Int, $end: Int){
            getBlogs(id: $id, author: $author, category: $category, keyword: $keyword, start: $start, end: $end){
                id
                categoryId
                authorId
                totalComments
                title
                content
                summary
                publish
                metaTitle
                metaKeyword
                metaDescription
                views
                fileId
                tags
                featured
                status
                authorName
                authorPhotoPath
                photoUrl
                categoryName
                dateCreated
                dateUpdated
            }
        }
    `,
    getBlogCategoryList: `
        query getBlogCategoryList {
            getBlogCategoryList {
                id
                name
                dateCreated
                dateUpdated
            }
        }
    `,
    getBlogAuthorList: `
        query getBlogAuthorList {
            getBlogAuthorList {
                id
                name
                dateCreated
                dateUpdated
            }
        }
    `,
};
export { mutation, query };