import { lazy } from 'react';
import { authRoles } from 'app/auth';
import PageManageCreate from './manage-page/PageManageCreate.js';

const PageManage = lazy(() => import('./manage-page/PageManage.js'));

const PagesCmsAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {
            path: 'apps/pages/manage',
            element: <PageManage />,
            auth: ["pgs-lst"],
        },
        {
            path: 'apps/pages/create',
            element: <PageManageCreate />,
            auth: ["pgs-crt"],
        },
        {
            path: 'apps/pages/edit',
            element: <PageManageCreate />,
            auth: ["pgs-crt"],
        },
    ],
};

export default PagesCmsAppConfig;
