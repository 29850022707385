import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { query, mutation } from "../graphql/mission_graphql";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import { v4 as uuidv4 } from 'uuid';
import { resolve } from "promise";
import { showMessage } from "app/store/fuse/messageSlice";


const saveImages = (itemData) => {

    itemData.fileid = uuidv4();

    return new Promise(async (resolve, reject) => {

        if (itemData.images.length) {

            var newImages = [];

            itemData.images.map(async (image, index) => {

                if (image.status === "new") {
                    newImages.push({
                        index: index,
                        image: image
                    })
                } else {
                    if (itemData.featuredImageId == image.fileId) {
                        itemData.featuredImagePath = image.path;
                    }
                }

            });

            if (newImages.length) {

                newImages.map(async (image, index) => {

                    await uploadSvc({
                        file: image.image.file,
                        destination_file_name: "missions/" + randomString() + "-" + (image.image.file ? image.image.file.name : image.image.fileName)
                    }).then(async (result) => {

                        if (result.status === 200 && result.data.status === "success") {

                            var uploadData = result.data.uploadData
                            uploadData['module'] = 'mission';
                            uploadData['file_id'] = image.image.id;
                            uploadData['fileid'] = itemData.fileid;

                            const response = await axios.post(CONFIG.API + "/api/", {
                                query: mutation.createPublicUpload,
                                variables: {
                                    data: JSON.stringify(uploadData),
                                },
                            });

                            if (response.data.data.createPublicUpload.status == "success") {
                                var upload = response.data.data.createPublicUpload.upload;

                                if (itemData.featuredImageId == image.image.id) {
                                    itemData.featuredImagePath = upload.path;
                                }
                                itemData.images[image.index].upload_id = upload.id;
                                itemData.images[image.index].file_name = upload.fileName;
                                itemData.images[image.index].file_id = upload.fileId;
                                itemData.images[image.index].file_type = upload.fileType;
                                itemData.images[image.index].path = upload.path;
                                itemData.images[image.index].module = upload.module;
                            }
                        }

                        if (newImages.length == (index + 1)) {
                            resolve(itemData);
                        }

                    });

                });

            } else {
                resolve(itemData)
            }

        } else {
            resolve(itemData);

        }

    });

}

export const createMission = createAsyncThunk(
    "missionApp/mission/createMission",
    async (params, { dispatch, getState }) => {

        const data = await saveImages(params).then(async (params) => {

            const response = await axios.post(CONFIG.API + "/api/", {
                query: mutation.createMission,
                variables: {
                    data: JSON.stringify(params),
                },
            });

            if (response.data.data.createMission.status != 'error') {
                dispatch(
                    showMessage({
                        message: "Mission has been successfully saved.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "success",
                    })
                );

                return response.data.data.createMission

            } else {
                dispatch(
                    showMessage({
                        message: "Something went wrong. Please try again later.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );

                return []
            }

        });

        return data;
    }

);

export const getMissionById = createAsyncThunk(
    "missionApp/mission/getMissionById",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getMissionById,
            variables: params,
        });

        return response.data.data.getMissionById;
    }
);

export const getSingleMissionById = createAsyncThunk(
    "missionApp/mission/getSingleMission",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getSingleMissionById,
            variables: params,
        });

        return response.data.data.missionById;
    }
);

export const getMissionManagerById = createAsyncThunk(
    "missionApp/mission/getMissionManagerById",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getMissionManagerById,
            variables: params,
        });

        return response.data.data.getMissionManagerById;
    }
);

export const joinedMission = createAsyncThunk(
    "missionApp/mission/joinedMission",
    async (data) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.createJoinedMission,
            variables: {
                data: JSON.stringify(data),
            },
        });

        return response;
    }
);

export const createComment = createAsyncThunk(
    "missionApp/mission/createCommentMission",

    async (params, { dispatch, getState }) => {

        await saveImages(params).then(async (params) => {

            const response = await axios.post(CONFIG.API + "/api/", {
                query: mutation.createCommentMission,
                variables: {
                    data: JSON.stringify(params),
                },
            });

            if (response.data.data.createCommentMission.status != 'error') {
                dispatch(
                    showMessage({
                        message: "Comment has been successfully saved.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "success",
                    })
                );
            } else {
                dispatch(
                    showMessage({
                        message: "Something went wrong. Please try again later.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }

        });

    }
);

export const createReply = createAsyncThunk(
    "missionApp/mission/createReply",

    async (params, { dispatch, getState }) => {

        await saveImages(params).then(async (params) => {

            const response = await axios.post(CONFIG.API + "/api/", {
                query: mutation.createReplyMission,
                variables: {
                    data: JSON.stringify(params),
                },
            });

            if (response.data.data.createReplyMission.status != 'error') {
                dispatch(
                    showMessage({
                        message: "Reply has been successfully saved.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "success",
                    })
                );
            } else {
                dispatch(
                    showMessage({
                        message: "Something went wrong. Please try again later.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }

        });

    }
);

export const deleteSingleMarkerById = createAsyncThunk(
    "missionApp/mission/deleteSingleMarkerById",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.deleteSingleMarkerById,
            variables: params,
        });

        return response;
    }
);

export const updateMarkerContetnById = createAsyncThunk(
    "missionApp/mission/updateMarkerContetnById",
    async (data) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.updateMarkerContetnById,
            variables: {
                data: JSON.stringify(data),
            },
        });

        return response;
    }
);

export const updateMarkerLocationById = createAsyncThunk(
    "missionApp/mission/updateMarkerLocationById",
    async (data) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.updateMarkerLocationById,
            variables: {
                data: JSON.stringify(data),
            },
        });

        if (response.data.data.updateMarkerLocationById) {
            dispatch(
                showMessage({
                    message: "Status has been successfully updated.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );
            resolve(true);
        } else {
            dispatch(
                showMessage({
                    message: "Something went wrong. Please try again later.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
            resolve(true);
        }
    }
);

export const deleteMissionById = createAsyncThunk(
    "missionApp/mission/deleteMissionById",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.deleteMissionById,
            variables: params,
        });
        if (response.data.data.deleteMissionById) {
            dispatch(
                showMessage({
                    message: "Status has been successfully updated.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );
            resolve(true);
        } else {
            dispatch(
                showMessage({
                    message: "Something went wrong. Please try again later.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
            resolve(true);
        }
    }
);

export const getCreatedMissions = createAsyncThunk(
    "missionApp/mission/getCreatedMissions",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getCreatedMissions,
            variables: params,
        });

        return response.data.data.getCreatedMissions
    }
);


export const getAcceptedMissions = createAsyncThunk(
    "missionApp/mission/getAcceptedMissions",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getAcceptedMissions,
            variables: params,
        });

        return response.data.data.getAcceptedMissions
    }
);

const MissionSlice = createSlice({
    name: "mission",
    initialState: null,
    reducers: null,
    extraReducers: {
        [createMission.fulfilled]: (state, action) => action.payload,
        [getMissionById.fulfilled]: (state, action) => action.payload,
        [getSingleMissionById.fulfilled]: (state, action) => action.payload,
        [joinedMission.fulfilled]: (state, action) => action.payload,
        [createComment.fulfilled]: (state, action) => action.payload,
        [createReply.fulfilled]: (state, action) => action.payload,
        [deleteSingleMarkerById.fulfilled]: (state, action) => action.payload,
        [updateMarkerContetnById.fulfilled]: (state, action) => action.payload,
        [updateMarkerLocationById.fulfilled]: (state, action) => action.payload,
        [deleteMissionById.fulfilled]: (state, action) => action.payload,
        [getCreatedMissions.fulfilled]: (state, action) => action.payload,
        [getAcceptedMissions.fulfilled]: (state, action) => action.payload,
    },
});

export default MissionSlice.reducer;
