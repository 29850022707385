import { combineReducers } from '@reduxjs/toolkit';
import BlogManageCategorySlice from './BlogManageCategorySlice';
import BlogManageTagsSlice from './BlogManageTagsSlice';
import BlogManageAuthorsSlice from './BlogManageAuthorsSlice';

const reducer = combineReducers({
    BlogManageCategorySlice,
    BlogManageTagsSlice,
    BlogManageAuthorsSlice,
});

export default reducer;