import { orange } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { useParams } from "react-router-dom";
import Icon from '@mui/material/Icon';
import clsx from 'clsx';
import FuseUtils from '@fuse/utils';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';

const Root = styled('div')(({ theme }) => ({
    '& .productImageFeaturedStar': {
        position: 'absolute',
        top: 0,
        right: 0,
        color: orange[400],
        opacity: 0,
    },

    '& .productImageUpload': {
        transitionProperty: 'box-shadow',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
    },

    '& .productImageItem': {
        transitionProperty: 'box-shadow',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        '&:hover': {
            '& .productImageFeaturedStar': {
                opacity: 0.8,
            },
        },
        '&.featured': {
            pointerEvents: 'none',
            boxShadow: theme.shadows[3],
            '& .productImageFeaturedStar': {
                opacity: 1,
            },
            '&:hover .productImageFeaturedStar': {
                opacity: 1,
            },
        },
    },
}));

function MIssionDetailsContentImages(props) {
    const dispatch = useDispatch();
    const methods = useFormContext();
    const { control, watch, setValue } = methods;
    const images = watch('images');
    const id = watch('id');
    const pId = id ? id : 'new';
    return (
        <Root>
            <div className="flex justify-center sm:justify-start flex-wrap mx-16 mt-16">
                <Controller
                    name="images"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <label
                            htmlFor="button-file"
                            className="productImageUpload flex items-center border-1 justify-center relative w-128 h-128 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
                        >
                            <input
                                accept="image/*"
                                className="hidden"
                                id="button-file"
                                type="file"
                                onClick={(event) => {
                                    event.target.value = null
                                }}
                                onChange={async (e) => {
                                    function readFileAsync() {
                                        return new Promise((resolve, reject) => {
                                            const file = e.target.files[0];
                                            if (!file) {
                                                return;
                                            }
                                            const reader = new FileReader();

                                            reader.onload = () => {
                                                var id = FuseUtils.generateGUID();
                                                resolve({
                                                    id: id,
                                                    fileId: id,
                                                    url: `data:${file.type};base64,${btoa(reader.result)}`,
                                                    type: 'image',
                                                    file: file,
                                                    status: "new"
                                                });
                                            };

                                            reader.onerror = reject;

                                            reader.readAsBinaryString(file);
                                        });
                                    }

                                    const newImage = await readFileAsync();

                                    onChange([newImage, ...value]);
                                    e.target.value = null;
                                }}
                            />
                            <Icon fontSize="large" color="action">
                                cloud_upload
                            </Icon>
                        </label>
                    )}
                />
                <Controller
                    name="featuredImageId"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) =>
                        images && images.length ? images.map((media, index) => (
                            media.status != 'deleted' ? <div className='flex' key={(index)}>
                                <div
                                    onClick={() => onChange((pId == "new" ? media.id : media.fileId))}
                                    onKeyDown={() => onChange((pId == "new" ? media.id : media.fileId))}
                                    role="button"
                                    tabIndex={0}
                                    className={clsx(
                                        'productImageItem flex items-center justify-center relative w-128 h-128 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer outline-none shadow hover:shadow-lg',
                                        (media.fileId === value || media.id === value) && 'featured'

                                    )}
                                    key={(pId == "new" ? media.id : media.fileId)}
                                >
                                    <img className="max-w-none w-full h-full object-contain" src={media.url} alt="product" />
                                </div>
                                <div className='relative -ml-40 mt-5'>
                                    <Icon className='text-red-600 cursor-pointer' onClick={() => {
                                        if (media.dateCreated) {
                                            media.status = "deleted";
                                        } else {
                                            images.splice(index, 1);
                                        }
                                        setValue("images", images, { shouldDirty: true });
                                    }}>delete</Icon>
                                </div>
                            </div> : null
                        )) : null
                    }
                />
            </div>
        </Root>
    );
}

export default MIssionDetailsContentImages;