import React, { useState, useRef, useEffect } from "react";
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { Notifications, NotificationsOff, PushPin } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { updateMarkerLocationById } from "app/main/apps/public/mission/store/missionSlice";

const token = AgentJwtService.getDecodedAccessToken();
const AgentMovePinLocation = (props) => {
    const dispatch = useDispatch();
    const { reloadData } = props
    const [status, setStatus] = useState(true);
    const [notif, setNotif] = useState('true');
    const [hideButton, setHideButton] = useState(true);
    async function handleSaveMovePinLocation() {

        const dataPin = JSON.parse(localStorage.getItem('dataKey'));
        dispatch(updateMarkerLocationById(dataPin)).then(function (action) {
            reloadData()
            setStatus(false)
        },);

    }
    return (
        <>
            {status && (
                <>
                    <Button
                        onClick={handleSaveMovePinLocation}
                        variant="contained"
                        aria-label="join"
                        className="mr-10"
                    >
                        <PushPin /> Save Changes

                    </Button>
                </>
            )
            }
        </>
    );
};

export default AgentMovePinLocation;