import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useFormContext, Controller } from "react-hook-form";

function SystemLogsHeader() {

    return (
        <div className="flex flex-1 w-full items-center justify-between">
            <div className="flex flex-col items-start max-w-full min-w-0">
                <div className="flex items-center max-w-full">
                    <div className="flex flex-col min-w-0 mx-8 sm:mc-16">
                        <motion.div
                            initial={{ x: -20 }}
                            animate={{ x: 0, transition: { delay: 0.3 } }}
                        >
                            <Typography className="text-24 sm:text-32 truncate font-semibold">
                                System Logs
                            </Typography>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SystemLogsHeader;