import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import CONFIG from "app/config";
import { mutation, query } from "app/main/agent/graphql/login_graphql";

class AgentJwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
    // this.updateAgentInfo();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            this.emit('onAutoLogout', 'Invalid access_token');
            // this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit('onNoAccessTokenAgent');

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit('onAutoLoginAgent', true);
    }
  };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {

      axios.post(CONFIG.API + "/api/", {
        query: mutation.login,
        variables: {
          username: email,
          password: password,
          agent: 'true'
        },
        isNoToken: true,
        publicUrl: '/',
      })
        .then((response) => {
          if (response.data.data.tokenAuth) {
            this.setSession(response.data.data.tokenAuth.token);
            localStorage.setItem('jwt_access_refresh_token_agent', response.data.data.tokenAuth.refreshToken);
            resolve(response.data.data.tokenAuth.payload);
          } else {
            reject(response.data.errors);
          }
        });
    });
  };

  signUserPayload = (userPayload) => {
    this.setSession(null);
    return new Promise((resolve, reject) => {
      this.setSession(userPayload.token);
      resolve(userPayload.payload);
    });
  };

  signUserPayloadDecoded = (token) => {
    return new Promise((resolve, reject) => {
      this.setSession(token);
      resolve(jwtDecode(token));
    });
  };

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem('jwt_access_token_agent', access_token);
      localStorage.setItem('temp_jwt_access_token_agent', access_token);
    } else {
      localStorage.removeItem('jwt_access_token_agent');
    }
  };

  updateUserData = async (user) => {
    const response = await axios.post(CONFIG.API + "/api/", {
      query: mutation.edit,
      variables: { data: JSON.stringify(user), id: parseInt(user.id) },
    });

    this.emit('onRefreshTokenAgent', true);

    return response;
  };


  updateUserDataSettings = async (user) => {
    const response = await axios.post(CONFIG.API + "/api/", {
      query: mutation.editSettings,
      variables: { data: JSON.stringify(user), id: parseInt(user.id) },
    });

    this.emit('onRefreshTokenAgent', true);

    return response;
  };

  logout = () => {
    this.setJwtLoginReload(false);
    this.setSession(null);
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    var currentDate = new Date();
    var futureTime = new Date(currentDate.getTime() + 30 * 60000) / 1000;

    if (decoded.exp < currentTime) {
      this.emit('onRefreshTokenAgent', true);
      return;
    }

    if (decoded.exp > futureTime && decoded.exp < currentTime) {
      this.setSession(null);
      this.emit('onAutoLogoutAgent', 'access_token expired');
      return false;
    }

    return true;
  };

  setUserRefreshToken = () => {
    this.emit('onRefreshTokenAgent', true);
  };

  signUserRefreshToken = (refreshToken) => {
    return new Promise((resolve, reject) => {

      axios.post(CONFIG.API + "/api/", {
        query: mutation.refresh_token,
        variables: {
          refreshToken: refreshToken
        },
        isNoToken: true
      })
        .then((response) => {
          if (response.data.data.refreshToken) {
            this.setSession(response.data.data.refreshToken.token);
            localStorage.setItem('jwt_access_refresh_token_crm', response.data.data.refreshToken.refreshToken);
            resolve(response.data.data.refreshToken.payload);
          } else {
            reject(response.data.errors);
          }
        });
    });

  };

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token_agent');
  };

  getJwtLoginReload = () => {
    return window.localStorage.getItem('jwt_login_reload_agent');
  };

  setJwtLoginReload = (value) => {
    localStorage.setItem('jwt_login_reload_agent', value);
  };

  getDecodedAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token_agent') ? jwtDecode(window.localStorage.getItem('jwt_access_token_agent')) : [];
  };

  getTempDecodedAccessToken = () => {
    return window.localStorage.getItem('temp_jwt_access_token_agent') ? jwtDecode(window.localStorage.getItem('temp_jwt_access_token_agent')) : [];
  };

  getRefreshAccessToken = () => {
    return window.localStorage.getItem('jwt_access_refresh_token_agent');
  };

  updateAgentInfo = async () => {

    const token = jwtDecode(this.getAccessToken());

    const response = axios.post(CONFIG.API + "/api/", {
      query: query.agentById,
      variables: {
        agentId: token.id
      }
    });

    return response;
  };

  getAgentInfoData = () => {

    // this.updateAgentInfo().then(function (res) {
    //   console.log(res)
    // })

  }
}

const instance = new AgentJwtService();

export default instance;
