const mutation = {};

const query = {
    getMissionById: `
        query getMissionById($id: String){
            getMissionById(id: $id){
            id
            title
            description
            dateCreated
            filesId
            views
            pinned
            commentsCount
            status
            agent{
                userId
                profilePicPath
                name
                flName
                username
            }
        }
    }
    `,
    getMarkersByMissionId: `
        query getMarkersByMissionId($id: String, $type: String){
            getMarkersByMissionId(id: $id, type: $type){
            id
            title
            description
            dateCreated
            filesId
            lat
            lng
            kingPin
            missionId
            missionViews
            missionPinned
            missionCommentsCount
            agent{
                userId
                profilePicPath
                name
                flName
                username
            }
        }
    }
    `,
};

export { mutation, query };
