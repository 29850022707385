import FusePageCarded from '@fuse/core/FusePageCarded';
import withReducer from 'app/store/withReducer';
import { styled } from '@mui/material/styles';
import reducer from '../store';
import { getAgentsList } from "../store/manageAgentsSlice";
import { useDispatch, useSelector } from "react-redux";
import ManageAgentsHeader from './ManageAgentsHeader';
import ManageAgentsTable from './ManageAgentsTable';
import ManageAgentsFilter from './ManageAgentsHeaderFilter';
import { useEffect, useState } from "react";

const Root = styled(FusePageCarded)(({ theme }) => ({
    '& .FusePageCarded-header': {
        minHeight: 72,
        height: 72,
        alignPatrons: 'center',
        [theme.breakpoints.up('sm')]: {
            minHeight: 136,
            height: 136,
        },
    },
    '& .FusePageCarded-content': {
        display: 'flex',
    },
    '& .FusePageCarded-contentCard': {
        overflow: 'hidden',
        marginBottom: "3rem",
        borderRadius: "20px",
    },
    '& .FusePageCarded-toolbar': {
        overflow: "auto",
        height: 110,
    },
}));

function ManageAgents() {
    const dispatch = useDispatch();

    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);
    const [origData, setOrigData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [keyword, setKeyword] = useState("");

    function loadData() {
        setLoading(true)
        dispatch(getAgentsList()).then((action) => {
            if (!!keyword) {
                setData(
                    _.filter(action.payload, (config) =>
                        config.name ? config.name.toLowerCase().includes(keyword.toLowerCase()) : ''
                    )
                );
            } else {
                setData(action.payload)
            }
            setOrigData(action.payload)
            setLoading(false)
        });
    }

    useEffect(() => {
        if (!!keyword) {
            setData(
                _.filter(origData, (config) =>
                    config.name ? config.name.toLowerCase().includes(keyword.toLowerCase()) : ''
                )
            );
        } else {
            setData(origData)
        }
    }, [keyword]);

    return <Root
        header={<ManageAgentsHeader />}
        contentToolbar={
            <ManageAgentsFilter
                selected={selected}
                setSelected={setSelected}
                data={data}
                setData={setData}
                origData={origData}
                setOrigData={setOrigData}
                setLoading={setLoading}
                loadData={loadData}
                setKeyword={setKeyword}
            />
        }
        content={
            <ManageAgentsTable
                selected={selected}
                setSelected={setSelected}
                loading={loading}
                setLoading={setLoading}
                data={data}
                setData={setData}
                loadData={loadData}
            />
        }
    />;
}

export default withReducer('agentsApp', reducer)(ManageAgents);
