import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import * as React from "react";
import Typography from "@mui/material/Typography";
import PublicHeader from "app/main/apps/public/public-header/PublicHeader";
import { GlobalStyles, height } from "@mui/system";
import FuseScrollbars from "@fuse/core/FuseScrollbars/FuseScrollbars";
import { Badge, Card, CardHeader, Stack, Avatar, useMediaQuery, Box, Grid, Button, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider, ListSubheader, Icon, IconButton, Popover, Skeleton } from "@mui/material";
import { cloneElement, memo } from 'react';
import PublicSidebarLeft from "app/main/apps/public/public-sidebar-left/PublicSidebarLeft";
import { useDispatch, useSelector } from "react-redux";
import PublicSidebarLeftLogo from "app/main/apps/public/public-sidebar-left/PublicSidebarLeftLogo";
import withReducer from 'app/store/withReducer';
import reducer from 'app/main/apps/agent/store';
import { Background, Parallax } from "react-parallax";
import CardContent from '@mui/material/CardContent';
import CardActions from "@mui/material/CardActions";
import { agentById, getMarkersByAgentId } from '../store/agentSlice';
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import FriendsActivityJoinMissionModal from "../../public/friends-activity/modal/FriendsActivityJoinMissionModal";
import FriendsActivityCommentModal from "../../public/friends-activity/modal/FriendsActivityCommentModal";
import { PiMapPinLineBold } from "react-icons/pi";
import AgentFollowUnfollow from "app/services/AgentFollowUnfollow";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import FuseLoading from "@fuse/core/FuseLoading";
import { getMarkersByMissionId } from "../../public/mission/store/missionDetailsSlice";
import format from "date-fns/format";
import AttachmentSvs from "app/services/AttachmentSvs";
import SkeletonLoader from "../../public/friends-activity/components/SkeletonLoader";
import AgentSkeletonLoader from "./components/AgentSkeletonLoader";
import PhotoGridView from "app/services/PhotoGridView";
import SharerSvs from "app/services/SharerSvs";

const token = AgentJwtService.getDecodedAccessToken();
console.log(token, 'token');

const Root = styled(FusePageSimple)(() => ({
    minHeight: "unset",
    "& .FusePageSimple-header.FusePageSimple-topBg": {
        display: "none",
    },
    "& .flex.flex-auto.flex-col.container.z-10.h-full": {
        width: "100%",
        maxWidth: "unset",
    },
    "& .FusePageSimple-header": {
        background: "transparent",
        backgroundSize: "cover",

        height: 152.99,
    },
    "& .FusePageSimple-wrapper": {
        background: "transparent",
        height: "unset",
        width: "100%",
    },
    "& .MuiDrawer-root.MuiDrawer-docked": {
        "& .MuiPaper-root": {
            width: "17.708333333333332vw",
            minWidth: "290px"
        }
    },
    "& .max-h-screen-content-100 .ps__rail-y": {
        display: "none !important",
    },
    "& .FusePageSimple-sidebarContent": {
        height: "calc(100vh - 7.6rem)",
    },
    "& .agent-profile-follow": {
        fontSize: '14px',
        padding: '8px 16px',
        marginTop: '6px'
    }
}));

const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
};

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: #141F2C;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[50]};
  }

  &:focus {
    color: #141F2C;
    border-radius: 3px;
    outline: none;
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #ffff;
    color: ${blue[600]};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  background-color: #E9E9E9;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;


function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
}
function stringAvatar(name) {
    var username = name.split(" "),
        firstName = username[0].split(" ")[0][0],
        lastName = username[username.length - 1].split(" ")[0][0];
    if (firstName == lastName) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}`,
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}${lastName}`,
        };
    }
}

const BackgroundAvatar = styled(Avatar)(({ theme }) => ({
    // width: 40,
    borderRadius: "unset",
    width: 60,
    height: 60,
    "& img": {
        objectFit: "contain",
    },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 34,
    height: 34,
}));


class PinnedIcon extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '1.8em', height: '1.8em' }} viewBox="0 0 8.84 12.243">
                <g id="Group_1452" data-name="Group 1452" transform="translate(-1197.877 -2025.664)">
                    <path id="Subtraction_1" data-name="Subtraction 1" d="M3.744,7.489A3.744,3.744,0,1,1,7.489,3.744,3.749,3.749,0,0,1,3.744,7.489ZM3.693,1.5A2.245,2.245,0,1,0,5.937,3.744,2.248,2.248,0,0,0,3.693,1.5Z" transform="translate(1198.553 2026.501)" fill="#FBB633" />
                    <path id="Path_1201" data-name="Path 1201" d="M8.87,4.082a4.317,4.317,0,0,0-.1-.667A4.412,4.412,0,0,0,4.594,0H4.33c-.119,0-.239.013-.357.026A4.3,4.3,0,0,0,3.139.2a4.419,4.419,0,0,0-3.1,4.066v.306c0,.13.014.259.028.388a5.429,5.429,0,0,0,.137.753A4.668,4.668,0,0,0,.7,6.924c.115.195.244.381.364.572L3.7,11.709c.05.079.1.163.152.236a.77.77,0,0,0,.845.26.7.7,0,0,0,.395-.288L7.733,7.706c.113-.18.224-.363.341-.541a4.235,4.235,0,0,0,.24-.4,5.18,5.18,0,0,0,.559-2.045,4.441,4.441,0,0,0,0-.642ZM6.343,7.16c-.031.022-.062.043-.094.063l-.039.025c-.036.023-.073.044-.11.065s-.075.042-.112.061l-.005,0-.11.054c-.039.018-.079.036-.119.052-.08.033-.162.063-.245.091s-.169.052-.255.073c-.024.006-.048.012-.072.017l-.045.01-.082.016L4.971,7.7l-.082.012h0c-.084.011-.168.018-.253.022l-.045,0H4.58l-.116,0h-.01l-.091,0c-.026,0-.051,0-.077,0s-.053,0-.079,0a3.369,3.369,0,0,1-.5-.076c-.054-.011-.106-.025-.159-.041L3.51,7.6l-.069-.021-.062-.021L3.318,7.54h0q-.088-.032-.174-.07l-.073-.032c-.048-.022-.1-.045-.142-.07l-.071-.038-.02-.011A3.335,3.335,0,1,1,6.343,7.16Z" transform="translate(1197.833 2025.664)" fill="#FBB633" />
                </g>
            </svg>
        )
    }
}
class PinnedIconBlue extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '1.8em', height: '1.8em' }} viewBox="0 0 8.84 12.243">
                <g id="Group_1452" data-name="Group 1452" transform="translate(-1197.877 -2025.664)">
                    <path id="Subtraction_1" data-name="Subtraction 1" d="M3.744,7.489A3.744,3.744,0,1,1,7.489,3.744,3.749,3.749,0,0,1,3.744,7.489ZM3.693,1.5A2.245,2.245,0,1,0,5.937,3.744,2.248,2.248,0,0,0,3.693,1.5Z" transform="translate(1198.553 2026.501)" fill="#2EB7D7" />
                    <path id="Path_1201" data-name="Path 1201" d="M8.87,4.082a4.317,4.317,0,0,0-.1-.667A4.412,4.412,0,0,0,4.594,0H4.33c-.119,0-.239.013-.357.026A4.3,4.3,0,0,0,3.139.2a4.419,4.419,0,0,0-3.1,4.066v.306c0,.13.014.259.028.388a5.429,5.429,0,0,0,.137.753A4.668,4.668,0,0,0,.7,6.924c.115.195.244.381.364.572L3.7,11.709c.05.079.1.163.152.236a.77.77,0,0,0,.845.26.7.7,0,0,0,.395-.288L7.733,7.706c.113-.18.224-.363.341-.541a4.235,4.235,0,0,0,.24-.4,5.18,5.18,0,0,0,.559-2.045,4.441,4.441,0,0,0,0-.642ZM6.343,7.16c-.031.022-.062.043-.094.063l-.039.025c-.036.023-.073.044-.11.065s-.075.042-.112.061l-.005,0-.11.054c-.039.018-.079.036-.119.052-.08.033-.162.063-.245.091s-.169.052-.255.073c-.024.006-.048.012-.072.017l-.045.01-.082.016L4.971,7.7l-.082.012h0c-.084.011-.168.018-.253.022l-.045,0H4.58l-.116,0h-.01l-.091,0c-.026,0-.051,0-.077,0s-.053,0-.079,0a3.369,3.369,0,0,1-.5-.076c-.054-.011-.106-.025-.159-.041L3.51,7.6l-.069-.021-.062-.021L3.318,7.54h0q-.088-.032-.174-.07l-.073-.032c-.048-.022-.1-.045-.142-.07l-.071-.038-.02-.011A3.335,3.335,0,1,1,6.343,7.16Z" transform="translate(1197.833 2025.664)" fill="#2EB7D7" />
                </g>
            </svg>
        )
    }
}

function AgentProfile(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [missions, setMissions] = useState([]);
    const [value, setValue] = React.useState('1');
    const [loading, setLoading] = useState(true);
    const [missionLoading, setmissionLoading] = useState(true);

    const handleChange = (newValue) => {
        setValue(newValue);
        setmissionLoading(true);

    };

    useEffect(() => {
        setLoading(true);
        setmissionLoading(true)
        if (params.get("agentId")) {
            dispatch(agentById(params.get("agentId"))).then((action) => {
                setData(action.payload);
                setLoading(false);
            });
        } else {
            dispatch(agentById(token.id)).then((action) => {
                setData(action.payload);
                setLoading(false);
            });
        }
    }, [dispatch, params.get("agentId")]);

    useEffect(() => {
        dispatch(getMarkersByAgentId({ id: (params.get("agentId") ? data.id : token.agentId), type: (value == '1' ? 'missions' : 'markers') })).then((action) => {
            console.log("MissionDetailContent", action.payload)
            setMissions(action.payload)
            setmissionLoading(false)
        })
    }, [dispatch, data, value]);


    const renderSkeletonLoaders = (count) => {
        const skeletonLoaders = [];
        for (let i = 0; i < count; i++) {
            skeletonLoaders.push(
                <Stack key={i} direction={'column'} gap={{ lg: 3, md: 1.5, sm: 1.5, xs: 1.5 }} className="p-0 pt-24 w-full">
                    <SkeletonLoader />
                </Stack>
            );
        }
        return skeletonLoaders;
    };

    return (
        <>
            <GlobalStyles
                styles={(theme) => ({
                    "#fuse-main": {
                        height: "100% !important",
                    },
                    " .FusePageSimple-sidebar, .FusePageSimple-sidebarHeader":
                    {
                        background: "white !important",
                    },
                    " .absolute-important":
                    {
                        position: "absolute !important",
                    },
                    " div.FusePageSimple-sidebarHeader.permanent": {
                        minHeight: 0,
                        height: "unset"
                    }
                })}
            />

            <Root

                content={
                    <>
                        <Root
                            content={
                                <FuseScrollbars className="h-full max-h-screen-content-100 overflow-auto overscroll-auto">
                                    <>
                                        {loading ? (
                                            <Skeleton variant="rectangular" height={260} />
                                        ) : (
                                            <Box className="relative">
                                                <div
                                                    className='relative rounded-none overflow-hidden m-auto !shadow-0 bg-pi-light-grey'
                                                    style={{ width: "100%", height: "260px" }}
                                                >
                                                    {data.length != 0 && (
                                                        data.bannerUrl ? (
                                                            <img className="max-w-none w-full h-full object-cover" src={data.bannerUrl} alt="Cover Photo" />
                                                        ) : (
                                                            <img className="max-w-none w-full h-full object-cover" src="assets/images/backgrounds/default-cover.png" alt="Cover Photo" />

                                                        )
                                                    )}
                                                </div>
                                            </Box>
                                        )}
                                        <Box className="w-full max1200px:w-md md:w-10/12 sm:w-full p-16 mt-16 m-auto mb-32">

                                            {loading ? (
                                                <Card className="mx-16 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                                                    <AgentSkeletonLoader />
                                                </Card >
                                            ) : (
                                                <Card className="mx-16 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                                                    <React.Fragment>
                                                        <CardContent className="!p-16">
                                                            <Stack direction={'row'} justifyContent={'space-between'}>
                                                                <Stack>
                                                                    <div>
                                                                        {data.length != 0 && (
                                                                            data.profileUrl ? (
                                                                                <Avatar sx={{ width: 120, height: 120 }} alt="user photo" src={data.profileUrl} className=" bg-pi-light-grey" />
                                                                            ) : (
                                                                                <Avatar style={{ width: 120, height: 120, fontSize: '42px' }} {...stringAvatar(data.name)} />
                                                                            )
                                                                        )}
                                                                    </div>
                                                                    <Typography className=" text-24 font-extrabold mt-8"> {`${data.firstName || ''} ${data.middleName ? data.middleName + ' ' : ''} ${data.lastName || ''}`}</Typography>
                                                                    <Typography className=" text-16 font-bold text-pi-grey">@{data.username} <span className=" font-normal text-15">{data.agentFollowersCount} followers</span></Typography>
                                                                </Stack>
                                                                <Stack direction={'row'}>

                                                                    {data.length !== 0 && token.id !== data.userId ? (
                                                                        <AgentFollowUnfollow className=' h-fit' item={data} module='agent-profile' />
                                                                    ) : (
                                                                        <>
                                                                            <PopupState variant="popover" popupId="demo-popup-popover">
                                                                                {(popupState) => (
                                                                                    <>
                                                                                        <IconButton  {...bindTrigger(popupState)} sx={{ height: '40px', bgcolor: 'background.paper', }} className=' p-6 ml-6' edge={false}>
                                                                                            <Icon className=' text-28'>settings</Icon>
                                                                                        </IconButton>
                                                                                        <Popover
                                                                                            {...bindPopover(popupState)}
                                                                                            anchorOrigin={{
                                                                                                vertical: 'bottom',
                                                                                                horizontal: 'center',
                                                                                            }}
                                                                                            transformOrigin={{
                                                                                                vertical: 'top',
                                                                                                horizontal: 'center',
                                                                                            }}
                                                                                        >
                                                                                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', }}>
                                                                                                <ListItemButton component={Link} to={'/agentprofile/edit'}>
                                                                                                    Edit Profile
                                                                                                </ListItemButton>
                                                                                                <ListItemButton component={Link} to={'/agentprofile/account'}>
                                                                                                    Edit Account
                                                                                                </ListItemButton>
                                                                                            </List>
                                                                                        </Popover>
                                                                                    </>
                                                                                )}
                                                                            </PopupState>
                                                                        </>
                                                                    )}
                                                                </Stack>
                                                            </Stack>
                                                            <Typography className=" text-16 my-16" gutterBottom>{data.about}</Typography>
                                                            <Stack direction={'row'} gap={2} className=" w-full mt-16" justifyContent={'space-between'}>
                                                                <Box
                                                                    className="px-10 py-3 font-bold rounded-md bg-default"
                                                                    sx={{
                                                                        width: '100%',
                                                                        border: (theme) => `1px solid rgba(240,240,240)`,
                                                                    }}
                                                                >
                                                                    <Typography className=" text-14 font-bold text-pi-grey">Total Reach</Typography>
                                                                    <Typography className=" text-24 font-extrabold">{data.getReach?.missions + data.getReach?.markers || ''}</Typography>
                                                                </Box>
                                                                <Box
                                                                    className="px-10 py-3 font-bold rounded-md bg-default"
                                                                    sx={{
                                                                        width: '100%',
                                                                        border: (theme) => `1px solid rgba(240,240,240)`,
                                                                    }}
                                                                >
                                                                    <Typography className=" text-14 font-bold text-pi-grey">Mission Reach</Typography>
                                                                    <Typography className=" text-24 font-extrabold">{data.getReach?.missions}</Typography>
                                                                </Box>
                                                                <Box
                                                                    className="px-10 py-3 font-bold rounded-md bg-default"
                                                                    sx={{
                                                                        width: '100%',
                                                                        border: (theme) => `1px solid rgba(240,240,240)`,
                                                                    }}
                                                                >
                                                                    <Typography className=" text-14 font-bold text-pi-grey">Colab Reach</Typography>
                                                                    <Typography className=" text-24 font-extrabold">{data.getReach?.markers}</Typography>
                                                                </Box>

                                                            </Stack>

                                                        </CardContent>

                                                    </React.Fragment>
                                                </Card >
                                            )}

                                            <Box className="mx-16 mt-24">
                                                <TabsUnstyled value={value} onChange={(e, newValue) => handleChange(newValue)} defaultValue={value}>
                                                    <TabsList className=' mb-0 max-600px:rounded-none'>
                                                        <Tab value="1" onClick={() => handleChange('1')} className=' text-16 text-bold rounded-md m-16'>Created Mission</Tab>
                                                        <Tab value="2" onClick={() => handleChange('2')} className=' text-16 text-bold rounded-md m-16'>Joined Mission</Tab>
                                                    </TabsList>
                                                    <TabPanel value="1">
                                                        {missionLoading ? (
                                                            renderSkeletonLoaders(3)
                                                        ) : (
                                                            <>
                                                                {missions.length !== 0 ? (
                                                                    missions.map((item) => (
                                                                        <React.Fragment key={item.id}>
                                                                            <Card className="p-16 pb-8 rounded-lg mb-18 mt-24 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0} key={item.id}>
                                                                                <CardHeader
                                                                                    className="p-0"
                                                                                    sx={{
                                                                                        "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                                                                        "& .MuiAvatar-root": { border: '2.5px solid #FBB633' },
                                                                                    }}
                                                                                    avatar={
                                                                                        <Badge
                                                                                            overlap="circular"
                                                                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                                            sx={{
                                                                                                '& .MuiBadge-anchorOriginBottomRightCircular': {
                                                                                                    borderRadius: '50%',
                                                                                                    backgroundColor: 'white',
                                                                                                    padding: '0px',
                                                                                                    paddingTop: '6px',
                                                                                                    bottom: '10px',
                                                                                                    minWidth: '20px',
                                                                                                    height: '20px'
                                                                                                }
                                                                                            }}
                                                                                            badgeContent={
                                                                                                <span
                                                                                                >
                                                                                                    <PinnedIcon style={{ fill: '#FBB633' }} size={'small'} />
                                                                                                </span>
                                                                                            }
                                                                                        >
                                                                                            <AttachmentSvs module={"at-6"} path={item.agent?.profilePicPath} data={item.agent} />

                                                                                        </Badge>
                                                                                    }
                                                                                    title={
                                                                                        <Typography className=" font-extrabold text-18">
                                                                                            {item.agent.flName}
                                                                                        </Typography>
                                                                                    }
                                                                                    subheader={
                                                                                        <Typography className=" line-clamp-1 text-14 text-pi-grey">
                                                                                            {format(new Date(item.dateCreated), 'MM/dd/yyyy hh:mm a')}
                                                                                        </Typography>
                                                                                    }
                                                                                    action={
                                                                                        ""
                                                                                    }
                                                                                />
                                                                                <CardContent style={{ cursor: 'pointer' }} onClick={() => navigate('/public/mission-details/' + item.missionId)} className="p-0">
                                                                                    <Typography
                                                                                        component="div"
                                                                                        className="font-bold mt-5 text-18 mb-0 text-pi-black line-clamp-2"
                                                                                    >
                                                                                        {item.title}
                                                                                    </Typography>
                                                                                    <Box className="flex items-end justify-start mb-6 toggleReadmore relative">
                                                                                        <Typography
                                                                                            className="text-16  transition duration-300 line-clamp-3 mb-3 paragraph contents"
                                                                                        >
                                                                                            {item.description}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <PhotoGridView fileId={item.filesId} />
                                                                                </CardContent>

                                                                                <CardActions className="flex-col pt-8 pb-0  px-0">
                                                                                    <Stack direction="row" gap={0} className="w-full" justifyContent={'space-between'}>
                                                                                        <Typography className=" gap-3 flex items-center ">
                                                                                            <Icon size="small" className="  text-pi-grey text-19">
                                                                                                <PiMapPinLineBold />
                                                                                            </Icon>
                                                                                            {item.missionPinned ? item.missionPinned : '0'} <span className="sm:flex hidden ml-8">Pinned</span>
                                                                                        </Typography>
                                                                                        <Typography className=" gap-3 flex items-center">
                                                                                            <Icon size="small" className=" text-pi-grey text-19">
                                                                                                visibility
                                                                                            </Icon>
                                                                                            {item.missionViews ? item.missionViews : '0'} <span className="sm:flex hidden ml-8">Views</span>
                                                                                        </Typography>
                                                                                        <Typography className=" gap-3 flex items-center">
                                                                                            <Icon size="small" className=" text-pi-grey text-19">
                                                                                                mode_comment
                                                                                            </Icon>
                                                                                            {item.missionCommentsCount ? item.missionCommentsCount : '0'} <span className="sm:flex hidden ml-8"> Comments</span>
                                                                                        </Typography>
                                                                                        <Box className=" text-right">
                                                                                            <SharerSvs link={`/public/mission-details/${item.missionId}`} title={item.title} description={item.description} />
                                                                                        </Box>
                                                                                    </Stack>
                                                                                </CardActions>
                                                                            </Card>
                                                                        </React.Fragment>
                                                                    ))
                                                                ) : (
                                                                    <Card className="p-16 pb-8 rounded-lg mb-18 mt-24 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                                                                        <CardContent className="p-0">
                                                                            <Typography
                                                                                component="div"
                                                                                className="font-bold mt-5 text-18 mb-0 text-pi-black line-clamp-2"
                                                                            >
                                                                                There are no created missions available
                                                                            </Typography>
                                                                        </CardContent>
                                                                    </Card>
                                                                )}
                                                            </>
                                                        )}



                                                    </TabPanel>
                                                    <TabPanel value="2">
                                                        {missionLoading ? (
                                                            renderSkeletonLoaders(3)
                                                        ) : (

                                                            missions.length !== 0 ? (
                                                                missions.map((item) => (
                                                                    <Card className="p-16 pb-8 rounded-lg mb-18 mt-24 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0} key={item.id}>
                                                                        <CardHeader
                                                                            className="p-0"
                                                                            sx={{
                                                                                "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                                                                "& .MuiAvatar-root": { border: '2.5px solid #2EB7D7' },
                                                                            }}
                                                                            avatar={
                                                                                <Badge
                                                                                    overlap="circular"
                                                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                                    sx={{
                                                                                        '& .MuiBadge-anchorOriginBottomRightCircular': {
                                                                                            borderRadius: '50%',
                                                                                            backgroundColor: 'white',
                                                                                            padding: '0px',
                                                                                            paddingTop: '6px',
                                                                                            bottom: '10px',
                                                                                            minWidth: '20px',
                                                                                            height: '20px'
                                                                                        }
                                                                                    }}
                                                                                    badgeContent={
                                                                                        <span
                                                                                        >
                                                                                            <PinnedIconBlue style={{ fill: '#FBB633' }} size={'small'} />
                                                                                        </span>
                                                                                    }
                                                                                >
                                                                                    <AttachmentSvs module={"at-6"} path={item.agent?.profilePicPath} data={item.agent} />

                                                                                </Badge>
                                                                            }
                                                                            title={
                                                                                <Typography className=" font-extrabold text-18">
                                                                                    {item.agent.flName}
                                                                                </Typography>
                                                                            }
                                                                            subheader={
                                                                                <Typography className=" line-clamp-1 text-14 text-pi-grey">
                                                                                    {format(new Date(item.dateCreated), 'MM/dd/yyyy hh:mm a')}
                                                                                </Typography>
                                                                            }
                                                                            action={
                                                                                ""
                                                                            }
                                                                        />
                                                                        <CardContent style={{ cursor: 'pointer' }} onClick={() => navigate('/public/mission-details/' + item.missionId)} className="p-0">
                                                                            <Typography
                                                                                component="div"
                                                                                className="font-bold mt-5 text-18 mb-0 text-pi-black line-clamp-2"
                                                                            >
                                                                                {item.title}
                                                                            </Typography>
                                                                            <Box className="flex items-end justify-start mb-6 toggleReadmore relative">
                                                                                <Typography
                                                                                    className="text-16  transition duration-300 line-clamp-3 mb-3 paragraph contents"
                                                                                >
                                                                                    {item.description}
                                                                                </Typography>
                                                                            </Box>
                                                                            <PhotoGridView fileId={item.filesId} />
                                                                        </CardContent>

                                                                        <CardActions className="flex-col pt-8 pb-0  px-0">
                                                                            <Stack direction="row" gap={0} className="w-full" justifyContent={'space-between'}>
                                                                                <Typography className=" gap-3 flex items-center ">
                                                                                    <Icon size="small" className="  text-pi-grey text-19">
                                                                                        <PiMapPinLineBold />
                                                                                    </Icon>
                                                                                    {item.missionPinned ? item.missionPinned : '0'} <span className="sm:flex hidden ml-8">Pinned</span>
                                                                                </Typography>
                                                                                <Typography className=" gap-3 flex items-center">
                                                                                    <Icon size="small" className=" text-pi-grey text-19">
                                                                                        visibility
                                                                                    </Icon>
                                                                                    {item.missionViews ? item.missionViews : '0'} <span className="sm:flex hidden ml-8">Views</span>
                                                                                </Typography>
                                                                                <Typography className=" gap-3 flex items-center">
                                                                                    <Icon size="small" className=" text-pi-grey text-19">
                                                                                        mode_comment
                                                                                    </Icon>
                                                                                    {item.missionCommentsCount ? item.missionCommentsCount : '0'} <span className="sm:flex hidden ml-8"> Comments</span>
                                                                                </Typography>
                                                                                <Box className=" text-right">
                                                                                    <SharerSvs link={`/public/mission-details/${item.missionId}`} title={item.title} description={item.description} />
                                                                                </Box>
                                                                            </Stack>
                                                                        </CardActions>
                                                                    </Card>
                                                                ))) : (
                                                                <Card className="p-16 pb-8 rounded-lg mb-18 mt-24 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                                                                    <CardContent className="p-0">
                                                                        <Typography
                                                                            component="div"
                                                                            className="font-bold mt-5 text-18 mb-0 text-pi-black line-clamp-2"
                                                                        >
                                                                            There are no joined missions available
                                                                        </Typography>
                                                                    </CardContent>
                                                                </Card>
                                                            )

                                                        )}
                                                    </TabPanel>
                                                </TabsUnstyled>
                                            </Box>


                                        </Box >
                                    </>
                                </FuseScrollbars >
                            }
                        />
                    </>
                }

            />
        </>
    );
}

export default withReducer('agentApp', reducer)(AgentProfile);