import React, { useState } from 'react';

const TextLimit = ({ text, limit }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className=' font-500'>
            {isExpanded ? (
                <div>
                    {text}
                    <button onClick={toggleExpansion} className=' text-pi-blue-1 hover:underline'>&nbsp; See Less</button>
                </div>
            ) : (
                <div >
                    {text.slice(0, limit)}
                    {text.length > limit && (
                        <>{'... '}
                            <button onClick={toggleExpansion} className=' text-pi-blue-1 hover:underline'>See More</button>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default TextLimit;