import React, { useState, useRef, useEffect } from "react";
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { Notifications, NotificationsOff } from "@mui/icons-material";
import { joinedMission } from "app/main/apps/public/mission/store/missionSlice";
const token = AgentJwtService.getDecodedAccessToken();
const AgentJoinMission = (props) => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState(true);
    const [notif, setNotif] = useState('true');
    const [hideButton, setHideButton] = useState(true);
    const { reloadData } = props

    async function handleJoinMission() {
        const data = {
            mission_id: props.id,
            agent: props.agent,
            lat: props.item[0].lat,
            lng: props.item[0].lng,
        };
        dispatch(joinedMission(data)).then(function (action) {
            reloadData()
        },);
    }

    return (
        <>
            <Button
                onClick={handleJoinMission}
                size="large"
                variant="contained"
                color="secondary"
                className="px-24 py-8 text-16 font-bold max-w-max rounded-md bg-pi-blue-1  hover:bg-pi-blue-1 text-white"
            >
                Join Mission
            </Button>
        </>
    );
};

export default AgentJoinMission;