import * as React from 'react';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import { ThemeProvider } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { showMessage } from "app/store/fuse/messageSlice";
import { confirmDelete } from 'app/services/swalService';
import { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { inputLabelClasses } from "@mui/material/InputLabel";
import { FiSearch, FiBell } from "react-icons/fi";
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { IconButton, List, ListItem, ListItemButton, ListItemText, Popover, Stack } from '@mui/material';

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    width: "100%",
    "& .MuiFilledInput-root": {
        borderRadius: "999px",
        backgroundColor: "#FAFAFB",
        border: "1px solid #E9E9E9",
        height: 40,
        width: '100%',
        minWidth: '0px',
        fontSize: '16px',
        paddingLeft: '4px',
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
    "& .MuiFilledInput-input": {
        paddingTop: "8px"
    },
    "& input#mui-1::placeholder": {
        color: "#8B8B8B",
        opacity: 'unset',
        fontWeight: 'semi-bold',
        fontSize: '16px'


    },
}));
function PublicMissionManagerListFilter(props) {
    const mainTheme = useSelector(selectMainTheme);
    const { setKeyword, setType } = props;

    return (
        <div className="flex flex-1 w-full items-center justify-evenly min-w-max">
            <div className="flex flex-1 items-center justify-start px-0">
                <ThemeProvider theme={mainTheme}>
                    <Stack direction="row" className='w-full'>
                        <TextFieldStyled
                            placeholder="Search..."
                            variant="filled"
                            className=' w-full'
                            InputLabelProps={{
                                sx: {
                                    color: "#48525C",
                                    fontSize: { xl: '18px', lg: '16px', md: '14px' },
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        fontSize: '14px',
                                        color: "#141F2C"
                                    }
                                }
                            }}
                            type="text"
                            onChange={(e) => setKeyword(e.target.value)}
                        />
                        <PopupState variant="popover" popupId="demo-popup-popover">
                            {(popupState) => (
                                <>
                                    <IconButton {...bindTrigger(popupState)} className=' p-6 ml-6' edge={false}>
                                        <Icon className=' text-28'>filter_list</Icon>
                                    </IconButton>
                                    <Popover
                                        {...bindPopover(popupState)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', }}>
                                            <ListItemButton onClick={() => { setType("missions"); popupState.close(); }}>
                                                Created Missions
                                            </ListItemButton>
                                            <ListItemButton onClick={() => { setType("markers"); popupState.close(); }}>
                                                Accepted Missions
                                            </ListItemButton>
                                        </List>
                                    </Popover>
                                </>
                            )}
                        </PopupState>
                    </Stack>
                </ThemeProvider>
            </div>
        </div>
    );
}

export default PublicMissionManagerListFilter;
