import {
	Card,
	CardContent,
	Stack,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	Avatar,
	Badge,
	CardHeader,
	IconButton,
	Container,
	Button,
	Divider,
	Icon,
	Tab,
	TextField,
	InputAdornment,
	Dialog,
	DialogTitle,
	DialogContent,
} from "@mui/material";
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { useState, useRef, useEffect, useMemo } from "react";
import { CardFooter } from "reactstrap";
import { styled } from "@mui/material/styles";
import { MoreVertIcon, PushPin, Share } from "@mui/icons-material";

// lazy loading
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { height } from "@mui/system";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import {
	faComments,
	faEye,
	faLocationDot,
} from "@fortawesome/free-regular-svg-icons";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Tab1 from "./tabs/Tab1";
import Tab2 from "./tabs/Tab2";
import Tab3 from "./tabs/Tab3";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import {
	createMission,
	getSingleMissionById,
	joinedMission,
} from "../store/missionSlice"; //from store
import { useNavigate } from "react-router-dom";

import {
	BrowserRouter as Router,
	Link,
	Route,
	Routes,
	useParams,
} from "react-router-dom";
import { FaRegCommentAlt, FaRegEye, FaMapMarkerAlt } from "react-icons/fa";
const Root = styled(Dialog)(({ theme }) => ({
	maxHeight: "unset !important",
	'& .MuiDialog-paperScrollPaper': {
		maxHeight: "630px !important",
		height: "80vh !important",
		borderRadius: "8px !important",
		width: "100%",
		overflowY: "hidden !important",
		margin: '0px 10px !important',

		[theme.breakpoints.down('sm')]: {
			margin: '0px 10px',

		},

	},

	'& .h-screen-full-modal': {
		[theme.breakpoints.down('sm')]: {
			height: "calc(100vh - 20rem)"
		},
	}


}));
import * as React from "react";
import MissionDetailsJoinMissionModal from "./modal/MissionDetailsJoinMissionModal";
import AgentJoinMission from "app/services/maps/AgentJoinMission";
import { getAllPublicUploads } from "app/services/store/PublicUploadsSlice";
import AttachmentContainer from "app/services/AttachmentContainer";

const CardStyled = styled(Card)(
	({ theme }) => `
	margin: ${theme.spacing(1)};
	padding: ${theme.spacing(2)};
	// border: 1px solid black;
	box-shadow: 0 0 20px #e5e5e5;
  `
);
const SmallAvatar = styled(Avatar)(({ theme }) => ({
	width: 34,
	height: 34,
	// border: `2px solid ${theme.palette.background.paper}`,
}));
const BigAvatar = styled(Avatar)(({ theme }) => ({
	width: 56,
	height: 56,
	// border: `2px solid ${theme.palette.background.paper}`,
}));
const BackgroundAvatar = styled(Avatar)(({ theme }) => ({
	// width: 40,
	borderRadius: "unset",
	width: 60,
	height: 60,
	"& img": {
		objectFit: "contain",
	},
	// height: 40,
	// border: `2px solid ${theme.palette.background.paper}`,
}));

function stringToColor(string) {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = "#";

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.substr(-2);
	}
	/* eslint-enable no-bitwise */

	return color;
}

function stringAvatar(name) {
	var username = name.split(" "),
		firstName = username[0].split(" ")[0][0],
		lastName = username[username.length - 1].split(" ")[0][0];
	if (firstName == lastName) {
		return {
			sx: {
				bgcolor: stringToColor(name),
			},

			children: `${firstName}`,
		};
	} else {
		return {
			sx: {
				bgcolor: stringToColor(name),
			},

			children: `${firstName}${lastName}`,
		};
	}
}
class CommentIcon extends React.Component {
	render() {
		return (
			<svg id="MDI_comment-outline" data-name="MDI / comment-outline" xmlns="http://www.w3.org/2000/svg" className=" h-auto w-28" viewBox="0 0 24 24">
				<g id="Boundary" transform="translate(0 0)" fill="#8b8b8b" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
					<rect width="24" height="24" stroke="none" />
					<rect x="0.5" y="0.5" width="23" height="23" fill="none" />
				</g>
				<path id="Path_comment-outline" data-name="Path / comment-outline" d="M9,22a1,1,0,0,1-1-1V18H4a2,2,0,0,1-2-2V4A2,2,0,0,1,4,2H20a2,2,0,0,1,2,2V16a2,2,0,0,1-2,2H13.9l-3.7,3.71a1.019,1.019,0,0,1-.7.29H9m1-6v3.08L13.08,16H20V4H4V16Z" transform="translate(0.479 -0.085)" fill="#8b8b8b" />
			</svg>

		)
	}
}
class ViewsIcon extends React.Component {
	render() {
		return (
			<svg id="MDI_eye-outline" data-name="MDI / eye-outline" xmlns="http://www.w3.org/2000/svg" className=" h-auto w-28" viewBox="0 0 24 24">
				<g id="Boundary" transform="translate(0 0)" fill="#8b8b8b" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
					<rect width="24" height="24" stroke="none" />
					<rect x="0.5" y="0.5" width="23" height="23" fill="none" />
				</g>
				<path id="Path_eye-outline" data-name="Path / eye-outline" d="M12,9a3,3,0,1,1-3,3,3,3,0,0,1,3-3m0-4.5A11.827,11.827,0,0,1,23,12,11.817,11.817,0,0,1,1,12,11.827,11.827,0,0,1,12,4.5M3.18,12a9.822,9.822,0,0,0,17.64,0A9.822,9.822,0,0,0,3.18,12Z" transform="translate(0.479 0.415)" fill="#8b8b8b" />
			</svg>


		)
	}
}
class PinnedIcon extends React.Component {
	render() {
		return (
			<svg id="MDI_map-marker-radius-outline" data-name="MDI / map-marker-radius-outline" className=" h-auto w-28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
				<g id="Boundary" transform="translate(0 0)" fill="#8b8b8b" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
					<rect width="24" height="24" stroke="none" />
					<rect x="0.5" y="0.5" width="23" height="23" fill="none" />
				</g>
				<path id="Path_map-marker-radius-outline" data-name="Path / map-marker-radius-outline" d="M12.827,4.207A4.426,4.426,0,0,1,17.24,8.62c0,2.317-2.317,6.069-4.413,8.717-2.1-2.758-4.413-6.4-4.413-8.717a4.426,4.426,0,0,1,4.413-4.413m0-2.207a6.64,6.64,0,0,0-6.62,6.62c0,4.965,6.62,12.137,6.62,12.137s6.62-7.282,6.62-12.137A6.64,6.64,0,0,0,12.827,2m0,4.413A2.207,2.207,0,1,0,15.034,8.62a2.213,2.213,0,0,0-2.207-2.207m8.827,14.344c0,2.427-3.972,4.413-8.827,4.413S4,23.185,4,20.757c0-1.434,1.324-2.648,3.42-3.531l.662.993c-1.1.552-1.876,1.214-1.876,1.986,0,1.545,2.979,2.758,6.62,2.758s6.62-1.214,6.62-2.758c0-.772-.772-1.434-1.986-1.986l.662-.993C20.33,18.109,21.654,19.323,21.654,20.757Z" transform="translate(-0.348 -1.17)" fill="#8b8b8b" />
			</svg>


		)
	}
}
class ButtonPinnedIcon extends React.Component {
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
				<g fill="#141f2c" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
					<rect width="24" height="24" stroke="none" />
					<rect x="0.5" y="0.5" width="23" height="23" fill="none" />
				</g>
				<path d="M11.5,9a2.5,2.5,0,0,1,4.87-.81A2.746,2.746,0,0,1,16.5,9a2.5,2.5,0,0,1-4.86.84A2.6,2.6,0,0,1,11.5,9M5,9c0,4.5,5.08,10.66,6,11.81L10,22S3,14.25,3,9A7.005,7.005,0,0,1,8,2.29,8.982,8.982,0,0,0,5,9m9-7a7,7,0,0,1,7,7c0,5.25-7,13-7,13S7,14.25,7,9a7,7,0,0,1,7-7m0,2A5,5,0,0,0,9,9c0,1,0,3,5,9.71C19,12,19,10,19,9A5,5,0,0,0,14,4Z" fill="#ffff" />
			</svg>


		)
	}
}
export default function MissionDetailsContent(props) {

	const token = AgentJwtService.getDecodedAccessToken();
	const dispatch = useDispatch();
	const [hideJoinMission, setHideJoinMission] = useState(true);
	const [followed, setfollowed] = useState(false);
	const { onClose, open, data, comments, pinned, missionUrl } = props;

	useEffect(() => {
		data.markers.filter(agent => agent.agent == token.id).map((agent) => {
			if (agent.agent == token.id) {
				setHideJoinMission(false)
			} else {
				setHideJoinMission(true)
			}
		})
	}, []);

	const handleClose = () => {
		onClose();
	};

	const [valueTabs, setValue] = useState("1");
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	function getTimeAgo(dateTime) {
		const customDate = new Date(dateTime);
		const currentDate = new Date();
		const timeDifference = currentDate - customDate;
		const seconds = Math.floor(timeDifference / 1000);
		const minutes = Math.floor(seconds / 60);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);
		const weeks = Math.floor(days / 7);

		if (weeks > 0) {
			return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
		} else if (days > 0) {
			return `${days} day${days > 1 ? 's' : ''} ago`;
		} else if (hours > 0) {
			return `${hours} hour${hours > 1 ? 's' : ''} ago`;
		} else if (minutes > 0) {
			return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
		} else {
			return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
		}
	}

	return (
		<Root maxWidth={'md'} onClose={handleClose} open={open} className="!p-0 !overflow-hidden w-full">
			<Stack direction={'row'} className=" bg-default !h-full">
				<Container className=" !p-16 scroll-auto overflow-auto !h-full" style={{ maxWidth: "260px", width: '19vw', minWidth: '228px' }}>
					<Box style={{ width: '100%', maxHeight: '185px', height: "10.9375vw", minHeight: '140px' }} className=" border-pi-default border-2 rounded-lg !relative overflow-hidden">
						<div style={{ maxWidth: "100%", height: '100%', }} className=" p-3 border-opacity-50 rounded-md bg-white">
							<img
								className="max-w-none w-full h-full object-contain p-0"
								src={'assets/images/pi-images/pi-map-bg.svg'}
								// alt={n.name}
								loading="lazy"
							/>
						</div>
						<Badge
							className=" !absolute "
							style={{ left: "calc(50% - 35px)", bottom: "calc(50% - 50px)" }}
							overlap="circular"
							sx={{
								display: "unset",
								"& .MuiBadge-anchorOriginTopRightCircular": {
									top: "26px",
									right: "unset",
									left: "8px",
									padding: 0,
									transform: "unset",
								},
							}}
							badgeContent={
								<BigAvatar
									{...stringAvatar(`Designer101`)}
									className=" uppercase text-16"
								// sx={{ width: '45px !important', height: '45px !important' }}

								/>
							}
						>
							<BackgroundAvatar
								className=" !w-72 !h-auto"
								alt="Remy Sharp"
								src="assets/images/pin-background.webp"
								loading="lazy"
							/>
						</Badge>
					</Box>
					<Typography variant="h5" className=" !text-24 !font-extrabold  text-center !mt-10 !text-pi-black">AgentKingPin</Typography>
					<Typography variant="h5" className=" !text-14  !text-pi-grey text-center">Created Mission</Typography>
					<Divider className="!my-16" />
					{/* views comments pinned */}
					<Stack gap={2} >
						<Stack direction={'row'} alignItems={'center'} gap={2}>
							<ViewsIcon />
							<Stack>
								<Typography className="!text-24 !font-extrabold !text-pi-black">{data.views}</Typography>
								<Typography className=" !text-16 !-mt-10">Views</Typography>
							</Stack>
						</Stack>
						<Stack direction={'row'} alignItems={'center'} gap={2}>
							<CommentIcon />
							<Stack>
								<Typography className="!text-24 !font-extrabold !text-pi-black">{comments}</Typography>
								<Typography className=" !text-16 !-mt-10">Comments</Typography>
							</Stack>
						</Stack>
						<Stack direction={'row'} alignItems={'center'} gap={2}>
							<PinnedIcon />
							<Stack>
								<Typography className="!text-24 !font-extrabold !text-pi-black">{pinned}</Typography>
								<Typography className=" !text-16 !-mt-10">Pinned</Typography>
							</Stack>
						</Stack>
					</Stack>
					<Box className="text-center" sx={{
						'& .MuiButton-root': {
							justifyContent: "space-between",
							textWrap: "nowrap",
							minWidth: "fit-content"
						}
					}}>
						<Divider className="!my-16" />
						<Button variant="outlined" className="custom-text w-full !text-16 !font-nunito !text-pi-blue-1 !border-pi-blue-1" startIcon={<Share />}> <span className=" !m-auto"> Share this mission</span></Button>

						{hideJoinMission &&
							<>
								<Typography className=" !text-14 !font-nunito">or</Typography>
								<Button variant="outlined" className="custom-text w-full !text-16 !font-nunito !text-pi-blue-1 !border-pi-blue-1" startIcon={<Share />}> <span className=" !m-auto"> Join Mission</span></Button>

							</>
						}
					</Box>
				</Container>
				<Stack direction={'column'} className="bg-white shadow-t-lg w-full">
					{/* ?Full Title */}
					<div className=" text-right z-10">
						<IconButton onClick={handleClose} size="large" edge={false}><Icon size="large">close</Icon></IconButton>
					</div>
					<Container className=" sticky !-mt-16">
						<Typography variant="h5" className=" !text-24 !font-bold !text-pi-dark-blue">{data.title}</Typography>
						<Typography variant="h5" className=" !text-14  !text-pi-grey">Created mission - {getTimeAgo(data.dateCreated)}</Typography>
						<Divider className=" !mt-10" />
					</Container>
					<Container className="  scroll-auto overflow-auto h-screen-full-modal py-10 !pr-16 !min-h-0" >
						<Typography className=" !text-16 !text-pi-secondary">
							{data.description}
						</Typography>
						{missionUrl && (
							<>
								{<AttachmentContainer id={missionUrl} />}
							</>
						)}
						<TabContext value={valueTabs} >
							<TabList
								className="mt-16"
								textColor="secondary"
								onChange={handleChange}
								aria-label="lab API tabs example"
								sx={{
									"& .MuiTabs-flexContainer": {
										justifyContent: "space-between",
									},
								}}
								TabIndicatorProps={{
									title: "indicator",
									hidden: true,
								}}
							>
								<Tab
									label="Comments"
									value="1"
									className={valueTabs == "1" ? " tracking-normal !text-14 uppercase !bg-default !text-pi-black !rounded-full !font-bold" : " tracking-normal !rounded-full !text-14 uppercase !font-bold !opacity-60"}
								/>
								<Tab
									label="Updates"
									value="2"
									className={valueTabs == "2" ? " tracking-normal !text-14 uppercase !bg-default !text-pi-black !rounded-full !font-bold" : " tracking-normal !rounded-full !text-14 uppercase !font-bold !opacity-60"}
								/>
								<Tab
									label="Community"
									value="3"
									className={valueTabs == "3" ? " tracking-normal !text-14 uppercase !bg-default !text-pi-black !rounded-full !font-bold" : " tracking-normal !rounded-full !text-14 uppercase !font-bold !opacity-60"}
								/>
							</TabList>
							{/* </Box> */}
							<TabPanel value="1" className="!px-0">
								<Tab1 mission_id={data.id} data={data} />
							</TabPanel>

							<TabPanel value="2" className="!px-0>">
								{/* <Tab2 /> */}
							</TabPanel>

							<TabPanel value="3" className="!px-0">
								{/* <Tab3 /> */}
							</TabPanel>
						</TabContext>

					</Container>
				</Stack>
			</Stack>
		</Root>
	);
}
