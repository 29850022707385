import {gql} from "@apollo/client";

const login = gql`
  mutation getToken($username:String!, $password:String!){
    tokenAuth(username:$username,password:$password){
      token
      version
      payload
      refreshToken
      refreshExpiresIn
    }
  } 
`;

const mutation = {
  forgotPassword: `
      mutation forgotPassword($data: JSONString!){
          forgotPassword(data: $data) {
              res,
              message,
              status
          }
      }
  `,
};

const query = {
  checkLoginEmail: `
       query checkLoginEmail($email: String, $userid: String, $ecommerce: Int, $crm: Int) {
           checkLoginEmail(email: $email, userid: $userid, ecommerce: $ecommerce, crm: $crm) {
              id
              email
              username
          }
      }
  `,
};

export { login, mutation, query };