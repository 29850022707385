import * as React from "react";
import JwtService from "app/services/jwtService";
import AgentJwtService from 'app/services/agent/AgentJwtService';
import CONFIG from "app/config";
import { isDuplicatedWindow } from "./AppDuplicateTab";

const AppAutoLogout = ({ children }) => {

    React.useEffect(() => {

        console.log("isDuplicatedWindow", isDuplicatedWindow((15 * 1000), (10 * 1000), "cmi-oss-system-intermed-llc"));

        const handleTabClose = (ev) => {
            if (CONFIG.env != 'dev') {
                ev.preventDefault();

                if (isDuplicatedWindow((15 * 1000), (10 * 1000), "cmi-oss-system-intermed-llc")) {
                    JwtService.setJwtLoginReload(false);
                    AgentJwtService.setJwtLoginReload(false);
                }

                if (JwtService.getJwtLoginReload() == 'true') {
                    JwtService.logout();
                }

                if (AgentJwtService.getJwtLoginReload() == 'true') {
                    AgentJwtService.logout();
                }

                if (['/agentlogin'].includes(window.location.pathname)) {
                    AgentJwtService.setJwtLoginReload(false);
                } else if (['/login'].includes(window.location.pathname)) {
                    JwtService.setJwtLoginReload(false);
                } else {
                    JwtService.setJwtLoginReload(true);
                    AgentJwtService.setJwtLoginReload(true);
                }

                JwtService.setJwtLoginReload(true);
            }
        };

        window.addEventListener('beforeunload', handleTabClose);

        return () => {
            window.removeEventListener('beforeunload', handleTabClose);
        };

    }, []);

    return children;
};

export default AppAutoLogout;
