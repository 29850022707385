import * as React from 'react';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import { ThemeProvider } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { deleteAgent } from "../store/manageAgentsSlice";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { showMessage } from "app/store/fuse/messageSlice";
import EditAgentsModal from "./modal/EditAgentsModal";
import { confirmDelete } from 'app/services/swalService';
import { useEffect, useState } from "react";

function ManageAgentsFilter(props) {
    const dispatch = useDispatch();

    const { data, setData } = props;
    const { origData, setOrigData } = props;
    const { setLoading, loadData } = props;
    const { setKeyword } = props;

    const mainTheme = useSelector(selectMainTheme);
    const { selected, setSelected } = props;

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="flex flex-1 w-full items-center justify-evenly min-w-max">
            <div className="flex flex-1 items-center justify-start px-16">
                <ThemeProvider theme={mainTheme}>
                    <TextField
                        className=" min-w-160 w-320"
                        onChange={(e) => setKeyword(e.target.value)}
                        sx={{
                            "& .MuiInputBase-root": {
                                height: 40,
                            },
                            "& .MuiOutlinedInput-root": {
                                px: 1, py: 0
                            }
                        }}
                        placeholder="Search Agent "
                        variant="outlined"

                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon color="action">search</Icon>
                                </InputAdornment>
                            )
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                </ThemeProvider>
            </div>
            <div className="flex flex-1 items-center justify-end px-6">
                <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
                >
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
                >
                    <Button
                        className="whitespace-nowrap mx-10 rounded-md text-white"
                        disabled={!selected.length}
                        variant="contained"
                        color="error"
                        onClick={() => {
                            confirmDelete({}, function () {
                                dispatch(deleteAgent(selected)).then((action) => {
                                    dispatch(
                                        showMessage({
                                            message: "Agent(s) has been successfully deleted.",
                                            autoHideDuration: 5000, //ms
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "right",
                                            },
                                            variant: "success",
                                        })
                                    );
                                    loadData();
                                });
                                setSelected([])
                            })
                        }}
                        startIcon={<Icon className="hidden sm:flex">delete</Icon>}
                    >
                        Remove Selected
                    </Button>
                </motion.div>
            </div>
        </div>
    );
}

export default ManageAgentsFilter;
