import FuseUtils from '@fuse/utils';
import appsConfigs from 'app/main/apps/appsConfigs';
import authRoleExamplesConfigs from 'app/main/auth/authRoleExamplesConfigs';
import CallbackConfig from 'app/main/callback/CallbackConfig';
import DocumentationConfig from 'app/main/documentation/DocumentationConfig';
import LoginConfig from 'app/main/login/LoginConfig';
import LogoutConfig from 'app/main/logout/LogoutConfig';
import pagesConfigs from 'app/main/pages/pagesConfigs';
import UserInterfaceConfig from 'app/main/user-interface/UserInterfaceConfig';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';

//new
import PublicConfig from 'app/main/apps/public/PublicConfig';
import PublicMissionConfig from 'app/main/apps/public/mission/PublicMissionConfig';
import AgentConfig from 'app/main/agent/AgentConfig';
import AgentAppConfig from 'app/main/apps/agent/AgentAppConfig';

const routeConfigs = [
  ...appsConfigs,
  ...pagesConfigs,
  ...authRoleExamplesConfigs,
  UserInterfaceConfig,
  DocumentationConfig,
  LogoutConfig,
  LoginConfig,
  AgentConfig,
  AgentAppConfig,
  CallbackConfig,
  PublicConfig, //new 14-06-2023
  PublicMissionConfig//new 14-06-2023
];

const routes = [
  // if you want to make whole app auth protected by default change defaultAuth for example:
  // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
  // The individual route configs which has auth option won't be overridden.
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
  {
    path: '/',
    element: <Navigate to="login" />,
  },
  {
    path: '/login',
    element: <Navigate to="login" />,
  },
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    path: "/agentlogin",
    element: <Navigate to="agentlogin" />,
  },
  {
    path: "/agentprofile",
    element: <Navigate to="agentprofile" />,
  },
  {
    path: '*',
    element: <Navigate to="pages/errors/error-404" />,
  },
];

export default routes;
