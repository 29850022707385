//FOR FUTURE USE
// import FusePageSimple from "@fuse/core/FusePageSimple";

// import { styled } from "@mui/material/styles";

// import { useCallback, useState, useRef } from "react";


// import {
//     Dialog,
//     DialogTitle,
//     DialogContent,
//     DialogActions,
//     CloseIcon,
//     Menu,
//     MenuItem,
//     IconButton,
//     Typography,
//     TextField,
//     Button,
//     Item,

// } from "@mui/material";

// import PropTypes from "prop-types";

// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
// import { useForm, Controller } from "react-hook-form";
// import { showMessage } from "app/store/fuse/messageSlice";
// import { useDispatch } from "react-redux";
// import {
//     createFileContent
// } from "../store/uploadFileSlice"; //from store

// import { useNavigate } from "react-router-dom";

// import { useRecordWebcam } from "react-record-webcam";

// import Webcam from "react-webcam";
// const WebcamComponent = () => <Webcam />;
// const videoConstraints = {
//     width: 400,
//     height: 400,
//     facingMode: "user",
// };

// const Root = styled(FusePageSimple)(() => ({
//     "& .FusePageCarded-topBg": {
//         background: "unset",
//     },
//     minHeight: "unset",
//     "& .FusePageSimple-contentCard, .FusePageSimple-header": {
//         backgroundColor: "unset",
//         background: "unset",
//         boxShadow: "unset",
//     },
//     "& .ps__rail-y": {
//         display: "none !important",
//     },
// }));

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//     "& .MuiDialogContent-root": {
//         padding: theme.spacing(2),
//     },
//     "& .MuiDialogActions-root": {
//         padding: theme.spacing(1),
//     },
// }));

// function BootstrapDialogTitle(props) {
//     //dialog
//     const { children, onClose, ...other } = props;

//     return (
//         <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
//             {children}
//             {onClose ? (
//                 <IconButton
//                     aria-label="close"
//                     onClick={onClose}
//                     sx={{
//                         position: "absolute",
//                         right: 8,
//                         top: 8,
//                         color: (theme) => theme.palette.grey[500],
//                     }}
//                 >
//                     <CloseIcon />
//                 </IconButton>
//             ) : null}
//         </DialogTitle>
//     );
// }

// BootstrapDialogTitle.propTypes = {
//     children: PropTypes.node,
//     onClose: PropTypes.func.isRequired,
// };

// const defaultValues = {
//     content_marker: "",
// };

// function uploadFileWithContent(props) {

//     const { savePinContent } = props;

//     const videoConstraints = {
//         width: 420,
//         height: 420,
//         facingMode: "user",
//     };

//     const [anchorEl, setAnchorEl] = useState(null);

//     const open = Boolean(anchorEl);

//     const [anchorElPhoto, setAnchorElPhoto] = useState(null);
//     const openPhoto = Boolean(anchorElPhoto);

//     const [selectedFile, setSelectedFile] = useState(null); //File
//     const fileInputRef = useRef(null); //File

//     const [selectedMenuItem, setSelectedMenuItem] = useState("");
//     const [openPhotoDialog, setOpenPhotoDialog] = useState(false); //OPEN DIALOG

//     const [previewUrl, setPreviewUrl] = useState("");


//     const recordWebcam = useRecordWebcam({ frameRate: 60 });

//     const saveFile = async () => {
//         const blob = await recordWebcam.getRecording();
//     };

//     const [img, setImg] = useState(null);
//     const [picture, setPicture] = useState("");
//     const webcamRef = useRef(null);

//     const mediaRecorderRef = useRef(null);
//     const [capturing, setCapturing] = useState(false);
//     const [recordedChunks, setRecordedChunks] = useState([]);



//     //form submission    
//     const schema = yup.object().shape({
//         content_marker: yup.string().required("Field is required").min(8).max(32),
//     });

//     const {
//         control,
//         formState,
//         handleSubmit,
//         reset,
//         setValue,
//         watch,
//         getValues,
//         trigger,
//     } = useForm({
//         mode: "onChange",
//         defaultValues,
//         resolver: yupResolver(schema),
//     });

//     const { isValid, dirtyFields, errors } = formState;

//     async function handleSave() {
//         const result = await trigger(["description"]);

//         savePinContent({
//             content_marker: getValues("content_marker"),
//             test: getValues("content_marker"),
//         })

//         // console.log("jusko", infodata)



//         // if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
//         //     return dispatch(
//         //         showMessage({
//         //             message: "Please fill in the required fields.",
//         //             autoHideDuration: 5000, //ms
//         //             anchorOrigin: {
//         //                 vertical: "bottom",
//         //                 horizontal: "center",
//         //             },
//         //             variant: "error",
//         //         })
//         //     );
//         // }

//         // var data = getValues();
//         // data.mission_id = "e96fd2c1-a757-41b7-b54f-2c42eab6b121"

//         // console.log("get values", data);

//         // dispatch(createFileContent(data)).then(function () {
//         //     toast.success("Mission Success !", {
//         //         position: toast.POSITION.BOTTOM_CENTER,
//         //     });
//         //     setTimeout(() => {
//         //         reset(defaultValues);
//         //         navigate("/public/mission-details");
//         //     }, 5000);
//         // });
//     }
//     //end of submission

//     const handleClick = (event) => {
//         setAnchorEl(event.currentTarget);
//     };

//     const handleClose = () => {
//         setAnchorEl(null);
//     };

//     const uploadPhotoClick = (event) => {
//         setAnchorElPhoto(event.currentTarget);
//     };

//     const handleClosePhoto = () => {
//         setAnchorElPhoto(null);
//     };

//     //Final dialog variables and function

//     const handleClickOpenPhotoMenu = () => {
//         setOpenPhotoDialog(true);
//     };
//     const handleClosePhotoMenu = () => {
//         setOpenPhotoDialog(false);
//     };

//     const handleMenuItemClick = (menuItem) => {
//         setOpenPhotoDialog(true);
//         setSelectedMenuItem(menuItem);
//     };

//     const handleFileChange = (event) => {
//         const file = event.target.files[0];
//         setSelectedFile(file);

//         if (file) {
//             const reader = new FileReader();
//             reader.onloadend = () => {
//                 setPreviewUrl(reader.result);
//             };
//             reader.readAsDataURL(file);
//         } else {
//             setPreviewUrl("");
//         }
//     };

//     const handleRemove = () => {
//         setSelectedFile(null);
//         setPreviewUrl("");

//         if (fileInputRef.current) {
//             fileInputRef.current.value = "";
//         }
//     };

//     const handleDataAvailable = useCallback(
//         ({ data }) => {
//             if (data.size > 0) {
//                 setRecordedChunks((prev) => prev.concat(data));
//             }
//         },
//         [setRecordedChunks]
//     );

//     const handleStartCaptureClick = useCallback(() => {
//         setCapturing(true);
//         mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
//             mimeType: "video/webm",
//         });
//         mediaRecorderRef.current.addEventListener(
//             "dataavailable",
//             handleDataAvailable
//         );
//         mediaRecorderRef.current.start();
//     }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

//     const handleStopCaptureClick = useCallback(() => {
//         mediaRecorderRef.current.stop();
//         setCapturing(false);
//     }, [mediaRecorderRef, setCapturing]);

//     const handleDownload = useCallback(() => {
//         if (recordedChunks.length) {
//             const blob = new Blob(recordedChunks, {
//                 type: "video/webm",
//             });
//             const url = URL.createObjectURL(blob);
//             const a = document.createElement("a");
//             document.body.appendChild(a);
//             a.style = "display: none";
//             a.href = url;
//             a.download = "react-webcam-stream-capture.webm";
//             a.click();
//             window.URL.revokeObjectURL(url);
//             setRecordedChunks([]);
//         }
//     }, [recordedChunks]);

//     const capture = useCallback(() => {
//         const imageSrc = webcamRef.current.getScreenshot();
//         setImg(imageSrc);
//     }, [webcamRef]);

//     //youtube Url
//     const [textInputYoutubeUrl, setTextInputYoutubeUrl] = useState("");
//     const handleCLickYoutubeUrl = (event) => {
//         console.log("event.target.value", event.target.values);
//         setTextInputYoutubeUrl(event.target.value);
//     };

//     return (
//         <div className="w-full h-full my-16 ">
//             <form name="registerForm" noValidate>
//                 <Typography variant="h3"> Add Content</Typography>
//                 <Controller
//                     name="content_marker"
//                     control={control}
//                     render={({ field }) => (
//                         <TextField
//                             {...field}
//                             className="mt-16 w-full"
//                             error={!!errors.content_marker}
//                             required
//                             helperText={errors?.content_marker?.message}
//                             label="Mission Content"
//                             id="content_marker"
//                             variant="outlined"
//                             fullWidth
//                         />
//                     )}
//                 />
//                 <div className="border-dotted flex items-center justify-center  border-2 border-gray-500 p-36">
//                     <Button
//                         id="uploadBtn"
//                         aria-controls={openPhoto ? "uplodPhoto" : undefined}
//                         aria-haspopup="true"
//                         aria-expanded={openPhoto ? "true" : undefined}
//                         onClick={uploadPhotoClick}
//                     >
//                         Attached File
//                     </Button>
//                 </div>
//                 <Button
//                     type="button"
//                     variant="contained"
//                     color="primary"
//                     className="w-full mx-auto mt-16 rounded-md h-48"
//                     aria-label="LOG IN"
//                     value="legacy"
//                     onClick={handleSave}
//                 >
//                     Submit
//                 </Button>
//             </form>

//             <Menu
//                 id="uplodPhoto"
//                 anchorEl={anchorElPhoto}
//                 open={openPhoto}
//                 onClose={handleClosePhoto}
//                 MenuListProps={{
//                     "aria-labelledby": "uploadBtn",
//                 }}
//             >
//                 <MenuItem
//                     variant="outlined"
//                     onClick={() => handleMenuItemClick("menuItem1")}
//                 >
//                     Upload File
//                 </MenuItem>

//                 <MenuItem onClick={() => handleMenuItemClick("menuItem2")}>
//                     Youtube Url
//                 </MenuItem>
//                 <MenuItem onClick={() => handleMenuItemClick("menuItem3")}>
//                     Take a Photo
//                 </MenuItem>
//                 <MenuItem onClick={() => handleMenuItemClick("menuItem4")}>
//                     Record a Video
//                 </MenuItem>
//             </Menu>
//             {/* Upload file */}
//             <BootstrapDialog
//                 onClose={handleClosePhotoMenu}
//                 aria-labelledby="customized-dialog-title"
//                 open={openPhotoDialog}
//             >
//                 <BootstrapDialogTitle
//                     id="customized-dialog-title"
//                     onClose={handleClosePhotoMenu}
//                 >
//                     {selectedMenuItem === "menuItem1" && <div>Upload File</div>}
//                     {selectedMenuItem === "menuItem2" && <div>Youtube Url</div>}
//                     {selectedMenuItem === "menuItem3" && <div>Take a photo</div>}
//                     {selectedMenuItem === "menuItem4" && <div>Record a video</div>}
//                 </BootstrapDialogTitle>
//                 <DialogContent dividers>
//                     {/* upload file */}
//                     {selectedMenuItem === "menuItem1" && (
//                         <div>
//                             <input type="file" onChange={handleFileChange} />
//                             {previewUrl && (
//                                 <div>
//                                     <img
//                                         ref={fileInputRef}
//                                         src={previewUrl}
//                                         alt="Preview"
//                                         style={{
//                                             maxWidth: "100%",
//                                             marginTop: "10px",
//                                         }}
//                                     />
//                                     <button onClick={handleRemove}>Remove</button>
//                                 </div>
//                             )}
//                             {selectedFile && (
//                                 <button onClick={() => setSelectedFile(null)}>Reset</button>
//                             )}
//                         </div>
//                     )}
//                     {selectedMenuItem === "menuItem2" && (
//                         <div>
//                             {/* <form onSubmit={handleSubmit}>
//                   <TextField
//                     label="Youtube Url"
//                     fullWidth
//                     margin="normal"
//                     value={textInputYoutubeUrl}
//                     onChange={handleCLickYoutubeUrl}
//                   />

//                   <iframe
//                     src="https://www.youtube.com/embed/uXWycyeTeCs"
//                     width={1000}
//                     height={500}
//                     sandbox="allow-scripts allow-modal"
//                   ></iframe>
//                 </form> */}
//                         </div>
//                     )}
//                     {selectedMenuItem === "menuItem3" && (
//                         <div>
//                             {img === null ? (
//                                 <>
//                                     <Webcam
//                                         audio={false}
//                                         mirrored={true}
//                                         height={400}
//                                         width={400}
//                                         ref={webcamRef}
//                                         screenshotFormat="image/jpeg"
//                                         videoConstraints={videoConstraints}
//                                     />
//                                     <button onClick={capture}>Capture photo</button>
//                                 </>
//                             ) : (
//                                 <>
//                                     <img src={img} alt="screenshot" />
//                                     <button onClick={() => setImg(null)}>Retake</button>
//                                 </>
//                             )}
//                         </div>
//                     )}
//                     {selectedMenuItem === "menuItem4" && (
//                         <div>
//                             <Webcam
//                                 height={400}
//                                 width={400}
//                                 audio={false}
//                                 mirrored={true}
//                                 ref={webcamRef}
//                                 videoConstraints={videoConstraints}
//                             />
//                             {capturing ? (
//                                 <button onClick={handleStopCaptureClick}>Stop Capture</button>
//                             ) : (
//                                 <button onClick={handleStartCaptureClick}>Start Capture</button>
//                             )}
//                             {recordedChunks.length > 0 && (
//                                 <button onClick={handleDownload}>Download</button>
//                             )}
//                         </div>
//                     )}
//                 </DialogContent>
//                 <DialogActions>
//                     {selectedMenuItem === "menuItem1" && (
//                         <div>
//                             <Button autoFocus onClick={handleClosePhotoMenu}>
//                                 Close
//                             </Button>

//                             <Button autoFocus onClick={handleClosePhotoMenu}>
//                                 Submit
//                             </Button>
//                         </div>
//                     )}
//                     {selectedMenuItem === "menuItem2" && (
//                         <div>
//                             <Button autoFocus onClick={handleClosePhotoMenu}>
//                                 Close
//                             </Button>

//                             <Button autoFocus onClick={handleClosePhotoMenu}>
//                                 Submit
//                             </Button>
//                         </div>
//                     )}
//                     {selectedMenuItem === "menuItem3" && (
//                         <div>
//                             <Button autoFocus onClick={handleClosePhotoMenu}>
//                                 Close
//                             </Button>

//                             <Button autoFocus onClick={handleClosePhotoMenu}>
//                                 Submit
//                             </Button>
//                         </div>
//                     )}
//                     {selectedMenuItem === "menuItem4" && (
//                         <div>
//                             {" "}
//                             <Button autoFocus onClick={handleClosePhotoMenu}>
//                                 Close
//                             </Button>
//                             <Button autoFocus onClick={handleClosePhotoMenu}>
//                                 Submit
//                             </Button>
//                         </div>
//                     )}
//                 </DialogActions>
//             </BootstrapDialog>
//             {/* End of file */}
//         </div>
//     );
// }

// export default uploadFileWithContent;
