import { lazy } from 'react';
import { authRoles } from 'app/auth';

const ManageMissions = lazy(() => import('./manage-missions/ManageMissions'));

const MissionsAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {
            path: 'apps/missions/manage-missions',
            element: <ManageMissions />,
            auth: ['missions-tag']
        },
    ],
};

export default MissionsAppConfig;
