import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { mutation, query } from "app/graphql/settings/audit_logs";
import { showMessage } from "app/store/fuse/messageSlice";
import format from 'date-fns/format';

export const getAuditLogs = createAsyncThunk(
    "settingsApp/auditLogs/getAuditLogs",
    async (params) => {
        params.dateFrom = (params.dateFrom ? format(new Date(params.dateFrom), 'yyyy-MM-dd') : null);
        params.dateTo = (params.dateTo ? format(new Date(params.dateTo), 'yyyy-MM-dd') : null);

        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getAuditLogs,
            variables: {
                dateFrom: params.dateFrom,
                dateTo: params.dateTo,
            }
        });

        if (response.data.data.getAuditLogs) {
            const data = response.data.data.getAuditLogs;

            return data === undefined ? null : data;
        } else {
            return null
        }
    }
);

const systemLogsSlice = createSlice({
    name: "settingsApp/auditLogs",
    initialState: null,
    reducers: null,
    extraReducers: {
        [getAuditLogs.fulfilled]: (state, action) => action.payload,
    },
});

export default systemLogsSlice.reducer;