import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller, FormProvider } from "react-hook-form";
import * as yup from "yup";
import reducer from 'app/main/apps/blogs/store';
import withReducer from "app/store/withReducer";
import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { styled, lighten } from '@mui/material/styles';
import { showMessage } from "app/store/fuse/messageSlice";
import MIssionDetailsContentImages from "./MIssionDetailsContentImages";
import { PushPin } from "@mui/icons-material";
import { joinedMission } from "../../store/missionSlice";

const Root = styled('div')(({ theme }) => ({
    background: theme.palette.primary,
    color: theme.palette.primary.contrastText,

    '& .bg-icon': {
        background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${lighten(
            theme.palette.primary.dark,
            0.1
        )} 100%)`,
        color: theme.palette.primary.contrastText,
    },

}));

class ButtonPinnedIcon extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g fill="#141f2c" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
                    <rect width="24" height="24" stroke="none" />
                    <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <path d="M11.5,9a2.5,2.5,0,0,1,4.87-.81A2.746,2.746,0,0,1,16.5,9a2.5,2.5,0,0,1-4.86.84A2.6,2.6,0,0,1,11.5,9M5,9c0,4.5,5.08,10.66,6,11.81L10,22S3,14.25,3,9A7.005,7.005,0,0,1,8,2.29,8.982,8.982,0,0,0,5,9m9-7a7,7,0,0,1,7,7c0,5.25-7,13-7,13S7,14.25,7,9a7,7,0,0,1,7-7m0,2A5,5,0,0,0,9,9c0,1,0,3,5,9.71C19,12,19,10,19,9A5,5,0,0,0,14,4Z" fill="#ffff" />
            </svg>


        )
    }
}

function MissionDetailsJoinMissionModal(props) {
    const { reloadData, data, agent } = props
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [origData, setOrigData] = useState({});
    function handleOpenDialog() {
        setOpenDialog(true);
    }

    function handleCloseDialog() {
        setOpenDialog(false);
    }

    async function handleSave() {
        setIsSaving(true);
        const data = {
            mission_id: props.data.id,
            agent: props.agent,
            lat: props.data.lat,
            lng: props.data.lng,
        };
        console.log("data in mission Model", data)
        dispatch(joinedMission(data)).then(function (action) {

            reloadData()
            handleCloseDialog();
        },);

    }
    return (
        <Root>

            {props.module == "joinmission" && (
                <Tooltip title={"Join Mission"} arrow>
                    <Button onClick={handleOpenDialog} disableElevation variant="contained" className="custom-text w-full !text-16 !font-nunito !bg-pi-blue-1" startIcon={<ButtonPinnedIcon />}><span className=" !m-auto"> Join this mission</span></Button>
                </Tooltip>
            )}

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
                scroll="body"
                sx={{

                    '& .MuiPaper-root': {
                        width: "100%",
                    },
                }}
            >
                <AppBar position="static" elevation={0}>
                    <Toolbar className="flex w-full">
                        <Typography variant="subtitle1" color="inherit">
                            Action
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>
                    <Typography variant="body2" color="text.secondary">
                        Are you sure want to Join this Mission?
                    </Typography>
                </DialogContent>
                <DialogActions className="justify-center px-8 py-16">
                    <div className="px-16">
                        <LoadingButton
                            className="rounded-md mr-5"
                            variant="contained"
                            color="primary"
                            type="button"
                            disabled={isSaving}
                            onClick={handleSave}
                        >
                            Save
                        </LoadingButton>
                        <Button
                            className="rounded-md ml-5"
                            variant="outlined"
                            type="button"
                            onClick={handleCloseDialog}
                        >
                            Cancel
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </Root>
    );
}
export default withReducer("MissionDetailsJoinMissionModal", reducer)(MissionDetailsJoinMissionModal);
