import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "@fuse/core/withRouter";
import FuseLoading from "@fuse/core/FuseLoading";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { styled } from "@mui/material/styles";
import { inputLabelClasses } from "@mui/material/InputLabel";
import AgentJwtService from "app/services/agent/AgentJwtService";
import { showMessage } from "app/store/fuse/messageSlice";
import FormHelperText from '@mui/material/FormHelperText';
import MoreVert from '@mui/icons-material/MoreVert';
import { savePublicUpload, getAllPublicUploads } from "app/services/store/PublicUploadsSlice";

import {
    Card,
    CardContent,
    Stack,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Avatar,
    Badge,
    CardHeader,
    IconButton,
    Container,
    Button,
    Divider,
    Icon,
    Tab,
    TextField,
    InputAdornment,
    Typography,
    Menu,
    MenuItem
} from "@mui/material";

function CommentAction(props) {
    const dispatch = useDispatch();

    const token = AgentJwtService.getDecodedAccessToken();

    const { id, data, attachmentId, handleDeleteComment, setIsEditId, setAttachmentData, setEditComment } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleEdit = (id) => {
        setIsEditId(id)
        setEditComment(data.comments)
        setAttachmentData([])
        dispatch(getAllPublicUploads({ id: attachmentId })).then((action) => {
            const payloadData = action.payload;
            const finalData = payloadData.map((item, index) => ({
                ...item,
                switchType: item.fileType.split('/')[0],
            }));
            setAttachmentData(finalData)
        })
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            {token.agentId == data.agentId && (
                <>
                    <IconButton aria-label="delete"
                        onClick={handleClick}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        edge={false}
                    >
                        <MoreVert />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,0.06))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={() => { handleEdit(id) }}>
                            Edit
                        </MenuItem>
                        <MenuItem onClick={() => { handleDeleteComment(id) }}>
                            Delete
                        </MenuItem>

                    </Menu>
                </>
            )}
        </>
    );
}

export default CommentAction;
