/**
 * Plugin: axios
 * Link: https://github.com/axios/axios
 */

import axios from 'axios';
import JwtService from './jwtService';
import AgentJwtService from "app/services/agent/AgentJwtService";
// import EcommerceJwtService from 'app/main/ecommerce/services/ecommerceJwtService';
// import CrmJwtService from 'app/services/crm/CrmJwtService';
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(function (config) {
    if (config.data?.publicUrl) {
        if (config.data?.publicUrl == 'agents') {
            config.headers.Authorization = "Bearer " + AgentJwtService.getAccessToken();
            // commented kasi kailangan muna isa isahin ang mga nagaaccess sa api kung kailangan ngtoken or hindi dapat. tapos kailangan pa nito. config.data?.publicUrl
            // if (!CrmJwtService.getAccessToken() && !config.data?.isNoToken) {
            //     return CrmJwtService.logout()
            // }
        }
        // if (config.data?.publicUrl == 'ecommerce') {
        //     config.headers.Authorization = "Bearer " + EcommerceJwtService.getAccessToken();
        //     // commented kasi kailangan muna isa isahin ang mga nagaaccess sa api kung kailangan ngtoken or hindi dapat. tapos kailangan pa nito. config.data?.publicUrl
        //     // if (!EcommerceJwtService.getAccessToken() && !config.data?.isNoToken) {
        //     //     return EcommerceJwtService.logout()
        //     // }
        // }
        return config;
    } else {
        config.headers.Authorization = "Bearer " + JwtService.getAccessToken();
        // commented kasi kailangan muna isa isahin ang mga nagaaccess sa api kung kailangan ngtoken or hindi dapat. tapos kailangan pa nito. config.data?.publicUrl
        // if (!JwtService.getAccessToken() && !config.data?.isNoToken) {
        //     return JwtService.logout()
        // }
    }
    return config
}, function (error) {

    // Do something with request error
    return Promise.reject(error);

});