import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CONFIG from 'app/config';
import randomString from 'app/services/randomString';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { savePublicUpload, getPublicUploads } from 'app/services/store/PublicUploadsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';

const AttachmentUploader = (props) => {
    const dispatch = useDispatch();

    const [attachments, setAttachments] = useState([]);
    const [youtubeURL, setYoutubeURL] = useState('');
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const { id, module, attachmentData, setAttachmentData, toUpload, setToUpload } = props;

    function fetchUploads() {
        dispatch(getPublicUploads({ id: id })).then((action) => {
            const newData = action.payload;
            newData.switchType = newData.fileType.split('/')[0];
            setAttachmentData([...attachmentData, newData]);
        });
    }

    function SavePublicUpload(params) {
        if (module == 'mission') {
            dispatch(savePublicUpload(params)).then(function (res) {
                setToUpload([...toUpload, res.payload]);
                setUploadPercentage(100);
                setTimeout(() => {
                    setUploadPercentage(0);
                    fetchUploads();
                }, 1000);
            });
        } else {
            dispatch(savePublicUpload(params)).then(function () {
                setUploadPercentage(100);
                setTimeout(() => {
                    setUploadPercentage(0);
                    fetchUploads();
                }, 1000);
            });
        }
    }

    const isYoutubeUrl = (url) => {
        const pattern = /^https?:\/\/(?:www\.)?youtube\.com\/watch\?v=[\w-]+&?/;
        return pattern.test(url);
    };

    const handleYouTubeURL = () => {
        if (isYoutubeUrl(youtubeURL)) {
            setUploadPercentage(1);
            if (youtubeURL) {
                var params = {
                    id: id,
                    module: module,
                    file_name: 'Youtube Link',
                    file_type: 'youtube',
                    path: youtubeURL,
                };
                SavePublicUpload(params);
                setYoutubeURL('');
                handleCloseDialog();
            }
        } else {
            dispatch(
                showMessage({
                    message: 'Invalid youtube URL.',
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                    variant: 'error',
                })
            );
        }
    };

    const handleUpload = (event) => {
        uploadFile(event);
        const file = event.target.files[0];
        if (file) {
            const params = { file: file, status: 'new' };
            setAttachments([...attachments, params]);
            handleClose();
        }
    };

    const uploadFile = ({ target: { files } }) => {
        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);

                if (percent < 100) {
                    setUploadPercentage(percent);
                }
            },
        };

        const formData = new FormData();

        formData.append('file', files[0]);

        formData.append('destination_file_name', props.module + '/' + randomString() + '-' + files[0].name);

        axios
            .post(CONFIG.API + '/external/uploads/gcloud', formData, options, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                if (res.data.status === 'success') {
                    var params = {
                        id: id,
                        module: module,
                        file_name: res.data.uploadData.file_name,
                        file_type: res.data.uploadData.file_type,
                        path: res.data.uploadData.path,
                    };
                    SavePublicUpload(params);
                }
            });
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [openURLDialog, setOpenURLDialog] = useState(false);

    function handleCloseDialog() {
        setOpenURLDialog(false);
    }
    function handleOpenDialog() {
        setOpenURLDialog(true);
        handleClose();
    }

    const CircularProgressWithLabel = (props) => {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        );
    };

    return (
        <div>
            {(() => {
                switch (module) {
                    case 'blogs':
                        return (
                            <div>
                                {uploadPercentage > 0 ? (
                                    <CircularProgressWithLabel value={uploadPercentage} />
                                ) : (
                                    <IconButton
                                        className='p-8'
                                        aria-label="Add photo"
                                        size="large"
                                        onClick={handleClick}
                                        title="Attach Media"
                                    >
                                        <Icon>photo</Icon>
                                    </IconButton>
                                )}
                            </div>
                        );
                    default:
                        return (
                            <div>
                                <Button
                                    className=' text-pi-blue-1 rounded-md'
                                    variant='text'
                                    aria-label="Add photo"
                                    size="large"
                                    onClick={handleClick}
                                    startIcon={
                                        uploadPercentage > 0 ? (
                                            <CircularProgressWithLabel value={uploadPercentage} />
                                        ) : (
                                            < Icon>attachment</Icon>
                                        )
                                    }
                                >
                                    Upload
                                </Button>
                            </div>
                        );
                }
            })()}
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <label>
                    <MenuItem className="p-10">
                        Photo/Videos
                        <input type="file" accept="image/*, video/*" style={{ display: 'none' }} onChange={handleUpload} />
                    </MenuItem>
                </label>
                <MenuItem onClick={handleOpenDialog} className="p-10">
                    Youtube URL
                </MenuItem>
            </Menu>
            <Dialog
                onClose={handleCloseDialog}
                open={openURLDialog}
                aria-labelledby="form-dialog-title"
                scroll="body"
                fullWidth
                maxWidth="xs"
            >
                <AppBar position="static" elevation={0}>
                    <Toolbar className="flex w-full">
                        <Typography variant="subtitle1" color="inherit">
                            Insert Youtube Link
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <TextField
                        label="Enter YouTube URL"
                        className="w-full"
                        variant="outlined"
                        value={youtubeURL}
                        margin="normal"
                        onChange={(e) => setYoutubeURL(e.target.value)}
                    />
                </DialogContent>
                <DialogActions className="py-16 self-center justify-center">
                    <Button className=" rounded-md" variant="outlined" onClick={handleYouTubeURL}>
                        Add
                    </Button>
                    <Button className=" rounded-md" variant="outlined" onClick={handleCloseDialog}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AttachmentUploader;
