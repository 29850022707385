import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import * as React from "react";
import Typography from "@mui/material/Typography";
import { GlobalStyles, height } from "@mui/system";
import FuseScrollbars from "@fuse/core/FuseScrollbars/FuseScrollbars";
import { Badge, Card, CardHeader, Stack, Avatar, useMediaQuery, Box, Grid, Button, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider, ListSubheader, Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import withReducer from 'app/store/withReducer';
import reducer from 'app/main/apps/settings/store';
import { useEffect, useState } from "react";
import { useForm, useFormContext, Controller, FormProvider } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Paper from '@mui/material/Paper';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputLabel, { inputLabelClasses } from "@mui/material/InputLabel";
import { getSettings, maintenanceSwitch } from "./store/settingsSlice";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
}));


const defaultValues = {
    maintenanceMessage: "",
    maintenanceStatus: "",
};

const Root = styled(FusePageSimple)(() => ({
    minHeight: "unset",
    "& .FusePageSimple-header.FusePageSimple-topBg": {
        display: "none",
    },
    "& .flex.flex-auto.flex-col.container.z-10.h-full": {
        width: "100%",
        maxWidth: "unset",
    },
    "& .FusePageSimple-header": {
        background: "transparent",
        backgroundSize: "cover",

        height: 152.99,
    },
    "& .FusePageSimple-wrapper": {
        background: "transparent",
        height: "unset",
        width: "100%",
    },
    "& .MuiDrawer-root.MuiDrawer-docked": {
        "& .MuiPaper-root": {
            width: "17.708333333333332vw",
            minWidth: "290px"
        }
    },
    "& .max-h-screen-content-100 .ps__rail-y": {
        display: "none !important",
    },
    "& .FusePageSimple-sidebarContent": {
        height: "calc(100vh - 7.6rem)",
    },
}));
const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        backgroundColor: "#ffff",
        border: "1px solid #E9E9E9",
        fontSize: '16px',
        [theme.breakpoints.up("lg")]: {
            height: 57,
            fontSize: '18px',
        },
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
    "& .MuiInputLabel-asterisk": {
        display: "none"
    },

    "& .MuiInputLabel-root": {
        top: "0px",
    },
}));
const FormControlStyled = styled(FormControl)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        backgroundColor: "#ffff",
        border: "1px solid #E9E9E9",
        fontSize: '16px',
        [theme.breakpoints.up("lg")]: {
            height: 57,
            fontSize: '18px',
        },
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff !important",
        border: "1px solid #141F2C",
        borderRadius: "8px"
    },
    "& .MuiSelect-select:focus": {
        backgroundColor: "transparent",
        border: "unset",
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
    "& .MuiInputLabel-asterisk": {
        display: "none",
    },

    "& .MuiInputLabel-root": {
        top: "0px",
        fontSize: "15px",
        color: '#48525C'
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: '#48525C'
    },
    "& .MuiSelect-select.MuiSelect-filled": {
        paddingTop: '20px'
    },
    "& .MuiSelect-nativeInput": {
        paddingTop: '3px'
    },
}));

function ManageMaintenance(props) {

    const user = useSelector(({ auth }) => auth.user);
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    const schema = yup.object().shape({
        maintenanceMessage: yup.string().required("Maintenance message is required"),
    });
    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });
    const { control, formState, setValue, watch, trigger, getValues } = methods;
    const { isValid, dirtyFields, errors } = formState;

    function getData() {
        dispatch(getSettings()).then((action) => {
            const pData = action.payload;
            setData(action.payload);
            setValue("maintenanceMessage", pData?.maintenanceMessage);
            setIsSaving(false);
        });
    }

    useEffect(() => {
        getData();
    }, [])

    async function handleSwitch() {
        setIsSaving(true);
        const result = await trigger(["maintenanceMessage"]);
        dispatch(maintenanceSwitch({ maintenanceMessage: getValues('maintenanceMessage') })).then((action) => {
            getData()
            setIsSaving(false);
        });
    }

    const color = "#2EB7D7";
    return (
        <FormProvider {...methods}>
            <>
                <GlobalStyles
                    styles={(theme) => ({
                        "#fuse-main": {
                            height: "100% !important",
                        },
                        " .FusePageSimple-sidebar, .FusePageSimple-sidebarHeader":
                        {
                            background: "white !important",
                        },
                        " .absolute-important":
                        {
                            position: "absolute !important",
                        },
                        " div.FusePageSimple-sidebarHeader.permanent": {
                            minHeight: 0,
                            height: "unset"
                        },
                        "& .FusePageSimple-toolbar": {
                            height: "76px !important",
                        },
                    })}
                />

                <Root

                    content={
                        <FuseScrollbars className=" h-full max-h-screen-content-100 overflow-auto overscroll-auto">
                            <Stack direction={'column'} gap={{ lg: 4, md: 2, sm: 2, xs: 2 }} className=" px-0 max1200px:py-40 py-20 m-auto max1200px:w-pi-655px-vw sm:w-4/5 w-full">
                                <Card className="p-24 rounded-lg mb-16 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                                    <Stack gap={1.5}>
                                        <Typography className=" text-18 font-bold mt-16">
                                            Maintenance
                                        </Typography>
                                        <Controller
                                            name="maintenanceMessage"
                                            control={control}
                                            render={({ field }) => (
                                                <div className="flex">
                                                    <TextFieldStyled
                                                        label={<Typography className="text-15" color="text.primary">Maintenance message: <span className="text-red-500">*</span></Typography>}
                                                        placeholder="Enter maintenance message"
                                                        variant="filled"
                                                        InputLabelProps={{
                                                            sx: {
                                                                color: "#48525C",
                                                                fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                                [`&.${inputLabelClasses.shrink}`]: {
                                                                    fontSize: '14px',
                                                                    color: !!errors.maintenanceMessage ? "red" : "#141F2C"
                                                                }
                                                            }
                                                        }}
                                                        {...field}
                                                        error={!!errors.maintenanceMessage}
                                                        required
                                                        helperText={errors?.maintenanceMessage?.message}
                                                        sx={{
                                                            "& .MuiInputBase-root": {
                                                                height: 'auto !important'
                                                            }
                                                        }}
                                                        id="maintenanceMessage"
                                                        fullWidth
                                                        multiline
                                                        maxRows={10}
                                                        minRows={10}
                                                    />
                                                </div>
                                            )}
                                        />
                                        <Button onClick={handleSwitch} variant="contained" color="primary"
                                            className=" mt-16 px-24 py-8 text-16 rounded-full font-bold w-full bg-pi-yellow-green">

                                            {data?.maintenanceStatus != '1' ? (
                                                <>
                                                    Turn On
                                                </>
                                            ) : (
                                                <>
                                                    Turn Off
                                                </>
                                            )}

                                        </Button>

                                    </Stack>
                                </Card>
                            </Stack>

                        </FuseScrollbars>
                    }
                />
            </>
        </FormProvider >
    );
}

export default withReducer('settingsApp', reducer)(ManageMaintenance);