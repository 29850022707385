import React from 'react';
import { Card, CardContent, CardHeader, Skeleton, Container } from "@mui/material";

const MissionDetailsSkeletonLoader = () => (
    <>
        <div className=" flex-grow basis-xs relative mt-16" style={{ maxWidth: '639px', }}>
            <Container>
                <Skeleton animation="wave" height={32} width="60%" style={{ marginBottom: 6 }} />
                <CardHeader
                    className='pt-0 px-0'
                    avatar={<Skeleton animation="wave" variant="circular" width={50} height={50} />}
                    title={<Skeleton animation="wave" height={20} width="40%" style={{ marginBottom: 6 }} />}
                    subheader={<Skeleton animation="wave" height={16} width="20%" />}
                />
                <CardContent className='p-0'>
                    <Skeleton animation="wave" variant="text" height={20} />
                    <Skeleton animation="wave" variant="text" height={20} />
                    <Skeleton animation="wave" variant="text" height={20} />
                    <Skeleton animation="wave" variant="text" height={20} />
                    <Skeleton animation="wave" variant="text" height={20} />
                    <Skeleton animation="wave" variant="text" height={20} />
                </CardContent>

            </Container>
            <Skeleton variant="text" height={50} className=' rounded-none' />

        </div>

        <Container className="flex-grow basis-xs p-0 max-w-full flex-1" >
            <Skeleton sx={{ height: '100%' }} animation="wave" variant="rectangular" />
        </Container >
    </>

);

export default MissionDetailsSkeletonLoader;
