import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { query, mutation } from "../graphql/mission_manager_graphql";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import { v4 as uuidv4 } from 'uuid';
import { resolve } from "promise";
import { showMessage } from "app/store/fuse/messageSlice";


export const getMissionById = createAsyncThunk(
    "missionManagerApp/getMissionById",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getMissionById,
            variables: params,
        });

        return response.data.data.getMissionById
    }
);

export const getMarkersByAgentId = createAsyncThunk(
    "missionManagerApp/getMarkersByAgentId",
    async (params) => {
        params.showInactive = "true"
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getMarkersByAgentId,
            variables: params,
        });

        if (response.data.data.getMarkersByAgentId) {
            const data = response.data.data.getMarkersByAgentId;

            var tempItems = [];

            if (data) {
                data.map((itemD) => {
                    itemD.lat = parseFloat(itemD.lat);
                    itemD.lng = parseFloat(itemD.lng);
                    tempItems.push(itemD);
                })
            }

            return data === undefined ? [] : tempItems;
        } else {
            return [];
        }
    }
);

export const updateMissionByType = createAsyncThunk(
    "missionManagerApp/updateMissionByType",
    async (params, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.updateMissionByType,
            variables: { data: JSON.stringify(params) },
            publicUrl: "agents"
        });

        const data = response.data.data.updateMissionByType

        if (data.status == 'success') {
            return dispatch(
                showMessage({
                    message: data.message,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            )
        } else {
            return dispatch(
                showMessage({
                    message: "Something went wrong please try again later.",
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            )
        }
    }
);

const MissionDetailsSlice = createSlice({
    name: "missionManagerApp",
    initialState: null,
    reducers: null,
    extraReducers: {
        [getMissionById.fulfilled]: (state, action) => action.payload,
        [getMarkersByAgentId.fulfilled]: (state, action) => action.payload,
        [updateMissionByType.fulfilled]: (state, action) => action.payload,
    },
});

export default MissionDetailsSlice.reducer;
