const mutation = {
    create: `
        mutation createInvoiceServices($data: JSONString!) {
            createInvoiceServices (data: $data) {
            status,
            res,
            message
            }
        }
    `,
    saveInvoiceService: `
        mutation saveInvoiceService($data: JSONString!) {
            saveInvoiceService (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    edit: `
        mutation editInvoice($data: JSONString!) {
            editInvoice (data: $data) {
            status,
            res,
            message
            }
        }
    `,
    delete: `
        mutation deleteInvoice($id: String!) {
            deleteInvoice (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
    updatePatronsPin: `
        mutation updatePatronsPin($data: JSONString!) {
            updatePatronsPin (data: $data) {
            status,
            res,
            message
            }
        }
    `,
    saveServicesCalendarConfig: `
        mutation saveServicesCalendarConfig($data: JSONString!) {
            saveServicesCalendarConfig (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    saveInvoiceServicesPriceList: `
        mutation saveInvoiceServicesPriceList($data: JSONString!) {
            saveInvoiceServicesPriceList (data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    getCostumer: `
        query getCostumer($keyword: String, $limit: Int) {
            getCostumer(keyword: $keyword, limit: $limit) {
                id
                patronId
                patronNameWId
                phone
                email
                name
                fullAddress
                lat
                lng
                totalCardCredits
                totalMealCredits
                totalBookCredits
            }
        }
        
    `,
    servicesPriceList: `
        query servicesPriceList{
            servicesPriceList {    
                id
                place
                cmi
                public
            }
        }
    `,
    getNewInvoice: `
        query getNewInvoice{
            newInvoice 
        }
    `,
    getServices: `    
        query getServices($fromDate: String!, $toDate: String) {
            getServices(fromDate: $fromDate, toDate: $toDate){
                id
                title
                contact
                address
                start
                end
                status
                backgroundColor
                textColor
                deliveryTimeFormatted
                deliveryDateFormatted
                deliveryDateViewFormatted
                deliveryDate
                invoiceNum
                invoiceStatus
                invoiceId
                display
                lat
                lng
            }
        }
    `,
    getServicesCalendarConfig: `    
        query servicesCalendarConfig {
            servicesCalendarConfig{
                selectedDates
            }
        }
    `,
    getInvoiceById: `
        query invoiceById ($invoiceId: String!) {
            invoiceById (invoiceId: $invoiceId) {
                id
                change
                paid
                change
                customer
                discount
                invoiceNum
                invoiceStatus
                invoiceType
                subTotal
                due
                grandTotal
                globalNote
                globalHeader
                costumerNote
                paidAt
                services {
                    id
                    contact
                    address
                    subTotal
                    lat
                    lng
                    notes
                    deliveryDate
                    deliveryTime
                    status
                    customerId
                    invoiceId
                    discount
                    name
                }
            }
        }
    `,
    waterDeliveryReports: `
        query waterDeliveryReports($limit: Int, $dateFrom: String, $dateTo: String, $page: Int, $order: String) {
            waterDeliveryReports(limit: $limit, dateFrom: $dateFrom,  dateTo: $dateTo, page: $page, order: $order) {
                page
                pages
                hasNext
                hasPrev
                totalItems
                itemsPerPage
                objects {
                    id
                    firstName
                    lastName
                    middleName
                    name
                    numberDelivery
                    totalNumberDelivery
                    totalCostDelivered
                    costDelivered
                    dateCreated      
                }
            }
        }
    `,
    isJoined: `
        query isJoined($missionId: String, $agentId: String){
            isJoined(missionId: $missionId, agentId: $agentId)
        }
    `
};

export { mutation, query };
