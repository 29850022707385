const mutation = {};

const query = {
    agentById: `
        query agentById($agentId: String){
            agentById(agentId: $agentId){
                id
                userId
                firstName
                middleName
                lastName
                email
                username
                gender
                birthdate
                country
                city
                about
                profileUrl
                bannerUrl
            }
        }
    `,
};
export { mutation, query };