import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { query, mutation } from "../graphql/agent_follow_unfollow_graphql";

export const followAgent = createAsyncThunk(
    "App/svc/followAgent",
    async (data) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.followAgent,
            variables: {
                data: JSON.stringify(data),
            },
        });

        if (response.data.data.followAgent) {
            const data = response.data.data.followAgent;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const unfollowAgent = createAsyncThunk(
    "App/svc/unfollowAgent",
    async (data) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.unfollowAgent,
            variables: {
                data: JSON.stringify(data),
            },
        });

        if (response.data.data.unfollowAgent) {
            const data = response.data.data.unfollowAgent;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const followNotifAgent = createAsyncThunk(
    "App/svc/followNotifAgent",
    async (data) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.followNotifAgent,
            variables: {
                data: JSON.stringify(data),
            },
        });

        if (response.data.data.followNotifAgent) {
            const data = response.data.data.followNotifAgent;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const agentFollowStatus = createAsyncThunk(
    "App/svc/agentFollowStatus",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.agentFollowStatus,
            variables: {
                agentId: params.agentId,
                followedId: params.followedId,
            },
        });

        if (response.data.data.agentFollowStatus) {
            const data = response.data.data.agentFollowStatus;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

const AgentFollowUnfollowSlice = createSlice({
    name: "follow_unfollow",
    initialState: null,
    reducers: null,
    extraReducers: {
        [followAgent.fulfilled]: (state, action) => action.payload,
        [unfollowAgent.fulfilled]: (state, action) => action.payload,
        [followNotifAgent.fulfilled]: (state, action) => action.payload,
        [agentFollowStatus.fulfilled]: (state, action) => action.payload,
    },
});

export default AgentFollowUnfollowSlice.reducer;