import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import jwtService from "app/services/jwtService";


export const getDecodedToken = createAsyncThunk('lockScreenApp/getDecodedToken', async (params) => {
  const data = await jwtService.getDecodedAccessToken();
  return data;
});

const lockSlice = createSlice({
  name: 'account/lock',
  initialState: null,
  reducers: {
  },
  extraReducers: {
    [getDecodedToken.fulfilled]: (state, action) => action.payload,
  },
});

export default lockSlice.reducer;
