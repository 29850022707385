import * as React from 'react';
import FusePageCarded from '@fuse/core/FusePageCarded';
import withReducer from 'app/store/withReducer';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { getMarkersByAgentId } from "app/main/apps/public/mission-manager-v2/store/missionManagerSlice"
import PublicMissionManagerListFilter from './PublicMissionManagerListFilter';
import PublicMissionManagerListTable from './PublicMissionManagerListTable';
import { Divider } from '@mui/material';

function PublicMissionManagerList() {
    const dispatch = useDispatch();
    const token = AgentJwtService.getDecodedAccessToken();

    const [data, setData] = useState([]);
    const [type, setType] = useState("missions");
    const [keyword, setKeyword] = useState("");
    const [loading, setLoading] = useState(true);

    function loadData() {
        dispatch(getMarkersByAgentId({ id: token.agentId, type: type, keyword: keyword })).then((action) => {
            setData(action.payload)
            setLoading(false)
        })
    }

    useEffect(() => {
        loadData();
    }, [keyword, type]);

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <PublicMissionManagerListFilter setKeyword={setKeyword} setType={setType} />
            <Divider className=" my-16" />
            <PublicMissionManagerListTable data={data} loadData={loadData} loading={loading} setData={setData} />
        </>
    );
}
export default PublicMissionManagerList;