
import FuseScrollbars from "@fuse/core/FuseScrollbars";
import _ from "@lodash";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import format from 'date-fns/format';
import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import withRouter from "@fuse/core/withRouter";
import FuseLoading from "@fuse/core/FuseLoading";
import { styled } from '@mui/material/styles';

import FriendsManagerTableHeader from "./FriendsManagerTableHeader";
import FriendsManagerStatusModal from "../modal/FriendsManagerStatusModal";
import FriendsManagerNotifyModal from "../modal/FriendsManagerNotifyModal";
import { Avatar, CardHeader } from "@mui/material";
import AgentFollowUnfollow from "app/services/AgentFollowUnfollow";
import AttachmentSvs from "app/services/AttachmentSvs";
import AgentJwtService from 'app/services/agent/AgentJwtService';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd):not(.Mui-selected):not(:hover)': {
        backgroundColor: theme.palette.background.default,
    },
    '&:hover': {
        backgroundColor: theme.palette.background.hover,
    },
    // hide last border
    '& td, & th': {
        border: 0,
    },
}));

function FriendsManagerTable(props) {
    const dispatch = useDispatch();
    const { loading, setLoading } = props;
    const { selected, setSelected } = props
    const { data, loadData } = props;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const token = AgentJwtService.getDecodedAccessToken();
    const [order, setOrder] = useState({
        direction: "asc",
        id: null,
    });

    function handleRequestSort(event, property) {
        const id = property;
        let direction = "desc";

        if (order.id === property && order.direction === "desc") {
            direction = "asc";
        }

        setOrder({
            direction,
            id,
        });
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            setSelected(data.map((n) => n.id));
            return;
        }
        setSelected([]);
    }

    function handleDeselect() {
        setSelected([]);
    }

    function handleCheck(event, id) {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    }

    function handleChangePage(event, value) {
        setPage(value);
    }

    function handleChangeRowsPerPage(event) {
        setPage(0);
        setRowsPerPage(event.target.value);
    }

    if (loading) {
        return <FuseLoading />;
    }

    if (data.length === 0) {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                className="flex flex-1 items-center justify-center h-full"
            >
                <Typography color="textSecondary" variant="h5">
                    There are no agents!
                </Typography>
            </motion.div>
        );
    }

    return (
        <>
            {_.orderBy(
                data,
                [
                    (o) => {
                        switch (order.id) {
                            case "categories": {
                                return o.categories[0];
                            }
                            default: {
                                return o[order.id];
                            }
                        }
                    },
                ],
                [order.direction]
            )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n) => {
                    const isSelected = selected.indexOf(n.id) !== -1;
                    const followers = n.followedInfo.allFollowerInfo;
                    return (
                        <div
                            className="h-72 cursor-pointer"
                            role="checkbox"
                            aria-checked={isSelected}
                            tabIndex={-1}
                            key={n.id}
                            selected={isSelected}
                        >
                            <CardHeader
                                key={n.id}
                                className="p-0 my-16"
                                sx={{ '& .MuiCardHeader-action': { alignSelf: "center" } }}
                                avatar={
                                    <>
                                        <AttachmentSvs module={"at-1"} path={n.followedInfo?.profilePicPath} data={n} />
                                    </>
                                }
                                title={
                                    < Typography className=" font-extrabold text-16" >
                                        {n.name}
                                    </Typography >
                                }
                                subheader={
                                    < Typography className=" line-clamp-1 text-14 text-pi-grey" >
                                        Followed by {followers.agent == '' || followers.agent == token.username ? 'You' : followers.agent} {followers.follower.length > 1 ? '+ ' + (followers.follower.length - 1) + ' more' : ''}
                                    </Typography >
                                }
                                action={
                                    <>
                                        <AgentFollowUnfollow item={{ id: n.agentidFollowed, userId: n.followedInfo?.userId, displayName: n.followedInfo?.displayName }} />
                                    </>
                                }
                            />
                        </div>
                    );
                })}
        </>
    );
}

export default withRouter(FriendsManagerTable);
