import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "@fuse/core/withRouter";
import FuseLoading from "@fuse/core/FuseLoading";
import { styled } from '@mui/material/styles';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { TextField, Button, Item, CardHeader } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import {
    Avatar,
    Box,
    CardActionArea,
    Divider,
    Icon,
    IconButton,
    Link,
    InputAdornment,
} from "@mui/material";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { faComments, faEye } from "@fortawesome/free-regular-svg-icons";
import CardActions from "@mui/material/CardActions";
import Stack from "@mui/material/Stack";
import TextLimit from "app/services/TextLimit";
import PhotoGridView from "app/services/PhotoGridView";
import AttachmentSvs from "app/services/AttachmentSvs";
import DateTimeDisplay from "app/services/DateTimeDisplay";
import ScrollToBottomTrigger from "app/services/ScrollToBottomTrigger";
import JoinerList from "./JoinerList";
import CollabSkeletonLoader from "./component/CollabSkeletonLoader";

function MissionDetailJoinersContent(params) {

    const dispatch = useDispatch();
    const { data, loading } = params;

    var modData = data ? data : [];

    if (loading) {
        return <div className="p-16">
            <Card className="p-16 rounded-lg mb-16 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0} > <CollabSkeletonLoader /></Card>
        </div>;
    }

    if (modData.length === 0) {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                className="flex flex-1 items-center justify-center h-full"
            >
                <Typography color="textSecondary" variant="h5">
                    There are no Joiners!
                </Typography>
            </motion.div>
        );
    }

    return (
        <>
            <JoinerList items={modData} onItemCenter={params.handleItemCenter} />
        </>
    );
}

export default MissionDetailJoinersContent;