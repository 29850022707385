import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller, FormProvider } from "react-hook-form";
import * as yup from "yup";
import reducer from 'app/main/apps/blogs/store';
import withReducer from "app/store/withReducer";
import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { styled, lighten } from '@mui/material/styles';
import { showMessage } from "app/store/fuse/messageSlice";
import { Switch } from "@mui/material";
import { followAgent, muteAgent, unFollowAgent, unmuteAgent } from "../store/FriendsManagerSlice";

import Notifications from "@mui/icons-material/Notifications";
import NotificationsOff from "@mui/icons-material/NotificationsOff";

const Root = styled('div')(({ theme }) => ({
    background: theme.palette.primary,
    color: theme.palette.primary.contrastText,

    '& .bg-icon': {
        background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${lighten(
            theme.palette.primary.dark,
            0.1
        )} 100%)`,
        color: theme.palette.primary.contrastText,
    },

}));

function FriendsManagerNotifyModal(props) {

    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [origData, setOrigData] = useState({});
    const [checked, setChecked] = useState(false);

    const [isActive, setIsActive] = useState(false);
    const [isDeactivate, setDeactivate] = useState(false);

    const [mute, setMute] = useState(false);
    const [unmute, setUnmute] = useState(false);

    useEffect(() => {
        if (props.item["notification"] == 1) {
            setMute(true)
            setUnmute(false)
        } else {
            setMute(false)
            setUnmute(true)
        }

    }, []);

    function handleOpenDialog() {
        setOpenDialog(true);
    }

    function handleCloseDialog() {
        setOpenDialog(false);
    }

    async function handleSaveMute() {
        const data = {
            agent_id: 21,
            notification: 0,
        }
        dispatch(muteAgent(data)).then(function (action) {
            setMute(false);
            setUnmute(true);
            handleCloseDialog()
        });
    }

    async function handleSaveUnmute() {
        const data = {
            agent_id: 21,
            notification: 1,
        }
        dispatch(unmuteAgent(data)).then(function (action) {
            setMute(true);
            setUnmute(false);
            props.loadData()
            handleCloseDialog()
        });
    }

    return (
        <Root>
            {props.module == "notify" && (
                <>
                    {
                        mute && (
                            < IconButton onClick={handleOpenDialog} aria-label="settings" size="large" >
                                <Notifications color="primary" className=" text-32" />
                            </IconButton >
                        )
                    }

                    {
                        unmute && (
                            < IconButton onClick={handleOpenDialog} aria-label="settings" size="large" >
                                <NotificationsOff color="disabled" className=" text-32" />
                            </IconButton >
                        )
                    }
                </>

            )}

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
                scroll="body"
                sx={{

                    '& .MuiPaper-root': {
                        width: "100%",
                    },
                }}
            >
                <AppBar position="static" elevation={0}>
                    <Toolbar className="flex w-full">
                        <Typography variant="subtitle1" color="inherit">
                            Friends Manager Action
                        </Typography>
                    </Toolbar>
                </AppBar>


                <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>

                    {mute && <Typography>Are you sure you want to mute this agent?</Typography>}

                    {unmute && <Typography>  Are you sure you want to unmute this agent?</Typography>}
                </DialogContent>

                <DialogActions className="justify-center px-8 py-16">
                    <div className="px-16">
                        {mute && <LoadingButton
                            className="rounded-md mr-5"
                            variant="contained"
                            color="primary"
                            type="button"
                            disabled={isSaving}
                            onClick={handleSaveMute}
                        >
                            Save
                        </LoadingButton>}

                        {unmute && <LoadingButton
                            className="rounded-md mr-5"
                            variant="contained"
                            color="primary"
                            type="button"
                            disabled={isSaving}
                            onClick={handleSaveUnmute}
                        >
                            Yes
                        </LoadingButton>}

                        <Button
                            className="rounded-md ml-5"
                            variant="outlined"
                            type="button"
                            onClick={handleCloseDialog}
                        >
                            Cancel
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </Root>
    );
}

export default withReducer("MissionApp", reducer)(FriendsManagerNotifyModal);
