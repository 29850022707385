import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";

import AppBar from "@mui/material/AppBar";
import { ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { memo } from "react";
import { useSelector } from "react-redux";
import { selectFooterTheme } from "app/store/fuse/settingsSlice";
import clsx from "clsx";

import Typography from "@mui/material/Typography";
import {
    Button,
    Divider,
    IconButton,
    Link,
    Stack,
    useMediaQuery,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFacebook, } from '@fortawesome/free-regular-svg-icons'
import {
    faTwitter,
    faFacebook,
    faYoutube,
    faPinterest,
    faTumblr,
    faInstagram,
} from "@fortawesome/free-brands-svg-icons";

function PublicFooter(props) {
    const footerTheme = useSelector(selectFooterTheme);
    const mobileSize = useMediaQuery("(min-width:600px)");
    const mobileSizeSm = useMediaQuery("(min-width:510px)");

    return (
        <ThemeProvider theme={footerTheme}>
            <AppBar
                id="fuse-footer"
                className={clsx(
                    "relative z-20 shadow-md bg-pi-primary",
                    props.className
                )}
                color="default"
            >
                <Toolbar className=" container min-h-48 md:min-h-64 px-8 sm:px-12 lg:px-20 py-0 flex flex-col items-center overflow-x-auto ">
                    <Stack
                        direction={mobileSizeSm == true ? "row" : "column"}
                        justifyContent={"space-between"}
                        className="w-full  max-w-lg m-auto my-24"
                    >
                        <div className=" w-full">
                            <Typography
                                className="FuseSettings-formGroupTitle font-medium font-anton  tracking-widest text-36 text-pi-black"
                                color="inherit"
                            >
                                REACH <span className="text-white">IMPOSSIBLE</span>
                            </Typography>
                            <Stack direction={"row"} className="mt-16" spacing={1}>
                                <IconButton edge={false}>
                                    {" "}
                                    <FontAwesomeIcon
                                        icon={faFacebook}
                                        size={mobileSizeSm == true ? "xl" : "lg"}
                                        className=" hover:animate-bounce"
                                        style={{ color: "#3b5998 " }}
                                    />{" "}
                                </IconButton>
                                <IconButton edge={false}>
                                    {" "}
                                    <FontAwesomeIcon
                                        icon={faTwitter}
                                        size={mobileSizeSm == true ? "xl" : "lg"}
                                        className=" hover:animate-bounce"
                                        style={{ color: "#00acee" }}
                                    />{" "}
                                </IconButton>
                                <IconButton edge={false}>
                                    {" "}
                                    <FontAwesomeIcon
                                        icon={faYoutube}
                                        size={mobileSizeSm == true ? "xl" : "lg"}
                                        className=" hover:animate-bounce"
                                        style={{ color: "#c4302b" }}
                                    />{" "}
                                </IconButton>
                                <IconButton edge={false}>
                                    {" "}
                                    <FontAwesomeIcon
                                        icon={faPinterest}
                                        size={mobileSizeSm == true ? "xl" : "lg"}
                                        className=" hover:animate-bounce"
                                        style={{ color: "#c8232c" }}
                                    />{" "}
                                </IconButton>
                                <IconButton edge={false}>
                                    {" "}
                                    <FontAwesomeIcon
                                        icon={faTumblr}
                                        size={mobileSizeSm == true ? "xl" : "lg"}
                                        className=" hover:animate-bounce"
                                        style={{ color: "#34526f" }}
                                    />{" "}
                                </IconButton>
                                <IconButton edge={false}>
                                    {" "}
                                    <FontAwesomeIcon
                                        icon={faInstagram}
                                        size={mobileSizeSm == true ? "xl" : "lg"}
                                        className=" hover:animate-bounce"
                                        style={{ color: "#bc2a8d" }}
                                    />{" "}
                                </IconButton>
                            </Stack>
                        </div>

                        <Stack direction={mobileSizeSm == true ? "row" : "column"}>
                            <Divider
                                orientation={mobileSizeSm == true ? "vertical" : "horizontal"}
                                flexItem
                                className={
                                    mobileSizeSm == true
                                        ? " border-pi-black"
                                        : "border-pi-black mt-16"
                                }
                            />
                            <Stack className={mobileSizeSm == true ? "ml-16" : "mt-16"}>
                                <Typography
                                    className="FuseSettings-formGroupTitle break-words text-16"
                                    color="inherit"
                                >
                                    Ready to join the Planet Impossible Network and make a
                                    difference?
                                </Typography>
                                <center className="mt-24 text-left">
                                    <Button
                                        variant="contained"
                                        className="bg-accept-mission text-pi-primary hover:bg-pi-hover-yellow hover:text-pi-black uppercase font-bold rounded-none"
                                    >
                                        Accept Missions
                                    </Button>
                                </center>
                            </Stack>
                        </Stack>
                    </Stack>
                    <div className="max-w-lg my-16 flex flex-col w-full items-center ">
                        <Divider
                            orientation="horizontal"
                            flexItem
                            className=" border-pi-black "
                        />
                        <img
                            className=" filter grayscale my-10"
                            src="assets/images/pi-logo-transpa.png"
                            alt="react"
                            width="260"
                            style={{ filter: "grayscale(100%)" }}
                        />
                        <Stack
                            direction={"row"}
                            justifyContent={"space-around"}
                            className={mobileSize == true ? "w-3/4" : "w-full"}
                            spacing={1}
                        >
                            <Link
                                underline="hover"
                                className=" text-pi-black text-13 no-underline"
                                component="button"
                            >
                                Contact
                            </Link>
                            <Link
                                underline="hover"
                                className=" text-pi-black text-13 no-underline"
                                component="button"
                            >
                                About
                            </Link>
                            <Link
                                underline="hover"
                                className=" text-pi-black text-13 no-underline"
                                component="button"
                            >
                                Terms
                            </Link>
                            <Link
                                underline="hover"
                                className=" text-pi-black text-13 no-underline"
                                component="button"
                            >
                                Privacy
                            </Link>
                            <Link
                                underline="hover"
                                className=" text-pi-black text-13 no-underline"
                                component="button"
                            >
                                Policy & Safety
                            </Link>
                            <Link
                                underline="hover"
                                className=" text-pi-black text-13 no-underline"
                                component="button"
                            >
                                My Account
                            </Link>
                        </Stack>
                        <Typography
                            className="FuseSettings-formGroupTitle text-pi-black text-13 mt-16"
                            color="textSecondary"
                        >
                            Copyright © 2023 Planet Impossible. All rights reserved.
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    );
}

export default PublicFooter;
