
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller, FormProvider } from "react-hook-form";
import * as yup from "yup";
import reducer from 'app/main/apps/blogs/store';
import withReducer from "app/store/withReducer";
import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { styled, lighten, alpha } from '@mui/material/styles';
import { showMessage } from "app/store/fuse/messageSlice";
import { Divider, Switch } from "@mui/material";
import { updateMissionStatus } from "app/main/apps/missions/store/MissionsManageSlice";
import { pink, blue } from '@mui/material/colors';
// import { updateMissionStatus } from "../../store/MissionsManageSlice";
import { DialogTitle } from "@mui/material";
const Root = styled('div')(({ theme }) => ({
    background: theme.palette.primary,
    color: theme.palette.primary.contrastText,

    '& .bg-icon': {
        background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${lighten(
            theme.palette.primary.dark,
            0.1
        )} 100%)`,
        color: theme.palette.primary.contrastText,
    },

}));
const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: blue[400],
        '&:hover': {
            backgroundColor: alpha(blue[400], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: blue[400],
    },
}));
function MissionManagerStatusModal(props) {

    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [origData, setOrigData] = useState({});
    const [checked, setChecked] = useState(false);

    const [isActive, setIsActive] = useState(false);
    const [isDeactivate, setDeactivate] = useState(false);

    // const n_id = watch("id");

    useEffect(() => {
        if (props.item["status"] == "activate") {
            setChecked(true)
        } else {
            setChecked(false)
        }

    }, []);

    function handleOpenDialog() {
        setOpenDialog(true);
    }

    function handleCloseDialog() {
        setOpenDialog(false);
    }

    async function handleSaveDeactivate() {
        // setIsSaving(true);
        const id = props.item["id"]
        const data = {
            id: id,
            change_status: "deactivate",
        }
        dispatch(updateMissionStatus(data)).then(function (action) {
            setChecked(false);
            handleCloseDialog()
        });


    }

    async function handleSave() {
        const id = props.item["id"]
        const data = {
            id: id,
            change_status: "activate",
        }
        dispatch(updateMissionStatus(data)).then(function (action) {
            setChecked(true);
            handleCloseDialog()
        });
    }

    const swithcChange = (event) => {
        console.log("switch status", event.target.checked)
        if (event.target.checked === true) {
            setIsActive(true)
            setDeactivate(false)
            handleOpenDialog()
        } else {
            setIsActive(false)
            setDeactivate(true)
            handleOpenDialog()
        }
    }

    return (
        <Root>
            {props.module == "status" && (
                <Tooltip title={"Change Status"} arrow>
                    <GreenSwitch edge="end" color="secondary" checked={checked} onChange={swithcChange} />
                </Tooltip>
            )}

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
                scroll="body"
                sx={{

                    '& .MuiPaper-root': {
                        width: "100%",
                    },
                }}
            >
                <DialogTitle className="flex w-full">
                    <Typography className="font-bold text-18" color="inherit">
                        Confirm Status Action
                    </Typography>
                </DialogTitle>
                <Divider />

                <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>

                    {isActive && <Typography>Are you sure you want to activate this mission?</Typography>}

                    {isDeactivate && <Typography>Are you sure you want to deactivate this mission?</Typography>}


                </DialogContent>

                <DialogActions className="justify-center px-8 py-16">
                    <div className="px-16">
                        {isActive && <LoadingButton
                            className="rounded-md mr-5"
                            variant="contained"
                            color="primary"
                            type="button"
                            disabled={isSaving}
                            onClick={handleSave}
                        >
                            Save
                        </LoadingButton>}

                        {isDeactivate && <LoadingButton
                            className="rounded-md mr-5"
                            variant="contained"
                            color="primary"
                            type="button"
                            disabled={isSaving}
                            onClick={handleSaveDeactivate}
                        >
                            Yes
                        </LoadingButton>}

                        <Button
                            className="rounded-md ml-5"
                            variant="outlined"
                            type="button"
                            onClick={handleCloseDialog}
                        >
                            Cancel
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </Root>
    );
}

export default withReducer("blogsApp", reducer)(MissionManagerStatusModal);
