import {
    createSelector,
    createEntityAdapter,
    createSlice,
} from "@reduxjs/toolkit";
import navigationConfig from "app/fuse-configs/navigationConfig";
import FuseUtils from "@fuse/utils";
import i18next from "i18next";
import JwtService from "app/services/jwtService";
import _ from "@lodash";

const navigationAdapter = createEntityAdapter();
const emptyInitialState = navigationAdapter.getInitialState();
const initialState = navigationAdapter.upsertMany(
    emptyInitialState,
    navigationConfig
);

export const appendNavigationItem =
    (item, parentId) => (dispatch, getState) => {
        const navigation = selectNavigationAll(getState());

        return dispatch(
            setNavigation(FuseUtils.appendNavItem(navigation, item, parentId))
        );
    };

export const prependNavigationItem =
    (item, parentId) => (dispatch, getState) => {
        const navigation = selectNavigationAll(getState());

        return dispatch(
            setNavigation(FuseUtils.prependNavItem(navigation, item, parentId))
        );
    };

export const updateNavigationItem = (id, item) => (dispatch, getState) => {
    const navigation = selectNavigationAll(getState());

    return dispatch(setNavigation(FuseUtils.updateNavItem(navigation, id, item)));
};

export const removeNavigationItem = (id) => (dispatch, getState) => {
    const navigation = selectNavigationAll(getState());

    return dispatch(setNavigation(FuseUtils.removeNavItem(navigation, id)));
};

export const {
    selectAll: selectNavigationAll,
    selectIds: selectNavigationIds,
    selectById: selectNavigationItemById,
} = navigationAdapter.getSelectors((state) => state.fuse.navigation);

const navigationSlice = createSlice({
    name: "navigation",
    initialState,
    reducers: {
        setNavigation: navigationAdapter.setAll,
        resetNavigation: (state, action) => initialState,
    },
});

export const { setNavigation, resetNavigation } = navigationSlice.actions;

const getUserRole = (state) => state.auth.user.roles;

const getUserType = (state) => state.auth.user.role;

export const selectNavigation = createSelector(
    [selectNavigationAll, ({ i18n }) => i18n.language, getUserRole, getUserType],
    (navigation, language, userRole, userType) => {
        function setTranslationValues(data) {
            // loop through every object in the array
            return data.map((item) => {
                if (item.show) {
                    if (item.translate && item.title) {
                        item.title = i18next.t(`navigation:${item.translate}`);
                    }

                    // see if there is a children node
                    if (item.children) {
                        // run this function recursively on the children array
                        item.children = setTranslationValues(item.children);
                    }

                    return item;
                } else {
                    return [];
                }
            });
        }

        return setTranslationValues(
            _.merge(
                [],
                filterRecursively(navigation, (item) =>
                    FuseUtils.hasPermission(item.auth, userRole, userType)
                )
            )
        );
    }
);

function filterRecursively(arr, predicate) {
    return arr.filter(predicate).map((item) => {
        item = { ...item };
        if (item.children) {
            item.children = filterRecursively(item.children, predicate);
        }
        return item;
    });
}

export const selectFlatNavigation = createSelector(
    [selectNavigation],
    (navigation) => FuseUtils.getFlatNavigation(navigation)
);

export default navigationSlice.reducer;
