const mutation = {
    unfollowAgent: `
        mutation unfollowAgent($data: JSONString) {
           unfollowAgent (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    followAgent: `
        mutation followAgent($data: JSONString) {
           followAgent (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    followNotifAgent: `
        mutation followNotifAgent($data: JSONString) {
           followNotifAgent (data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    agentFollowStatus: `
        query agentFollowStatus($agentId: String, $followedId: String) {
            agentFollowStatus(agentId: $agentId, followedId: $followedId) {
                agentidFollower
                notification
            }
        }
    `,
};

export { mutation, query };
