import { amber, blue, green } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Typography from '@mui/material/Typography';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideMessage } from 'app/store/fuse/messageSlice';
import Portal from '@mui/base/Portal';

const StyledSnackbar = styled(Snackbar)(({ theme, variant }) => ({
  '& .FuseMessage-content': {
    ...(variant === 'success' && {
      backgroundColor: green[600],
      color: '#FFFFFF',
    }),

    ...(variant === 'error' && {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.getContrastText(theme.palette.error.dark),
    }),

    ...(variant === 'info' && {
      backgroundColor: blue[600],
      color: '#FFFFFF',
    }),

    ...(variant === 'warning' && {
      backgroundColor: amber[600],
      color: '#FFFFFF',
    }),
  },
}));

const variantIcon = {
  success: 'check_circle',
  warning: 'warning',
  error: 'error_outline',
  info: 'info',
};

function FuseMessage(props) {
  const dispatch = useDispatch();
  const state = useSelector(({ fuse }) => fuse.message.state);
  const options = useSelector(({ fuse }) => fuse.message.options);

  return (
    <Portal>
      <StyledSnackbar
        {...options}
        open={state}
        onClose={() => dispatch(hideMessage())}
        ContentProps={{
          variant: 'body2',
          headlineMapping: {
            body1: 'div',
            body2: 'div',
          },
        }}
      >
        <SnackbarContent
          className="FuseMessage-content max-600px:mb-20  max-600px:flex-nowrap max-600px:px-12 justify-between"
          sx={{
            '& .MuiSnackbarContent-action': { paddingLeft: '0', marginLeft: '5px' },
            '& .MuiButtonBase-root': { textAlign: 'right' },
          }}
          message={
            <div className="flex items-center ">
              {variantIcon[options.variant] && (
                <Icon color="inherit">{variantIcon[options.variant]}</Icon>
              )}
              <Typography className="mx-8 max-600px:mx-3">{options.message}</Typography>
            </div>
          }
          action={[
            <IconButton
              className='max-600px:hidden'
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => dispatch(hideMessage())}
              size="large"
              edge={false}
            >
              <Icon>close</Icon>
            </IconButton>,
            <IconButton
              key="close"
              className='hidden max-600px:block'
              aria-label="Close"
              color="inherit"
              onClick={() => dispatch(hideMessage())}
              size="small"
              edge={false}
            >
              <Icon className='max-600px:mt-3'>close</Icon>
            </IconButton>,
          ]}
        />
      </StyledSnackbar>
    </Portal>
  );
}

export default memo(FuseMessage);
