import { Badge, Box, Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import AttachmentSvs from 'app/services/AttachmentSvs';
import DateTimeDisplay from 'app/services/DateTimeDisplay';
import PhotoGridView from 'app/services/PhotoGridView';
import TextLimit from 'app/services/TextLimit';
import React, { useRef, useEffect } from 'react';
import MoreVertIcon from "@mui/icons-material/MoreVert";

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Input from '@mui/material/Input';
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";

const JoinerList = ({ items, onItemCenter }) => {
    console.log("JoinerList")
    const observerRef = useRef();
    const itemRefs = useRef({});

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1, // Trigger when 50% of the element is in view n
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Call the function when item reaches the center
                    onItemCenter(itemRefs.current[entry.target.dataset.itemId].id);
                }
            });
        }, options);

        observerRef.current = observer;

        // return () => {
        //     // Cleanup observer on component unmount
        //     observer.disconnect();
        // };
    }, [onItemCenter]);

    useEffect(() => {
        // Observe each item in the list
        items.forEach((item) => {
            itemRefs.current[item.id] = item.ref;
            observerRef.current.observe(item.ref);
        });

        return () => {
            // Cleanup observer on component unmount
            items.forEach((item) => {
                observerRef.current.unobserve(itemRefs.current[item.id]);
            });
        };
    }, [items]);

    class PinnedIcon extends React.Component {
        render() {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '1.8em', height: '1.8em' }} viewBox="0 0 8.84 12.243">
                    <g id="Group_1452" data-name="Group 1452" transform="translate(-1197.877 -2025.664)">
                        <path id="Subtraction_1" data-name="Subtraction 1" d="M3.744,7.489A3.744,3.744,0,1,1,7.489,3.744,3.749,3.749,0,0,1,3.744,7.489ZM3.693,1.5A2.245,2.245,0,1,0,5.937,3.744,2.248,2.248,0,0,0,3.693,1.5Z" transform="translate(1198.553 2026.501)" fill="#2EB7D7" />
                        <path id="Path_1201" data-name="Path 1201" d="M8.87,4.082a4.317,4.317,0,0,0-.1-.667A4.412,4.412,0,0,0,4.594,0H4.33c-.119,0-.239.013-.357.026A4.3,4.3,0,0,0,3.139.2a4.419,4.419,0,0,0-3.1,4.066v.306c0,.13.014.259.028.388a5.429,5.429,0,0,0,.137.753A4.668,4.668,0,0,0,.7,6.924c.115.195.244.381.364.572L3.7,11.709c.05.079.1.163.152.236a.77.77,0,0,0,.845.26.7.7,0,0,0,.395-.288L7.733,7.706c.113-.18.224-.363.341-.541a4.235,4.235,0,0,0,.24-.4,5.18,5.18,0,0,0,.559-2.045,4.441,4.441,0,0,0,0-.642ZM6.343,7.16c-.031.022-.062.043-.094.063l-.039.025c-.036.023-.073.044-.11.065s-.075.042-.112.061l-.005,0-.11.054c-.039.018-.079.036-.119.052-.08.033-.162.063-.245.091s-.169.052-.255.073c-.024.006-.048.012-.072.017l-.045.01-.082.016L4.971,7.7l-.082.012h0c-.084.011-.168.018-.253.022l-.045,0H4.58l-.116,0h-.01l-.091,0c-.026,0-.051,0-.077,0s-.053,0-.079,0a3.369,3.369,0,0,1-.5-.076c-.054-.011-.106-.025-.159-.041L3.51,7.6l-.069-.021-.062-.021L3.318,7.54h0q-.088-.032-.174-.07l-.073-.032c-.048-.022-.1-.045-.142-.07l-.071-.038-.02-.011A3.335,3.335,0,1,1,6.343,7.16Z" transform="translate(1197.833 2025.664)" fill="#2EB7D7" />
                    </g>
                </svg>
            )
        }
    }
    return (
        <div className='p-16 pt-0'>
            {items && items.map((item, index) => (
                <div id={item.id} key={index} ref={(el) => (item.ref = el)} data-item-id={item.id}>


                    <Card className="p-16 rounded-lg mb-16 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0} key={index}>
                        <CardHeader
                            className="p-0"
                            sx={{
                                "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                "& .MuiAvatar-root": { border: '2.5px solid #2EB7D7' },
                            }}
                            avatar={
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    sx={{
                                        '& .MuiBadge-anchorOriginBottomRightCircular': {
                                            borderRadius: '50%',
                                            backgroundColor: 'white',
                                            padding: '0px',
                                            paddingTop: '6px',
                                            bottom: '10px',
                                            minWidth: '20px',
                                            height: '20px'
                                        }
                                    }}
                                    badgeContent={
                                        <span
                                        >
                                            <PinnedIcon style={{ fill: '#FBB633' }} size={'small'} />
                                        </span>
                                    }
                                >
                                    <Link style={{ textDecoration: 'none' }} to={`/agentprofile?agentId=${item.agent?.userId}`}>
                                        <AttachmentSvs module={"at-6"} path={item.agent?.profilePicPath} data={{ name: item.agent?.name }} />
                                    </Link>

                                </Badge>
                            }
                            title={
                                <Typography className=" font-extrabold text-16">
                                    <Link style={{ textDecoration: 'none' }} to={`/agentprofile?agentId=${item.agent?.userId}`}>
                                        {item.agent?.flName}
                                    </Link>
                                </Typography>
                            }
                            subheader={
                                <Typography className=" line-clamp-1 font-500 text-15 text-pi-grey">
                                    Joined {item.dateCreated && (
                                        <DateTimeDisplay dateTime={item.dateCreated} />
                                    )}
                                </Typography>
                            }
                            action={
                                <IconButton aria-label="settings" className="hidden" edge={false}>
                                    <MoreVertIcon />
                                </IconButton>
                            }
                        />
                        <CardContent className="p-0">
                            <Typography
                                component="div"
                                className="font-bold mt-5 text-18 mb-0 text-pi-black line-clamp-2"
                            >
                                {item.title}
                            </Typography>
                            <Box className="flex items-end mb-6 relative">
                                <div className="text-16 mb-3">
                                    {item.description && (
                                        <TextLimit text={item.description} limit={200} />
                                    )}
                                </div>
                            </Box>
                        </CardContent>
                        <PhotoGridView fileId={item.filesId} />
                    </Card>
                </div>
            ))}
        </div>
    );
};

export default JoinerList;