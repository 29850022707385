import Card from '@mui/material/Card';
import { styled, lighten } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import JWTLoginTab from './tabs/JWTLoginTab';
import JwtService from "app/services/jwtService";


function Login() {
  useEffect(function() {
    JwtService.setJwtLoginReload(false);
  }, []);
  return (
    <div className="flex flex-col flex-auto items-center justify-center p-16 sm:p-32">
      <div className="flex flex-col items-center justify-center w-full">
        <motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }}>
          <Card className="w-full max-w-384">
            <CardContent className="flex flex-col items-center justify-center p-16 sm:p-24 md:p-32">
              <img className=" w-80 mx-32" src="assets/images/pi-icon-original.svg" alt="logo" />
              <Typography variant="h6" className="font-semibold text-18 sm:text-24">
                Welcome back Admin!
              </Typography>
              <JWTLoginTab />
            </CardContent>
          </Card>
        </motion.div>
      </div>
    </div>
  );
}

export default Login;
