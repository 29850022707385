import { Container, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { Component } from "react";
import ReactApexChart from 'react-apexcharts';
// button
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

export default function Tab3() {
    // Responsive design hwag delete mag aaway tayo
    const theme = useTheme();
    // const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches = useMediaQuery('(min-width:690px)');

    const options = ['Top Countries', 'Top Cities',];
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleClick = () => {
        console.info(`You clicked ${options[selectedIndex]}`);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const CitiesLabel = ['Phillipines', 'United States', 'China'];
    const CountriesLabel = ['Manila', 'Las Vegas', 'Bonjing'];


    const data = {
        agentMissions: {
            chart: {
                type: "donut"
            },
            options: {
                plotOptions: {
                    pie: {
                        donut: {
                            size: "50%"
                        }
                    },
                },
                colors: ['#ffcc00', '#2A384E'],
                labels: ['Agents are following Agent 0071', 'Agents who accepted the mission of Agent 0071',],
                legend: {
                    position: 'right',
                    fontSize: '16px',
                    offsetX: 0,
                    offsetY: 0,
                },
                responsive: [
                    {
                        breakpoint: 630,
                        options: {
                            chart: {
                                width: '100%'
                            },
                            legend: {
                                position: "top",
                                horizontalAlign: 'left',
                                inverseOrder: false,
                            }
                        }
                    }],
            },

            series: [44, 55,],
        },
        missionLoacations: {
            options: {
                series: [{
                    name: 'Agents',
                    data: [{
                        x: 'category A',
                        y: 10
                    }, {
                        x: 'category B',
                        y: 18
                    }, {
                        x: 'category C',
                        y: 13
                    }]
                }],
                chart: {
                    type: "bar",
                    toolbar: {
                        show: false
                    },
                },

                plotOptions: {
                    pie: {
                        donut: {
                            size: "50%"
                        }
                    },
                    plotOptions: {
                        bar: {
                            distributed: true
                        }
                    }
                },
                colors: [options[selectedIndex] == 'Top Cities' ? '#2A384E' : '#ffcc00'],
                // colors:['#2A384E'], kapag city ito
                labels: options[selectedIndex] == 'Top Countries' ? CitiesLabel : CountriesLabel,
                legend: {
                    position: 'right',
                    fontSize: '16px',
                    offsetX: 0,
                    offsetY: 0,
                },
                responsive: [
                    {
                        breakpoint: 630,
                        options: {
                            chart: {
                                width: '100%'
                            },
                            legend: {
                                position: "top",
                                horizontalAlign: 'left',
                                inverseOrder: false,
                            }
                        }
                    }],
            },


        },

    };

    return (
        <>
            <ReactApexChart
                options={data.agentMissions.options}
                series={data.agentMissions.series}
                type={data.agentMissions.chart.type}
                height={matches == true ? '200' : '300'}
            />
            <Typography variant="h5" align="center" className="my-10">Where these Mission Agents are Taking Action</Typography>

            <Container>
                <Stack direction={'row'} justifyContent={'space-between'} className=" z-20 relative">
                    <Typography>{options[selectedIndex]}</Typography>
                    <React.Fragment>
                        <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                            <Button onClick={handleClick}>{options[selectedIndex]}</Button>
                            <Button
                                size="small"
                                aria-controls={open ? 'split-button-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-label="select merge strategy"
                                aria-haspopup="menu"
                                onClick={handleToggle}
                            >
                                <ArrowDropDownIcon />
                            </Button>
                        </ButtonGroup>
                        <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom' ? 'center top' : 'center bottom',
                                    }}
                                >
                                    <Paper className="">
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList id="split-button-menu">
                                                {options.map((option, index) => (
                                                    <MenuItem
                                                        key={option}
                                                        disabled={index === 2}
                                                        selected={index === selectedIndex}
                                                        onClick={(event) => handleMenuItemClick(event, index)}
                                                    >
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </React.Fragment>
                </Stack>
                <ReactApexChart
                    options={data.missionLoacations.options}
                    series={data.missionLoacations.options.series}
                    type={'bar'}
                    height={matches == true ? '200' : '300'}
                />
            </Container>
        </>
    );
}
