const mutation = {};

const query = {
    getAuditLogs: `
        query getAuditLogs($dateFrom: String, $dateTo: String) {
            getAuditLogs(dateFrom: $dateFrom, dateTo: $dateTo) {
                id
                module
                action
                username
                userId
                details
                dateFormatted
                dateCreated
            }
        }
        
    `,
}

export { mutation, query };