import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from 'app/config';
import { query, mutation } from "../graphql/blogs_tag_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import format from 'date-fns/format';
import JwtService from "app/services/jwtService";
import { isEmpty } from "lodash";

export const saveBlogTag = createAsyncThunk(
    "blogsApp/tag/saveBlogTag",
    async (params, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.saveBlogTag,
            variables: { data: JSON.stringify(params) },
        });

        if (response.data.data.saveBlogTag) {
            dispatch(
                showMessage({
                    message: "Tag has been successfully saved.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );
        } else {
            dispatch(
                showMessage({
                    message: "Something went wrong. Please try again later.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }
    }
);

export const getBlogTagList = createAsyncThunk(
    "blogsApp/tag/getBlogTagList",
    async (keyword) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getBlogTagList,
            variables: {
                keyword: keyword ? keyword : null
            }
        });

        if (response.data.data.getBlogTagList) {
            const data = response.data.data.getBlogTagList;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const deleteBlogTag = createAsyncThunk(
    'blogsApp/tag/deleteBlogTag',
    async (depIds, { dispatch, getState }) => {

        var count = 0;

        await depIds.map(async (id) => {
            await axios.post(CONFIG.API + "/api/", {
                query: mutation.deleteBlogTag,
                variables: {
                    id: id
                }
            });
            count++;
        })

        if (count == depIds.length) {
            dispatch(
                showMessage({
                    message: "Tag(s) has been successfully deleted.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );
            return depIds;
        }

    }
);

export const checkBlogTagName = (filter) => async (dispatch) => {

    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.checkBlogTagName,
        variables: filter
    });

    if (response.data.data.checkBlogTagName.length) {
        return false;
    } else {
        return true;
    }
};


const blogManageTagsSlice = createSlice({
    name: 'blogsApp/tag',
    initialState: null,
    reducers: null,
    extraReducers: {
        [saveBlogTag.fulfilled]: (state, action) => action.payload,
        [getBlogTagList.fulfilled]: (state, action) => action.payload,
        [deleteBlogTag.fulfilled]: (state, action) => action.payload,
    },
});

export default blogManageTagsSlice.reducer;
