import { createSlice } from '@reduxjs/toolkit';
import 'firebase/compat/auth';
import history from '@history';
import _ from '@lodash';
import { setInitialSettings, setDefaultSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import AgentJwtService from 'app/services/agent/AgentJwtService';
import settingsConfig from 'app/fuse-configs/settingsConfig';

export const setUserData = (user) => async (dispatch, getState) => {
    /*
    You can redirect the logged-in user to a specific route depending on his role
    */
    if (user.loginRedirectUrl) {
        settingsConfig.loginRedirectUrl = user.loginRedirectUrl; // for example 'apps/academy'
    }
    /*
    Set User Settings
    */
    dispatch(setDefaultSettings(user.data.settings));

    dispatch(setUser(user));
};

export const updateUserSettings = (settings) => async (dispatch, getState) => {
    const oldUser = getState().agent.agentuser;
    const user = _.merge({}, oldUser, { data: { settings } });

    dispatch(updateUserDataSettings(user));

    return dispatch(setUserData(user));
};

export const updateUserShortcuts = (shortcuts) => async (dispatch, getState) => {
    const { agentuser } = getState().agent;
    const newUser = {
        ...agentuser,
        data: {
            ...agentuser.data,
            shortcuts,
        },
    };

    dispatch(updateUserData(newUser));

    return dispatch(setUserData(newUser));
};

export const logoutUser = () => async (dispatch, getState) => {
    const { agentuser } = getState().agent;

    if ((!agentuser.role || agentuser.role.length === 0)) {
        // is guest
        return null;
    }

    history.push({
        pathname: '/agentlogin',
    });

    AgentJwtService.logout();

    return dispatch(setInitialSettings());
};

export const updateUserData = (user) => async (dispatch, getState) => {
    AgentJwtService
        .updateUserData(user)
        .then(() => {
            dispatch(showMessage({ message: 'User data saved with api' }));
        })
        .catch((error) => {
            dispatch(showMessage({ message: error.message }));
        });
};

export const updateUserDataSettings = (user) => async (dispatch, getState) => {
    AgentJwtService
        .updateUserDataSettings(user)
        .then(() => {
            dispatch(showMessage({ message: 'User data saved with api' }));
        })
        .catch((error) => {
            dispatch(showMessage({ message: error.message }));
        });
};

const initialState = {
    role: [], // guest
    data: {
        displayName: 'John Doe',
        photoURL: 'assets/images/avatars/Velazquez.jpg',
        email: 'johndoe@withinpixels.com',
        shortcuts: ['calendar', 'mail', 'contacts', 'todo'],
    },
};

const agentUserSlice = createSlice({
    name: 'agent/user',
    initialState,
    reducers: {
        setUser: (state, action) => action.payload,
        userLoggedOut: (state, action) => initialState,
    },
    extraReducers: {},
});

export const { setUser, userLoggedOut } = agentUserSlice.actions;

export default agentUserSlice.reducer;
