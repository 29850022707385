import { Box } from "@mui/material";
import * as React from "react";
import { Link } from 'react-router-dom';
function PublicSidebarLeftLogo(props) {

    class PiLogo extends React.Component {
        render() {
            return (
                <svg id="hv2" xmlns="http://www.w3.org/2000/svg" width="219.219" height="33.539" viewBox="0 0 219.219 33.539">
                    <g id="Group_753" data-name="Group 753">
                        <g id="Group_749" data-name="Group 749">
                            <g id="Group_748" data-name="Group 748" transform="translate(0.399 0.399)">
                                <path id="Rectangle_109" data-name="Rectangle 109" d="M27.473,12.04h0A16.377,16.377,0,0,1,43.847,28.413h0A16.377,16.377,0,0,1,27.473,44.787h0A16.377,16.377,0,0,1,11.1,28.413h0A16.377,16.377,0,0,1,27.473,12.04Z" transform="translate(-11.1 -12.04)" fill="#fc0" />
                                <circle id="Ellipse_75" data-name="Ellipse 75" cx="12.977" cy="12.977" r="12.977" transform="translate(3.323 3.359)" fill="#f8fafb" />
                                <path id="Path_166" data-name="Path 166" d="M28.068,30.22c-1.148,5.362-2.279,10.656-3.482,16.288a13.088,13.088,0,0,1-3.445-3.022c.375-1.689.719-3.224,1.05-4.766s.6-3.1.977-4.631c.626-2.543,2.064-3.679,4.9-3.863Z" transform="translate(-14.974 -19.055)" fill="#314159" />
                                <path id="Path_15" data-name="Path 15" d="M34.769,30.05h4.066c-.768,3.568-1.511,7.057-2.272,10.582H32.49c.774-3.568,1.523-7.057,2.285-10.582Z" transform="translate(-19.353 -18.989)" fill="#314159" />
                                <path id="Path_167" data-name="Path 167" d="M45.192,30.056h4.053l-.332,1.683-.326,1.628-.166.817c-.172.817-.35,1.634-.553,2.444-.645,2.586-1.855,3.587-4.76,3.943l2-10.06v-.025l.092-.436Z" transform="translate(-23.451 -18.989)" fill="#314159" />
                                <path id="Path_168" data-name="Path 168" d="M51.42,23.449l-.117.59H47.25l.712-3.568a12.881,12.881,0,0,1,3.458,2.985Z" transform="translate(-25.048 -15.293)" fill="#314159" />
                            </g>
                            <rect id="Rectangle_203" data-name="Rectangle 203" width="33.539" height="33.539" fill="none" />
                        </g>
                        <g id="Group_752" data-name="Group 752" transform="translate(37.697 5.546)">
                            <g id="Group_750" data-name="Group 750" transform="translate(0 5.914)">
                                <path id="Path_169" data-name="Path 169" d="M74.532,33.323l-2.7,13.911h3.783L78.954,30.05h-.51a4.168,4.168,0,0,0-3.906,3.273Z" transform="translate(-71.83 -30.05)" fill="#314159" />
                                <path id="Path_170" data-name="Path 170" d="M99.125,30.05H95.348L93.34,40.368h7.29l.522-2.69H97.645Z" transform="translate(-80.129 -30.05)" fill="#314159" />
                                <path id="Path_171" data-name="Path 171" d="M152.6,30.05h-3.187l-2,10.318h8.23l.547-2.813H151.74l.2-1.062h3.151l.5-2.573h-3.151l.2-1.062H157.1l.547-2.807Z" transform="translate(-100.992 -30.05)" fill="#314159" />
                                <path id="Path_172" data-name="Path 172" d="M166.7,32.857h2.653l-1.462,7.511h3.783l1.456-7.511h2.653l.54-2.807h-9.077Z" transform="translate(-108.435 -30.05)" fill="#314159" />
                                <path id="Path_173" data-name="Path 173" d="M83.67,40.368h3.783l2-10.318H85.672Z" transform="translate(-76.398 -30.05)" fill="#314159" />
                                <path id="Path_174" data-name="Path 174" d="M119.044,30.05h-6.915a1.79,1.79,0,0,0-1.677,1.4l-1.732,8.911H112.5l.479-2.475h2.156l-.479,2.475h3.783l1.732-8.911a1.12,1.12,0,0,0-1.13-1.4Zm-5.515,5.03.411-2.113H116.1l-.411,2.113Z" transform="translate(-86.064 -30.05)" fill="#314159" />
                                <path id="Path_175" data-name="Path 175" d="M138.388,30.05h-8.316l-2,10.312h3.783l1.462-7.505h2.156l-1.462,7.505h3.783l1.732-8.911a1.12,1.12,0,0,0-1.13-1.4Z" transform="translate(-93.53 -30.05)" fill="#314159" />
                            </g>
                            <g id="Group_751" data-name="Group 751" transform="translate(72.452)">
                                <path id="Path_176" data-name="Path 176" d="M277.78,30.05h-6.915a1.79,1.79,0,0,0-1.677,1.4l-.706,3.636a1.12,1.12,0,0,0,1.13,1.4h5.079l-.3,1.542h-3.237l.135-.706h-3.243l-.135.706h0l-.178.927a1.12,1.12,0,0,0,1.13,1.4h6.915a1.79,1.79,0,0,0,1.677-1.4l.706-3.636a1.12,1.12,0,0,0-1.13-1.4h-5.073l.3-1.542H275.5l-.135.7H278.6l.135-.7h0l.178-.927a1.12,1.12,0,0,0-1.13-1.4Z" transform="translate(-219.859 -24.136)" fill="#314159" />
                                <path id="Path_177" data-name="Path 177" d="M297.113,30.05H290.2a1.79,1.79,0,0,0-1.677,1.4l-.706,3.636a1.12,1.12,0,0,0,1.13,1.4h5.073l-.3,1.542H290.48l.135-.706h-3.243l-.283,1.456h0l-.037.178a1.12,1.12,0,0,0,1.13,1.4H295.1a1.79,1.79,0,0,0,1.677-1.4l.706-3.636a1.12,1.12,0,0,0-1.13-1.4h-5.073l.3-1.542h3.237l-.135.7h3.243l.135-.7h0l.178-.927a1.12,1.12,0,0,0-1.13-1.4Z" transform="translate(-227.313 -24.136)" fill="#314159" />
                                <path id="Path_178" data-name="Path 178" d="M189.806,40.417h.51a4.168,4.168,0,0,0,3.906-3.273L195.6,30.05h-3.783L189.8,40.417Z" transform="translate(-189.8 -24.136)" fill="#314159" />
                                <path id="Path_179" data-name="Path 179" d="M215.767,30.05h-5.018a1.79,1.79,0,0,0-1.677,1.4,1.12,1.12,0,0,0-1.13-1.4h-5.024a1.79,1.79,0,0,0-1.677,1.4l-1.732,8.911h3.783l1.462-7.511h2.156l-1.462,7.511h3.783l1.462-7.511h2.156l-1.462,7.511h3.783L216.9,31.45a1.12,1.12,0,0,0-1.13-1.4Z" transform="translate(-193.546 -24.136)" fill="#314159" />
                                <path id="Path_180" data-name="Path 180" d="M326.018,30.05H317.7l-2,10.312h8.316a1.79,1.79,0,0,0,1.677-1.4l.08-.418h0l.375-1.935a1.12,1.12,0,0,0-1.13-1.4,1.79,1.79,0,0,0,1.677-1.4l.467-2.4h0a1.116,1.116,0,0,0-1.136-1.357Zm-3.175,4.109h-2.156l.252-1.3h2.156Zm-2.567,2.107h2.156l-.252,1.3h-2.156Z" transform="translate(-238.377 -24.136)" fill="#314159" />
                                <path id="Path_181" data-name="Path 181" d="M258.437,30.05h-6.915a1.79,1.79,0,0,0-1.677,1.4l-1.462,7.511a1.12,1.12,0,0,0,1.13,1.4h6.915a1.79,1.79,0,0,0,1.677-1.4l1.462-7.511a1.12,1.12,0,0,0-1.13-1.4Zm-5.994,7.505.915-4.7h2.156l-.915,4.7Z" transform="translate(-212.393 -24.136)" fill="#314159" />
                                <path id="Path_182" data-name="Path 182" d="M360.415,32.857l.547-2.807h-8.23l-2,10.318h8.23l.547-2.813h-4.453l.209-1.062h3.151l.5-2.573H355.76l.209-1.062Z" transform="translate(-251.893 -24.136)" fill="#314159" />
                                <path id="Path_183" data-name="Path 183" d="M341.125,30.05h-3.777L335.34,40.368h7.29l.522-2.69h-3.507Z" transform="translate(-245.955 -24.136)" fill="#314159" />
                                <path id="Path_184" data-name="Path 184" d="M229.032,33.323l-2.7,13.911h3.783l3.341-17.184h-.51a4.168,4.168,0,0,0-3.906,3.273Z" transform="translate(-203.895 -24.136)" fill="#314159" />
                                <path id="Path_185" data-name="Path 185" d="M238.17,40.368h3.783l2-10.318h-3.777Z" transform="translate(-208.463 -24.136)" fill="#314159" />
                                <path id="Path_186" data-name="Path 186" d="M198.284,20.42h-3.777L193.77,24.2h3.783Z" transform="translate(-191.332 -20.42)" fill="#314159" />
                                <path id="Path_187" data-name="Path 187" d="M305.966,40.417h.51a4.168,4.168,0,0,0,3.906-3.273l1.376-7.094h-3.783L305.96,40.417Z" transform="translate(-234.619 -24.136)" fill="#314159" />
                                <path id="Path_188" data-name="Path 188" d="M314.444,20.42h-3.777L309.93,24.2h3.777Z" transform="translate(-236.151 -20.42)" fill="#314159" />
                            </g>
                        </g>
                    </g>
                </svg>


            )
        }
    }
    return (
        <Link style={{ textDecoration: 'none' }} to={`/public/home`}>
            <PiLogo className="m-auto" />
        </Link>
    );
}

export default PublicSidebarLeftLogo;
