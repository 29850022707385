const MaterialUIComponentsNavigation = {
  id: 'material-ui-components',
  title: 'Material UI Components',
  type: 'collapse',
  icon: 'layers',
  children: [
    {
      id: 'accordion',
      title: 'Accordion',
      type: 'item',
      url: '/documentation/material-ui-components/accordion',
    },
    {
      id: 'alert',
      title: 'Alert',
      type: 'item',
      url: '/documentation/material-ui-components/alert',
    },
    {
      id: 'appBar',
      title: 'App Bar',
      type: 'item',
      url: '/documentation/material-ui-components/app-bar',
    },
    {
      id: 'autocomplete',
      title: 'Autocomplete',
      type: 'item',
      url: '/documentation/material-ui-components/autocomplete',
    },
    {
      id: 'avatars',
      title: 'Avatars',
      type: 'item',
      url: '/documentation/material-ui-components/avatars',
    },
    {
      id: 'backdrop',
      title: 'Backdrop',
      type: 'item',
      url: '/documentation/material-ui-components/backdrop',
    },
    {
      id: 'badges',
      title: 'Badges',
      type: 'item',
      url: '/documentation/material-ui-components/badges',
    },
    {
      id: 'bottomNavigation',
      title: 'Bottom Navigation',
      type: 'item',
      url: '/documentation/material-ui-components/bottom-navigation',
    },
    {
      id: 'box',
      title: 'Box',
      type: 'item',
      url: '/documentation/material-ui-components/box',
    },
    {
      id: 'breadcrumbs',
      title: 'Breadcrumbs',
      type: 'item',
      url: '/documentation/material-ui-components/breadcrumbs',
    },
    {
      id: 'buttonGroup',
      title: 'Button Group',
      type: 'item',
      url: '/documentation/material-ui-components/button-group',
    },
    {
      id: 'buttons',
      title: 'Buttons',
      type: 'item',
      url: '/documentation/material-ui-components/buttons',
    },
    {
      id: 'cards',
      title: 'Cards',
      type: 'item',
      url: '/documentation/material-ui-components/cards',
    },
    {
      id: 'checkboxes',
      title: 'Checkboxes',
      type: 'item',
      url: '/documentation/material-ui-components/checkboxes',
    },
    {
      id: 'chips',
      title: 'Chips',
      type: 'item',
      url: '/documentation/material-ui-components/chips',
    },
    {
      id: 'clickAwayListener',
      title: 'Click Away Listener',
      type: 'item',
      url: '/documentation/material-ui-components/click-away-listener',
    },
    {
      id: 'container',
      title: 'Container',
      type: 'item',
      url: '/documentation/material-ui-components/container',
    },
    {
      id: 'cssBaseline',
      title: 'Css Baseline',
      type: 'item',
      url: '/documentation/material-ui-components/css-baseline',
    },
    {
      id: 'datePicker',
      title: 'Date Picker',
      type: 'item',
      url: '/documentation/material-ui-components/date-picker',
    },
    {
      id: 'dateRangePicker',
      title: 'Date Range Picker',
      type: 'item',
      url: '/documentation/material-ui-components/date-range-picker',
    },
    {
      id: 'dateTimePicker',
      title: 'Date Time Picker',
      type: 'item',
      url: '/documentation/material-ui-components/date-time-picker',
    },
    {
      id: 'dialogs',
      title: 'Dialogs',
      type: 'item',
      url: '/documentation/material-ui-components/dialogs',
    },
    {
      id: 'dividers',
      title: 'Dividers',
      type: 'item',
      url: '/documentation/material-ui-components/dividers',
    },
    {
      id: 'drawers',
      title: 'Drawers',
      type: 'item',
      url: '/documentation/material-ui-components/drawers',
    },
    {
      id: 'floatingActionButton',
      title: 'Floating Action Button',
      type: 'item',
      url: '/documentation/material-ui-components/floating-action-button',
    },
    {
      id: 'grid',
      title: 'Grid',
      type: 'item',
      url: '/documentation/material-ui-components/grid',
    },
    {
      id: 'imageList',
      title: 'Image List',
      type: 'item',
      url: '/documentation/material-ui-components/image-list',
    },
    {
      id: 'links',
      title: 'Links',
      type: 'item',
      url: '/documentation/material-ui-components/links',
    },
    {
      id: 'lists',
      title: 'Lists',
      type: 'item',
      url: '/documentation/material-ui-components/lists',
    },
    {
      id: 'masonry',
      title: 'Masonry',
      type: 'item',
      url: '/documentation/material-ui-components/masonry',
    },
    {
      id: 'menus',
      title: 'Menus',
      type: 'item',
      url: '/documentation/material-ui-components/menus',
    },
    {
      id: 'modal',
      title: 'Modal',
      type: 'item',
      url: '/documentation/material-ui-components/modal',
    },
    {
      id: 'noSsr',
      title: 'No Ssr',
      type: 'item',
      url: '/documentation/material-ui-components/no-ssr',
    },
    {
      id: 'pagination',
      title: 'Pagination',
      type: 'item',
      url: '/documentation/material-ui-components/pagination',
    },
    {
      id: 'paper',
      title: 'Paper',
      type: 'item',
      url: '/documentation/material-ui-components/paper',
    },
    {
      id: 'pickers',
      title: 'Pickers',
      type: 'item',
      url: '/documentation/material-ui-components/pickers',
    },
    {
      id: 'popover',
      title: 'Popover',
      type: 'item',
      url: '/documentation/material-ui-components/popover',
    },
    {
      id: 'popper',
      title: 'Popper',
      type: 'item',
      url: '/documentation/material-ui-components/popper',
    },
    {
      id: 'portal',
      title: 'Portal',
      type: 'item',
      url: '/documentation/material-ui-components/portal',
    },
    {
      id: 'progress',
      title: 'Progress',
      type: 'item',
      url: '/documentation/material-ui-components/progress',
    },
    {
      id: 'radioButtons',
      title: 'Radio Buttons',
      type: 'item',
      url: '/documentation/material-ui-components/radio-buttons',
    },
    {
      id: 'rating',
      title: 'Rating',
      type: 'item',
      url: '/documentation/material-ui-components/rating',
    },
    {
      id: 'selects',
      title: 'Selects',
      type: 'item',
      url: '/documentation/material-ui-components/selects',
    },
    {
      id: 'skeleton',
      title: 'Skeleton',
      type: 'item',
      url: '/documentation/material-ui-components/skeleton',
    },
    {
      id: 'slider',
      title: 'Slider',
      type: 'item',
      url: '/documentation/material-ui-components/slider',
    },
    {
      id: 'snackbars',
      title: 'Snackbars',
      type: 'item',
      url: '/documentation/material-ui-components/snackbars',
    },
    {
      id: 'speedDial',
      title: 'Speed Dial',
      type: 'item',
      url: '/documentation/material-ui-components/speed-dial',
    },
    {
      id: 'stack',
      title: 'Stack',
      type: 'item',
      url: '/documentation/material-ui-components/stack',
    },
    {
      id: 'steppers',
      title: 'Steppers',
      type: 'item',
      url: '/documentation/material-ui-components/steppers',
    },
    {
      id: 'switches',
      title: 'Switches',
      type: 'item',
      url: '/documentation/material-ui-components/switches',
    },
    {
      id: 'tables',
      title: 'Tables',
      type: 'item',
      url: '/documentation/material-ui-components/tables',
    },
    {
      id: 'tabs',
      title: 'Tabs',
      type: 'item',
      url: '/documentation/material-ui-components/tabs',
    },
    {
      id: 'textFields',
      title: 'Text Fields',
      type: 'item',
      url: '/documentation/material-ui-components/text-fields',
    },
    {
      id: 'textareaAutosize',
      title: 'Textarea Autosize',
      type: 'item',
      url: '/documentation/material-ui-components/textarea-autosize',
    },
    {
      id: 'timePicker',
      title: 'Time Picker',
      type: 'item',
      url: '/documentation/material-ui-components/time-picker',
    },
    {
      id: 'timeline',
      title: 'Timeline',
      type: 'item',
      url: '/documentation/material-ui-components/timeline',
    },
    {
      id: 'toggleButton',
      title: 'Toggle Button',
      type: 'item',
      url: '/documentation/material-ui-components/toggle-button',
    },
    {
      id: 'tooltips',
      title: 'Tooltips',
      type: 'item',
      url: '/documentation/material-ui-components/tooltips',
    },
    {
      id: 'transferList',
      title: 'Transfer List',
      type: 'item',
      url: '/documentation/material-ui-components/transfer-list',
    },
    {
      id: 'transitions',
      title: 'Transitions',
      type: 'item',
      url: '/documentation/material-ui-components/transitions',
    },
    {
      id: 'trapFocus',
      title: 'Trap Focus',
      type: 'item',
      url: '/documentation/material-ui-components/trap-focus',
    },
    {
      id: 'treeView',
      title: 'Tree View',
      type: 'item',
      url: '/documentation/material-ui-components/tree-view',
    },
    {
      id: 'typography',
      title: 'Typography',
      type: 'item',
      url: '/documentation/material-ui-components/typography',
    },
  ],
};

export default MaterialUIComponentsNavigation;
