const mutation = {
    maintenanceSwitch: `
        mutation maintenanceSwitch($data: JSONString!) {
            maintenanceSwitch (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    updateSite: `
        mutation updateSite($data: JSONString!) {
            updateSite (data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    getSettings: `
        query getSettings {
            getSettings {
                id
                uploadId
                fileName
                fileType
                fileId
                path
                siteName
                email
                address
                copyright
                maintenanceMessage
                maintenanceStatus
                phone
                dateCreated
                dateUpdated
                logoUrl
            }
        }
    `,
    forceLogoutUser: `
        query forceLogoutUser($agentId: String, $userType: String) {
            forceLogoutUser(agentId: $agentId, userType: $userType)
        }
    `,
    updateChecker: `
        query updateChecker($agentId: String, $userType: String, $updateFrom: String) {
            updateChecker(agentId: $agentId, userType: $userType, updateFrom: $updateFrom)
        }
    `,
};

export { mutation, query };