import FuseScrollbars from "@fuse/core/FuseScrollbars";
import _ from "@lodash";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import format from 'date-fns/format';
import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import withRouter from "@fuse/core/withRouter";
import FuseLoading from "@fuse/core/FuseLoading";
import ManageAgentsTableHeader from "./ManageAgentsTableHeader";
import EditAgentsModal from "./modal/EditAgentsModal";
import { styled } from '@mui/material/styles';
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { toggleSave } from "../store/manageAgentsSlice";
import AgentProfilePassword from "../modal/AgentProfilePassword"
import { Stack } from "@mui/material";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd):not(.Mui-selected):not(:hover)': {
        backgroundColor: theme.palette.background.default,
    },
    '&:hover': {
        backgroundColor: theme.palette.background.hover,
    },
    // hide last border
    '& td, & th': {
        border: 0,
    },
}));

function ManageAgentsTable(props) {
    const dispatch = useDispatch();
    const { loading, setLoading } = props;
    const { selected, setSelected } = props
    const { data, setData, loadData } = props;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState({
        direction: "desc",
        id: "dateCreated",
    });

    function handleRequestSort(event, property) {
        const id = property;
        let direction = "desc";

        if (order.id === property && order.direction === "desc") {
            direction = "asc";
        }

        setOrder({
            direction,
            id,
        });
    }

    useEffect(() => {
        setSelected([]);
    }, [data])

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            setSelected(data.map((n) => n.id));
            return;
        }
        setSelected([]);
    }

    function handleDeselect() {
        setSelected([]);
    }

    function handleCheck(event, id) {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    }

    function handleChangePage(event, value) {
        setPage(value);
    }

    function handleChangeRowsPerPage(event) {
        setPage(0);
        setRowsPerPage(event.target.value);
    }

    function toggleReplace(itemData) {
        console.log("toggleSave", itemData)

        var value = itemData.status == '1' ? '0' : '1';

        const newData = [...data];

        const index = newData.findIndex(item => item.id === itemData.id);

        if (index !== -1) {

            const updatedData = { ...newData[index], status: value }
            newData[index] = updatedData;
            setData(newData);

            var params = {
                id: itemData.id,
                username: itemData.username,
                status: value
            }

            dispatch(toggleSave(params)).then((action) => {
                console.log("toggleSave", action)

            });
        }
    };

    if (loading) {
        return <FuseLoading />;
    }

    if (data.length === 0) {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                className="flex flex-1 items-center justify-center h-full"
            >
                <Typography color="textSecondary" variant="h5">
                    There are no agent!
                </Typography>
            </motion.div>
        );
    }

    return (
        <div className="w-full flex flex-col">
            <FuseScrollbars className="grow overflow-x-auto overscroll-auto">
                <Table stickyHeader className="min-w-xl" aria-labelledby="tableTitle">
                    <ManageAgentsTableHeader
                        selectedConfigurationIds={selected}
                        order={order}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={data.length}
                        onMenuItemClick={handleDeselect}
                    />

                    <TableBody>
                        {_.orderBy(
                            data,
                            [
                                (o) => {
                                    switch (order.id) {
                                        case "categories": {
                                            return o.categories[0];
                                        }
                                        default: {
                                            return o[order.id];
                                        }
                                    }
                                },
                            ],
                            [order.direction]
                        )
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((n) => {
                                const isSelected = selected.indexOf(n.id) !== -1;
                                return (
                                    <StyledTableRow
                                        role="checkbox"
                                        aria-checked={isSelected}
                                        tabIndex={-1}
                                        key={n.id}
                                        selected={isSelected}
                                    >
                                        <TableCell>
                                            <Checkbox
                                                checked={isSelected}
                                                onClick={(event) => event.stopPropagation()}
                                                onChange={(event) => handleCheck(event, n.id)}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            {n.lastName + ', ' + n.firstName + (n.middleName ? ' ' + n.middleName[0] + '.' : '')}
                                        </TableCell>

                                        <TableCell>
                                            {n.username}
                                        </TableCell>

                                        <TableCell>
                                            {n.email}
                                        </TableCell>

                                        <TableCell className=" min-w-160">
                                            {n.state + ', ' + n.country}
                                        </TableCell>

                                        <TableCell>
                                            <div>
                                                <div className="inline-block">
                                                    <FormControlLabel
                                                        sx={{
                                                            display: 'block',
                                                        }}
                                                        control={
                                                            <Switch
                                                                checked={n.status == '1'}
                                                                onChange={() => toggleReplace(n)}
                                                                name="status"
                                                                color="primary"
                                                            />
                                                        }
                                                        label={n.status == '1' ? 'Active' : 'Deactivated'}
                                                    />
                                                </div>
                                            </div>
                                        </TableCell>

                                        <TableCell


                                        >
                                            {format(new Date(n.dateCreated), 'MM/dd/yyyy hh:mm a')}
                                        </TableCell>

                                        <TableCell
                                            className="p-0"
                                            align="right"
                                        >
                                            <Stack direction={'row'}>
                                                <EditAgentsModal item={n} module="edit" loadData={loadData} />
                                                <AgentProfilePassword item={n} module="reset" loadData={loadData} />
                                            </Stack>
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </FuseScrollbars>

            <TablePagination
                className="shrink-0 border-t-1"
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={"Show: "}
                page={page}
                backIconButtonProps={{
                    "aria-label": "Previous Page",
                }}
                nextIconButtonProps={{
                    "aria-label": "Next Page",
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}

export default withRouter(ManageAgentsTable);
