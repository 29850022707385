/*****
* Module: Module name
* Task: https://app.activecollab.com/187150/projects/96?modal=Task-39723-96
* DateUpdated: 11/14/2022
* Updated By: JF
* Update Summary: update auth of roles
*/

import { lazy } from 'react';
import { authRoles } from 'app/auth';

const ProjectDashboardApp = lazy(() => import('./ProjectDashboardApp'));

const ProjectDashboardAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {
            path: 'apps/dashboards/project',
            element: <ProjectDashboardApp />,
            auth: ['dash']
        },
    ],
};

export default ProjectDashboardAppConfig;
