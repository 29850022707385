// import { authCrmRoles } from 'app/crm';
// import { authRoles } from "app/auth";
import { authAgentRoles } from 'app/agent';
import PublicCreateMission from "app/main/apps/public/mission/PublicCreateMission";
import MissionCreate from "app/main/apps/public/mission/v2/MissionCreate";
import PublicMissionDetails from "app/main/apps/public/mission/PublicMissionDetails";
import FriendsActivity from '../friends-activity/FriendsActivity';
import PublicBlog from '../blog/PublicBlog';
import PublicAbout from '../about/PublicAbout';
import PublicJoinMission from './PublicJoinMission';

const PublicMissionConfig = {
	settings: {
		type: "public",
		theme: {
			main: "default",
			navbar: "default",
			toolbar: "default",
			footer: "false",
		},
		layout: {
			config: {
				navbar: {
					display: true,
				},
				toolbar: {
					display: true,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: true,
				},
				rightSidePanel: {
					display: true,
				},
			},
		},
	},
	auth: authAgentRoles.agent,
	routes: [
		{
			path: "public/create-mission",
			element: <MissionCreate />,
		},
		// {
		// 	path: "public/join-mission",
		// 	element: <PublicJoinMission />,
		// },
		{
			path: "public/mission-details/:id",
			element: <PublicMissionDetails />,
		},
		{
			path: "public/friend-activity",
			element: <FriendsActivity />,
		},
		{
			path: "public/blogs",
			element: <PublicBlog />,
		},
		{
			path: "public/about",
			element: <PublicAbout />,
		},
	],
};

export default PublicMissionConfig;
