import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showMessage } from 'app/store/fuse/messageSlice';
import { setUserData } from './agentUserSlice';
import { mutation, query } from "app/graphql/login_graphql";
import AgentJwtService from 'app/services/agent/AgentJwtService';
import axios from "axios";
import CONFIG from "app/config";

export const submitLogin =
    ({ email, password }) =>
        async (dispatch) => {
            return await AgentJwtService
                .signInWithEmailAndPassword(email, password)
                .then((user) => {
                    window.location.reload();

                    dispatch(setUserData(user));

                    return dispatch(agentloginSuccess());
                })
                .catch((errors) => {
                    return errors;
                    // for future reference
                    // errors.forEach((error) => {
                    //     return dispatch(
                    //         showMessage({
                    //             message: error.message,
                    //             autoHideDuration: 5000, //ms
                    //             anchorOrigin: {
                    //                 vertical: "top",
                    //                 horizontal: "center",
                    //             },
                    //             variant: "warning",
                    //         })
                    //     );
                    // });
                });
        };

export const forgotPassword = createAsyncThunk(
    "login/user/forgotpassword",
    async (postdata, { dispatch, getState }) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.forgotPassword,
            variables: { data: JSON.stringify(postdata) },
        });

        return response.data.data.forgotPassword;
    }

);

export const checkLoginEmail =
    (filter) => async (dispatch) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.checkLoginEmail,
            variables: filter
        });

        console.log(response.data.data.checkLoginEmail)

        if (response.data.data.checkLoginEmail.length) {
            return true;
        } else {
            return false;
        }
    };

const initialState = {
    success: false,
    errors: [],
};

const agentLoginSlice = createSlice({
    name: 'agents/agentlogin',
    initialState,
    reducers: {
        agentloginSuccess: (state, action) => {
            state.success = true;
            state.errors = [];
        },
        agentloginError: (state, action) => {
            state.success = false;
            state.errors = action.payload;
        },
    },
    extraReducers: {},
});

export const { agentloginSuccess, agentloginError } = agentLoginSlice.actions;

export default agentLoginSlice.reducer;
