const mutation = {
    saveBlogAuthor: `
        mutation saveBlogAuthor($data: JSONString!) {
            saveBlogAuthor (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    deleteMissionById: `
        mutation deleteMissionById($id: String!) {
            deleteMissionById (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
    updateMissionStatus: `
        mutation updateMissionStatus($data: JSONString!) {
            updateMissionStatus (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    updateMissionFeatured: `
        mutation updateMissionFeatured($data: JSONString!) {
            updateMissionFeatured (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    updateMissionContent: `
        mutation updateMissionContent($data: JSONString!) {
            updateMissionContent (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    createPublicUpload: `
        mutation createPublicUpload($data: JSONString) {
           createPublicUpload (data: $data) {
                status,
                message,
                upload {
                id,
                fileId,
                fileName,
                fileSize,
                fileType,
                path,
                module,
                source          
                }
            }
        }
    `,
    missionToggleSave: `
        mutation missionToggleSave($data: JSONString!) {
            missionToggleSave (data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    getMissionList: `
        query getMissionList {
            getMissionList {
                id
                title
                description
                dateCreated
                filesId
                lat
                lng
                views
                pinned
                status
                featured
                agent{
                    profilePicPath
                    name
                    flName
                    username
                }
            }
        }
    `,
};
export { mutation, query };