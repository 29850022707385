import {
    Card,
    CardContent,
    Stack,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Avatar,
    Badge,
    CardHeader,
    IconButton,
    Container,
    Button,
    Divider,
    Icon,
    Tab,
    TextField,
    InputAdornment,
} from "@mui/material";
import * as React from "react";
import { CardFooter } from "reactstrap";
import { styled } from "@mui/material/styles";
import { MoreVertIcon, PushPin } from "@mui/icons-material";

// lazy loading
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { height } from "@mui/system";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import {
    faComments,
    faEye,
    faLocationDot,
} from "@fortawesome/free-regular-svg-icons";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const itemData = [
    {
        img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
        title: "Breakfast",
        author: "bkristastucchio",
    },
    {
        img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
        title: "Burger",
        author: "rollelflex graphy726",
    },
    {
        img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
        title: "Camera",
        author: "hello imnik",
    },
    {
        img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
        title: "Coffee",
        author: "nolan issac",
    },
    {
        img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
        title: "Hats",
        author: "hjr c33",
    },
    {
        img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
        title: "Honey",
        author: "arwi nneil",
    },
    {
        img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
        title: "Basketball",
        author: "tjdra gotta",
    },
    {
        img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
        title: "Fern",
        author: "katie wasserman",
    },
];

const CardStyled = styled(Card)(
    ({ theme }) => `
    margin: ${theme.spacing(1)};
    padding: ${theme.spacing(2)};
    // border: 1px solid black;
    box-shadow: 0 0 20px #e5e5e5;
  `
);
const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 34,
    height: 34,
    // border: `2px solid ${theme.palette.background.paper}`,
}));
const BackgroundAvatar = styled(Avatar)(({ theme }) => ({
    // width: 40,
    borderRadius: "unset",
    width: 60,
    height: 60,
    "& img": {
        objectFit: "contain",
    },
    // height: 40,
    // border: `2px solid ${theme.palette.background.paper}`,
}));

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    var username = name.split(" "),
        firstName = username[0].split(" ")[0][0],
        lastName = username[username.length - 1].split(" ")[0][0];
    if (firstName == lastName) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}`,
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}${lastName}`,
        };
    }
}

export default function Tab2() {
    return (
        <>
            <center>
                <Typography variant="h3">COMING SOON</Typography>
                <img src="assets/images/loading-gears-animation-3.gif"></img>
            </center>
        </>
    );
}
