import * as React from "react";
import { useState, useEffect } from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { styled, lighten } from '@mui/material/styles';
import { showMessage } from "app/store/fuse/messageSlice";
import CardActions from "@mui/material/CardActions";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { TextField, Button, Item, CardHeader } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    Avatar,
    Box,
    CardActionArea,
    Divider,
    Icon,
    IconButton,
    Link,
    InputAdornment,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TextLimit from "app/services/TextLimit";
import Comments from "app/services/comments/Comments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import PhotoGridView from "app/services/PhotoGridView";
import { updateViews } from "app/main/apps/public/blog/store/PublicBlogSlice";
import SharerSvs from "app/services/SharerSvs";
import { format } from "date-fns";
import AttachmentSvs from "app/services/AttachmentSvs";

const Root = styled('div')(({ theme }) => ({
    background: theme.palette.primary,
    color: theme.palette.primary.contrastText,

    '& .bg-icon': {
        background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${lighten(
            theme.palette.primary.dark,
            0.1
        )} 100%)`,
        color: theme.palette.primary.contrastText,
    },

}));

function PublicBlogDetailsModal(props) {
    const dispatch = useDispatch();

    const { openDialog, setOpenDialog } = props;
    const { data } = props;

    function handleCloseDialog() {
        setOpenDialog(false);
    }

    if (openDialog) {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(rData =>
                dispatch(updateViews({ ipAddress: rData.ip, module: "blogs", moduleId: data.id })).then((action) => {
                    console.log(action.payload);
                }))
            .catch(error => console.log(error))
    }

    return (
        <Root>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
                scroll="body"
                sx={{

                    '& .MuiPaper-root': {
                        width: "100%",
                    },
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Icon>close</Icon>
                </IconButton>
                <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>
                    <Card className="p-16 pb-8 rounded-lg mb-16 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                        <CardHeader
                            className="p-0"
                            avatar={
                                <AttachmentSvs module={"at-3"} path={data.authorPhotoPath} data={data} />
                            }
                            title={
                                <Typography className=" font-extrabold text-18">
                                    {data.authorName}
                                    {data.featured == 1 && (<FontAwesomeIcon className="ml-5 text-14" icon={faCrown} />)}
                                </Typography>
                            }
                            subheader={
                                <Typography className=" line-clamp-1 text-14 text-pi-grey">
                                    {data.dateCreated && (format(new Date(data.dateCreated), 'MMMM d, yyyy'))}
                                </Typography>
                            }
                            action={
                                <IconButton aria-label="settings" className="hidden" edge={false}>
                                    <MoreVertIcon />
                                </IconButton>
                            }
                        />
                        <CardContent className="p-0">
                            <Typography
                                component="div"
                                className="font-bold mt-5 text-18 mb-0 text-pi-black line-clamp-2"
                            >
                                {data.metaTitle}
                            </Typography>
                            <Box className="flex items-end justify-start mb-6 relative">
                                <div className="text-16 mb-3">
                                    <TextLimit text={data.content} limit={200} />
                                </div>
                            </Box>
                        </CardContent>
                        <PhotoGridView fileId={data.fileId} />

                        <CardActions className="flex-col pt-8 pb-0  px-0">
                            <Stack
                                direction="row"
                                className="justify-between w-full items-center"
                            >
                                <Stack direction="row" spacing={2}>
                                    <Typography className="text-pi-black self-center pt-2">
                                        {data.views} views
                                    </Typography>
                                    <Link
                                        underline="none"
                                        component={"button"}
                                        className=" text-pi-black hover:text-pi-primary"
                                    >
                                        {data.totalComments} comments
                                    </Link>
                                </Stack>
                                {/* <Button variant="text" className="text-14 font-bold text-pi-blue-1 "><Icon className="mr-6">share</Icon>share</Button> */}
                                <SharerSvs link={`/public/blog?id=${data.id}`} title={data.metaTitle} description={data.content} />
                            </Stack>
                        </CardActions>
                    </Card>
                    <Card className="p-16 rounded-lg shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                        <Comments data={data} moduleType="blogs" moduleId={data.id} />
                    </Card>
                </DialogContent>


            </Dialog>
        </Root>
    );
}

export default PublicBlogDetailsModal;
