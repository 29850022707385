import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { mutation, query } from "../graphql/public_register_graphql";

export const registerAgent = createAsyncThunk(
    "publicRegisterApp/registerAgent",
    async (data) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.registerAgent,
            variables: {
                data: JSON.stringify(data),
            },
        });

        if (response.data.data.registerAgent) {
            const data = response.data.data.registerAgent;

            return data == undefined ? null : data;
        }
    }
);

export const registerActivateAgent = createAsyncThunk(
    "publicRegisterApp/registerActivateAgent",
    async (id) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.registerActivateAgent,
            variables: {
                id: id,
            },
        });

        if (response.data.data.registerActivateAgent) {
            const data = response.data.data.registerActivateAgent;

            return data == undefined ? null : data;
        }
    }
);

export const checkUsernameAndEmail = (filter) => async (dispatch) => {

    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.checkUsernameAndEmail,
        variables: filter
    });

    if (response.data.data.checkUsernameAndEmail.length) {
        return false;
    } else {
        return true;
    }

};

const publicRegister = createSlice({
    name: "publicRegister",
    initialState: null,
    reducers: null,
    extraReducers: {
        [registerAgent.fulfilled]: (state, action) => action.payload,
        [registerActivateAgent.fulfilled]: (state, action) => action.payload,
    },
});

export default publicRegister.reducer;
