import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import { mutation as mutationUpload } from "app/graphql/upload_graphql";
import axios from 'axios';
import CONFIG from 'app/config';
import { query, mutation } from "../graphql/agent_profile_graphql";
import { showMessage } from "app/store/fuse/messageSlice";

export const updateAgent = createAsyncThunk(
    "agentApp/info/updateAgent",
    async (params, { dispatch, getState }) => {

        return new Promise(async (resolve, reject) => {

            saveImages(params).then(async (params) => {
                const response = await axios.post(CONFIG.API + "/api/", {
                    query: mutation.updateAgent,
                    variables: { data: JSON.stringify(params) },
                });

                if (response.data.data.updateAgent) {
                    dispatch(
                        showMessage({
                            message: "Agent profile has been successfully updated.",
                            autoHideDuration: 5000, //ms
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            variant: "success",
                        })
                    );
                    resolve(true);
                } else {
                    dispatch(
                        showMessage({
                            message: "Something went wrong. Please try again later.",
                            autoHideDuration: 5000, //ms
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            variant: "error",
                        })
                    );
                    resolve(true);
                }
            });
        })
    }
);

const saveImages = (params) => {

    return new Promise(async (resolve, reject) => {
        console.log(params, 'params');
        params.images = [...params.images, ...params.bannerImages];
        if (params.images.length) {

            var newImages = [];

            params.images.map(async (image, index) => {

                if (image.status === "new") {
                    newImages.push({
                        index: index,
                        image: image
                    })
                } else {
                    if (params.featuredImageId == image.fileId) {
                        params.featuredImagePath = image.path;
                    }
                }

            });

            if (newImages.length) {
                var count = 0;
                newImages.map(async (image, index) => {

                    // return
                    await uploadSvc({
                        file: image.image.file,
                        destination_file_name: "useraccount/user/user-" + randomString() + "-" + (image.image.file ? image.image.file.name : image.image.fileName)
                    }).then(async (result) => {

                        if (result.status === 200 && result.data.status === "success") {

                            var uploadData = result.data.uploadData
                            uploadData['module'] = 'user-account';
                            uploadData['file_id'] = image.image.id;
                            uploadData['imageType'] = image.image.imageType;

                            const response = await axios.post(CONFIG.API + "/api/", {
                                query: mutationUpload.create,
                                variables: {
                                    data: JSON.stringify(uploadData),
                                },
                                publicUrl: 'agents',
                            });

                            if (response.data.data.createUpload.status == "success") {
                                count++;
                                console.log(count);
                                var upload = response.data.data.createUpload.upload;

                                if (params.featuredImageId == image.image.id) {
                                    params.featuredImagePath = upload.path;
                                }
                                params.imageType = image.image.imageType;

                                params.images[image.index].upload_id = upload.id;
                                params.images[image.index].file_name = upload.fileName;
                                params.images[image.index].file_id = upload.fileId;
                                params.images[image.index].file_type = upload.fileType;
                                params.images[image.index].path = upload.path;
                                params.images[image.index].module = upload.module;
                                // params.images[image.index].url = "";

                                if (newImages.length == count) {
                                    resolve(params);
                                }

                            }

                        }

                    });

                });

            } else {
                resolve(params)
            }


        } else {

            resolve(params);

        }

    });

}

export const updateAccount = createAsyncThunk(
    "agentApp/info/updateAccount",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.updateAccount,
            variables: {
                data: JSON.stringify(data)
            },
        });
        dispatch(
            showMessage({
                message: response.data.data.updateAccount["message"],
                autoHideDuration: 5000, //ms
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
                variant: response.data.data.updateAccount["status"],
            })
        );
    }
);

export const getMarkersByAgentId = createAsyncThunk(
    "agentApp/getMarkersByAgentId",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getMarkersByAgentId,
            variables: params,
        });

        if (response.data.data.getMarkersByAgentId) {
            const data = response.data.data.getMarkersByAgentId;

            var tempItems = [];

            if (data) {
                data.map((itemD) => {
                    itemD.lat = parseFloat(itemD.lat);
                    itemD.lng = parseFloat(itemD.lng);
                    tempItems.push(itemD);
                })
            }

            return data === undefined ? [] : tempItems;
        } else {
            return [];
        }
    }
);

export const checkAccount = createAsyncThunk(
    "agentApp/user/checkAccount",
    async (data, { dispatch, getState }) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.checkAccount,
            variables: { data: JSON.stringify(data) },
            publicUrl: 'agents',
        });

        return response.data.data.checkAccount;
    }
);

export const updatePassword = createAsyncThunk(
    "agentApp/user/updatePassword",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.updatePassword,
            variables: {
                data: JSON.stringify(data)
            },
        });
        dispatch(
            showMessage({
                message: response.data.data.updatePassword["message"],
                autoHideDuration: 5000, //ms
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
                variant: response.data.data.updatePassword["status"],
            })
        );
    }
);

export const agentById = createAsyncThunk(
    "agentApp/agentById",
    async (agentId) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.agentById,
            variables: {
                agentId: agentId
            },
            publicUrl: 'agents'
        });

        if (response.data.data.agentById) {
            const data = response.data.data.agentById;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const agentAccountById = createAsyncThunk(
    "agentApp/info/agentAccountById",
    async (agentId) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.agentAccountById,
            variables: {
                agentId: agentId
            },
            publicUrl: 'agents'
        });

        if (response.data.data.agentById) {
            const data = response.data.data.agentById;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

const agentSlice = createSlice({
    name: 'agentsApp/profile',
    initialState: null,
    reducers: null,
    extraReducers: {
        [agentById.fulfilled]: (state, action) => action.payload,
        [agentAccountById.fulfilled]: (state, action) => action.payload,
        [checkAccount.fulfilled]: (state, action) => action.payload,
        [updatePassword.fulfilled]: (state, action) => action.payload,
        [getMarkersByAgentId.fulfilled]: (state, action) => action.payload,
    },
});

export default agentSlice.reducer;
