import React, { useState, useEffect } from 'react';
import { formatDistanceToNow, format } from 'date-fns';

const DateTimeDisplay = ({ dateTime = "2023-11-10T03:15:01.446479+00:00", start = "" }) => {
    const [distanceToNow, setDistanceToNow] = useState(formatDistanceToNow(new Date(dateTime), { addSuffix: true }));

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDistanceToNow(formatDistanceToNow(new Date(dateTime), { addSuffix: true }));
        }, 1000); // Refresh every minute 
        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [dateTime]);

    // Format the date
    const formattedDate = format(new Date(dateTime), "MMMM d, yyyy 'at' h:mm a");

    return (
        <>
            {start + " " + distanceToNow}
        </>
    );
};

export default DateTimeDisplay;