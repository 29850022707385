import {
    Stack,
    Avatar,
    Card,
    Badge,
    CardHeader,
    Container,
    Button,
    Box,
    Divider,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { styled } from "@mui/material/styles";
import { useState, useRef, useEffect, useMemo } from "react";
import withReducer from "app/store/withReducer";
import reducer from "../../store";
import CommentComponent from "../comments/CommentForm";
import Comments from "app/services/comments/Comments";

const defaultReplyValues = {
    reply: "",
};
const CardStyled = styled(Card)(
    ({ theme }) => `
		margin: ${theme.spacing(1)};
		padding: ${theme.spacing(2)};
		// border: 1px solid black;
		box-shadow: 0 0 20px #e5e5e5;
	`
);
const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 34,
    height: 34,
    // border: `2px solid ${theme.palette.background.paper}`,
}));
const BackgroundAvatar = styled(Avatar)(({ theme }) => ({
    // width: 40,
    borderRadius: "unset",
    width: 60,
    height: 60,
    "& img": {
        objectFit: "contain",
    },
    // height: 40,
    // border: `2px solid ${theme.palette.background.paper}`,
}));
function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}
function stringAvatar(name) {
    var username = name.split(" "),
        firstName = username[0].split(" ")[0][0],
        lastName = username[username.length - 1].split(" ")[0][0];
    if (firstName == lastName) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}`,
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}${lastName}`,
        };
    }
}
const Tab1 = (props) => {
    const { mission_id, data } = props
    return (
        <>
            {/* <CommentComponent mission_id={mission_id} /> */}
            <Comments data={data} moduleType="missions" moduleId={data.id} />
            <Box className=" py-16 !px-0">
                <Divider />
                <Typography className="px-16 !my-6 sm:px-0 !text-16 !capitalize text-pi-grey mb-16">Suggested Missions For You</Typography>
                <Stack direction={'row'} useflexgap="true" gap={2} flexWrap="wrap" className="!px-0">
                    <Stack direction={"column"} sx={{ width: { xl: "calc(50% - 8px)", lg: "calc(60% - 84px)", md: "calc(48% - 0px)", sm: "calc(49% - 5px)", xs: "calc(100%)" } }} >
                        <Box
                            sx={{ maxHeight: 280, width: "100%", height: { xl: "10.416666666666666vw", lg: "13.416667vw", md: "17.416667vw", sm: "24.416667vw", xs: "56.25vw" } }}

                            className="overflow-hidden bg-white"
                        >
                            <img
                                className="max-w-none w-full h-full object-cover rounded-none sm:rounded-md"
                                srcSet={'https://images.unsplash.com/photo-1661904708589-2e20885a3297?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80'}
                                alt={'Volunteer with a local charity to help your community.'}
                                loading="lazy"
                            />
                        </Box>
                        <CardHeader
                            className={"!py-8 items-start !px-5 sm:px-0"}
                            sx={{
                                "& .MuiCardHeader-avatar": {
                                    marginRight: "0",
                                },
                                "&.MuiAvatar-root": {
                                    width: "unset",
                                    height: "unset",
                                },
                            }}
                            title={
                                <Typography className="!font-bold !line-clamp-2 !text-18 !leading-tight">
                                    Missions Title
                                </Typography>
                            }
                            subheader={
                                <>
                                    <p className=" font-300 text-16 mt-0">AgebtNameHere</p>
                                    <p className=" font-300 text-16 -mt-1">
                                        1.1M reach · January 14, 2017
                                    </p>
                                </>
                            }
                        />
                    </Stack>
                    <Stack direction={"column"} sx={{ width: { xl: "calc(50% - 8px)", lg: "calc(60% - 84px)", md: "calc(48% - 0px)", sm: "calc(49% - 5px)", xs: "calc(100%)" } }} >
                        <Box
                            sx={{ maxHeight: 280, width: "100%", height: { xl: "10.416666666666666vw", lg: "13.416667vw", md: "17.416667vw", sm: "24.416667vw", xs: "56.25vw" } }}

                            className="overflow-hidden bg-white"
                        >
                            <img
                                className="max-w-none w-full h-full object-cover rounded-none sm:rounded-md"
                                srcSet={'https://images.unsplash.com/photo-1661904708589-2e20885a3297?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80'}
                                alt={'Volunteer with a local charity to help your community.'}
                                loading="lazy"
                            />
                        </Box>
                        <CardHeader
                            className={"!py-8 items-start !px-5 sm:px-0"}
                            sx={{
                                "& .MuiCardHeader-avatar": {
                                    marginRight: "0",
                                },
                                "&.MuiAvatar-root": {
                                    width: "unset",
                                    height: "unset",
                                },
                            }}
                            title={
                                <Typography className="!font-bold line-clamp-2 !text-18 !leading-tight">
                                    Missions Title
                                </Typography>
                            }
                            subheader={
                                <>
                                    <p className=" font-300 text-16 mt-0">AgebtNameHere</p>
                                    <p className=" font-300 text-16 -mt-1">
                                        1.1M reach · January 14, 2017
                                    </p>
                                </>
                            }
                        />
                    </Stack>
                </Stack>

            </Box>
        </>
    );
};

export default withReducer("missionApp", reducer)(Tab1);