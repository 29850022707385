import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller, FormProvider } from "react-hook-form";
import * as yup from "yup";
import reducer from 'app/main/apps/blogs/store';
import withReducer from "app/store/withReducer";
import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { styled, lighten } from '@mui/material/styles';
import { showMessage } from "app/store/fuse/messageSlice";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { DialogTitle } from "@mui/material";
import { updateMissionByType } from "app/main/apps/public/mission-manager-v2/store/missionManagerSlice"

const Root = styled('div')(({ theme }) => ({
    background: theme.palette.primary,
    color: theme.palette.primary.contrastText,

    '& .bg-icon': {
        background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${lighten(
            theme.palette.primary.dark,
            0.1
        )} 100%)`,
        color: theme.palette.primary.contrastText,
    },

}));

function MissionDeleteModal(props) {

    const { item, loadData, popupState } = props;

    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);

    function handleOpenDialog() {
        setOpenDialog(true);
    }

    function handleCloseDialog() {
        setOpenDialog(false);
    }

    async function handleSave() {

        var data = item;

        data.type = "delete";

        dispatch(updateMissionByType(data)).then(function () {
            handleCloseDialog()
            loadData()
            popupState.close();
        });
    }

    return (
        <Root>
            <ListItemButton className=" text-pi-red" onClick={handleOpenDialog}>
                <ListItemIcon sx={{ minWidth: '36px' }}>
                    <Icon className=" text-pi-red">delete</Icon>
                </ListItemIcon>
                <ListItemText primary="Delete" />
            </ListItemButton>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
                scroll="body"
                sx={{

                    '& .MuiPaper-root': {
                        width: "100%",
                    },
                }}
            >
                <AppBar position="static" elevation={0}>
                    <Toolbar className="flex w-full">
                        <Typography className="font-bold text-18" color="inherit">
                            Confirm Delete Action
                        </Typography>
                    </Toolbar>
                </AppBar>

                <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>
                    <Typography>Are you sure you want to delete this mission? {item.kingPin == 1 && ("All joiners will also deleted.")} This action cannot be undone.</Typography>
                </DialogContent>

                <DialogActions className="justify-center px-8 py-16">
                    <div className="px-16">
                        <LoadingButton
                            className="rounded-md mr-5"
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={handleSave}
                        >
                            Delete
                        </LoadingButton>
                        <Button
                            className="rounded-md ml-5"
                            variant="outlined"
                            type="button"
                            onClick={handleCloseDialog}
                        >
                            Cancel
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </Root>
    );
}

export default MissionDeleteModal;
