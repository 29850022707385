import { useDispatch, useSelector } from 'react-redux';
import { getSearchById, updateSearchHistory } from "../search/store/searchSlice";
import { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import { TextField, InputAdornment } from "@mui/material";
import { FiSearch } from "react-icons/fi";
import { useNavigate, useLocation } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        backgroundColor: "#FAFAFB",
        border: "1px solid #E9E9E9",
        height: 48,
        width: '100%',
        minWidth: '0px',
        fontSize: '16px'
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
    "& .MuiFilledInput-input": {
        paddingTop: "8px"
    },
    "& input#mui-1::placeholder": {
        color: "#8B8B8B",
        opacity: 'unset',
        fontWeight: 'semi-bold',
        fontSize: '16px'


    },
}));



export default function SearchBar(props) {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const dispatch = useDispatch();
    const [isSave, setIsSave] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [searchHistory, setSearchHistory] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true)
        if (props.id) {
            dispatch(getSearchById(props.id)).then((action) => {
                if (action.payload.length != 0) {
                    setSearchHistory(action.payload[0].searchHistory.split("&&&"));
                }
                setIsLoading(false)
            });
        }
    }, [isSave == true]);

    function handleSubmit(keyword) {
        const shChecker = searchHistory.find((item) => {
            return keyword === item;
        })
        if (!shChecker && (keyword && keyword != '')) {
            setSearchHistory([keyword, ...searchHistory]);
            dispatch(updateSearchHistory({ agentId: props.id, sh: [keyword, ...searchHistory].join("&&&") })).then((action) => {
                if (action.payload.status == 'success') {
                    setIsSave(true);
                }
            });
        }
        if (keyword) {
            navigate("/public/result?searchKey=" + keyword);
        }
    }

    useEffect(() => {
        if (searchHistory.length == 11) {
            searchHistory.splice(-1);
            const stringSH = searchHistory.join("&&&");
            dispatch(updateSearchHistory({ agentId: props.id, sh: stringSH }));
        };
    }, [searchHistory]);

    return (
        <>
            <Stack spacing={2} sx={{ width: '100%', maxWidth: 400 }}>
                <Autocomplete
                    freeSolo
                    id="mainSearch"
                    value={params.get('searchKey')}
                    disableClearable
                    options={searchHistory.map((option) => option)}
                    onKeyDown={(e) => e.key == "Enter" && handleSubmit(e.target.value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label=""
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                placeholder: "Search...",
                            }}
                        />
                    )}
                />
            </Stack>
        </>
    );
}