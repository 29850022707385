import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from 'app/config';
import { query, mutation } from "../graphql/comments_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import format from 'date-fns/format';
import JwtService from "app/services/jwtService";
import { isEmpty } from "lodash";

export const saveComment = createAsyncThunk(
    "commentsApp/saveComment",
    async (params, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.saveComment,
            variables: { data: JSON.stringify(params) },
        });

        const data = response.data.data.saveComment;
        if (data) {
            dispatch(
                showMessage({
                    message: "Comment has been successfully saved.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );
            return data === undefined ? null : data;
        } else {
            dispatch(
                showMessage({
                    message: "Something went wrong. Please try again later.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }
    }
);

export const getComments = createAsyncThunk(
    "commentsApp/getComments",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getComments,
            variables: {
                moduleId: params.moduleId ? params.moduleId : null,
                moduleType: params.moduleType ? params.moduleType : null,
            }
        });

        if (response.data.data.getComments) {
            const data = response.data.data.getComments;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const deletePublicComment = createAsyncThunk(
    "uploadApp/deletePublicComment",
    async (params, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.deletePublicComment,
            variables: { id: params.id },
        });

        if (response.data.data.deletePublicComment) {
            dispatch(
                showMessage({
                    message: "Comment has been successfully deleted.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );
        } else {
            dispatch(
                showMessage({
                    message: "Something went wrong. Please try again later.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }
    }
);

export const getMissionById = createAsyncThunk(
    "missionDetailsApp/getMissionById",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getMissionById,
            variables: params,
        });

        return response.data.data.getMissionById
    }
);

const commentsSlice = createSlice({
    name: 'commentsApp',
    initialState: null,
    reducers: null,
    extraReducers: {
        [saveComment.fulfilled]: (state, action) => action.payload,
        [getComments.fulfilled]: (state, action) => action.payload,
        [deletePublicComment.fulfilled]: (state, action) => action.payload,
        [getMissionById.fulfilled]: (state, action) => action.payload,
    },
});

export default commentsSlice.reducer;
