import * as React from 'react';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import { ThemeProvider } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
// import { deleteBlogAuthor } from "../store/BlogManageAuthorsSlice";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { showMessage } from "app/store/fuse/messageSlice";
// import BlogNewAuthorsModal from "./modal/BlogNewAuthorsModal";
import { confirmDelete } from 'app/services/swalService';
import { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { inputLabelClasses } from "@mui/material/InputLabel";
import { FiSearch, FiBell } from "react-icons/fi";

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    width: "100%",
    "& .MuiFilledInput-root": {
        borderRadius: "999px",
        backgroundColor: "#FAFAFB",
        border: "1px solid #E9E9E9",
        height: 40,
        width: '100%',
        minWidth: '0px',
        fontSize: '16px'
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
    "& .MuiFilledInput-input": {
        paddingTop: "8px"
    },
    "& input#mui-1::placeholder": {
        color: "#8B8B8B",
        opacity: 'unset',
        fontWeight: 'semi-bold',
        fontSize: '16px'


    },
}));
function FriendsManagerFilter(props) {
    const dispatch = useDispatch();

    const { data, setData } = props;
    const { origData, setOrigData } = props;
    const { setLoading, loadData } = props;
    const { setKeyword } = props;

    const mainTheme = useSelector(selectMainTheme);
    const { selected, setSelected } = props;

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="flex flex-1 w-full items-center justify-evenly min-w-max">
            <div className="flex flex-1 items-center justify-start px-0 w-full">
                <ThemeProvider theme={mainTheme}>
                    <TextFieldStyled
                        placeholder="Search Friends..."
                        variant="filled"
                        InputLabelProps={{
                            sx: {
                                color: "#48525C",
                                fontSize: { xl: '18px', lg: '16px', md: '14px' },
                                [`&.${inputLabelClasses.shrink}`]: {
                                    fontSize: '14px',
                                    color: "#141F2C"
                                }
                            }
                        }}
                        type="text"
                        onChange={(e) => setKeyword(e.target.value)}

                    />

                </ThemeProvider>
            </div>
            <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
            >
                {/* //for future */}
                {/* <BlogNewAuthorsModal module="new" loadData={loadData} /> */}
            </motion.div>
            <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
            >
                {/* //for future */}
                {/* <Button
                        className="whitespace-nowrap mx-10 rounded-md text-white"
                        disabled={!selected.length}
                        variant="contained"
                        color="error"
                        onClick={() => {
                            confirmDelete({}, function () {
                                dispatch(deleteBlogAuthor(selected)).then((action) => {
                                    dispatch(
                                        showMessage({
                                            message: "Author(s) has been successfully deleted.",
                                            autoHideDuration: 5000, //ms
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "right",
                                            },
                                            variant: "success",
                                        })
                                    );
                                    loadData();
                                });
                                setSelected([])
                            })
                        }}
                        startIcon={<Icon className="hidden sm:flex">delete</Icon>}
                    >
                        Remove Selected
                    </Button> */}
            </motion.div>
        </div>
    );
}

export default FriendsManagerFilter;
