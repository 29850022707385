import React from 'react';
import { Card, CardContent, CardHeader, Skeleton } from "@mui/material";

const SkeletonLoader = () => (
    <Card className="p-16 pb-8 rounded-lg mb-18 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
        <CardHeader
            className='pt-0 px-0'
            avatar={<Skeleton animation="wave" variant="circular" width={50} height={50} />}
            title={<Skeleton animation="wave" height={18} width="80%" style={{ marginBottom: 6 }} />}
            subheader={<Skeleton animation="wave" height={16} width="40%" />}
        />
        <CardContent className="p-0">
            <Skeleton sx={{ height: 264 }} animation="wave" variant="rectangular" className="rounded-md" />
        </CardContent>
    </Card>
);

export default SkeletonLoader;
