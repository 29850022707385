import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from 'app/config';
import { query, mutation } from "../graphql/blogs_category_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import format from 'date-fns/format';
import JwtService from "app/services/jwtService";
import { isEmpty } from "lodash";

export const saveBlogCategory = createAsyncThunk(
    "blogsApp/blogs/saveBlogCategory",
    async (params, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.saveBlogCategory,
            variables: { data: JSON.stringify(params) },
        });

        if (response.data.data.saveBlogCategory) {
            dispatch(
                showMessage({
                    message: "Category has been successfully saved.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );
        } else {
            dispatch(
                showMessage({
                    message: "Something went wrong. Please try again later.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }
    }
);

export const getBlogCategoryList = createAsyncThunk(
    "blogsApp/blogs/getBlogCategoryList",
    async (keyword) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getBlogCategoryList,
            variables: {
                keyword: keyword ? keyword : null
            }
        });

        if (response.data.data.getBlogCategoryList) {
            const data = response.data.data.getBlogCategoryList;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const deleteBlogCategory = createAsyncThunk(
    'blogsApp/blogs/deleteBlogCategory',
    async (depIds, { dispatch, getState }) => {

        var count = 0;

        await depIds.map(async (id) => {
            await axios.post(CONFIG.API + "/api/", {
                query: mutation.deleteBlogCategory,
                variables: {
                    id: id
                }
            });
            count++;
        })

        if (count == depIds.length) {
            dispatch(
                showMessage({
                    message: "Category(s) has been successfully deleted.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );
            return depIds;
        }
    }
);

export const checkBlogCategoryName = (filter) => async (dispatch) => {

    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.checkBlogCategoryName,
        variables: filter
    });

    if (response.data.data.checkBlogCategoryName.length) {
        return false;
    } else {
        return true;
    }
};


const blogManageCategorySlice = createSlice({
    name: 'blogsApp/blogs',
    initialState: null,
    reducers: null,
    extraReducers: {
        [saveBlogCategory.fulfilled]: (state, action) => action.payload,
        [deleteBlogCategory.fulfilled]: (state, action) => action.payload,
    },
});

export default blogManageCategorySlice.reducer;
