const mutation = {
    registerAgent: `
        mutation registerAgent($data: JSONString!) {
            registerAgent (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    registerActivateAgent: `
        mutation registerActivateAgent($id: String!) {
            registerActivateAgent (id: $id) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    checkUsernameAndEmail: `
      query checkUsernameAndEmail($value: String) {
          checkUsernameAndEmail(value: $value) {
              id
              email
              username
          }
      }
  `,
};

export { mutation, query };
