const mutation = {
    updateMissionByType: `
        mutation updateMissionByType($data: JSONString) {
           updateMissionByType(data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    getMissionById: `
        query getMissionById($id: String){
            getMissionById(id: $id){
            id
            title
            description
            dateCreated
            filesId
            agent{
                profilePicPath
                name
                flName
                username
            }
        }
    }
    `,
    getMarkersByAgentId: `
        query getMarkersByAgentId($id: String, $type: String, $keyword: String, $showInactive: String){
            getMarkersByAgentId(id: $id, type: $type, keyword: $keyword, showInactive: $showInactive){
            id
            title
            description
            dateCreated
            filesId
            lat
            missionId
            lng
            missionViews
            kingPin
            pinned
            status
            agent{
                profilePicPath
                name
                flName
                username
            }
        }
    }
    `,
};

export { mutation, query };
