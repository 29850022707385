import FuseUtils from "@fuse/utils";
import AppContext from "app/AppContext";
import { Component } from "react";
import { connect } from "react-redux";
import { matchRoutes } from "react-router-dom";
import { useContext } from 'react';
import withRouter from "@fuse/core/withRouter";
import settingsConfig from "app/fuse-configs/settingsConfig";
import history from "@history";
import JwtService from "app/services/jwtService";
import AgentJwtService from "app/services/agent/AgentJwtService";

class FuseAuthorization extends Component {
    constructor(props, context) {
        super(props);
        const { routes } = context;
        this.state = {
            accessGranted: true,
            routes,
        };
        this.defaultLoginRedirectUrl = "/login";
    }

    componentDidMount() {
        if (!this.state.accessGranted) {
            this.redirectRoute();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.accessGranted !== this.state.accessGranted;
    }

    componentDidUpdate() {
        if (!this.state.accessGranted) {
            this.redirectRoute();
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { location, userRole } = props;
        const { pathname } = location;

        const matchedRoutes = matchRoutes(state.routes, pathname);

        const matched = matchedRoutes ? matchedRoutes[0] : false;

        var token = JwtService.getDecodedAccessToken();

        if (matched.route.auth && matched.route.auth.includes('agent')) {
            token = AgentJwtService.getDecodedAccessToken();

            return {
                accessGranted: matched
                    ? FuseUtils.hasPermission(matched.route.auth, token.role, token.roles, token.role)
                    : true,
            };

        } else {
            return {
                accessGranted: matched
                    ? FuseUtils.hasPermission(matched.route.auth, userRole, token.roles, token.role)
                    : true,
            };
        }
    }

    redirectRoute() {
        const { location, userRole } = this.props;
        const { pathname } = location;
        const loginRedirectUrl = settingsConfig.loginRedirectUrl
            ? settingsConfig.loginRedirectUrl
            : this.defaultLoginRedirectUrl;

        const matchedRoutes = matchRoutes(this.state.routes, location.pathname);
        const matched = matchedRoutes ? matchedRoutes[0] : false;

        if (matched.route.auth && matched.route.auth.includes('agent')) {
            setTimeout(() => history.push(settingsConfig.loginRedirectUrlAgent), 0);
        } else {

            if (userRole === 'agent') {
                setTimeout(() => history.push(settingsConfig.loginRedirectUrlAgent), 0);
            } else {
                /*
                    User is guest
                    Redirect to Login Page
                    */
                if (!userRole || userRole.length === 0) {
                    setTimeout(() => history.push("/login"), 0);
                    settingsConfig.loginRedirectUrl = pathname;
                } else {
                    /*
                      User is member
                      User must be on unAuthorized page or just logged in
                      Redirect to dashboard or loginRedirectUrl
                      */
                    setTimeout(() => history.push("/login"), 0);
                    settingsConfig.loginRedirectUrl = this.defaultLoginRedirectUrl;
                }

            }

        }
    }

    render() {
        console.info('Fuse Authorization rendered', this.state.accessGranted);
        return (
            this.state.accessGranted ?
                <>
                    {this.props.children}
                </>
                : null
        );
    }
}

function mapStateToProps({ auth }) {
    return {
        userRole: auth.user.role,
    };
}

FuseAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps)(FuseAuthorization));
