import { lazy } from 'react';

const ChatApp = lazy(() => import('./ChatApp'));

const ChatAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
  routes: [
    {
      path: 'apps/chat',
      element: <ChatApp />,
    },
  ],
};

export default ChatAppConfig;
