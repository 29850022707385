import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller, FormProvider } from "react-hook-form";
import * as yup from "yup";
import reducer from 'app/main/apps/blogs/store';
import withReducer from "app/store/withReducer";
import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { styled, lighten } from '@mui/material/styles';
import { showMessage } from "app/store/fuse/messageSlice";
import { Switch } from "@mui/material";
import { followAgent, unFollowAgent } from "../store/FriendsManagerSlice";
import AgentJwtService from 'app/services/agent/AgentJwtService';

const Root = styled('div')(({ theme }) => ({
    background: theme.palette.primary,
    color: theme.palette.primary.contrastText,

    '& .bg-icon': {
        background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${lighten(
            theme.palette.primary.dark,
            0.1
        )} 100%)`,
        color: theme.palette.primary.contrastText,
    },

}));

function FriendsManagerStatusModal(props) {

    const token = AgentJwtService.getDecodedAccessToken();

    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [origData, setOrigData] = useState({});
    const [follow, setFollow] = useState(false);
    const [unfollow, setUnfollow] = useState(false);

    const [isActive, setIsActive] = useState(false);
    const [isDeactivate, setDeactivate] = useState(false);

    useEffect(() => {
        if (props.item["status"] == "Follow") {
            setFollow(false)
            setUnfollow(true)
        } else {
            setUnfollow(false)
            setFollow(true)
        }

    }, []);

    function handleOpenDialog() {
        setOpenDialog(true);
    }

    function handleCloseDialog() {
        setOpenDialog(false);
    }

    async function handleUnfollow() {
        const follower_id = token.id
        const id = props.item.agentFollowerList[0].id
        const data = {
            id: props.item["id"],
            agentid_follower: follower_id,
            agentid_followed: id,
            status: 0,
        }
        dispatch(unFollowAgent(data)).then(function (action) {
            setFollow(false)
            setUnfollow(true)
            props.loadData()
            handleCloseDialog()
        });
    }

    async function handleFollow() {
        const follower_id = token.id
        const id = props.item.agentFollowerList[0].id

        const data = {
            agentid_follower: follower_id,
            agentid_followed: id,
            status: 1,
        }

        dispatch(followAgent(data)).then(function (action) {
            setFollow(true);
            setUnfollow(false);
            props.loadData()
            handleCloseDialog()
        });
    }
    return (
        <Root>
            {props.module == "status" && (

                <>
                    {
                        follow && (
                            < Button onClick={handleOpenDialog} variant="text" className=" rounded-md bg-grey-200 hover:bg-grey-300 font-medium text-pi-black text-14">Follow</Button>
                        )
                    }
                    {
                        unfollow && (
                            < Button onClick={handleOpenDialog} variant="text" className=" rounded-md bg-grey-200 hover:bg-grey-300 font-medium text-pi-black text-14">Unfollow</Button>
                        )
                    }
                </>
            )
            }

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
                scroll="body"
                sx={{

                    '& .MuiPaper-root': {
                        width: "100%",
                    },
                }}
            >
                <AppBar position="static" elevation={0}>
                    <Toolbar className="flex w-full">
                        <Typography variant="subtitle1" color="inherit">
                            Friends Manager Action
                        </Typography>
                    </Toolbar>
                </AppBar>


                <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>

                    {follow && <Typography>Are you sure you want to follow this agent?</Typography>}

                    {unfollow && <Typography> Are you sure you want to unfollow this agent?</Typography>}

                </DialogContent>

                <DialogActions className="justify-center px-8 py-16">
                    <div className="px-16">
                        {follow && <LoadingButton
                            className="rounded-md mr-5"
                            variant="contained"
                            color="primary"
                            type="button"
                            disabled={isSaving}
                            onClick={handleFollow}
                        >
                            Save
                        </LoadingButton>}

                        {unfollow && <LoadingButton
                            className="rounded-md mr-5"
                            variant="contained"
                            color="primary"
                            type="button"
                            disabled={isSaving}
                            onClick={handleUnfollow}
                        >
                            Yes
                        </LoadingButton>}

                        <Button
                            className="rounded-md ml-5"
                            variant="outlined"
                            type="button"
                            onClick={handleCloseDialog}
                        >
                            Cancel
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </Root >
    );
}

export default withReducer("MissionApp", reducer)(FriendsManagerStatusModal);
