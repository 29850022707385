import FusePageCarded from '@fuse/core/FusePageCarded';
import withReducer from 'app/store/withReducer';
import reducer from 'app/main/apps/blogs/store';
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import AgentJwtService from 'app/services/agent/AgentJwtService';
import FriendsManagerHeader from './FriendsManagerHeader';
import FriendsManagerFilter from './FriendsManagerFilter';
import { getMissionManagerById } from '../../mission/store/missionSlice';
import FriendsManagerTable from './FriendsManagerTable';
import { agentList, followerList } from '../store/FriendsManagerSlice';

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {
    Avatar,
    Divider,
    IconButton,
    InputAdornment,
} from "@mui/material";
import { TextField, Button, Item, CardHeader } from "@mui/material";
import Stack from "@mui/material/Stack";
import Notifications from "@mui/icons-material/Notifications";
import NotificationsOff from "@mui/icons-material/NotificationsOff";

function FriendsManager() {

    const token = AgentJwtService.getDecodedAccessToken();
    const dispatch = useDispatch();
    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);
    const [updatedData, setUpdatedData] = useState([]);
    const [origData, setOrigData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [keyword, setKeyword] = useState("");

    function loadData() {
        setLoading(true)
        dispatch(followerList({ agentId: token.agentId, keyword: keyword })).then((action) => {
            if (!!keyword) {
                setData(
                    _.filter(action.payload, (config) =>
                        config.name && config.name.toLowerCase().includes(keyword.toLowerCase())
                    )
                );
            } else {
                setData(action.payload)
            }
            setOrigData(action.payload)
            setLoading(false)
        });
    }

    useEffect(() => {
        if (!!keyword) {
            setData(
                _.filter(origData, (config) =>
                    config.name && config.name.toLowerCase().includes(keyword.toLowerCase())
                )
            );
        } else {
            setData(origData)
        }
    }, [keyword]);

    useEffect(() => {
        setUpdatedData(data.filter(item => item.followedInfo != null))
    }, [data]);

    return <Stack direction={'column'} gap={{ lg: 4, md: 2, sm: 2, xs: 2 }} className=" px-0 max1200px:py-40 py-20 m-auto max1200px:w-pi-655px-vw sm:w-4/5 w-full">
        <Card className="p-24 rounded-lg mb-16 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>

            <FriendsManagerFilter
                selected={selected}
                setSelected={setSelected}
                data={updatedData}
                setData={setData}
                origData={origData}
                setOrigData={setOrigData}
                setLoading={setLoading}
                loadData={loadData}
                setKeyword={setKeyword}
            />
            <Divider className=" my-16" />

            <FriendsManagerTable
                selected={selected}
                setSelected={setSelected}
                loading={loading}
                setLoading={setLoading}
                data={updatedData}
                loadData={loadData}
            />

        </Card>
    </Stack>;
}

export default withReducer('missionApp', reducer)(FriendsManager);
