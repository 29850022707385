import FuseScrollbars from "@fuse/core/FuseScrollbars";
import _ from "@lodash";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import format from 'date-fns/format';
import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import withRouter from "@fuse/core/withRouter";
import FuseLoading from "@fuse/core/FuseLoading";
import SystemLogsTableHead from "./SystemLogsTableHead";
import { styled } from '@mui/material/styles';
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import parse from 'html-react-parser';
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd):not(.Mui-selected):not(:hover)': {
        backgroundColor: theme.palette.background.default,
    },
    '&:hover': {
        backgroundColor: theme.palette.background.hover,
    },
    '& td, & th': {
        border: 0,
    },
}));

function SystemLogsTable(props) {
    const dispatch = useDispatch();

    const { loading, setLoading } = props;
    const { data, setData } = props;
    const { page, setPage } = props;
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState({
        direction: "asc",
        id: null,
    });

    function handleRequestSort(event, property) {
        const id = property;
        let direction = "desc";

        if (order.id === property && order.direction === "desc") {
            direction = "asc";
        }

        setOrder({
            direction,
            id,
        });
    }

    function handleChangePage(event, value) {
        setPage(value);
    }

    function handleChangeRowsPerPage(event) {
        setPage(0);
        setRowsPerPage(event.target.value);
    }

    if (loading) {
        return <FuseLoading />;
    }

    if (data.length === 0) {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                className="w-full text-center  h-full m-auto"
            >
                <Typography color="textSecondary" variant="h5">
                    There are no System Logs!
                </Typography>
            </motion.div>
        );
    }

    return (
        <div className="w-full flex flex-col justify-between h-full">
            <Table aria-labelledby="tableTitle">
                <SystemLogsTableHead
                    order={order}
                    onRequestSort={handleRequestSort}
                    rowCount={data.length}
                />

                <TableBody>
                    {_.orderBy(
                        data,
                        [
                            (o) => {
                                switch (order.id) {
                                    case "categories": {
                                        return o.categories[0];
                                    }
                                    default: {
                                        return o[order.id];
                                    }
                                }
                            },
                        ],
                        [order.direction]
                    )
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((n) => {
                            return (
                                <StyledTableRow
                                    className="h-72 cursor-pointer"
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={n.id}
                                >
                                    <TableCell
                                        className="p-4 md:p-16"
                                        component="th"
                                        scope="row"
                                    >
                                        {n.module}
                                    </TableCell>

                                    <TableCell
                                        className="p-4 md:p-16"
                                        component="th"
                                        scope="row"
                                    >
                                        {n.action}
                                    </TableCell>

                                    <TableCell
                                        className="p-4 md:p-16"
                                        component="th"
                                        scope="row"
                                    >
                                        {parse(n.details)}
                                    </TableCell>

                                    <TableCell
                                        className="p-4 md:p-16"
                                        component="th"
                                        scope="row"
                                    >
                                        {n.username}
                                    </TableCell>

                                    <TableCell
                                        className="p-4 md:p-16"
                                        component="th"
                                        scope="row"
                                    >
                                        {format(new Date(n.dateFormatted), 'MM/dd/yyyy')}
                                    </TableCell>
                                </StyledTableRow>
                            );
                        })}
                </TableBody>
            </Table>

            <TablePagination
                className="shrink-0 border-t-1"
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={"Show: "}
                page={page}
                backIconButtonProps={{
                    "aria-label": "Previous Page",
                }}
                nextIconButtonProps={{
                    "aria-label": "Next Page",
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}

export default withRouter(SystemLogsTable);
