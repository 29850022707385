import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller, FormProvider } from "react-hook-form";
import * as yup from "yup";
import withReducer from "app/store/withReducer";
import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { styled, lighten } from '@mui/material/styles';
import reducer from 'app/main/apps/agent/store';
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { agentById, updateAgent, updatePassword, checkAccount } from '../store/agentSlice';
import { showMessage } from "app/store/fuse/messageSlice";
import InputLabel, { inputLabelClasses } from "@mui/material/InputLabel";
import { DialogTitle, Divider } from "@mui/material";
const Root = styled('div')(({ theme }) => ({
    background: theme.palette.primary,
    color: theme.palette.primary.contrastText,

    '& .bg-icon': {
        background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${lighten(
            theme.palette.primary.dark,
            0.1
        )} 100%)`,
        color: theme.palette.primary.contrastText,
    },

}));

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        backgroundColor: "#ffff",
        border: "1px solid #E9E9E9",
        fontSize: '16px',
        [theme.breakpoints.up("lg")]: {
            height: 57,
            fontSize: '18px',
        },
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
    "& .MuiInputLabel-asterisk": {
        display: "none"
    },

    "& .MuiInputLabel-root": {
        top: "0px",
    },
}));
const defaultValues = {
    id: null,
    password: "",
    newPassword: "",
    confirmPassword: "",
};

function AgentProfilePassword(props) {
    const dispatch = useDispatch();

    /**
     * Form Validation Schema
     */
    const schema1 = yup.object().shape({
        password: yup
            .string()
            .required("Current Password is required"),
        newPassword: yup
            .string()
            .required("Password is required")
            .test({
                message: () => 'Please dont use old password.',
                test: async (pass) => {
                    if (!!pass) {
                        const res = await checkPassword(pass);
                        if (res) {
                            return null;
                        } else {
                            return [];
                        }
                    } else {
                        return [];
                    }
                },
            })
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,  // Special Character validation,  // Special Character validation
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
        confirmPassword: yup
            .string()
            .required("Confirm Password is required")
            .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
    });



    const schema2 = yup.object().shape({
        newPassword: yup
            .string()
            .required("Password is required")
            .test({
                message: () => 'Please dont use old password.',
                test: async (pass) => {
                    if (!!pass) {
                        const res = await checkPassword(pass);
                        if (res) {
                            return null;
                        } else {
                            return [];
                        }
                    } else {
                        return [];
                    }
                },
            })
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,  // Special Character validation,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
        confirmPassword: yup
            .string()
            .required("Confirm Password is required")
            .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
    });

    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: yupResolver(props.module != 'reset' ? schema1 : schema2),
    });

    const {
        control,
        formState,
        handleSubmit,
        reset,
        getValues,
        setValue,
        watch,
        trigger
    } = methods;

    const [openDialog, setOpenDialog] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { isValid, dirtyFields, errors } = formState;
    const [password, setPassword] = useState(false);
    const [newPassword, setNewPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const n_id = watch("id");
    const c_pass = watch("password");
    const n_pass = watch("newPassword");

    useEffect(() => {
        if (openDialog) {
            if (props.item) {
                setValue("id", props.item["id"])
            }
        } else {
            setTimeout(() => {
                reset(defaultValues);
            }, 500);
        }

    }, [openDialog]);

    async function checkPassword(pass) {
        return new Promise((resolve, reject) => {
            dispatch(checkAccount({ id: props.item.userId, password: pass })).then((action) => {
                if (action.payload.status == 'success') {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    function handleOpenDialog() {
        setOpenDialog(true);
    }

    function handleCloseDialog() {
        setOpenDialog(false);
    }

    async function handleSave() {
        setIsSaving(true);
        const result = await trigger(["password", "newPassword", "confirmPassword"]);

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            setIsSaving(false);
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }

        if (Object.keys(dirtyFields).length === 0) {
            dispatch(
                showMessage({
                    message: "No changes have been made.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "warning",
                })
            );

        } else {
            dispatch(updatePassword({ id: props.item.userId, cpassword: c_pass, npassword: n_pass })).then((action) => {
                setOpenDialog(false);
                setIsSaving(false);
            });
        }
    }

    return (
        <FormProvider {...methods}>

            <Root>

                {props.module != "reset" && (
                    <Tooltip title={"Update Password"} arrow>
                        <Button
                            onClick={handleOpenDialog}
                            size="large"
                            variant="contained"
                            color="secondary"
                            className="px-24 py-8 text-16 font-bold max-w-max rounded-md bg-pi-blue-1  hover:bg-pi-blue-1 text-white"
                        >
                            <Icon className="mr-6 text- text-white fill-white">lock</Icon> Update Password
                        </Button>
                    </Tooltip>
                )}
                {props.module == "reset" && (
                    <Tooltip title={"Reset Password"} arrow>
                        <IconButton
                            onClick={handleOpenDialog}
                            size="large"
                            edge={false}
                        >
                            <Icon>lock</Icon>
                        </IconButton>
                    </Tooltip>
                )}


                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="form-dialog-title"
                    scroll="body"
                    sx={{

                        '& .MuiPaper-root': {
                            width: "100%",
                        },
                    }}
                >
                    <DialogTitle className="flex w-full">
                        {props.module != "reset" && (
                            <Typography className="font-bold text-18" color="inherit">
                                Change Password
                            </Typography>
                        )}
                        {props.module == "reset" && (
                            <Typography className="font-bold text-18" color="inherit">
                                Reset Password
                            </Typography>
                        )}
                    </DialogTitle>
                    <Divider />
                    <form
                        noValidate
                        className="flex flex-col"
                    >
                        <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>
                            {props.module != "reset" && (
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldStyled
                                            label={<Typography className="text-15" color="text.primary">Current Password  <span className="text-red-500">*</span></Typography>}
                                            placeholder="*********"
                                            variant="filled"
                                            InputLabelProps={{
                                                sx: {
                                                    color: "#48525C",
                                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                        fontSize: '14px',
                                                        color: !!errors.email ? "red" : "#141F2C"
                                                    }
                                                }
                                            }}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    height: 56
                                                }
                                            }}
                                            {...field}
                                            className="mb-16"
                                            type="password"
                                            name="password"
                                            error={!!errors.password}
                                            helperText={errors?.password?.message}
                                            required
                                            fullWidth
                                            InputProps={{
                                                className: "pr-2",
                                                type: showPassword ? "text" : "password",
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            size="large"
                                                            edge={false}
                                                        >
                                                            <Icon className="text-20" color="action">
                                                                {showPassword ? "visibility" : "visibility_off"}
                                                            </Icon>
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            )}
                            <Controller
                                name="newPassword"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldStyled
                                        label={<Typography className="text-15" color="text.primary">New Password  <span className="text-red-500">*</span></Typography>}
                                        placeholder="*********"
                                        variant="filled"
                                        InputLabelProps={{
                                            sx: {
                                                color: "#48525C",
                                                fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                [`&.${inputLabelClasses.shrink}`]: {
                                                    fontSize: '14px',
                                                    color: !!errors.email ? "red" : "#141F2C"
                                                }
                                            }
                                        }}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                height: 56
                                            }
                                        }}
                                        {...field}
                                        className="mb-16"
                                        type="password"
                                        name="newPassword"
                                        error={!!errors.newPassword}
                                        helperText={errors?.newPassword?.message}
                                        required
                                        fullWidth
                                        InputProps={{
                                            className: "pr-2",
                                            type: showNewPassword ? "text" : "password",
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowNewPassword(!showNewPassword)}
                                                        size="large"
                                                        edge={false}
                                                    >
                                                        <Icon className="text-20" color="action">
                                                            {showNewPassword ? "visibility" : "visibility_off"}
                                                        </Icon>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name="confirmPassword"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldStyled
                                        label={<Typography className="text-15" color="text.primary">Confirm Password <span className="text-red-500">*</span></Typography>}
                                        placeholder="*********"
                                        variant="filled"
                                        InputLabelProps={{
                                            sx: {
                                                color: "#48525C",
                                                fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                [`&.${inputLabelClasses.shrink}`]: {
                                                    fontSize: '14px',
                                                    color: !!errors.email ? "red" : "#141F2C"
                                                }
                                            }
                                        }}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                height: 56
                                            }
                                        }}
                                        {...field}
                                        className="mb-16"
                                        type="password"
                                        name="confirmPassword"
                                        error={!!errors.confirmPassword}
                                        helperText={errors?.confirmPassword?.message}
                                        required
                                        fullWidth
                                        InputProps={{
                                            className: "pr-2",
                                            type: showConfirmPassword ? "text" : "password",
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                        size="large"
                                                        edge={false}
                                                    >
                                                        <Icon className="text-20" color="action">
                                                            {showConfirmPassword ? "visibility" : "visibility_off"}
                                                        </Icon>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </DialogContent>

                        <DialogActions className="justify-center px-8 py-16">
                            <div className="px-16">
                                <LoadingButton
                                    className="rounded-md mr-5"
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    disabled={isSaving}
                                    onClick={handleSave}
                                >
                                    Save
                                </LoadingButton>
                                <Button
                                    className="rounded-md ml-5"
                                    variant="outlined"
                                    type="button"
                                    onClick={handleCloseDialog}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </DialogActions>
                    </form>
                </Dialog>
            </Root>
        </FormProvider>
    );
}

export default withReducer("agentApp", reducer)(AgentProfilePassword);
