import {
    IconButton,
    Divider,
    Icon,
    TextField,
    InputAdornment,
    Stack,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { showMessage } from "app/store/fuse/messageSlice";
import { Provider, useDispatch } from "react-redux";
import {
    createReply,
} from "../../store/missionSlice"; //from store
import AgentJwtService from 'app/services/agent/AgentJwtService';


import UploadFile from "../../../shared/Upload/UploadFile";

import { useState, useRef, useEffect, useMemo } from "react";
import { styled } from "@mui/material/styles";

import {
    BrowserRouter as Router,
    useParams,
} from "react-router-dom";
import { inputLabelClasses } from "@mui/material/InputLabel";

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        fontFamily: "'Nunito Sans', sans-serif !important",
        paddingTop: "8px !important",
        backgroundColor: "#F8FAFB",
        border: "1px solid #E9E9E9",
        // height: 48,
        fontSize: '14px',
        borderRadius: '20px',
        [theme.breakpoints.up("lg")]: {
            height: 'unset',
            fontSize: '14px',
        },
        // borderRadius:"8px"
    },
    "& .MuiInputLabel-root": {
        // height: 48,
        fontSize: '14px',
        [theme.breakpoints.up("lg")]: {
            fontSize: '14px',
        },
        // borderRadius:"8px"
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },

}));

const defaultValues = {
    reply: "",
    images: [],
};

export default function ReplyForm(props) {
    const token = AgentJwtService.getDecodedAccessToken();
    const { commentId, reloadData, mission_id } = props
    const [openDialog, setOpenDialog] = useState(false);
    const [imagesData, setImagesData] = useState([]);
    const { id } = useParams();
    const comment_id = commentId;
    const dispatch = useDispatch();
    const commentSchema = yup.object().shape({
        reply: yup.string().required("Reply is required"),
    });

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };
    function saveImages(data) {
        setImagesData(data)
    }

    const {
        control,
        formState,
        handleSubmit,
        reset,
        setValue,
        watch,
        getValues,
        trigger,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            reply: "",
        },
        resolver: yupResolver(commentSchema),
    });

    const { isValid, dirtyFields, errors } = formState;

    async function handleSave() {
        const result = await trigger(["comment"]);

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                    variant: "error",
                })
            );
        }

        var data = getValues();
        data.mission_id = mission_id;
        data.comment_id = comment_id;
        data.agent = token.id
        data.images = imagesData.saveImages ? imagesData.saveImages : []

        dispatch(createReply(data)).then(function () {
            reloadData()
            reset(defaultValues);
        }, []);
    }
    return (
        <div>
            <ToastContainer />
            <form name="replyForm" noValidate>
                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                    <Controller
                        name="reply"
                        control={control}
                        render={({ field }) => (
                            <TextFieldStyled
                                {...field}
                                error={!!errors.reply}
                                required
                                helperText={errors?.reply?.message}
                                id="reply"
                                className="w-full"
                                size="medium"
                                placeholder="Write a reply..."
                                variant="filled"
                                InputLabelProps={{
                                    sx: {
                                        color: "#48525C",
                                        fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                        [`&.${inputLabelClasses.shrink}`]: {
                                            fontSize: '14px',
                                            color: !!errors.username ? "red" : "#141F2C"
                                        }
                                    }
                                }}
                                fullWidth
                                multiline
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            className="h-full max-h-max justify-between pr-5"
                                        >
                                            <IconButton
                                                className="!p-5"
                                                aria-label="toggle password visibility"
                                                onClick={handleClickOpen}
                                                title="Attach Media"
                                                edge="end"
                                            >
                                                <Icon className=" !text-20">attach_file</Icon>
                                            </IconButton>
                                            <Dialog onClose={handleClose} open={openDialog}>
                                                <DialogTitle>Set backup account</DialogTitle>
                                                <UploadFile saveImages={saveImages} />
                                            </Dialog>
                                            <Divider orientation="vertical" flexItem />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    />
                    <IconButton
                        onClick={handleSave}
                        aria-label="toggle password visibility"
                        title="Submit"
                        edge="end"
                        className="  !p-5"
                    >
                        <Icon className=" !text-20" size="large">send</Icon>
                    </IconButton>
                </Stack>

            </form>
        </div>
    );
};