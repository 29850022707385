import { lazy } from 'react';
import { authRoles } from 'app/auth';

const Users = lazy(() => import('./user/Users'));
const ManageUsers = lazy(() => import('./manage-account/Manage'));
const UsersProfile = lazy(() => import('./user-profile/UsersProfile'));

const UserAccountAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {
            path: 'apps/user-account/manage',
            element: <ManageUsers />,
            auth: ['usr-mu']
        },
        {
            path: 'apps/user-account/users/:usersId/*',
            element: <Users />,
            auth: ['usr-mu']
        },
        {
            path: 'apps/user-profile/:usersId/*',
            element: <UsersProfile />,
            auth: ['usr-profile']
        },
    ],
};

export default UserAccountAppConfig;
