import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import FuseNavItem from '../FuseNavItem';

const StyledList = styled(List)(({ theme }) => ({
  '& .fuse-list-item': {
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0,0,0,.04)',
    },
    '&:focus:not(.active)': {
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.06)' : 'rgba(0,0,0,.05)',
    },
  },
  '&.active-square-list': {
    '& .fuse-list-item, & .active.fuse-list-item': {
      width: '100%',
      borderRadius: '0',
    },
  },
  '&.dense': {
    '& .fuse-list-item': {
      paddingTop: 0,
      paddingBottom: 0,
      height: 32,
    },
  },
  '& .fuse-list-item': {
    height: 45,
    marginTop: 8,
    marginBottom: 8,
  },
  '&.active-square-list': {
    '& .fuse-list-item, & .active.fuse-list-item': {
      width: '100%',
      borderRadius: '0',
    },
  },
  '& .MuiListItemText-root .MuiTypography-root': {
    fontSize: '14px',
    fontWeight: "bold",
  },
  '& .active.fuse-list-item': {
    backgroundColor: "#FFF7E8 !important",
    color: "#FBB633 !important",
  },
  '& .material-icons ': {
    marginRight: "18px !important",
    fontSize: '2.3rem',
    color: '#141f2c !important',
    opacity: '.5',
  },
  '& .fuse-list-item:hover ': {
    '& .material-icons': {
      color: '#141f2c !important',
      opacity: '.7',
    }
  },
  '& .active.fuse-list-item .material-icons': {
    fill: '#FBB633 ',
    opacity: 'unset',
  },


}));

function FuseNavVerticalLayout1(props) {
  const { navigation, layout, active, dense, className, onItemClick } = props;
  const dispatch = useDispatch();

  function handleItemClick(item) {
    onItemClick?.(item);
  }

  return (
    <StyledList
      className={clsx(
        'navigation whitespace-nowrap px-12',
        `active-${active}-list`,
        dense && 'dense',
        className
      )}
    >
      {navigation.map((_item) => (
        _item.show ?
          <FuseNavItem
            key={_item.id}
            type={`vertical-${_item.type}`}
            item={_item}
            nestedLevel={0}
            onItemClick={handleItemClick}
          /> : null
      ))}
    </StyledList>
  );
}

export default FuseNavVerticalLayout1;
