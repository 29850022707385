const mutation = {
    createMission: `
        mutation createMission($data: JSONString!) {
            createMission (data: $data) {
                status,
                res,
                message
            }
        }
    `,

    createJoinedMission: `
        mutation joinedMission($data: JSONString!) {
            joinMission (data: $data) {
                status,
                res,
                message
            }
        }
    `,

    createCommentMission: `
        mutation createComment($data: JSONString!) {
            createComment (data: $data) {
                status,
                res,
                message
            }
        }
    `,

    createReplyMission: `
        mutation createReply($data: JSONString!) {
            createReply (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    deleteSingleMarkerById: `
        mutation deleteSingleMarkerById($id: String) {
            deleteSingleMarkerById (id: $id) {
                status,
                res,
                message
            }
        }
    `,

    updateMarkerContetnById: `
        mutation updateContentMarkerById($data: JSONString!) {
            updateContentMarkerById (data: $data) {
                status,
                res,
                message
            }
        }
    `,

    updateMarkerLocationById: `
        mutation updateMarkerLocationById($data: JSONString!) {
            updateLocationMarkerById (data: $data) {
                status,
                res,
                message
            }
        }
    `,

    deleteMissionById: `
        mutation deleteMissionById($id: String!) {
            deleteMissionById (id: $id) {
                status,
                res,
                message            
            }
        }
    `,

    createPublicUpload: `
        mutation createPublicUpload($data: JSONString) {
           createPublicUpload (data: $data) {
                status,
                message,
                upload {
                id,
                fileId,
                fileName,
                fileSize,
                fileType,
                path,
                module,
                source          
                }
            }
        }
    `,

};

const query = {
    getMissionById: `
        query getMissionById($id: String){
            getMissionById(id: $id){
            id
            title
            description
            lat
            lng
            agent{
                id
                userId
                displayName
            }
        }
    }
    `,
    getSingleMissionById: `
    query getSingleMissionById($id: String) {
        missionById(id: $id) {
            totalMissionViews{
                id,
                title,
                description,
                lat,
                lng,
                address,
                kingpin,
                views,
                filesId,
                dateCreated,
                agent{
                    id,
                    email,
                    agentName
                    firstName,
                    lastName,
                    middleName,
                    gender,
                    birthdate,
                    status,
                    images{
                      profileUrl
                    }
                },
                markers{
                    id,
                    missionId,
                    description,
                    title,
                    lat,
                    lng,
                    pin,
                    pin,
                    status,
                    filesId,
                    dateCreated,
                    agentJoined,
                    agent{
                        id,
                        userId,
                        email,
                        agentName
                        firstName,
                        lastName,
                        middleName,
                        gender,
                        birthdate,
                        status,
                        profilePicPath,
                    },
                },
            }
            totalMissionComments,
            totalMissionPinned
        }
      }
    `,
    getMissionManagerById: `
    query getMissionManagerById($id: String) {
        getMissionManagerById(id: $id) {
            id
            title
            description
            missionBriefing
            lat
            lng
            address
            views
            status
            featured
            pinned
            dateCreated
            dateUpdated 
        }
      }
    `,
    getCreatedMissions: `
    query getCreatedMissions($id: String) {
        getCreatedMissions(id: $id) {
            id
            title
            description
            missionBriefing
            lat
            lng
            address
            agent
            views
            status
            featured
            dateCreated
            dateUpdated        
            comments{
                id,
                comment,
                replies{
                    reply,
                    repliesImages{
                        preSignedUrl
                    }
                }
                commentsImages{
                    preSignedUrl
                }
            },
            markers{
                id,
                agent,
                description,
                lat,
                lng,
                pin,
                markersImages{
                    preSignedUrl
                }
            },
            missionsFile{
                preSignedUrl
            }
        }
      }
    `,
    getAcceptedMissions: `
    query getAcceptedMissions($id: String) {
        getAcceptedMissions(id: $id) {
            id
            title
            description
            lat
            lng
            address
            agent
            views
            status
            featured
            dateCreated
            dateUpdated
            comments{
                id,
                comment
            },
        }
      }
    `,
};

export { mutation, query };
