const mutation = {
    saveBlogCategory: `
        mutation saveBlogCategory($data: JSONString!) {
            saveBlogCategory (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    deleteBlogCategory: `
        mutation deleteBlogCategory($id: String!) {
            deleteBlogCategory (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
};

const query = {
    getBlogCategoryList: `
        query getBlogCategoryList {
            getBlogCategoryList {
                id
                name
                dateCreated
                dateUpdated
            }
        }
    `,
    checkBlogCategoryName: `
        query checkBlogCategoryName($value: String, $id: String) {
            checkBlogCategoryName(value: $value, id: $id) {
                id
                name
            }
        }
    `,
};
export { mutation, query };