import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { query, mutation } from "../graphql/friends_manager_graphql";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import { v4 as uuidv4 } from 'uuid';
import { resolve } from "promise";
import { showMessage } from "app/store/fuse/messageSlice";


export const unFollowAgent = createAsyncThunk(
    "missionApp/mission/unFollowAgent",
    async (data) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.unFollowAgent,
            variables: {
                data: JSON.stringify(data),
            },
        });

        if (response.data.data.unFollowAgent) {
            const data = response.data.data.unFollowAgent;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const followAgent = createAsyncThunk(
    "missionApp/mission/followAgent",
    async (data) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.followAgent,
            variables: {
                data: JSON.stringify(data),
            },
        });

        if (response.data.data.followAgent) {
            const data = response.data.data.followAgent;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const muteAgent = createAsyncThunk(
    "missionApp/mission/muteAgent",
    async (data) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.muteAgent,
            variables: {
                data: JSON.stringify(data),
            },
        });

        if (response.data.data.muteAgent) {
            const data = response.data.data.muteAgent;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const unmuteAgent = createAsyncThunk(
    "missionApp/mission/unmuteAgent",
    async (data) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.unmuteAgent,
            variables: {
                data: JSON.stringify(data),
            },
        });

        if (response.data.data.unmuteAgent) {
            const data = response.data.data.unmuteAgent;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const followerList = createAsyncThunk(
    "missionApp/mission/followerList",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.followerList,
            variables: {
                agentId: params.agentId,
                keyword: params.keyword ? params.keyword : null
            }
        });

        if (response.data.data.followerList) {
            const data = response.data.data.followerList;

            return data === undefined ? [] : data;
        } else {
            return null;
        }
    }
);

export const friendList = createAsyncThunk(
    "missionApp/mission/friendList",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.friendList,
            variables: {
                agentId: params.agentId,
                keyword: params.keyword ? params.keyword : null
            }
        });

        if (response.data.data.friendList) {
            const data = response.data.data.friendList;

            return data === undefined ? [] : data;
        } else {
            return null;
        }
    }
);

const saveImages = (itemData) => {

    itemData.fileid = uuidv4();

    return new Promise(async (resolve, reject) => {

        if (itemData.images.length) {

            var newImages = [];

            itemData.images.map(async (image, index) => {

                if (image.status === "new") {
                    newImages.push({
                        index: index,
                        image: image
                    })
                } else {
                    if (itemData.featuredImageId == image.fileId) {
                        itemData.featuredImagePath = image.path;
                    }
                }

            });

            if (newImages.length) {

                newImages.map(async (image, index) => {

                    await uploadSvc({
                        file: image.image.file,
                        destination_file_name: "missions/" + randomString() + "-" + (image.image.file ? image.image.file.name : image.image.fileName)
                    }).then(async (result) => {

                        if (result.status === 200 && result.data.status === "success") {

                            var uploadData = result.data.uploadData
                            uploadData['module'] = 'mission';
                            uploadData['file_id'] = image.image.id;
                            uploadData['fileid'] = itemData.fileid;

                            const response = await axios.post(CONFIG.API + "/api/", {
                                query: mutation.createPublicUpload,
                                variables: {
                                    data: JSON.stringify(uploadData),
                                },
                            });

                            if (response.data.data.createPublicUpload.status == "success") {
                                var upload = response.data.data.createPublicUpload.upload;

                                if (itemData.featuredImageId == image.image.id) {
                                    itemData.featuredImagePath = upload.path;
                                }
                                itemData.images[image.index].upload_id = upload.id;
                                itemData.images[image.index].file_name = upload.fileName;
                                itemData.images[image.index].file_id = upload.fileId;
                                itemData.images[image.index].file_type = upload.fileType;
                                itemData.images[image.index].path = upload.path;
                                itemData.images[image.index].module = upload.module;
                            }
                        }

                        if (newImages.length == (index + 1)) {
                            resolve(itemData);
                        }

                    });

                });

            } else {
                resolve(itemData)
            }

        } else {
            resolve(itemData);

        }

    });

}

const FriendsManagerSlice = createSlice({
    name: "friends_activity",
    initialState: null,
    reducers: null,
    extraReducers: {
        [unFollowAgent.fulfilled]: (state, action) => action.payload,
        [followAgent.fulfilled]: (state, action) => action.payload,
        [muteAgent.fulfilled]: (state, action) => action.payload,
        [unmuteAgent.fulfilled]: (state, action) => action.payload,
        [followerList.fulfilled]: (state, action) => action.payload,
        [friendList.fulfilled]: (state, action) => action.payload,
    },
});

export default FriendsManagerSlice.reducer;
