import React, { useState, useRef } from "react";
import { Avatar, Box, CardHeader, Stack, Typography } from "@mui/material";
import HomeContent from "../home/content/HomeContent";

function SidePanelMission(props) {
    return (
        <>
            <HomeContent module={'sidePanel'} type={'featured'} initial={4} />
            <HomeContent module={'sidePanel'} type={'popular'} initial={4} />
        </>
    );
}

export default SidePanelMission;
