import { authRoles } from 'app/auth';
import ManageMaintenance from './ManageMaintenance';
import ManageSysLogs from './system-logs/ManageSysLogs';
import ManageSite from './ManageSite';
import MaintenanceTemplate from './template/MaintenanceTemplate';

const SettingsCmsAppConfig = {
    settings: {
        theme: {
            main: "default",
            navbar: "default",
            toolbar: "default",
            footer: "default"
        },
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {
            path: 'apps/settings/maintenance',
            element: <ManageMaintenance />,
            auth: ["settings-mnt"],
        },
        {
            path: 'apps/settings/site',
            element: <ManageSite />,
            auth: ["settings-site"],
        },
        {
            path: 'apps/settings/syslogs',
            element: <ManageSysLogs />,
            auth: ["settings-syslogs"],
        },
        {
            path: 'server/maintenance',
            element: <MaintenanceTemplate />,
        },
    ],
};

export default SettingsCmsAppConfig;
