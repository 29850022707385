import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import AboutContent from "app/main/apps/public/about/content/AboutContent";
import { GlobalStyles, height } from "@mui/system";
import PublicSidebarLeftLogo from "../public-sidebar-left/PublicSidebarLeftLogo";
import PublicSidebarLeft from "../public-sidebar-left/PublicSidebarLeft";
import FuseScrollbars from "@fuse/core/FuseScrollbars/FuseScrollbars";
import PublicHeader from "app/main/apps/public/public-header/PublicHeader";
const Root = styled(FusePageSimple)(() => ({
    minHeight: "unset",
    "& .FusePageSimple-header.FusePageSimple-topBg": {
        display: "none",
    },
    "& .flex.flex-auto.flex-col.container.z-10.h-full": {
        width: "100%",
        maxWidth: "unset",
    },
    "& .FusePageSimple-header": {
        background: "transparent",
        backgroundSize: "cover",

        height: 152.99,
    },
    "& .FusePageSimple-wrapper": {
        background: "transparent",
        height: "unset",
        width: "100%",
    },
    "& .MuiDrawer-root.MuiDrawer-docked": {
        "& .MuiPaper-root": {
            width: "17.708333333333332vw",
            minWidth: "290px"
        }
    },
    "& .max-h-screen-content-100 .ps__rail-y": {
        display: "none !important",
    },
    "& .FusePageSimple-sidebarContent": {
        height: "calc(100vh - 7.6rem)",
    },
    "& .FusePageSimple-toolbar": {
        height: "76px",
    },
}));

function PublicAbout(props) {
    return (
        <>
            <GlobalStyles
                styles={(theme) => ({
                    "#fuse-main": {
                        height: "100% !important",
                    },
                    " .FusePageSimple-sidebar, .FusePageSimple-sidebarHeader":
                    {
                        background: "white !important",
                    },
                    " .absolute-important":
                    {
                        position: "absolute !important",
                    },
                    " div.FusePageSimple-sidebarHeader.permanent": {
                        minHeight: 0,
                        height: "unset"
                    }
                })}
            />

            <Root
                contentToolbar={
                    <> </>
                    // <PublicHeader />
                }
                content={
                    <FuseScrollbars className=" h-full max-h-screen-content-100 overflow-auto overscroll-auto">
                        <AboutContent />
                    </FuseScrollbars>
                }
            />
        </>
    );
}

export default PublicAbout;
