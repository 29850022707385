import * as React from "react";
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import TableHead from '@mui/material/TableHead';
import { styled } from '@mui/material/styles';
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& .MuiTableCell-root': {
        backgroundColor: theme.palette.background.paper,
    },
}));
const rows = [
    {
        id: 'module',
        align: 'left',
        disablePadding: false,
        label: 'Module',
        sort: true,
    },
    {
        id: 'action',
        align: 'left',
        disablePadding: false,
        label: 'Action',
        sort: true,
    },
    {
        id: 'details',
        align: 'left',
        disablePadding: false,
        label: 'Details',
        sort: true,
    },
    {
        id: 'username',
        align: 'left',
        disablePadding: false,
        label: 'Username',
        sort: true,
    },
    {
        id: 'dateFormatted',
        align: 'left',
        disablePadding: false,
        label: 'Date',
        sort: true,
    },
];

function SystemLogsTableHead(props) {

    const dispatch = useDispatch();

    const createSortHandler = (property) => (event) => {
        props.onRequestSort(event, property);
    };

    return (
        <TableHead>
            <StyledTableRow className="h-48 sm:h-64">
                {rows.map((row) => {
                    return (
                        <TableCell
                            className="p-4 md:p-16"
                            key={row.id}
                            align={row.align}
                            padding={row.disablePadding ? 'none' : 'normal'}
                            sortDirection={props.order.id === row.id ? props.order.direction : false}
                        >
                            {row.sort ? (
                                <Tooltip
                                    title="Sort"
                                    placement={row.align === 'right' ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={props.order.id === row.id}
                                        direction={props.order.direction}
                                        onClick={createSortHandler(row.id)}
                                        className="font-bold truncate"
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            ) : <b>{row.label}</b>}
                        </TableCell>
                    );
                }, this)}
            </StyledTableRow>
        </TableHead>
    );
}

export default SystemLogsTableHead;
