import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import { inputLabelClasses } from "@mui/material/InputLabel";

import React, { useState, useRef, useEffect } from "react";
import Box from '@mui/material/Box';
import { useForm, Controller, useFormContext } from "react-hook-form";
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Typography } from "@mui/material";
import { Badge, Card, CardHeader, Stack, Avatar, useMediaQuery, Button, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider, ListSubheader, TextField, Icon } from "@mui/material";
import LazyLoad from 'react-lazy-load';
import { Link } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { showMessage } from 'app/store/fuse/messageSlice';
import AttachmentSvs from "app/services/AttachmentSvs";
import randomString from "app/services/randomString";
import AttachmentUploader from "app/services/AttachmentUploader";
import { deletePublicAttachment } from "app/services/store/PublicUploadsSlice";
import { useNavigate, generatePath } from "react-router-dom";
import { createMission } from "app/main/apps/public/mission/store/missionSlice";
import ReactPlayer from "react-player";
import notification from "app/services/notification";
import moment from "moment";
import { addNotification } from "app/fuse-layouts/shared-components/quickPanel/store/dataSlice";
import { friendList } from "../../../friends-manager/store/FriendsManagerSlice";

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        backgroundColor: "#ffff",
        border: "1px solid #E9E9E9",
        fontSize: '16px',
        borderRadius: '8px',
        [theme.breakpoints.up("lg")]: {
            height: 'unset',
            fontSize: '18px',
        },
    },
    "& .MuiInputLabel-root": {
        fontSize: '16px',
        [theme.breakpoints.up("lg")]: {
            fontSize: '16px',
        },
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
}));

class PinIcon extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className="mr-3" width="24" height="24" viewBox="0 0 24 24"><g fill="#141f2c" stroke="#314159" strokeWidth="1" opacity="0"><rect width="24" height="24" stroke="none" /><rect x="0.5" y="0.5" width="23" height="23" fill="none" /></g><path d="M11.5,9a2.5,2.5,0,0,1,4.87-.81A2.746,2.746,0,0,1,16.5,9a2.5,2.5,0,0,1-4.86.84A2.6,2.6,0,0,1,11.5,9M5,9c0,4.5,5.08,10.66,6,11.81L10,22S3,14.25,3,9A7.005,7.005,0,0,1,8,2.29,8.982,8.982,0,0,0,5,9m9-7a7,7,0,0,1,7,7c0,5.25-7,13-7,13S7,14.25,7,9a7,7,0,0,1,7-7m0,2A5,5,0,0,0,9,9c0,1,0,3,5,9.71C19,12,19,10,19,9A5,5,0,0,0,14,4Z" fill="#314159" /></svg>
        )
    }
}

const DisabledPlayer = styled('div')(({ theme }) => ({
    position: "relative",

    "& .disable": {
        position: "relative",
        height: 80,
        width: 80,
    },
    "& .react-player": {
        position: "absolute",
        top: 0,
        left: 0,
    },


}));

const MapInfoContents = (props) => {
    const token = AgentJwtService.getDecodedAccessToken();

    const methods = useFormContext();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { control, formState, watch, setValue, getValues, trigger } = methods;
    const { isValid, dirtyFields, errors } = formState;

    const wData = watch();

    const [attachmentTempId, setAttachmentTempId] = useState(wData.fileId);
    const [attachmentData, setAttachmentData] = useState([]);
    const [friends, setFriends] = useState([]);

    const handleDeleteAttachment = (id) => {
        dispatch(deletePublicAttachment({ id: id })).then(() => {
            const updatedAttachments = attachmentData.filter(item => item.id !== id);
            setAttachmentData(updatedAttachments);
        })
    };

    useEffect(() => {
        if (wData.fileId) {
            setAttachmentData(wData.attachmentData);
        } else {
            const id = randomString()
            setValue("fileId", id)
            setAttachmentTempId(id)
        }
    }, [attachmentTempId])

    useEffect(() => {
        setValue("attachmentData", attachmentData)
    }, [attachmentData])

    function createNotification(obj) {
        dispatch(addNotification(obj));
    }

    useEffect(() => {
        dispatch(friendList({ agentId: token.agentId })).then((action) => {
            setFriends(action.payload);
        })
    }, [dispatch])

    async function handleSave() {

        const result = await trigger([
            "title",
            "description",
        ]);

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                    variant: "error",
                })
            );
        }

        var data = getValues();
        data.agent_id = token.id

        dispatch(createMission(data)).then(function (action) {
            const id = action.payload.res;
            if (props.item) {
                createNotification({
                    uid: token.id,
                    uname: token.data.displayName,
                    upicPath: token.data.profilePicPath,
                    userId: props.item.agent.userId,
                    userName: props.item.agent.displayName,
                    link: 'public/mission-details/' + id,
                    message: notification({
                        uname: token.data.displayName,
                    }, 'mission', 'join'),
                    module: 'mission',
                    time: moment().format('yyyy-MM-DD[T]HH:mm:ss'),
                    status: "Unread"
                })
            } else {
                if (friends != []) {
                    friends.map((item) => {
                        if (item.followerInfo && item.followerInfo.userId) {
                            if (item.notification == 'true') {
                                createNotification({
                                    uid: token.id,
                                    uname: token.data.displayName,
                                    upicPath: token.data.profilePicPath,
                                    userId: item.followerInfo.userId,
                                    userName: item.followerInfo.displayName,
                                    link: 'public/mission-details/' + id,
                                    message: notification({
                                        uname: token.data.displayName,
                                    }, 'mission', 'create'),
                                    module: 'mission',
                                    time: moment().format('yyyy-MM-DD[T]HH:mm:ss'),
                                    status: "Unread"
                                })
                            }
                        }
                    })
                }
            }
            navigate(generatePath("/public/mission-details/:id", { id }));
        });

    }

    return (
        <>
            <Typography className="!text-18 !font-bold" sx={{ mb: '13px' }}>{wData.id ? 'Join' : 'Create'} Mission</Typography>
            <Box className="w-full mt-16 text-center">
                <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                        <TextFieldStyled
                            {...field}
                            className=" w-full"
                            error={!!errors.title}
                            required
                            helperText={errors?.title?.message}
                            label="Mission Title"
                            id="title"
                            variant="filled"
                            InputLabelProps={{
                                sx: {
                                    color: "#48525C",
                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        fontSize: '14px',
                                        color: !!errors.username ? "red" : "#141F2C"
                                    }
                                }
                            }}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            fullWidth
                        />
                    )}
                />
                <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                        <TextFieldStyled
                            {...field}
                            className="mt-16 w-full"
                            error={!!errors.description}
                            required
                            helperText={errors?.description?.message}
                            label="Description"
                            id="description"
                            variant="filled"
                            InputLabelProps={{
                                sx: {
                                    color: "#48525C",
                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        fontSize: '14px',
                                        color: !!errors.username ? "red" : "#141F2C"
                                    }
                                }
                            }}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            fullWidth
                            multiline
                            rows={3}
                        />
                    )}
                />
                <div className="mt-10 mb-10 text-left">
                    <AttachmentUploader module="mission" id={attachmentTempId} attachmentData={attachmentData} setAttachmentData={setAttachmentData} />
                    <Stack direction={'row'} gap={1} className="mt-10" flexWrap={'wrap'}>
                        {
                            attachmentData.length > 0 &&
                            attachmentData.map((itemAttchment) => (
                                <Stack direction={'row'} gap={1.5} flexWrap={'wrap'} key={itemAttchment.id}>
                                    <div
                                        style={{ width: 80, height: 80 }}
                                        className="overflow-hidden bg-white rounded-lg border-1 border-pi-light-grey relative"
                                        key={itemAttchment.id}
                                    >

                                        <Icon className='text-red-600 cursor-pointer absolute top-0 right-5 z-20' key={itemAttchment.id}
                                            onClick={() => { handleDeleteAttachment(itemAttchment.id) }}
                                        >delete</Icon>
                                        {
                                            (() => {
                                                switch (itemAttchment.switchType) {
                                                    case "youtube":
                                                        return <div className="image-container">
                                                            <DisabledPlayer>
                                                                <div className=" z-10 disable"></div>
                                                                <ReactPlayer
                                                                    light={true}
                                                                    playing={false}
                                                                    className="react-player rounded-lg overflow-hidden "
                                                                    url={itemAttchment.srcUrl}
                                                                    height={80}
                                                                    width={80}
                                                                />
                                                            </DisabledPlayer>
                                                        </div>;
                                                    case "video":
                                                        return <div className="image-container">
                                                            <video autoPlay={false} className="blog">
                                                                <source
                                                                    type={itemAttchment.fileType}
                                                                    src={itemAttchment.srcUrl}
                                                                />
                                                            </video>
                                                        </div>;
                                                    default:
                                                        return <div className="image-container2">
                                                            <img

                                                                className="max-w-none w-full h-full object-cover"
                                                                src={itemAttchment.srcUrl}
                                                            />
                                                        </div>;
                                                }
                                            })()
                                        }
                                    </div>
                                </Stack>
                            ))
                        }

                    </Stack>
                </div>
                <Button
                    type="button"
                    variant="contained"
                    className=" mx-auto rounded-md h-40"
                    sx={{ background: "#F0D060", py: "8px", color: "#314159", fontSize: "16px", borderRadius: "100px", mb: '8px', "&:hover": { background: "#FECB00", } }}
                    aria-label="pin"
                    value="legacy"
                    onClick={handleSave}
                >
                    < PinIcon /> SAVE
                </Button>
            </Box>
        </>
    );
};

export default MapInfoContents;