import * as React from "react";
import { useMemo, useState, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker, InfoBox, InfoWindow, useJsApiLoader, OverlayView } from "@react-google-maps/api";
import CONFIG from "app/config";
import { Card, TextField, Button, Item, Typography, Container, Dialog, Alert, IconButton, Avatar, MenuItem, Menu } from "@mui/material";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Box, GlobalStyles, height } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getMarkersByAgentId } from "../store/missionManagerSlice"
import AgentJwtService from 'app/services/agent/AgentJwtService';
import SettingsIcon from "@mui/icons-material/SettingsOutlined";

import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useParams,
} from "react-router-dom";
import JoinButtonSvs from "app/services/JoinButtonSvs";
import { ButtonUnstyled } from "@mui/base";
import AttachmentSvs from "app/services/AttachmentSvs";

const containerStyle = {
    width: '100%',
    height: '850px'
};


function PublicMissionManagerMap(props) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: CONFIG.MAPS
    })

    const dispatch = useDispatch();

    const token = AgentJwtService.getDecodedAccessToken();

    const [map, setMap] = useState(null)
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showSettings, setShowSettings] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [type, setType] = useState("missions");

    const [activeMission, setActiveMission] = useState({});
    const [markerPosition, setMarkerPosition] = useState({ lat: 40.7128, lng: -74.006 });

    useEffect(() => {
        setData(null)
        dispatch(getMarkersByAgentId({ id: token.agentId, type: type })).then((action) => {
            if (action.payload.length > 0) {
                setActive(action.payload[0]);
                setData(action.payload)
            }
            setLoading(false)
            setTimeout(() => {
                setShowSettings(false)
            }, 1000);
        })
    }, [dispatch, type]);

    useEffect(() => {
        animateMapCenter(markerPosition);
    }, [markerPosition]);

    const setActive = (data) => {
        if (data) {
            setMarkerPosition({ lat: data.lat, lng: data.lng });
            setActiveMission(data)
        }
    }

    const animateMapCenter = (newCenter) => {
        if (map) {
            const start = map.getCenter();
            const startTime = Date.now();
            const duration = 50; // Animation duration in milliseconds

            const animate = () => {
                const now = Date.now();
                const elapsedTime = now - startTime;
                const easing = Math.sin((elapsedTime / duration) * (Math.PI / 2));
                const newLat = start.lat() + easing * (newCenter.lat - start.lat());
                const newLng = start.lng() + easing * (newCenter.lng - start.lng());
                const newCenterPosition = { lat: newLat, lng: newLng };

                map.panTo(newCenterPosition);

                if (elapsedTime < duration) {
                    requestAnimationFrame(animate);
                }
            };
            animate();
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return isLoaded && !loading ? (
        <div>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={markerPosition}
                onLoad={(map) => setMap(map)}
                zoom={14}
            >
                { /* Child components, such as markers, info windows, etc. */}
                {data && data.map((val, index) => (
                    <>
                        <OverlayView
                            key={val.id}
                            position={{ lat: val.lat, lng: val.lng }}
                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        >
                            <ButtonUnstyled
                                onClick={() => setActive(val)}
                                style={{ position: 'absolute', transform: 'translate(-50%, -100%)', textAlign: 'center' }}>
                                <Marker
                                    draggable={false}
                                    animation={google.maps.Animation.DROP}
                                    icon={{
                                        url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAW0lEQVR42mP8/wcAAwAB/AB1gYAAAAABJRU5ErkJggg==',
                                        // Transparent 1x1 pixel image
                                    }}
                                />
                                <AttachmentSvs module={"at-9"} path={val.agent?.profilePicPath} data={val} />
                            </ButtonUnstyled>
                        </OverlayView>
                    </>
                ))}
                {!showSettings && (
                    <div className="map-menu-controls ">
                        <IconButton
                            onClick={handleClick}
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            edge={false}
                        >
                            <SettingsIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    "&:before": {
                                        content: '""',
                                        display: "block",
                                        position: "absolute",
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: "background.paper",
                                        transform: "translateY(-50%) rotate(45deg)",
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                            }}
                            anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                            }}
                        >
                            <MenuItem onClick={() => { setType("missions") }}>
                                Created Mission
                            </MenuItem>
                            <MenuItem onClick={() => { setType("markers") }}>
                                Accepted Mission
                            </MenuItem>
                        </Menu>
                    </div>
                )}
            </GoogleMap>
        </div>
    ) : <></>
}

export default PublicMissionManagerMap