import {
    Card,
    Avatar,
    Dialog,
    DialogContent,
    Typography,
    TextField,
    Button,
    DialogActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { showMessage } from "app/store/fuse/messageSlice";

import React, { useState, useRef, useEffect, useMemo, createRef, useReducer } from "react";
import { deleteSingleMarkerById, updateMarkerContetnById } from "../../store/missionSlice";



const CardStyled = styled(Card)(
    ({ theme }) => `
	margin: ${theme.spacing(1)};
	padding: ${theme.spacing(2)};
	// border: 1px solid black;
	box-shadow: 0 0 20px #e5e5e5;
  `
);
const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 34,
    height: 34,
    // border: `2px solid ${theme.palette.background.paper}`,
}));
const BackgroundAvatar = styled(Avatar)(({ theme }) => ({
    // width: 40,
    borderRadius: "unset",
    width: 60,
    height: 60,
    "& img": {
        objectFit: "contain",
    },
    // height: 40,
    // border: `2px solid ${theme.palette.background.paper}`,
}));

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    var username = name.split(" "),
        firstName = username[0].split(" ")[0][0],
        lastName = username[username.length - 1].split(" ")[0][0];
    if (firstName == lastName) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}`,
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}${lastName}`,
        };
    }
}


const defaultValues = {
    content_marker: "",
};

export default function DeleteMarkerContent(props) {

    const { onClose, open, data } = props;

    const handleClose = () => {
        onClose();
    };

    const clickDeleteMarkers = () => {
        dispatch(
            deleteSingleMarkerById({
                id: data.id,
            })
        ).then((action) => {
            onClose()
        });
    }
    return (
        <>
            <Dialog onClose={handleClose} open={open}>
                <DialogContent>
                    <Typography>Are you sure want to delete this</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={clickDeleteMarkers} autoFocus>
                        Agree
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    );
}
