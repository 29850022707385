import {
    Card,
    CardContent,
    Stack,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Avatar,
    Badge,
    CardHeader,
    IconButton,
    Container,
    Button,
    Divider,
    Icon,
    Tab,
    TextField,
    InputAdornment,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import * as React from "react";

import { ToastContainer, toast } from "react-toastify";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { showMessage } from "app/store/fuse/messageSlice";
import { Provider, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useMemo } from "react";
import {
    BrowserRouter as Router,
    useParams,
} from "react-router-dom";
import CommentsContent from "../../mission/content/comments/CommentsContent";
import { createComment, getSingleMissionById } from "../../mission/store/missionSlice";
import FriendsActivityImages from "../modal/FriendsActivityImages";
import FriendsActivityViewModal from "../modal/FriendsActivityViewModal";

const defaultValues = {
    comment: "",
    images: [],
};

export default function CommentForm(props) {

    console.log("item", props.item)

    const { mission_id } = props
    const [commentData, setCommentData] = useState([]);
    const [replyData, setReplyData] = useState([]);

    const dispatch = useDispatch();
    const schema = yup.object().shape({
        comment: yup.string().required("Comment is required"),
    });

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    function saveImages(data) {
        setImagesData(data)
    }

    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState,
        handleSubmit,
        reset,
        getValues,
        setValue,
        watch,
        trigger
    } = methods;

    const { isValid, dirtyFields, errors } = formState;

    function reloadData() {
        dispatch(
            getSingleMissionById({
                id: props.item.id,
            })
        ).then((action) => {
            setCommentData(action.payload.comments);
            setReplyData(action.payload.comments.replies);
        });
    }

    async function handleSave() {
        const result = await trigger(["comment"]);

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                    variant: "error",
                })
            );
        }
        var data = getValues();
        data.mission_id = props.item.id;
        dispatch(createComment(data)).then(function (action) {
            reloadData();
            reset(defaultValues);
        },);
    }
    return (
        <div>

            <ToastContainer />
            <FormProvider {...methods}>
                <form name="commentForm" noValidate>
                    <Controller
                        name="comment"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={!!errors.comment}
                                required
                                helperText={errors?.comment?.message}
                                id="comment"
                                className="w-full"
                                size="medium"
                                placeholder="Write a comment..."
                                sx={{
                                    "& .MuiOutlinedInput-root": { borderRadius: "9999px" },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            className="h-full max-h-max justify-between pr-5"
                                        >
                                            <FriendsActivityViewModal module="view"></FriendsActivityViewModal>

                                            <Divider orientation="vertical" flexItem />
                                            <IconButton
                                                onClick={handleSave}
                                                aria-label="toggle password visibility"
                                                title="Submit"
                                                edge="end"
                                            >
                                                <Icon>send</Icon>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}

                            ></TextField>
                        )}
                    />
                </form>
            </FormProvider>
            <CommentsContent commentData={commentData} replyData={replyData} reloadData={reloadData} mission_id={props.item.id} />
        </div>
    );
};


