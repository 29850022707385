const mutation = {
    createNotifications: `
      mutation createNotifications($data: JSONString!) {
          createNotifications (data: $data) {
                status,
                res,
                message
          }
      }
    `,
    updateNotifications: `
        mutation updateNotifications($data: JSONString!) {
            updateNotifications (data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    notificationList: `
        query notificationList($uid: String!, $start: Int, $end: Int) {
            notificationList (uid: $uid, start: $start, end: $end) {
                id
                uid
                uname
                userId
                upicPath
                userName
                message
                link
                module
                status
                time
                dateCreated
                dateUpdated
            }
        }
    `,
}

export { mutation, query };