// comments need for the future. kapag wala pa syang uploaded na profile picture on line 103 and 199
import {
    Stack,
    Avatar,
    Badge,
    CardHeader,
    Container,
    Button,
    Box,
    Divider,
} from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useState, useRef, useEffect, useMemo } from "react";
import ReplyForm from "./ReplyForm"
import { format } from "date-fns";

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 34,
    height: 34,
}));

const BackgroundAvatar = styled(Avatar)(({ theme }) => ({
    borderRadius: "unset",
    width: 60,
    height: 60,
    "& img": {
        objectFit: "contain",
    },

}));

function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
}
function stringAvatar(name) {
    var username = name.split(" "),
        firstName = username[0].split(" ")[0][0],
        lastName = username[username.length - 1].split(" ")[0][0];
    if (firstName == lastName) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}`,
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}${lastName}`,
        };
    }
}
export default function CommentsContent(props) {
    const { mapData, commentData, replyData, reloadData, mission_id } = props
    const [isReply, setReply] = useState(null); //for reply

    function getTimeAgo(dateTime) {
        const customDate = new Date(dateTime);
        const currentDate = new Date();
        const timeDifference = currentDate - customDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);

        if (weeks > 0) {
            return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
            return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
        }
    }
    useEffect(() => {
        reloadData()
    }, []);
    const showReplyInput = (id) => () => {
        setReply(id);
    };
    return (
        <div>
            <Container className="!px-0">
                {commentData.map((item) => (
                    <div>
                        <CardHeader
                            className=" !px-0 !pb-0"
                            sx={{
                                "& .MuiCardHeader-avatar": {
                                    marginRight: "0px",
                                    alignSelf: "start",
                                },
                                "& .MuiCardHeader-content": {
                                    alignSelf: "start",
                                    background: "#F8FAFB",
                                    borderRadius: "15px",
                                    padding: "8px",
                                    flex: "unset",
                                },
                            }}
                            avatar={
                                <>
                                    {/* <Avatar {...stringAvatar(`Super Agent`)}  /> */}
                                    <Badge
                                        className="!-ml-10 !-mr-2"
                                        overlap="circular"
                                        sx={{
                                            display: "unset",
                                            "& .MuiBadge-anchorOriginTopRightCircular": {
                                                top: "12px",
                                                right: "13px",
                                                padding: 0,
                                                transform: "unset",
                                            },
                                        }}
                                        badgeContent={
                                            <SmallAvatar
                                                {...stringAvatar(`Agent GoodGuy`)}
                                                className=" uppercase text-16"
                                            />
                                        }
                                    >
                                        <BackgroundAvatar
                                            alt="Remy Sharp"
                                            src="assets/images/pin-background.webp"
                                            loading="lazy"
                                        />
                                    </Badge>
                                </>
                            }
                            title={
                                <Typography className="!font-bold !text-16 !font-nunito">

                                    {item.agents.map((item) => (
                                        <>
                                            {item.firstName} {item.lastName}
                                        </>

                                    ))}
                                </Typography>
                            }
                            subheader={
                                <>
                                    <div>
                                        <>
                                            <p className=" !font-500 !text-14 !font-nunito -mt-4">
                                                {"Commented"} - {getTimeAgo(item.dateCreated)}
                                            </p>
                                            <p className=" !text-pi-black !font-normal !text-16 leading-3 mt-3">
                                                {item.replies.reply}
                                                {item.comment}
                                            </p>
                                        </>
                                    </div>
                                    {item.commentsImages.map((item) => (
                                        <Stack direction={"row"} flexWrap={"wrap"} padding={1}>
                                            <div
                                                style={{ maxWidth: 180, maxHeight: 180 }}
                                                cols={1}
                                                className="overflow-hidden bg-white w-full h-full m-5"
                                            >
                                                <img
                                                    className="max-w-none w-full h-full object-cover"
                                                    src={item.preSignedUrl}
                                                    // srcSet={`${item.img}?w=315&fit=crop&auto=format&dpr=2 2x`} //optimize image
                                                    // alt={item.title}
                                                    loading="lazy"
                                                />
                                            </div>
                                        </Stack>
                                    ))}
                                </>
                            }
                        />
                        <Container className="!p-0 !pb-4">
                            <Button variant="Text" className=" !text-14 !text-pi-blue-1 !capitalize !font-nunito !p-0 !ml-48 !w-fit !min-w-fit " onClick={showReplyInput(item.id)}>Reply</Button>
                            {isReply === item.id && (
                                <div className="!ml-48">
                                    <ReplyForm commentId={item.id} replyData={replyData} reloadData={reloadData} mission_id={mission_id} />
                                </div>
                            )}
                        </Container>


                        {item.replies.map((reps) => {
                            return (
                                <>
                                    <CardHeader
                                        className=" !px-0 !pb-14 !pt-0 !ml-48"
                                        sx={{
                                            "& .MuiCardHeader-avatar": {
                                                marginRight: "0px",
                                                alignSelf: "start",
                                            },
                                            "& .MuiCardHeader-content": {
                                                alignSelf: "start",
                                                background: "#F8FAFB",
                                                borderRadius: "15px",
                                                padding: "8px",
                                                flex: "unset",
                                            },
                                        }}
                                        avatar={
                                            <>
                                                {/* <Avatar {...stringAvatar(`Super Agent`)}  /> */}
                                                <Badge
                                                    className="!-ml-6 !-mr-4"
                                                    overlap="circular"
                                                    sx={{
                                                        display: "unset",
                                                        "& .MuiBadge-anchorOriginTopRightCircular": {
                                                            top: "12px",
                                                            right: "13px",
                                                            padding: 0,
                                                            transform: "unset",
                                                        },
                                                    }}
                                                    badgeContent={

                                                        item.agents.map((item) => (
                                                            <>
                                                                <SmallAvatar
                                                                    {...stringAvatar(item.firstName + item.lastName)}
                                                                    className=" uppercase text-16"
                                                                />
                                                            </>

                                                        ))
                                                    }
                                                >
                                                    <BackgroundAvatar
                                                        alt="Remy Sharp"
                                                        src="assets/images/pin-background.webp"
                                                        loading="lazy"
                                                    />
                                                </Badge>
                                            </>
                                        }
                                        title={
                                            <Typography className="!font-bold !text-16 !font-nunito">

                                                {item.agents.map((item) => (
                                                    <>
                                                        {item.firstName} {item.lastName}
                                                    </>

                                                ))}
                                            </Typography>
                                        }
                                        subheader={
                                            <>
                                                <div>
                                                    <>
                                                        <p className=" !font-500 !text-14 !font-nunito -mt-4">
                                                            {"Commented"} - {getTimeAgo(reps.dateCreated)}
                                                        </p>
                                                        <p className=" !text-pi-black !font-normal !text-16 leading-3 mt-3">
                                                            {reps.reply}
                                                        </p>
                                                    </>
                                                </div>
                                                {reps.repliesImages.map((item) => (
                                                    <Stack direction={"row"} flexWrap={"wrap"} padding={1}>
                                                        <div
                                                            style={{ maxWidth: 180, maxHeight: 180 }}
                                                            cols={1}
                                                            className="overflow-hidden bg-white w-full h-full m-5"
                                                        >
                                                            <img
                                                                className="max-w-none w-full h-full object-cover"
                                                                src={item.preSignedUrl}
                                                                loading="lazy"
                                                            />
                                                        </div>
                                                    </Stack>
                                                ))}
                                            </>
                                        }
                                    />
                                </>
                            );
                        })}
                    </div>
                ))}
                <Box className=" py-16 !px-0">
                    <Divider />
                    <Typography className="px-16 !my-6 sm:px-0 !text-16 !capitalize text-pi-grey mb-16">Suggested Missions For You</Typography>
                    <Stack direction={'row'} useflexgap="true" gap={2} flexWrap="wrap" className="!px-0">
                        <Stack direction={"column"} sx={{ width: { xl: "calc(50% - 8px)", lg: "calc(60% - 84px)", md: "calc(48% - 0px)", sm: "calc(49% - 5px)", xs: "calc(100%)" } }} >
                            <Box
                                sx={{ maxHeight: 280, width: "100%", height: { xl: "10.416666666666666vw", lg: "13.416667vw", md: "17.416667vw", sm: "24.416667vw", xs: "56.25vw" } }}

                                className="overflow-hidden bg-white"
                            >
                                <img
                                    className="max-w-none w-full h-full object-cover rounded-none sm:rounded-md"
                                    srcSet={'https://images.unsplash.com/photo-1661904708589-2e20885a3297?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80'}
                                    alt={'Volunteer with a local charity to help your community.'}
                                    loading="lazy"
                                />
                            </Box>
                            <CardHeader
                                className={"!py-8 items-start !px-5 sm:px-0"}
                                sx={{
                                    "& .MuiCardHeader-avatar": {
                                        marginRight: "0",
                                    },
                                    "&.MuiAvatar-root": {
                                        width: "unset",
                                        height: "unset",
                                    },
                                }}
                                title={
                                    <Typography className="!font-bold !line-clamp-2 !text-18 !leading-tight">
                                        Missions Title
                                    </Typography>
                                }
                                subheader={
                                    <>
                                        <p className=" font-300 text-16 mt-0">AgebtNameHere</p>
                                        <p className=" font-300 text-16 -mt-1">
                                            1.1M reach · January 14, 2017
                                        </p>
                                    </>
                                }
                            />
                        </Stack>
                        <Stack direction={"column"} sx={{ width: { xl: "calc(50% - 8px)", lg: "calc(60% - 84px)", md: "calc(48% - 0px)", sm: "calc(49% - 5px)", xs: "calc(100%)" } }} >
                            <Box
                                sx={{ maxHeight: 280, width: "100%", height: { xl: "10.416666666666666vw", lg: "13.416667vw", md: "17.416667vw", sm: "24.416667vw", xs: "56.25vw" } }}

                                className="overflow-hidden bg-white"
                            >
                                <img
                                    className="max-w-none w-full h-full object-cover rounded-none sm:rounded-md"
                                    srcSet={'https://images.unsplash.com/photo-1661904708589-2e20885a3297?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80'}
                                    alt={'Volunteer with a local charity to help your community.'}
                                    loading="lazy"
                                />
                            </Box>
                            <CardHeader
                                className={"!py-8 items-start !px-5 sm:px-0"}
                                sx={{
                                    "& .MuiCardHeader-avatar": {
                                        marginRight: "0",
                                    },
                                    "&.MuiAvatar-root": {
                                        width: "unset",
                                        height: "unset",
                                    },
                                }}
                                title={
                                    <Typography className="!font-bold line-clamp-2 !text-18 !leading-tight">
                                        Missions Title
                                    </Typography>
                                }
                                subheader={
                                    <>
                                        <p className=" font-300 text-16 mt-0">AgebtNameHere</p>
                                        <p className=" font-300 text-16 -mt-1">
                                            1.1M reach · January 14, 2017
                                        </p>
                                    </>
                                }
                            />
                        </Stack>
                    </Stack>

                </Box>
            </Container>
        </div>
    );
};