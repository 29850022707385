import { orange } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { useParams } from "react-router-dom";
import Icon from '@mui/material/Icon';
import clsx from 'clsx';
import FuseUtils from '@fuse/utils';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { Button, Skeleton, Stack, Typography, Avatar } from '@mui/material';
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { useState } from 'react';

const Root = styled('div')(({ theme }) => ({
    '& .productImageFeaturedStar': {
        position: 'absolute',
        top: 0,
        right: 0,
        color: orange[400],
        opacity: 0,
    },

    '& .productImageUpload': {
        transitionProperty: 'box-shadow',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
    },

    '& .productImageItem': {
        transitionProperty: 'box-shadow',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        '&:hover': {
            '& .productImageFeaturedStar': {
                opacity: 0.8,
            },
        },
        '&.featured': {
            pointerEvents: 'none',
            boxShadow: theme.shadows[3],
            '& .productImageFeaturedStar': {
                opacity: 1,
            },
            '&:hover .productImageFeaturedStar': {
                opacity: 1,
            },
        },
    },
}));

function AgentProfileBannerImage(props) {
    const token = AgentJwtService.getDecodedAccessToken();
    const dispatch = useDispatch();
    const routeParams = useParams();
    const methods = useFormContext();
    const { control, watch, setValue } = methods;

    const bannerImages = watch('bannerImages');

    const id = watch('id');
    const [isChanged, setIsChanged] = useState(false);

    const pId = id ? id : 'new';

    return (
        <Root className='mb-16'>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography className="text-18 mb-16 font-bold">
                    Cover Photo
                </Typography>
                <Controller
                    name="bannerImages"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <label
                            htmlFor="button-file2"
                            className="productImageUpload  text-pi-blue-1 text-16 font-bold rounded-md cursor-pointer "
                        >
                            Change
                            <input
                                accept="image/*"
                                className="hidden"
                                id="button-file2"
                                type="file"
                                onChange={async (e) => {
                                    function readFileAsync() {
                                        return new Promise((resolve, reject) => {
                                            const file = e.target.files[0];
                                            if (!file) {
                                                return;
                                            }
                                            if (file.size > 10000000) {
                                                dispatch(
                                                    showMessage({
                                                        message: "Sorry your image exceeded to 10mb. Please reduce the size.",
                                                        autoHideDuration: 5000, //ms
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "right",
                                                        },
                                                        variant: "warning",
                                                    })
                                                )
                                                return;
                                            }
                                            const reader = new FileReader();

                                            reader.onload = () => {
                                                resolve({
                                                    id: FuseUtils.generateGUID(),
                                                    url: `data:${file.type};base64,${btoa(reader.result)}`,
                                                    type: 'image',
                                                    imageType: 'banner',
                                                    file: file,
                                                    status: "new"
                                                });
                                            };

                                            reader.onerror = reject;

                                            reader.readAsBinaryString(file);
                                        });
                                    }

                                    setIsChanged(true);

                                    const newImage = await readFileAsync();

                                    setValue('featuredImageId', newImage.id, { shouldDirty: true });

                                    onChange([newImage]);
                                    e.target.value = null;
                                }}
                            />
                        </label>
                    )}
                />
            </Stack>
            <Controller
                name="featuredImageId"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) =>
                    bannerImages && bannerImages.length ? bannerImages.map((media, index) => (

                        media.status != 'deleted' ? <div className='m-auto' key={(index)}>
                            <div
                                onClick={() => onChange((pId == "new" ? media.id : media.fileId))}
                                onKeyDown={() => onChange((pId == "new" ? media.id : media.fileId))}
                                role="button"
                                tabIndex={0}
                                className={clsx(
                                    'productImageItem relative rounded-md overflow-hidden outline-none m-auto !shadow-0',
                                    (media.fileId === value || media.id === value) && 'featured'

                                )}
                                style={{ width: "100%", height: "116px" }}
                                key={(pId == "new" ? media.id : media.fileId)}
                            >
                                {media.url && isChanged ? (
                                    <img className="max-w-none w-full h-full object-cover" src={media.url} alt="" />
                                ) : (
                                    <img className="max-w-none w-full h-full object-cover" src={token.data.bannerURL} alt="" />
                                )}
                            </div>
                            {/* for future reference
                            <div className='relative -ml-40 mt-5'>
                                <Icon className='text-red-600 cursor-pointer' onClick={() => {
                                    if (media.dateCreated) {
                                        media.status = "deleted";
                                    } else {
                                        bannerImages.splice(index, 1);
                                    }
                                    setValue("bannerImages", bannerImages, { shouldDirty: true });
                                    setValue("featuredImagePath", "", { shouldDirty: true });
                                    setValue("featuredImageId", "", { shouldDirty: true });
                                }}>delete</Icon>
                            </div> */}
                        </div> : null
                    )) : <div
                        className='relative rounded-md overflow-hidden m-auto !shadow-0 bg-pi-black'
                        style={{ width: "100%", height: "116px" }}
                    >
                        <img className="max-w-none w-full h-full object-cover" src="assets/images/backgrounds/default-cover.png" alt="Cover Photo" />
                    </div>

                }
            />
        </Root >
    );
}

export default AgentProfileBannerImage;