import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';

function FriendsManagerHeader(props) {
    const mainTheme = useSelector(selectMainTheme);
    return (
        <div className="flex flex-1 w-full items-center justify-between">
            <ThemeProvider theme={mainTheme}>
                <div className="flex flex-col items-start">
                    <Typography
                        component={motion.span}
                        initial={{ x: -20 }}
                        animate={{ x: 0, transition: { delay: 0.2 } }}
                        delay={300}
                        className="text-16 sm:text-32 truncate font-semibold"
                    >
                        Friends Manager
                    </Typography>
                    <Typography variant="caption" className="font-medium text-16">
                        List of Friends
                    </Typography>
                </div>
            </ThemeProvider>

        </div>
    );
}

export default FriendsManagerHeader;