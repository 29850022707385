import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from 'app/config';
import { query, mutation } from "../../notificationPanel/graphql/notification_graphql";
import jwtService from 'app/services/jwtService';

export const getNotification = createAsyncThunk('notificationPanel/getNotification',
  async (params, { dispatch, getState }) => {

    const response = await axios.post(CONFIG.API + "/api/", {
      query: query.notificationList,
      variables: {
        uid: params.uid,
        start: params.start,
        end: params.end,
      }
    });
    const data = response.data.data.notificationList;
    return data;
  });

export const addNotification = createAsyncThunk(
  "notificationPanel/savenotification",
  async (data) => {
    const response = await axios.post(CONFIG.API + "/api/", {
      query: mutation.createNotifications,
      variables: {
        data: JSON.stringify(data)
      },
      publicUrl: 'agents'
    });

    const createNotifications = await response.data.data.createNotifications;

    if (createNotifications) {

      return createNotifications;
    }
  }
);

export const updateNotifications = createAsyncThunk(
  "notificationPanel/updatenotification",
  async (data) => {
    const response = await axios.post(CONFIG.API + "/api/", {
      query: mutation.updateNotifications,
      variables: {
        data: JSON.stringify(data)
      }
    });

    const updateNotifications = await response.data.data.updateNotifications;

    if (updateNotifications) {

      return updateNotifications;
    }
  }
);

const notificationsAdapter = createEntityAdapter({});

const initialState = notificationsAdapter.upsertMany(notificationsAdapter.getInitialState(), []);

export const { selectAll: selectNotifications, selectById: selectNotificationsById } =
  notificationsAdapter.getSelectors((state) => state.notificationPanel.data);

const dataSlice = createSlice({
  name: 'notificationPanel',
  initialState,
  reducers: {
    dismissItem: (state, action) => notificationsAdapter.removeOne(state, action.payload),
    dismissAll: (state, action) => notificationsAdapter.removeAll(state),
    addNotification: (state, action) => notificationsAdapter.addOne(state, action.payload),
    updateNotification: (state, action) => notificationsAdapter.updateOne(state, action.payload),
  },
  extraReducers: {
    [getNotification.fulfilled]: (state, action) =>
      notificationsAdapter.addMany(state, action.payload),
  },
});

export const { dismissItem, dismissAll } = dataSlice.actions;

export default dataSlice.reducer;
