import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";

import React, { useState, useRef, useEffect } from "react";
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from "react-redux";
import { CardContent, Chip, Modal, Skeleton, Typography } from "@mui/material";
import { Badge, Card, CardHeader, Stack, Avatar, useMediaQuery, Button, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider, ListSubheader, } from "@mui/material";
import LazyLoad from 'react-lazy-load';
import { homeMissionItems } from "../store/homeSlice";
import { Link } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { showMessage } from 'app/store/fuse/messageSlice';
import AttachmentSvs from "app/services/AttachmentSvs";
import PhotoGridView from "app/services/PhotoGridView";
import DateTimeDisplay from "app/services/DateTimeDisplay";
import FuseScrollbars from "@fuse/core/FuseScrollbars";
import SkeletonLoader from "../components/SkeletonLoader";
import HomeSkeletonLoader from "../components/HomeSkeletonLoader";

const HomeContent = ({ module, type, initial }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const [hasLoadMore, setHasLoadMore] = useState(false);
    const [range, setRange] = useState([0, initial]);
    const [items, setItems] = useState([]);

    const title = {
        'recent': 'RECENT MISSIONS',
        'featured': 'FEATURED MISSIONS',
        'popular': module == 'sidePanel' ? 'Suggested Missions For You' : 'POPULAR MISSIONS',
    };

    function loadItems(start, end) {
        dispatch(homeMissionItems({ module: type, start: start, end: end })).then((action) => {
            setItems([...items, ...action.payload]);
            if (action.payload.length == 0 && initial != range[1]) {
                return dispatch(
                    showMessage({
                        message: "No more missions to load.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "info",
                    })
                );
            }
        })
            .finally(() => {
                setLoading(false);
            });
    }

    function population_formatter(reach) {
        if (reach > 999999) {
            return parseFloat(reach / 1000000).toFixed(1) + 'M';
        } else if (reach > 999) {
            return parseFloat(reach / 1000).toFixed(1) + 'K';
        } else {
            return reach;
        }
    }

    function loadMore() {
        const start = range[0] + initial;
        const end = range[1] + initial;

        setLoading(true);
        loadItems(start, end);
        setRange([start, end]);
    }

    useEffect(() => {
        loadItems(range[0], range[1]);
    }, []);

    useEffect(() => {
        if (range[1] > items.length) {
            setHasLoadMore(false)
        } else {
            setHasLoadMore(true)
        }
    }, [items, range, hasLoadMore]);

    return (
        <>
            {module == 'home' && (
                <Box className={module != 'sidePanel' ? "bg-default py-16 sm:pt-0 sm:p-36" : ""}>
                    {items.length != 0 && (
                        <Typography className={module != 'sidePanel' ? "px-16  sm:px-0 text-24 uppercase text-pi-grey mb-16" : "text-18 font-extrabold px-16 pt-24 pb-10"}>{title[type]}</Typography>
                    )}
                    <Stack direction={'row'} useflexgap="true" gap={2} flexWrap="wrap">
                        {items.map((item) => (
                            <Stack direction={"column"} sx={{ width: { xl: "calc(25% - 13px)", lg: "calc(33.17% - 10.5px)", md: "calc(32.3% - 2.5px)", sm: "calc(50% - 10px)", xs: "calc(100%)" } }} key={item.id}><Box
                                sx={{ width: "100%", height: 0, paddingBottom: "56.25%" }}
                                className="overflow-hidden bg-pi-light-grey rounded-md"
                            >
                                <Link style={{ textDecoration: 'none' }} to={`/public/mission-details/${item.id}`}>
                                    <PhotoGridView fileId={item.filesId} single={true} isDisabledModal={true} />
                                </Link>
                            </Box>
                                <CardHeader
                                    className={"py-8 items-start px-16 sm:px-0"}
                                    sx={{
                                        "& .MuiCardHeader-avatar": {
                                            marginRight: "0",
                                        },
                                        "&.MuiAvatar-root": {
                                            width: "unset",
                                            height: "unset",
                                        },
                                    }}
                                    title={
                                        <Link style={{ textDecoration: 'none' }} to={`/public/mission-details/${item.id}`}>
                                            <Typography className="font-800 line-clamp-2 text-18 leading-tight">
                                                {item.title}
                                            </Typography>
                                        </Link>
                                    }
                                    subheader={
                                        <Stack direction={'row'} gap={1} className=" items-center mt-3">
                                            <AttachmentSvs module={"at-1"} path={item.agent?.profilePicPath} data={item.agent} />
                                            <div>
                                                <p className=" font-500 text-16">
                                                    <Link
                                                        className="!text-pi-grey"
                                                        style={{ textDecoration: 'none' }
                                                        }
                                                        to={`/agentprofile?agentId=${item.agent?.userId}`}
                                                    >
                                                        {item.agent?.firstName}
                                                    </Link>

                                                </p>
                                                <Typography className=" font-500 text-16 -mt-1 line-clamp-1 text-pi-grey">
                                                    {item.pinned} reach · <DateTimeDisplay dateTime={item.dateCreated} />
                                                </Typography>
                                            </div>
                                        </Stack>
                                    }
                                />
                            </Stack>
                        ))}
                    </Stack >
                    {loading && (
                        <>
                            <Stack direction={'row'} useflexgap="true" gap={2} flexWrap="wrap">
                                {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
                                    <HomeSkeletonLoader key={index} />
                                ))}
                            </Stack >
                        </>
                    )}

                    {
                        hasLoadMore && (
                            <Divider className="mt-16">
                                <Chip
                                    className="p-0"
                                    sx={{
                                        '& .MuiChip-label': {
                                            p: 0
                                        }
                                    }}
                                    label={
                                        <LoadingButton
                                            className="rounded-full px-16"
                                            variant="text"
                                            color="secondary"
                                            type="button"
                                            onClick={() => loadMore()}
                                        >
                                            Load more
                                        </LoadingButton>
                                    } />
                            </Divider>
                        )
                    }
                </Box >
            )}
            {module == 'search' && (
                <>
                    {
                        items.length != 0 && (
                            <>
                                <Divider />
                                <Typography className={module != 'sidePanel' ? " py-16 text-20 font-bold" : "text-18 font-extrabold px-16 pt-24 pb-10"}>{title[type]}</Typography>
                                <Divider />
                            </>
                        )
                    }
                    < FuseScrollbars >
                        {
                            items.map((item) => (
                                <Card elevation={0} sx={{ display: 'flex', alignItems: 'start' }} className=' rounded-none bg-transparent my-16'>

                                    <Box
                                        sx={{ maxWidth: '350px', width: "100%", height: 200 }}
                                        className="overflow-hidden bg-white"
                                    >
                                        <Link style={{ textDecoration: 'none' }} to={`/public/mission-details/${item.id}`}>
                                            <PhotoGridView fileId={item.filesId} single={true} isDisabledModal={true} />
                                        </Link>
                                    </Box>

                                    <CardContent className='flex-1 py-0'>
                                        <Link style={{ textDecoration: 'none' }} to={`/public/mission-details/${item.id}`}>
                                            <Typography className=' text-18 font-extrabold capitalize line-clamp-2'>{item.title}</Typography>
                                        </Link>
                                        <Typography variant="body2" className='mb-3 text-14'>{population_formatter(item.pinned)} reach · {new Date(item.dateCreated).toLocaleDateString('en-En', { year: 'numeric', month: 'long', day: 'numeric' })}</Typography>
                                        <Link
                                            style={{ textDecoration: 'none' }
                                            }
                                            component={Link}
                                            to={"/agentprofile?agentId=" + item.agent?.userId}
                                            className="hover:bg-transparent"
                                        >
                                            <CardHeader
                                                className='px-0 py-8'
                                                sx={{ '& .MuiAvatar-root': { width: 29, height: 29, fontSize: '1.25rem' } }}
                                                avatar={
                                                    (
                                                        <AttachmentSvs module={"at-1"} path={item.agent?.profilePicPath} data={item.agent} />
                                                    )
                                                }
                                                title={'@' + item.agent?.username}
                                            />
                                        </Link>
                                        <Typography variant="body2" className=' text-14 line-clamp-2'>
                                            {item.description}
                                        </Typography>
                                        <Link
                                            style={{ textDecoration: 'none' }
                                            }
                                            component={Link}
                                            to={"/public/mission-details/" + item.id}
                                            className="hover:bg-transparent"
                                        >
                                            <Button variant='filled' className=' rounded-md bg-pi-light-grey mt-8'>View mission</Button>
                                        </Link>
                                    </CardContent>

                                </Card>
                            ))
                        }
                    </FuseScrollbars >
                    {
                        hasLoadMore && (
                            <Divider className="mt-16">
                                <Chip
                                    className="p-0"
                                    sx={{
                                        '& .MuiChip-label': {
                                            p: 0
                                        }
                                    }}
                                    label={
                                        <LoadingButton
                                            className="rounded-full px-16"
                                            variant="text"
                                            color="secondary"
                                            type="button"
                                            onClick={() => loadMore()}
                                        >
                                            Load more
                                        </LoadingButton>
                                    } />
                            </Divider>
                        )
                    }
                </>
            )
            }
            {
                module == 'sidePanel' && (
                    <>
                        {
                            items.length != 0 && (
                                <Typography className={module != 'sidePanel' ? "px-16  sm:px-0 text-24 uppercase text-pi-grey mb-16" : "text-18 font-extrabold px-16 pt-24 pb-10"}>{title[type]}</Typography>
                            )
                        }
                        <FuseScrollbars >

                            {items.map((item) => (
                                <>
                                    <Stack direction={"row"} gap={1} className=" px-16 py-8">
                                        <div
                                            style={{ maxWidth: 75, maxHeight: 75 }}
                                            cols={1}
                                            className="overflow-hidden bg-pi-light-grey w-full rounded-md"
                                        >
                                            <Link style={{ textDecoration: 'none' }} to={`/public/mission-details/${item.id}`}>
                                                <PhotoGridView fileId={item.filesId} single={true} isDisabledModal={true} />
                                            </Link>
                                        </div>
                                        <Link style={{ textDecoration: 'none' }} to={`/public/mission-details/${item.id}`}>
                                            <CardHeader
                                                className="p-0 w-x cursor-pointer"
                                                title={
                                                    <>
                                                        <p className=" !text-16 !font-bold !text-pi-dark-blue-text line-clamp-2 leading-tight">{item.title}</p>
                                                    </>
                                                }
                                                subheader={
                                                    <Stack direction={'row'} gap={1} alignItems={'center'}>

                                                        <Box>
                                                            <Typography className=" !text-12 !text-pi-black !font-bold">{item.agent.name}</Typography>
                                                            <Typography className=" line-clamp-1 !text-pi-dark-blue-text">
                                                                Created <DateTimeDisplay dateTime={item.dateCreated} />
                                                            </Typography>
                                                        </Box>
                                                    </Stack>
                                                }
                                            />
                                        </Link>
                                    </Stack>
                                </>
                            ))}
                            {loading && (
                                <>
                                    <Skeleton className='mx-16 mt-24 mb-10' height={26} />

                                    {[1, 2, 3].map((index) => (
                                        <SkeletonLoader key={index} />
                                    ))}
                                </>
                            )}
                        </FuseScrollbars>

                        {
                            hasLoadMore && (
                                <Divider className="mt-16">
                                    <Chip
                                        className="p-0"
                                        sx={{
                                            '& .MuiChip-label': {
                                                p: 0
                                            }
                                        }}
                                        label={
                                            <LoadingButton
                                                className="rounded-full px-16"
                                                variant="text"
                                                color="secondary"
                                                type="button"
                                                onClick={() => loadMore()}
                                            >
                                                Load more
                                            </LoadingButton>
                                        } />
                                </Divider>
                            )
                        }
                    </>
                )
            }
        </>
    );
};

export default HomeContent;