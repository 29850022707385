import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { query } from "../graphql/navbar_graphql";

export const getCounters = createAsyncThunk(
    "missionApp/mission/getCounters",
    async (agentId) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getCounters,
            variables: {
                agentId: agentId
            }
        });

        if (response.data.data.getCounters) {
            const data = response.data.data.getCounters;

            return data === undefined ? [] : data;
        } else {
            return null;
        }
    }
);


const NavbarSlice = createSlice({
    name: "friends_activity",
    initialState: null,
    reducers: null,
    extraReducers: {
        [getCounters.fulfilled]: (state, action) => action.payload,
    },
});

export default NavbarSlice.reducer;
