import { Card, CardContent, Stack, ImageList, ImageListItem, ImageListItemBar, Avatar, Badge, CardHeader, Divider, Button, Box } from '@mui/material';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Parallax, Background } from 'react-parallax';

export default function AboutContent() {
    return (
        <Box className='relative mx-16 my-16 sm:my-56px-vw sm:mx-72px-vw' sx={{backgroundImage: 'url("assets/images/pi-images/global-map.svg")', backgroundSize:'contain', backgroundRepeat: "no-repeat", backgroundPosition:'center'}}>
            <Box className=" w-full max-w-screen-md">
                <Typography className="smMax:text-18 text-48px-vw max1200px:text-48px-vw mt-8px-vw text-pi-primary  font-bold">
                ABOUT PLANET IMPOSSIBLE
                </Typography>
                <Typography className='smMax:text-14 sm:block text-28px-vw max1200px:text-18px-vw text-pi-dark-blue-text '>
                At Planet Impossible, our mission is to spread ideas that benefit everyone and to effect change through the use of collective action.
                </Typography>
                <Typography className='smMax:text-14 sm:block text-28px-vw max1200px:text-18px-vw mt-24px-vw text-pi-dark-blue-text'>
                When we came up with the idea of Planet Impossible, we asked ourselves, “What can we do to improve the human condition?” Inspired by the reach of social media and the power of grass-roots organizations and movements, we felt the internet was the perfect medium to organize like-minded people to collective action.
                </Typography >
                <Typography className='smMax:text-14 sm:block text-28px-vw max1200px:text-18px-vw mt-24px-vw text-pi-dark-blue-text '>
                A single idea has the potential to revolutionize the world. Even simple ideas can have amazing power if everyone adopts them. However, if no one knows about these ideas, the potential of the idea is lost. Planet Impossible was established as a stage for anyone to lead and organize collaborative action, as well as serve as a venue to discover new inspirations on how to improve our world.
                </Typography>
                <Typography className="smMax:text-18 smMax:mt-10 text-48px-vw max1200px:text-48px-vw text-pi-primary mt-24px-vw font-bold">
                THE ULTIMATE MISSION
                </Typography>
                <Typography  className='smMax:text-14 sm:block text-28px-vw max1200px:text-18px-vw text-pi-dark-blue-text '>
                Have you ever had an idea or dreamt of ways to improve our planet? In these trying times, wouldn't you like to do your part and take action to make our world that much better? With Planet Impossible, we hope to encourage each person makes a conscious choice to do something different to make positive change and act on it.
                </Typography>
                <Typography  className='smMax:text-14 sm:block text-28px-vw max1200px:text-18px-vw mt-24px-vw text-pi-dark-blue-text '>
                Become a part of the Planet Impossible Network (PIN)! Together, let's reach impossible.
                </Typography>
                <Typography className="smMax:text-18 smMax:mt-10 text-40px-vw max1200px:text-30px-vw mt-24px-vw text-pi-dark-blue  font-bold">
                    ARE YOU READY?
                </Typography>
                <Button variant="contained" className="block smMax:text-12 smMax:mt-0 mt-8 tracking-wider py-8px-vw px-40px-vw uppercase  bg-pi-yellow-green text-28px-vw max1200px:text-18px-vw font-bold hover:bg-pi-hover-yellow text-pi-dark-blue-text min-w-fit rounded-md">ACCEPT MISSION</Button>
            </Box>
        </Box>
    );
}
