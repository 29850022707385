import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from 'app/config';
import { showMessage } from "app/store/fuse/messageSlice";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import format from 'date-fns/format';
import JwtService from "app/services/jwtService";
import { isEmpty } from "lodash";
import { query, mutation } from '../graphql/pages_graphql';

export const getPageInfo = createAsyncThunk(
    "pagesApp/getPageInfo",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getPageInfo,
            variables: {
                id: params.id ? params.id : null,
                slugs: params.slugs ? params.slugs : null
            }
        });

        if (response.data.data.getPageInfo) {
            const data = response.data.data.getPageInfo;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const getPagesList = createAsyncThunk(
    "pagesApp/getPagesList",
    async (keyword) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getPagesList,
            variables: {
                keyword: keyword ? keyword : null
            }
        });

        if (response.data.data.getPagesList) {
            const data = response.data.data.getPagesList;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const pageToggleSave = createAsyncThunk(
    "pagesApp/pageToggleSave",
    async (params, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.pageToggleSave,
            variables: { data: JSON.stringify(params) },
        });

        if (response.data.data.pageToggleSave) {
            dispatch(
                showMessage({
                    message: "Page has been successfully updated.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );
        } else {
            dispatch(
                showMessage({
                    message: "Something went wrong. Please try again later.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }
    }
);

export const checkTypeExistence = createAsyncThunk(
    "pagesApp/checkTypeExistence",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.checkTypeExistence,
            variables: {
                data: JSON.stringify(params),
            }
        });

        if (response.data.data.checkTypeExistence) {
            const data = response.data.data.checkTypeExistence;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const createPage = createAsyncThunk(
    "pagesApp/createPage",
    async (params, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.createPage,
            variables: {
                data: JSON.stringify(params),
            }
        });
        dispatch(
            showMessage({
                message: response.data.data.createPage["message"],
                autoHideDuration: 5000, //ms
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
                variant: response.data.data.createPage["status"],
            })
        );
        if (response.data.data.createPage) {
            const data = response.data.data.createPage;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const updatePage = createAsyncThunk(
    "pagesApp/updatePage",
    async (params, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.updatePage,
            variables: {
                data: JSON.stringify(params),
            }
        });
        dispatch(
            showMessage({
                message: response.data.data.updatePage["message"],
                autoHideDuration: 5000, //ms
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
                variant: response.data.data.updatePage["status"],
            })
        );
        if (response.data.data.updatePage) {
            const data = response.data.data.updatePage;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const deletePage = createAsyncThunk(
    'pagesApp/deletePage',
    async (depIds, { dispatch, getState }) => {

        var count = 0;

        await depIds.map(async (id) => {
            await axios.post(CONFIG.API + "/api/", {
                query: mutation.deletePage,
                variables: {
                    id: id
                }
            });
            count++;
        })

        if (count == depIds.length) {
            dispatch(
                showMessage({
                    message: "Page(s) has been successfully deleted.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );
            return depIds;
        }
    }
);

const PageSlice = createSlice({
    name: 'pagesApp',
    initialState: null,
    reducers: null,
    extraReducers: {
        [checkTypeExistence.fulfilled]: (state, action) => action.payload,
        [pageToggleSave.fulfilled]: (state, action) => action.payload,
        [updatePage.fulfilled]: (state, action) => action.payload,
        [createPage.fulfilled]: (state, action) => action.payload,
        [deletePage.fulfilled]: (state, action) => action.payload,
    },
});

export default PageSlice.reducer;
