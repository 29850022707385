import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";

import React, { useState, useRef } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
    Avatar,
    CardActionArea,
    Divider,
    Icon,
    IconButton,
    Link,
} from "@mui/material";
import { TextField, Button, Item, CardHeader } from "@mui/material";
import Stack from "@mui/material/Stack";
import PublicHeader from "app/main/apps/public/public-header/PublicHeader";
import PublicFooter from "app/main/apps/public/public-footer/PublicFooter";

import FooterLayout3 from "app/fuse-layouts/layout3/components/FooterLayout3";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { faComments, faEye } from "@fortawesome/free-regular-svg-icons";

const Root = styled(FusePageSimple)(() => ({
    "& .FusePageCarded-topBg": {
        background: "unset",
    },
    "& .FusePageSimple-toolbar": {
        background: "white",
        minHeight: "unset",
        height: "unset",
    },
    minHeight: "unset",
    "& .FusePageSimple-contentCard, .FusePageSimple-header": {
        backgroundColor: "unset",
        background: "unset",
        boxShadow: "unset",
    },
    "& .ps__rail-y": {
        display: "none !important",
    },
}));

function PublicBlogDetails(props) {
    const pageLayout = useRef(null);
    const [selected, setSelected] = React.useState(false);

    return (
        <>
            <PublicHeader></PublicHeader>
            <Root
                className=" max-w-screen-smm m-auto"
                contentToolbar={
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        className="w-full px-20 py-32"
                    >
                        <Typography className="">All Post</Typography>
                        <Stack direction={"row"} className="">
                            <IconButton edge={false}>
                                <Icon>search</Icon>
                            </IconButton>
                            <Button
                                variant="outlined"
                                color="primary"
                                className="rounded-none"
                            >
                                {" "}
                                Log in / Sign up
                            </Button>
                        </Stack>
                    </Stack>
                }
                content={
                    <Card elevation={0} className=" rounded-none px-20 pb-16">
                        <Stack direction={"column"} className=" flex-wrap-reverse">
                            <div className=" px-28 flex flex-col justify-between">
                                <div>
                                    <CardHeader
                                        className="py-0 pt-5"
                                        avatar={
                                            <Avatar aria-label="recipe">
                                                <img src="assets/images/agent-pi-circle-yellow-gray-white.png" />
                                            </Avatar>
                                        }
                                        title={
                                            <Typography className="">
                                                planetimpossible
                                                <FontAwesomeIcon className="ml-5" icon={faCrown} />
                                            </Typography>
                                        }
                                        subheader={
                                            <Typography className=" line-clamp-1 text-12">
                                                July 27, 2019 · 1 min
                                            </Typography>
                                        }
                                        action={
                                            <IconButton aria-label="settings" edge={false}>
                                                <MoreVertIcon />
                                            </IconButton>
                                        }
                                    />
                                    <CardContent className="">
                                        <Typography
                                            gutterBottom
                                            component="div"
                                            className="font-bold text-24 text-pi-black line-clamp-2"
                                        >
                                            What does collective action look like?
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            className=" line-clamp-3 leading-6"
                                        >
                                            Crowds and how they behave is such a fascinating thing to
                                            observe. I have been to my fair share of concerts,
                                            conventions, expos and festivals and seeing the event and
                                            people attending the event as a whole is like watching a
                                            whole different living entity.
                                        </Typography>
                                    </CardContent>
                                </div>
                            </div>
                            <ReactPlayer
                                loop={false}
                                muted={false}
                                playing={false}
                                className="react-player px-40"
                                url="https://www.youtube.com/watch?v=1jXNkjjEuS8"
                                width={"100%"}
                            // height="400px"
                            />
                            <div className=" px-40 py-16">
                                <Divider className=" w-full mb-16" />
                                <Stack direction={"row"} spacing={3} justifyContent={"start"}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="19"
                                        viewBox="0 0 19 19"
                                        role="img"
                                        className=" hover:fill-pi-blue cursor-pointer"
                                    >
                                        <path d="M8.08865986,17 L8.08865986,10.2073504 L5.7890625,10.2073504 L5.7890625,7.42194226 L8.08865986,7.42194226 L8.08865986,5.08269399 C8.08865986,3.38142605 9.46779813,2.00228778 11.1690661,2.00228778 L13.5731201,2.00228778 L13.5731201,4.50700008 L11.8528988,4.50700008 C11.3123209,4.50700008 10.874068,4.94525303 10.874068,5.48583089 L10.874068,7.42198102 L13.5299033,7.42198102 L13.1628515,10.2073892 L10.874068,10.2073892 L10.874068,17 L8.08865986,17 Z"></path>
                                    </svg>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="19"
                                        viewBox="0 0 19 19"
                                        role="img"
                                        className=" hover:fill-pi-blue cursor-pointer"
                                    >
                                        <path d="M18,4.65548179 C17.3664558,4.9413444 16.6940105,5.12876845 16.0053333,5.21143556 C16.7308774,4.76869949 17.2742158,4.07523994 17.5353333,3.25870539 C16.8519351,3.66952531 16.1046338,3.95967186 15.3253333,4.116758 C14.3449436,3.05903229 12.8270486,2.71461351 11.4952673,3.24769481 C10.1634861,3.78077611 9.28740204,5.08344943 9.28466667,6.53469742 C9.28603297,6.80525838 9.31643401,7.07486596 9.37533333,7.33876278 C6.57168283,7.1960128 3.95976248,5.85317869 2.19,3.64465676 C1.87608497,4.18262214 1.71160854,4.79663908 1.714,5.42164122 C1.61438697,6.56033644 2.09783469,7.6712643 2.99466667,8.36452045 C2.36720064,8.27274888 1.74900117,8.12475716 1.14733333,7.9222845 L1.14733333,7.96708243 C1.26738074,9.69877048 2.5327167,11.1265052 4.21866667,11.4326042 C3.96602896,11.5152522 3.7021383,11.5571156 3.43666667,11.55666 C3.23854288,11.557327 3.0409356,11.5361435 2.84733333,11.4934834 C3.31534048,12.9376046 4.63446966,13.9228162 6.134,13.9481801 C4.90488101,14.9328579 3.38271245,15.4661427 1.816,15.4609716 C1.5432586,15.4614617 1.27074516,15.4449665 1,15.411579 C4.05446938,17.394368 7.93290025,17.5303291 11.1152384,15.7661758 C14.2975765,14.0020226 16.2768505,10.6187983 16.2773333,6.94247342 C16.2773333,6.789701 16.266,6.63692858 16.266,6.4830075 C16.9469737,5.98359293 17.5342367,5.3646551 18,4.65548179 L18,4.65548179 Z"></path>
                                    </svg>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="19"
                                        viewBox="0 0 19 19"
                                        role="img"
                                        className=" hover:fill-pi-blue cursor-pointer"
                                    >
                                        <path d="M17,17 L13.89343,17 L13.89343,12.1275733 C13.89343,10.9651251 13.87218,9.47069458 12.2781416,9.47069458 C10.660379,9.47069458 10.4126568,10.7365137 10.4126568,12.0434478 L10.4126568,17 L7.30623235,17 L7.30623235,6.98060885 L10.2883591,6.98060885 L10.2883591,8.3495072 L10.3296946,8.3495072 C10.7445056,7.56190587 11.7585364,6.7312941 13.2709225,6.7312941 C16.418828,6.7312941 17,8.80643844 17,11.5041407 L17,17 Z M3.80289931,5.61098151 C2.80647978,5.61098151 2,4.80165627 2,3.80498046 C2,2.80903365 2.80647978,2 3.80289931,2 C4.79669898,2 5.60434314,2.80903365 5.60434314,3.80498046 C5.60434314,4.80165627 4.79669898,5.61098151 3.80289931,5.61098151 Z M2.24786773,17 L2.24786773,6.98060885 L5.35662096,6.98060885 L5.35662096,17 L2.24786773,17 Z"></path>
                                    </svg>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="19"
                                        height="19"
                                        viewBox="0 0 19 19"
                                        role="img"
                                        className=" hover:fill-pi-blue cursor-pointer"
                                    >
                                        <path
                                            d="M10.6000004,11.7622375 L14.2108923,11.7622375 C15.4561791,11.7622375 16.4656836,10.7527331 16.4656836,9.50744629 L16.4656836,9.50744629 L16.4656836,9.50744629 C16.4656836,8.26215946 15.4561791,7.25265503 14.2108923,7.25265503 L10.6000004,7.25265503 L10.6000004,5.84470702 L10.6000004,5.84470702 C10.6000004,5.73425007 10.6895434,5.64470702 10.8000004,5.64470702 L14.3209766,5.64470702 C16.4501961,5.64470702 18.1762695,7.37078048 18.1762695,9.5 C18.1762695,11.6292195 16.4501961,13.355293 14.3209766,13.355293 L10.8000004,13.355293 L10.8000004,13.355293 C10.6895434,13.355293 10.6000004,13.2657499 10.6000004,13.155293 L10.6000004,11.7622375 Z M8.39999962,7.25265503 L4.82047474,7.25265503 C3.57518792,7.25265503 2.56568348,8.26215946 2.56568348,9.50744629 L2.56568348,9.50744629 L2.56568348,9.50744629 C2.56568348,10.7527331 3.57518792,11.7622375 4.82047474,11.7622375 L8.39999962,11.7622375 L8.39999962,13.1578418 C8.39999962,13.2682987 8.31045657,13.3578418 8.19999962,13.3578418 L4.60784179,13.3578418 C2.4772146,13.3578418 0.75,11.6306272 0.75,9.5 C0.75,7.36937281 2.4772146,5.64215821 4.60784179,5.64215821 L8.19999962,5.64215821 L8.19999962,5.64215821 C8.31045657,5.64215821 8.39999962,5.73170126 8.39999962,5.84215821 L8.39999962,7.25265503 Z M6.66568358,8.69999981 L12.2656836,8.69999981 C12.3761405,8.69999981 12.4656836,8.78954286 12.4656836,8.89999981 L12.4656836,10.1499998 C12.4656836,10.2604567 12.3761405,10.3499998 12.2656836,10.3499998 L6.66568358,10.3499998 C6.55522663,10.3499998 6.46568358,10.2604567 6.46568358,10.1499998 L6.46568358,8.89999981 C6.46568358,8.78954286 6.55522663,8.69999981 6.66568358,8.69999981 Z"
                                            transform="rotate(-45 9.463 9.5)"
                                        ></path>
                                    </svg>
                                </Stack>
                                <Divider className=" w-full my-16" />
                                <Stack
                                    direction="row"
                                    className="justify-between w-full items-center"
                                >
                                    <Stack direction="row" spacing={2}>
                                        <Typography className="text-pi-black">46 views</Typography>
                                        <Link
                                            underline="none"
                                            component={"button"}
                                            className=" text-pi-black hover:text-pi-primary"
                                        >
                                            0 comments
                                        </Link>
                                    </Stack>
                                    <Typography>
                                        {!selected ? "1" : "2"}
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setSelected(!selected);
                                            }}
                                            edge={false}
                                        >
                                            <Icon size="small" className=" text-red-500 text-19">
                                                {!selected ? "favorite_border" : "favorite"}
                                            </Icon>
                                        </IconButton>
                                    </Typography>
                                </Stack>
                            </div>
                            <Stack className="mt-16">
                                <Typography className="text-14">Recent Post</Typography>
                                <Stack
                                    direction={"row"}
                                    className="flex-wrap mb-68"
                                    justifyContent="start"
                                >
                                    <div className="player-wrapper relative my-5 mr-5">
                                        <img
                                            src="https://static.wixstatic.com/media/94dadca0e12503a29a8581cee4c4df20.jpg"
                                            alt="Thumbnail"
                                            className=" object-cover"
                                            style={{ width: "250px", height: "250px" }}
                                        />
                                        <div className=" absolute bottom-0 z-10 h-full w-full flex flex-col justify-between bg-grey-900 bg-opacity-60">
                                            <CardHeader
                                                className=" p-16"
                                                title={
                                                    <Typography className="text-white">
                                                        planetimpossible
                                                        <FontAwesomeIcon className="ml-5" icon={faCrown} />
                                                    </Typography>
                                                }
                                                subheader={
                                                    <Typography className=" line-clamp-1 text-white text-12">
                                                        Mar 14, 2013 · 1 min
                                                    </Typography>
                                                }
                                            />
                                            <div className="py-16 px-16">
                                                <Typography className="text-14 font-bold text-white line-clamp-2">
                                                    Happy Pi Day!
                                                </Typography>
                                                <Divider className=" border-white mt-8 mb-5" />
                                                <Stack
                                                    direction="row"
                                                    className="justify-between w-full items-center"
                                                >
                                                    <Stack direction="row" spacing={2}>
                                                        <Typography className="text-white">
                                                            <FontAwesomeIcon
                                                                className="mr-5"
                                                                icon={faEye}
                                                                size="lg"
                                                            />
                                                            12
                                                        </Typography>
                                                        <Typography className="text-white">
                                                            <FontAwesomeIcon
                                                                className="mr-5"
                                                                icon={faComments}
                                                                size="lg"
                                                            />
                                                            0
                                                        </Typography>
                                                    </Stack>
                                                    <Typography className="text-white">
                                                        {!selected ? "5" : "6"}
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => {
                                                                setSelected(!selected);
                                                            }}
                                                            edge={false}
                                                        >
                                                            <Icon
                                                                size="small"
                                                                className=" text-red-500 text-19"
                                                            >
                                                                {!selected ? "favorite_border" : "favorite"}
                                                            </Icon>
                                                            {/* <Icon className=" text-red-500">favorite</Icon> */}
                                                        </IconButton>
                                                    </Typography>
                                                </Stack>
                                            </div>
                                        </div>
                                    </div>
                                </Stack>
                                <div className="px-40 my-16">
                                    <Typography className=" font-semibold">Comments</Typography>
                                    <Divider className="my-16"></Divider>
                                    <TextField
                                        className="w-full"
                                        placeholder="Write a comment..."
                                    ></TextField>
                                </div>
                            </Stack>
                        </Stack>
                    </Card>
                }
            />
            <PublicFooter className="fixed bottom-0"></PublicFooter>
        </>
    );
}

export default PublicBlogDetails;
