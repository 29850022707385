import * as React from "react";
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import TableHead from '@mui/material/TableHead';
import { styled } from '@mui/material/styles';
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& .MuiTableCell-root': {
        backgroundColor: theme.palette.background.paper,
    },
    // hide last border
    // '&:last-child td, &:last-child th': {
    //   border: 0,
    // },
}));
const rows = [
    {
        id: 'image',
        align: 'left',
        disablePadding: true,
        label: '',
        sort: false,
    },
    {
        id: 'agent',
        align: 'left',
        disablePadding: false,
        label: 'Agent',
        sort: true,
    },
    {
        id: 'status',
        align: 'left',
        disablePadding: false,
        label: 'Status',
        sort: true,
    },
    {
        id: 'notification',
        align: 'left',
        disablePadding: false,
        label: 'Notification',
        sort: true,
    },
    {
        id: 'dateCreated',
        align: 'left',
        disablePadding: false,
        label: 'Date Added',
        sort: true,
    },
    {
        id: 'actions',
        align: 'right',
        disablePadding: false,
        label: 'Actions',
        sort: false,
        width: '200px'
    },
];

function FriendsManagerTableHeader(props) {
    const { selectedConfigurationIds } = props;
    const numSelected = selectedConfigurationIds.length;

    const dispatch = useDispatch();

    const createSortHandler = (property) => (event) => {
        props.onRequestSort(event, property);
    };

    return (
        <TableHead>
            <StyledTableRow className="h-48 sm:h-64">
                <TableCell padding="none" className="w-40 md:w-64 text-center z-99">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < props.rowCount}
                        checked={props.rowCount !== 0 && numSelected === props.rowCount}
                        onChange={props.onSelectAllClick}
                    />
                </TableCell>
                {rows.map((row) => {
                    return (
                        <TableCell
                            className="p-4 md:p-16"
                            key={row.id}
                            width={row.width ? row.width : ''}
                            align={row.align}
                            padding={row.disablePadding ? 'none' : 'normal'}
                            sortDirection={props.order.id === row.id ? props.order.direction : false}
                        >
                            {row.sort ? (
                                <Tooltip
                                    title="Sort"
                                    placement={row.align === 'right' ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={props.order.id === row.id}
                                        direction={props.order.direction}
                                        onClick={createSortHandler(row.id)}
                                        className="font-bold"
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            ) : <TableSortLabel
                                className="font-bold"
                            >
                                {row.label}
                            </TableSortLabel>}
                        </TableCell>
                    );
                }, this)}
            </StyledTableRow>
        </TableHead>
    );
}

export default FriendsManagerTableHeader;
