import React from 'react';
import { Card, CardContent, CardHeader, Skeleton, Container } from "@mui/material";

const CollabSkeletonLoader = () => (
    <>
        <div className=" flex-grow basis-xs relative mt-16" style={{ maxWidth: '639px', }}>
            <Container>
                <CardHeader
                    className='pt-0 px-0'
                    avatar={<Skeleton animation="wave" variant="circular" width={50} height={50} />}
                    title={<Skeleton animation="wave" height={20} width="40%" style={{ marginBottom: 6 }} />}
                    subheader={<Skeleton animation="wave" height={16} width="20%" />}
                />
                <CardContent className='p-0'>
                    <Skeleton animation="wave" variant="text" height={20} />
                    <Skeleton animation="wave" variant="text" height={20} />
                    <Skeleton animation="wave" variant="text" height={20} />
                </CardContent>

            </Container>
            <Skeleton variant="rectangular" height={264} className=' rounded-md' />

        </div>
    </>

);

export default CollabSkeletonLoader;
