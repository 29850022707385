import swal from "sweetalert";

export const confirmDelete = function (params, successCall, errorCall) {
    swal({
        title: (params?.title ? params?.title : "Delete Confirmation"),
        text: (params?.msg ? params?.msg : "Are you sure you want to delete this record(s)?"),
        icon: (params?.icon ? params?.icon : "error"),
        closeOnClickOutside: false,
        buttons: {
            confirm: {
                text: (params?.confirmButtonTxt ? params?.confirmButtonTxt : "Yes"),
                className: (params?.confirmButtonClass ? params?.confirmButtonClass : "bg-red-500"),
                value: true,
                visible: true,
                closeModal: true
            },
            cancel: {
                text: (params?.cancelButtonTxt ? params?.cancelButtonTxt : "No"),
                className: (params?.cancelButtonClass ? params?.cancelButtonClass : "bg-red-500 text-white border-0 hover:bg-red-300"),
                value: false,
                visible: true,
                closeModal: true
            }
        }
    }).then((resp) => {
        if (resp) {
            if (successCall) {
                successCall();
            }
        } else {
            if (errorCall) {
                errorCall();
            }
        }
    });
}