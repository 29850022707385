import React, { useState } from 'react';
import CKEditor from 'react-ckeditor-component';

const MyCKEditor = (props) => {
    const { content, setContent } = props;

    const editorConfig = {
        toolbar: 'basic',
        height: 300,
        allowedContent: true,
    };

    const handleChange = (event) => {
        const newContent = event.editor.getData();
        setContent(newContent);
    };

    return (
        <div>
            <CKEditor
                config={editorConfig}
                content={content}
                events={{
                    change: handleChange,
                }}
            />
        </div>
    );
};

export default MyCKEditor;