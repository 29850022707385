const mutation = {
    saveBlogAuthor: `
        mutation saveBlogAuthor($data: JSONString!) {
            saveBlogAuthor (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    deleteBlogAuthor: `
        mutation deleteBlogAuthor($id: String!) {
            deleteBlogAuthor (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
};

const query = {
    getBlogAuthorList: `
        query getBlogAuthorList {
            getBlogAuthorList {
                id
                name
                location
                occupation
                about
                images {
                    id
                    userId
                    uploadId
                    fileName
                    fileType
                    fileId
                    module
                    path
                    url
                    dateCreated
                    dateUpdated
                }
                featuredImageId
                featuredImagePath
                photoUrl
                dateCreated
                dateUpdated
            }
        }
    `,
    checkBlogAuthorName: `
        query checkBlogAuthorName($value: String, $id: String) {
            checkBlogAuthorName(value: $value, id: $id) {
                id
                name
            }
        }
    `,
};
export { mutation, query };