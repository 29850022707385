import React, { useEffect, useRef, useState } from 'react';

function ScrollToBottomTrigger({ onReachBottom }) {
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;

        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    onReachBottom();
                }
            },
            {
                root: null, // Use the viewport as the container
                rootMargin: '0px',
                threshold: 0.1, // Trigger when at least 10% of the target is visible
            }
        );

        if (container) {
            observer.observe(container);
        }

        return () => {
            if (container) {
                observer.unobserve(container);
            }
        };
    }, [onReachBottom]);

    return (
        <div ref={containerRef} style={{ height: '1px', overflowY: 'scroll' }}>
            {/* Your scrollable content here */}
        </div>
    );
}

export default ScrollToBottomTrigger;