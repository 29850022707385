import {
    Card,
    Avatar,
    Dialog,
    DialogContent,
    Typography,
    TextField,
    Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { showMessage } from "app/store/fuse/messageSlice";

import React, { useState, useRef, useEffect, useMemo, createRef, useReducer } from "react";
import { updateMarkerContetnById } from "../../store/missionSlice";



const CardStyled = styled(Card)(
    ({ theme }) => `
	margin: ${theme.spacing(1)};
	padding: ${theme.spacing(2)};
	// border: 1px solid black;
	box-shadow: 0 0 20px #e5e5e5;
  `
);
const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 34,
    height: 34,
    // border: `2px solid ${theme.palette.background.paper}`,
}));
const BackgroundAvatar = styled(Avatar)(({ theme }) => ({
    // width: 40,
    borderRadius: "unset",
    width: 60,
    height: 60,
    "& img": {
        objectFit: "contain",
    },
    // height: 40,
    // border: `2px solid ${theme.palette.background.paper}`,
}));

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    var username = name.split(" "),
        firstName = username[0].split(" ")[0][0],
        lastName = username[username.length - 1].split(" ")[0][0];
    if (firstName == lastName) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}`,
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}${lastName}`,
        };
    }
}
const defaultValues = {
    content_marker: "",
};
export default function UpdateMarkerContent(props) {
    const { onClose, open, data } = props;
    const handleClose = () => {
        onClose();
    };
    const schema = yup.object().shape({
        content_marker: yup.string().required("Field is required").min(8).max(32),
    });
    const {
        control,
        formState,
        handleSubmit,
        reset,
        setValue,
        watch,
        getValues,
        trigger,
    } = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(schema),
    });
    const { isValid, dirtyFields, errors } = formState;
    async function handleSave() {
        const result = await trigger(["content_marker"]);
        var data = getValues();
        data.id = mapMarkerUpdateData.id
        console.log("get valuesss", data);
        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                    variant: "error",
                })
            );
        }
        dispatch(updateMarkerContetnById(data)).then(function () {
            reset(defaultValues);
            setShowingInfoWindow(!ishowingInfoWindow);
            dispatch(
                getSingleMissionById({
                    id: id,
                })
            ).then((action) => {
                setMapData(action.payload);
                setMapMarkersData(action.payload.markers);
                console.log("setMapData", action.payload);
            });
        });

    }
    return (
        <>
            <Dialog onClose={handleClose} open={open}>
                <DialogContent>
                    <div>
                        <form name="updateForm" noValidate>
                            <Typography variant="h3"> Update Content</Typography>
                            <Controller
                                name="content_marker"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mt-16 w-full"
                                        error={!!errors.content_marker}
                                        required
                                        helperText={errors?.content_marker?.message}
                                        label="Mission Content"
                                        id="content_marker"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className="w-full mx-auto mt-16 rounded-md h-48"
                                aria-label="LOG IN"
                                value="legacy"
                                onClick={handleSave}
                            >
                                Submit
                            </Button>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}
