import React, { useState, useRef, useEffect } from "react";
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from "react-redux";
import { followAgent, unfollowAgent, agentFollowStatus, followNotifAgent } from './store/AgentFollowUnfollowSlice';
import IconButton from "@mui/material/IconButton";
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { Notifications, NotificationsOff } from "@mui/icons-material";
import { addNotification } from "app/fuse-layouts/shared-components/quickPanel/store/dataSlice";
import notification from "./notification";
import moment from "moment";

import { Icon, List, ListItem, ListItemText, Switch } from "@mui/material";
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

const token = AgentJwtService.getDecodedAccessToken();


const AgentFollowUnfollow = (props) => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState(true);
    const [notif, setNotif] = useState('true');

    useEffect(() => {
        dispatch(agentFollowStatus({ agentId: token.agentId, followedId: props.item.id })).then(function (action) {
            setStatus(action.payload.length > 0 ? true : false);
            if (action.payload.length > 0) {
                setNotif(action.payload[0].notification == 'true' ? 'true' : 'false');
            }
        });
    }, [dispatch]);

    async function handleFollow() {

        const data = {
            agentid_follower: token.agentId,
            agentid_followed: props.item.id,
        }
        dispatch(followAgent(data)).then(function (action) {
            if (action.payload.status == 'success') {
                setStatus(true);
                createNotification({
                    uid: token.id,
                    uname: token.data.displayName,
                    upicPath: token.data.profilePicPath,
                    userId: props.item.userId,
                    userName: props.item.displayName,
                    link: 'agentprofile?agentId=' + token.id,
                    message: notification({
                        uname: token.data.displayName,
                        username: props.item.displayName
                    }, 'followUnfollow', 'follow'),
                    module: 'followUnfollow',
                    time: moment().format('yyyy-MM-DD[T]HH:mm:ss'),
                    status: "Unread"
                })
            }
        });
    }

    function createNotification(obj) {
        dispatch(addNotification(obj));
    }

    async function handleUnfollow() {

        const data = {
            agentid_follower: token.agentId,
            agentid_followed: props.item.id,
        }
        dispatch(unfollowAgent(data)).then(function (action) {
            setStatus(false);
        });
    }

    async function handleNotif() {
        const data = {
            agentid_follower: token.agentId,
            agentid_followed: props.item.id,
            notification: notif == 'true' ? 'false' : 'true'
        }
        dispatch(followNotifAgent(data)).then(function (action) {
            setNotif(notif == 'true' ? 'false' : 'true');
        });
    }
    const ButtonFollowUnfollow = () => {
        return (
            <>
                {
                    status ? (
                        props.module != 'friends-activity' && (
                            <Button
                                onClick={handleUnfollow}
                                aria-label="Unfollow"
                                variant="contained"
                                className="agent-profile-follow mr-3 px-8 py-3 rounded-md hover:bg-pi-light-grey bg-pi-light-grey bg-opacity-50 text-pi-black font-bold h-fit">
                                Unfollow
                            </Button>
                        )
                    ) :
                        (
                            token.agentId != props.item.id && (
                                <Button
                                    onClick={handleFollow}
                                    aria-label="Follow"
                                    variant="contained"
                                    className="agent-profile-follow mx-3 text-12 px-0 py-0 rounded-md hover:bg-pi-red hover:bg-opacity-5 bg-pi-red bg-opacity-5 text-pi-red font-bold h-fit">

                                    Follow
                                </Button>
                            )
                        )
                }
            </>
        );

    };
    return (
        <>
            <div className=" max-600px:hidden block">
                <ButtonFollowUnfollow />
                {props.module != 'agent-profile' && props.module != 'friends-activity' && (
                    notif == 'true' ? (
                        < IconButton onClick={handleNotif} aria-label="settings" size="small" >
                            <Notifications className=" text-32 fill-pi-default" />
                        </IconButton >
                    ) : (
                        < IconButton onClick={handleNotif} aria-label="settings" size="small" >
                            <NotificationsOff color="disable" className=" text-32" />
                        </IconButton >
                    )
                )}
            </div>
            <div className="hidden max-600px:block">
                {props.module != 'agent-profile' && props.module != 'friends-activity' && (

                    <PopupState variant="popover" popupId="demo-popup-popover">
                        {(popupState) => (
                            <>
                                <IconButton {...bindTrigger(popupState)}>
                                    <Icon>more_vert</Icon>
                                </IconButton>
                                <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', }}>

                                        <ListItem onClick={handleNotif}>
                                            <ListItemText primary={
                                                props.module !== 'agent-profile' && props.module !== 'friends-activity' && notif === 'true' ? (
                                                    "Mute Notification"

                                                ) : (
                                                    "Unmute Notification"
                                                )
                                            } />
                                        </ListItem>
                                        <ListItem onClick={status ? handleUnfollow : handleFollow}>
                                            <ListItemText primary=
                                                {status ? "Unfollow" : "Follow"}
                                            />
                                        </ListItem>

                                    </List>
                                </Popover>
                            </>
                        )}
                    </PopupState>
                )}
                {!status ? (
                    props.module == 'friends-activity' && (
                        token.agentId != props.item.id && (
                            <Button
                                onClick={handleFollow}
                                aria-label="Follow"
                                variant="contained"
                                className=" text-10 mx-3 px-0 py-0 rounded-md hover:bg-pi-red hover:bg-opacity-5 bg-pi-red bg-opacity-5 text-pi-red font-bold h-fit">

                                Follow
                            </Button>
                        )
                    )
                ) : null}
            </div>

        </>
    );
};

export default AgentFollowUnfollow;