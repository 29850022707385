const mutation = {
    updateAgent: `
        mutation updateAgent($data: JSONString!) {
            updateAgent (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    updateAccount: `
        mutation updateAccount($data: JSONString!) {
            updateAccount (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    updatePassword: `
        mutation updatePassword($data: JSONString!){
            updatePassword(data: $data) {
                res,
                message,
                status
            }
        }
    `,
    checkAccount: `
        mutation checkAccount($data: JSONString!){
            checkAccount(data: $data) {
                res,
                message,
                status
            }
        }
    `,
};

const query = {
    agentById: `
        query agentById($agentId: String) {
            agentById(agentId: $agentId) {
                id
                userId
                city
                email
                displayName
                about
                country
                state
                gender
                name
                flName
                username
                firstName
                middleName
                lastName
                birthdate
                agentName
                dateCreated
                profileUrl
                bannerUrl
                dateFormatted
                images {
                    id
                    userId
                    uploadId
                    fileName
                    fileType
                    fileId
                    module
                    path
                    url
                    imageType
                    dateCreated
                    dateUpdated
                }
                bannerImages {
                    id
                    userId
                    uploadId
                    fileName
                    fileType
                    fileId
                    module
                    path
                    url
                    imageType
                    dateCreated
                    dateUpdated
                }
                agentFollowersCount
                getReach {
                    missions
                    markers
                }
            }
        }
    `,
    agentAccountById: `
        query agentById($agentId: String) {
            agentById(agentId: $agentId) {
                id
                userId
                email
                username
            }
        }
    `,
    getMarkersByAgentId: `
        query getMarkersByAgentId($id: String, $type: String){
            getMarkersByAgentId(id: $id, type: $type){
                id
                title
                description
                dateCreated
                filesId
                lat
                lng
                kingPin
                missionId
                missionViews
                missionPinned
                missionCommentsCount
                agent{
                    profilePicPath
                    name
                    flName
                    username
                }
            }
        }
    `,
};
export { mutation, query };