import axios from "axios";

const presigned = async (data, loading) => {
    var formData = new FormData();
    formData.append("key", data.url.replace(data.server + "/", ""));
    // formData.append("key", data.url);
    formData.append("filename", "medisource");
    formData.append("forcedownload", data.download);
    return await new Promise(function (resolve, reject) {
        axios
            .post(data.api, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    if (typeof loading == "function") {
                        loading(percentCompleted);
                    }
                }
            })
            .then(function (result) {
                // const {records} = result.data;
                // resolve(records.temporaryUrl);
                resolve(result);
            })
            .catch(function (e) {
                reject(e);
            })
            .finally(function () {
                // So somthing here
            });
    });
};

const randomString = (length = 8) => {

    var result = "";
    var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
};

const arrayToStr = (array, separator = ", ") => {

    var str = "";

    var isArray = Array.isArray(array)

    if (!isArray) {
        return str;
    }

    var filter = [false, null, undefined, NaN, ''];

    var filteredArray = array.filter(function (v) {
        return (filter.indexOf(v) === -1);
    });

    return filteredArray.join(separator);

}

export {
    presigned,
    randomString,
    arrayToStr
};

