import { lazy } from 'react';
import { authRoles } from 'app/auth';

const BlogManageAuthors = lazy(() => import('./manage-authors/BlogManageAuthors'));
const BlogManageCategory = lazy(() => import('./manage-category/BlogManageCategory'));
const BlogManageTags = lazy(() => import('./manage-tags/BlogManageTags'));
const BlogManage = lazy(() => import('./manage-blogs/BlogManage'));

const BlogsAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {
            path: 'apps/blogs/manage-blogs',
            element: <BlogManage />,
            auth: ['blogs-blg']
        },
        {
            path: 'apps/blogs/manage-author',
            element: <BlogManageAuthors />,
            auth: ['blogs-matr']
        },
        {
            path: 'apps/blogs/manage-category',
            element: <BlogManageCategory />,
            auth: ['blogs-cat']
        },
        {
            path: 'apps/blogs/manage-tag',
            element: <BlogManageTags />,
            auth: ['blogs-tag']
        },
    ],
};

export default BlogsAppConfig;
