import {
  ApolloClient,
  InMemoryCache
} from "@apollo/client";
import CONFIG from './config';
import jwtService from 'app/services/jwtService';

const client = new ApolloClient({
    uri: CONFIG.API + "/api/",
    cache: new InMemoryCache(),
    request: operation => {
        // console.log("APOLLO REQUEST OPERATION", operation);

        if (operation.variables.hasOwnProperty("skipAuthorization") && operation.variables.skipAuthorization === true) {

            // do not proceed to authorization process
            return
        }

        const accessToken = "JWT "+ jwtService.getAccessToken();
        operation.setContext({ headers: { authorization: accessToken } });
    }
});


export default client
