import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { query, mutation } from "../graphql/mission_details_graphql";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import { v4 as uuidv4 } from 'uuid';
import { resolve } from "promise";
import { showMessage } from "app/store/fuse/messageSlice";


export const getMissionById = createAsyncThunk(
    "missionDetailsApp/getMissionById",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getMissionById,
            variables: params,
        });

        return response.data.data.getMissionById
    }
);

export const getMarkersByMissionId = createAsyncThunk(
    "missionDetailsApp/getMarkersByMissionId",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getMarkersByMissionId,
            variables: params,
        });

        if (response.data.data.getMarkersByMissionId) {
            const data = response.data.data.getMarkersByMissionId;

            var tempItems = [];

            if (data) {
                data.map((itemD) => {
                    itemD.lat = parseFloat(itemD.lat);
                    itemD.lng = parseFloat(itemD.lng);
                    itemD.latLang = { lat: parseFloat(itemD.lat), lng: parseFloat(itemD.lng) }
                    tempItems.push(itemD);
                })
            }

            return data === undefined ? [] : tempItems;
        } else {
            return [];
        }
    }
);

const MissionDetailsSlice = createSlice({
    name: "missionDetailsApp",
    initialState: null,
    reducers: null,
    extraReducers: {
        [getMissionById.fulfilled]: (state, action) => action.payload,
        [getMarkersByMissionId.fulfilled]: (state, action) => action.payload,
    },
});

export default MissionDetailsSlice.reducer;
