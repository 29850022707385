import FusePageSimple from "@fuse/core/FusePageSimple";

import { styled } from "@mui/material/styles";

import { useCallback, useState, useRef } from "react";
import { TextField, Button, Item, Typography, Stack } from "@mui/material";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import FuseUtils from '@fuse/utils';


import Icon from '@mui/material/Icon';
import { Box } from "@mui/system";

const Root = styled('div')(() => ({
    "& .MuiTypography-root": {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: "unset",
        fontFamily: "'Nunito Sans', sans-serif !important"


    },

}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    //dialog
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle style={{ width: 900 }} sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    edge={false}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function uploadFile(props) {

    const { saveImages } = props

    const [anchorElPhoto, setAnchorElPhoto] = useState(null);

    const openPhoto = Boolean(anchorElPhoto);

    const [selectedMenuItem, setSelectedMenuItem] = useState("");

    const [openPhotoDialog, setOpenPhotoDialog] = useState(false); //OPEN DIALOG

    const [imagesDataArr, setImagesDataArr] = useState([]);

    function handleUpload(file, type) {

        setImagesDataArr([...imagesDataArr, file]);

        saveImages({
            saveImages: [...imagesDataArr, file],
        })
    }

    const uploadPhotoClick = (event) => {
        console.log("file upload", event)
        setAnchorElPhoto(event.currentTarget);
    };

    const handleClosePhoto = () => {
        setAnchorElPhoto(null);
    };
    const handleClosePhotoMenu = () => {
        setOpenPhotoDialog(false);
    };

    const handleMenuItemClick = (menuItem) => {
        setOpenPhotoDialog(true);
        setSelectedMenuItem(menuItem);
    };

    const handleFileChange = async (e) => {
        function readFileAsync() {
            return new Promise(async (resolve, reject) => {
                const file = e.target.files[0];

                if (!file) {
                    return;
                }
                const reader = new FileReader();
                reader.onload = () => {
                    var id = FuseUtils.generateGUID();
                    resolve({
                        id: id,
                        fileId: id,
                        url: `data:${file.type};base64,${btoa(reader.result)}`,
                        type: 'image',
                        file: file,
                        status: "new"
                    });
                };
                reader.onerror = reject;
                reader.readAsBinaryString(file);

            });
        }

        const newImage = await readFileAsync();

        handleUpload(newImage, "upload");
    };

    return (
        <Root className="w-full h-full mb-8 ">
            <label
                htmlFor="button-file"
                className="productImageUpload "
            >
                <input
                    accept="image/*"
                    className="hidden"
                    id="button-file"
                    type="file"
                    onClick={(event) => {

                        event.target.value = null
                    }}
                    onChange={handleFileChange}
                />
                <Typography type="button" variant="button" className=" text-white px-12 py-6 cursor-pointer"
                    sx={(theme) => ({
                        textTransform: "initial !important", fontSize: "14px", borderRadius: "8px", background: "#2EB7D7", '&:hover': { background: "#229bb7" }

                    })}>
                    <Icon sx={{ fontSize: '20px', mr: '6px', mb: '-5px' }}>attachment</Icon> Add photos / videos
                </Typography>

            </label>
            <Stack direction={'row'} gap={.5} className="mt-10" flexWrap={'wrap'}>
                {imagesDataArr.slice(0, 5).map((item) => (

                    <div
                        style={{ width: 100, height: 100 }}
                        className="overflow-hidden bg-white border-1 border-pi-secondary rounded-lg"
                    >
                        <img
                            className="max-w-none w-full h-full object-cover"
                            // ref={fileInputRef}
                            src={item.url}
                            alt="Preview"
                        />
                    </div>

                ))}
            </Stack>
        </Root>
    );
}
export default uploadFile;
