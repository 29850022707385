const mutation = {
    saveBlogTag: `
        mutation saveBlogTag($data: JSONString!) {
            saveBlogTag (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    deleteBlogTag: `
        mutation deleteBlogTag($id: String!) {
            deleteBlogTag (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
};

const query = {
    getBlogTagList: `
        query getBlogTagList {
            getBlogTagList {
                id
                name
                dateCreated
                dateUpdated
            }
        }
    `,
    checkBlogTagName: `
        query checkBlogTagName($value: String, $id: String) {
            checkBlogTagName(value: $value, id: $id) {
                id
                name
            }
        }
    `,
};
export { mutation, query };