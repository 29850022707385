const notification = (data, module, action) => {
    var notification = {
        followUnfollow: {
            follow: 'is now following you.',
        },
        mission: {
            create: 'Created a mission.',
            join: 'Joined your mission.',
            comment: 'commented on your mission.',
            reply: 'replied on your mission.',
        },
        comment: {
            reply: 'replied on your comment.',
        }
    };

    return notification[module][action];
}

export { notification };