import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from 'app/config';
import { query, mutation } from "../graphql/agent_manage_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import { mutation as mutationUpload } from "app/graphql/upload_graphql";
import format from 'date-fns/format';
import JwtService from "app/services/jwtService";
import { isEmpty } from "lodash";

export const updateAgent = createAsyncThunk(
    "agentApp/info/updateAgent",
    async (params, { dispatch, getState }) => {

        return new Promise(async (resolve, reject) => {

            saveImages(params).then(async (params) => {
                const response = await axios.post(CONFIG.API + "/api/", {
                    query: mutation.updateAgent,
                    variables: { data: JSON.stringify(params) },
                });

                if (response.data.data.updateAgent) {
                    dispatch(
                        showMessage({
                            message: "Agent has been successfully saved.",
                            autoHideDuration: 5000, //ms
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            variant: "success",
                        })
                    );
                    resolve(true);
                } else {
                    dispatch(
                        showMessage({
                            message: "Something went wrong. Please try again later.",
                            autoHideDuration: 5000, //ms
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            variant: "error",
                        })
                    );
                    resolve(true);
                }
            });
        })
    }
);

const saveImages = (params) => {

    return new Promise(async (resolve, reject) => {
        console.log(params, 'params');
        params.images = [...params.images, ...params.bannerImages];
        if (params.images.length) {

            var newImages = [];

            params.images.map(async (image, index) => {

                if (image.status === "new") {
                    newImages.push({
                        index: index,
                        image: image
                    })
                } else {
                    if (params.featuredImageId == image.fileId) {
                        params.featuredImagePath = image.path;
                    }
                }

            });

            if (newImages.length) {
                var count = 0;
                newImages.map(async (image, index) => {

                    // return
                    await uploadSvc({
                        file: image.image.file,
                        destination_file_name: "useraccount/user/user-" + randomString() + "-" + (image.image.file ? image.image.file.name : image.image.fileName)
                    }).then(async (result) => {

                        if (result.status === 200 && result.data.status === "success") {

                            var uploadData = result.data.uploadData
                            uploadData['module'] = 'user-account';
                            uploadData['file_id'] = image.image.id;
                            uploadData['imageType'] = image.image.imageType;

                            const response = await axios.post(CONFIG.API + "/api/", {
                                query: mutationUpload.create,
                                variables: {
                                    data: JSON.stringify(uploadData),
                                },
                                publicUrl: 'agents',
                            });

                            if (response.data.data.createUpload.status == "success") {
                                count++;
                                console.log(count);
                                var upload = response.data.data.createUpload.upload;

                                if (params.featuredImageId == image.image.id) {
                                    params.featuredImagePath = upload.path;
                                }
                                params.imageType = image.image.imageType;

                                params.images[image.index].upload_id = upload.id;
                                params.images[image.index].file_name = upload.fileName;
                                params.images[image.index].file_id = upload.fileId;
                                params.images[image.index].file_type = upload.fileType;
                                params.images[image.index].path = upload.path;
                                params.images[image.index].module = upload.module;
                                // params.images[image.index].url = "";

                                if (newImages.length == count) {
                                    console.log('pasok mo');
                                    resolve(params);
                                }

                            }

                        }

                    });

                });

            } else {
                resolve(params)
            }


        } else {

            resolve(params);

        }

    });

}

export const toggleSave = createAsyncThunk(
    "agentsApp/agent/toggleSave",
    async (params, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.toggleSave,
            variables: { data: JSON.stringify(params) },
        });

        if (response.data.data.toggleSave) {
            dispatch(
                showMessage({
                    message: "Agent has been successfully updated.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );
        } else {
            dispatch(
                showMessage({
                    message: "Something went wrong. Please try again later.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }
    }
);

export const getAgentsList = createAsyncThunk(
    "agentsApp/agent/getAgentsList",
    async (keyword) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getAgentsList,
            variables: {
                keyword: keyword ? keyword : null
            }
        });

        if (response.data.data.getAgentsList) {
            const data = response.data.data.getAgentsList;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const deleteAgent = createAsyncThunk(
    'agentsApp/agent/deleteAgent',
    async (depIds, { dispatch, getState }) => {

        var count = 0;

        await depIds.map(async (id) => {

            await axios.post(CONFIG.API + "/api/", {
                query: mutation.deleteAgent,
                variables: {
                    id: id
                }
            });

            count++;

        })

        if (count == depIds.length) {
            dispatch(
                showMessage({
                    message: "Agent(s) has been successfully deleted.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );

            return depIds;
        }

    }
);

export const checkAgentName = (filter) => async (dispatch) => {

    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.checkAgentName,
        variables: filter
    });

    if (response.data.data.checkAgentName.length) {
        return false;
    } else {
        return true;
    }

};

const ManageAgentsSlice = createSlice({
    name: 'agentsApp/agent',
    initialState: null,
    reducers: null,
    extraReducers: {
        [updateAgent.fulfilled]: (state, action) => action.payload,
        [getAgentsList.fulfilled]: (state, action) => action.payload,
        [deleteAgent.fulfilled]: (state, action) => action.payload,
        [toggleSave.fulfilled]: (state, action) => action.payload,
    },
});

export default ManageAgentsSlice.reducer;
