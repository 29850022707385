import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from 'app/config';
import { query, mutation } from "../graphql/public_blog_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import format from 'date-fns/format';
import JwtService from "app/services/jwtService";
import { isEmpty } from "lodash";

export const getBlogs = createAsyncThunk(
    "blogsApp/public/getBlogs",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getBlogs,
            variables: {
                id: params.id,
                author: params.author == 'all_author' ? null : params.author,
                category: params.category == 'all_post' ? null : params.category,
                keyword: params.keyword ? params.keyword : null,
                start: params.start,
                end: params.end,
            }
        });

        if (response.data.data.getBlogs) {
            const data = response.data.data.getBlogs;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const getBlogCategoryList = createAsyncThunk(
    "blogsApp/public/getBlogCategoryList",
    async (keyword) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getBlogCategoryList,
            variables: {
                keyword: keyword ? keyword : null
            }
        });

        if (response.data.data.getBlogCategoryList) {
            const data = response.data.data.getBlogCategoryList;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const getBlogAuthorList = createAsyncThunk(
    "blogsApp/public/getBlogAuthorList",
    async (keyword) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getBlogAuthorList,
            variables: {
                keyword: keyword ? keyword : null
            }
        });

        if (response.data.data.getBlogAuthorList) {
            const data = response.data.data.getBlogAuthorList;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const updateViews = createAsyncThunk(
    "blogsApp/updateViews",
    async (params, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.updateViews,
            variables: { data: JSON.stringify(params) },
            publicUrl: "agents"
        });

        if (response.data.data.updateViews) {
        }
    }
);


const PublicBlogSlice = createSlice({
    name: 'blogsApp/public',
    initialState: null,
    reducers: null,
    extraReducers: {
        [getBlogs.fulfilled]: (state, action) => action.payload,
        [getBlogAuthorList.fulfilled]: (state, action) => action.payload,
        [getBlogCategoryList.fulfilled]: (state, action) => action.payload,
        [updateViews.fulfilled]: (state, action) => action.payload,
    },
});

export default PublicBlogSlice.reducer;
