import { lazy } from 'react';

const FileManagerApp = lazy(() => import('./FileManagerApp'));

const FileManagerAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
  routes: [
    {
      path: 'apps/file-manager',
      element: <FileManagerApp />,
    },
  ],
};

export default FileManagerAppConfig;
