import FuseNavigation from '@fuse/core/FuseNavigation';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavigation } from 'app/store/fuse/navigationSlice';
import { navbarCloseMobile } from '../../store/fuse/navbarSlice';
import JwtService from "app/services/jwtService";
import { useEffect } from 'react';
import { logoutUser } from 'app/auth/store/userSlice';
import { forceLogoutUser, updateChecker } from 'app/main/apps/settings/store/settingsSlice';

function Navigation(props) {
  const navigation = useSelector(selectNavigation);
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();

  function handleItemClick(item) {
    if (mdDown) {
      dispatch(navbarCloseMobile());
    }
  }

  const token = JwtService.getDecodedAccessToken();

  useEffect(() => {

    const myFunction = () => {
      if (token.id) {
        dispatch(forceLogoutUser({ agentId: token.id, userType: 'user' })).then((action) => {
          if (action.payload) {
            dispatch(logoutUser())
          }
        })
        dispatch(updateChecker({ agentId: token.id, userType: 'user' })).then((action) => {
          if (action.payload) {
            JwtService.setUserRefreshToken();
          }
        })
      }
    };

    const intervalId = setInterval(myFunction, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <FuseNavigation
      className={clsx('navigation', props.className)}
      navigation={navigation}
      layout={props.layout}
      dense={props.dense}
      active={props.active}
      onItemClick={handleItemClick}
    />
  );
}

Navigation.defaultProps = {
  layout: 'vertical',
};

export default memo(Navigation);
