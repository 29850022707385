import React from 'react';
import { Box, Card, CardContent, CardHeader, Skeleton, Stack } from "@mui/material";

const HomeSkeletonLoader = () => (

    <>

        <Stack direction={"column"} sx={{ width: { xl: "calc(25% - 18px)", lg: "calc(35% - 36px)", md: "calc(35% - 35px)", sm: "calc(50% - 18px)", xs: "calc(100%)" } }} >
            <Box
                sx={{ width: "100%", height: { xl: "10.416666666666666vw", lg: "13.416667vw", md: "17.416667vw", sm: "24.416667vw", xs: "56.25vw" } }}
                className="overflow-hiddenrounded-md"
            >
                <Skeleton sx={{ height: "100%", width: "100%", }} animation="wave" variant="rectangular" className="rounded-md" />
            </Box>
            <CardHeader
                className={"py-8 items-start px-16 sm:px-0"}

                avatar={<Skeleton animation="wave" variant="circular" width={50} height={50} />}
                title={<Skeleton animation="wave" height={26} width="80%" style={{ marginBottom: 6 }} />}
                subheader={<Skeleton animation="wave" height={18} width="40%" />}
            />
        </Stack>


    </>
);

export default HomeSkeletonLoader;
