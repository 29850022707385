import AcademyAppConfig from './academy/AcademyAppConfig';
import CalendarAppConfig from './calendar/CalendarAppConfig';
import ChatAppConfig from './chat/ChatAppConfig';
import ContactsAppConfig from './contacts/ContactsAppConfig';
import AnalyticsDashboardAppConfig from './dashboards/analytics/AnalyticsDashboardAppConfig';
import ProjectDashboardAppConfig from './dashboards/project/ProjectDashboardAppConfig';
import FileManagerAppConfig from './file-manager/FileManagerAppConfig';
import MailAppConfig from './mail/MailAppConfig';
import NotesAppConfig from './notes/NotesAppConfig';
import ScrumboardAppConfig from './scrumboard/ScrumboardAppConfig';
import TodoAppConfig from './todo/TodoAppConfig';
import UserAccountAppConfig from './user-account/UserAccountAppConfig';
import PublicConfig from './public/PublicConfig';
import BlogsAppConfig from 'app/main/apps/blogs/BlogsAppConfig';
import MissionsAppConfig from './missions/MissionsAppConfig';
import AgentAppConfig from 'app/main/apps/agent/AgentAppConfig';
import AgentCmsAppConfig from './agent/AgentCmsAppConfig';
import PublicMissionConfig from 'app/main/apps/public/mission/PublicMissionConfig';
import SettingsCmsAppConfig from './settings/SettingsCmsAppConfig';
import PagesCmsAppConfig from './pages/PagesCmsAppConfig';
import PagesAppConfig from './pages/PagesAppConfig';

const appsConfigs = [
    AnalyticsDashboardAppConfig,
    ProjectDashboardAppConfig,
    MailAppConfig,
    TodoAppConfig,
    FileManagerAppConfig,
    ContactsAppConfig,
    CalendarAppConfig,
    ChatAppConfig,
    ScrumboardAppConfig,
    AcademyAppConfig,
    NotesAppConfig,
    UserAccountAppConfig,
    PublicConfig,
    BlogsAppConfig,
    MissionsAppConfig,
    AgentAppConfig,
    AgentCmsAppConfig,
    PublicMissionConfig,
    SettingsCmsAppConfig,
    PagesCmsAppConfig,
    PagesAppConfig
];

export default appsConfigs;
