import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { styled, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Logo from 'app/fuse-layouts/shared-components/Logo';
import NavbarToggleButton from 'app/fuse-layouts/shared-components/NavbarToggleButton';
import Navigation from 'app/fuse-layouts/shared-components/Navigation';
import UserNavbarHeader from 'app/fuse-layouts/shared-components/UserNavbarHeader';
import clsx from 'clsx';
import { memo } from 'react';
import { Box } from '@mui/material';
import * as React from "react";
import { GlobalStyles, } from "@mui/system";
import { useEffect, useState } from 'react';
const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  '& ::-webkit-scrollbar-thumb': {
    boxShadow: `inset 0 0 0 20px ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.24)' : 'rgba(255, 255, 255, 0.24)'
      }`,
  },
  '& ::-webkit-scrollbar-thumb:active': {
    boxShadow: `inset 0 0 0 20px ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.37)' : 'rgba(255, 255, 255, 0.37)'
      }`,
  },
}));

const StyledContent = styled(FuseScrollbars)(({ theme }) => ({
  overscrollBehavior: 'auto',
  overflowX: 'hidden',
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  // for future reference
  // background:
  //   'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)',
  // backgroundRepeat: 'no-repeat',
  // backgroundSize: '100% 40px, 100% 10px',
  // backgroundAttachment: 'local, scroll',
}));
function useScrollTop() {
  const [scrollTop, setScrollTop] = React.useState(0);
  const onScroll = (event) => setScrollTop(event.target.scrollTop);
  return [scrollTop, { onScroll }];
}
function NavbarStyle1Content(props) {
  const theme = useTheme();
  const [scrollTop, scrollProps] = useScrollTop();
  return (
    <Root className={clsx('flex flex-auto flex-col overflow-hidden h-full', props.className)}
    >
      <AppBar
        color=""
        position="static"
        className="flex flex-row items-center shrink h-76 md:h-76 min-h-48 md:min-h-76 px-12 shadow-0"
      >
        <Box className="flex flex-1 mx-4">
          <Logo />
        </Box>

        <NavbarToggleButton className="w-40 h-40 p-0" />
      </AppBar>

      <Box className="h-full overflow-auto overscroll-auto"
        {...scrollProps}
        sx={{
          background: 'white',
          boxShadow:
            scrollTop > 0 ? "inset 0 8px 5px -9px rgb(0 0 0 / 0.4) !important" : "none",
          transition: "box-shadow 0.8s !important",
          '&::-webkit-scrollbar': { width: '0' },
          '&::-webkit-scrollbar-thumb': { boxShadow: 'inset 0 0 0 20px rgba(0, 0, 0, 0)', },
          ':hover&::-webkit-scrollbar-thumb': { boxShadow: 'inset 0 0 0 20px rgba(0, 0, 0, 0.24)' },
        }}
      >

        <Navigation layout="vertical" />
      </Box >
    </Root>
  );
}

export default memo(NavbarStyle1Content);
