import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from 'app/config';
import { query, mutation } from "../graphql/manage_mission_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import { mutation as mutationUpload } from "app/graphql/upload_graphql";
import format from 'date-fns/format';
import JwtService from "app/services/jwtService";
import { isEmpty } from "lodash";

import { v4 as uuidv4 } from 'uuid';


export const getMissionList = createAsyncThunk(
    "blogsApp/author/getMissionList",
    async (keyword) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getMissionList,
            variables: {
                keyword: keyword ? keyword : null
            }
        });

        if (response.data.data.getMissionList) {
            const data = response.data.data.getMissionList;

            var tempItems = [];

            if (data) {
                data.map((itemD) => {
                    itemD.lat = parseFloat(itemD.lat);
                    itemD.lng = parseFloat(itemD.lng);
                    tempItems.push(itemD);
                })
            }

            return data === undefined ? [] : tempItems;
        } else {
            return null;
        }
    }
);

export const deleteMissionById = createAsyncThunk(
    "missionApp/mission/deleteMissionById",
    async (depIds, { dispatch, getState }) => {

        var count = 0;

        await depIds.map(async (id) => {

            await axios.post(CONFIG.API + "/api/", {
                query: mutation.deleteMissionById,
                variables: {
                    id: id
                }
            });

            count++;

        })

        if (count == depIds.length) {
            dispatch(
                showMessage({
                    message: "Mission(s) has been successfully deleted.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );

            return depIds;
        }

    }
);

export const updateMissionStatus = createAsyncThunk(
    "missionApp/mission/updateMissionStatus",
    async (params, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.updateMissionStatus,
            variables: { data: JSON.stringify(params) },
        });
        if (response.data.data.updateMissionStatus) {
            dispatch(
                showMessage({
                    message: "Status has been successfully updated.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );
            resolve(true);
        } else {
            dispatch(
                showMessage({
                    message: "Something went wrong. Please try again later.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
            resolve(true);
        }
    }
);

export const updateMissionContent = createAsyncThunk(
    "missionApp/mission/updateMissionContent",

    async (params, { dispatch, getState }) => {

        const data = await saveImages(params).then(async (params) => {

            const response = await axios.post(CONFIG.API + "/api/", {
                query: mutation.updateMissionContent,
                variables: {
                    data: JSON.stringify(params),
                },
            });

            if (response.data.data.updateMissionContent.status != 'error') {
                dispatch(
                    showMessage({
                        message: "Mission has been successfully updated.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "success",
                    })
                );

                return response.data.data.updateMissionContent

            } else {
                dispatch(
                    showMessage({
                        message: "Something went wrong. Please try again later.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );

                return []
            }

        });

        return data;
    }
);

export const updateMissionFeatured = createAsyncThunk(
    "missionApp/mission/updateMissionFeatured",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.updateMissionFeatured,
            variables: { data: JSON.stringify(params) },
        });
        return response;
    }


);

const saveImages = (itemData) => {

    itemData.fileid = uuidv4();

    return new Promise(async (resolve, reject) => {

        if (itemData.images.length) {

            var newImages = [];

            itemData.images.map(async (image, index) => {

                if (image.status === "new") {
                    newImages.push({
                        index: index,
                        image: image
                    })
                } else {
                    if (itemData.featuredImageId == image.fileId) {
                        itemData.featuredImagePath = image.path;
                    }
                }

            });

            if (newImages.length) {

                newImages.map(async (image, index) => {

                    await uploadSvc({
                        file: image.image.file,
                        destination_file_name: "missions/" + randomString() + "-" + (image.image.file ? image.image.file.name : image.image.fileName)
                    }).then(async (result) => {

                        if (result.status === 200 && result.data.status === "success") {

                            var uploadData = result.data.uploadData
                            uploadData['module'] = 'mission';
                            uploadData['file_id'] = image.image.id;
                            uploadData['fileid'] = itemData.fileid;

                            const response = await axios.post(CONFIG.API + "/api/", {
                                query: mutation.createPublicUpload,
                                variables: {
                                    data: JSON.stringify(uploadData),
                                },
                            });

                            if (response.data.data.createPublicUpload.status == "success") {
                                var upload = response.data.data.createPublicUpload.upload;

                                if (itemData.featuredImageId == image.image.id) {
                                    itemData.featuredImagePath = upload.path;
                                }
                                itemData.images[image.index].upload_id = upload.id;
                                itemData.images[image.index].file_name = upload.fileName;
                                itemData.images[image.index].file_id = upload.fileId;
                                itemData.images[image.index].file_type = upload.fileType;
                                itemData.images[image.index].path = upload.path;
                                itemData.images[image.index].module = upload.module;
                            }
                        }

                        if (newImages.length == (index + 1)) {
                            resolve(itemData);
                        }

                    });

                });

            } else {
                resolve(itemData)
            }

        } else {
            resolve(itemData);

        }

    });

}

export const missionToggleSave = createAsyncThunk(
    "MissionsApp/missions/missionToggleSave",
    async (params, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.missionToggleSave,
            variables: { data: JSON.stringify(params) },
        });

        if (response.data.data.missionToggleSave) {
            dispatch(
                showMessage({
                    message: "Mission has been successfully updated.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );
        } else {
            dispatch(
                showMessage({
                    message: "Something went wrong. Please try again later.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }
    }
);

const MissionsManageSlice = createSlice({
    name: 'MissionsApp/missions',
    initialState: null,
    reducers: null,
    extraReducers: {
        [updateMissionStatus.fulfilled]: (state, action) => action.payload,
        [getMissionList.fulfilled]: (state, action) => action.payload,
        [deleteMissionById.fulfilled]: (state, action) => action.payload,
        [updateMissionContent.fulfilled]: (state, action) => action.payload,
        [missionToggleSave.fulfilled]: (state, action) => action.payload,
    },
});

export default MissionsManageSlice.reducer;
