import React from 'react';
import { Card, CardContent, CardHeader, Skeleton, Container } from "@mui/material";

const AttachmentSkeletonLoader = () => (
    <>

        <CardContent className='!p-0'>
            <Skeleton animation="wave" variant="rectangular" className="rounded-4" height={78} width={78} />
        </CardContent>

    </>

);

export default AttachmentSkeletonLoader;
