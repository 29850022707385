import { lazy } from 'react';

const CalendarApp = lazy(() => import('./CalendarApp'));

const CalendarAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
  routes: [
    {
      path: 'apps/calendar',
      element: <CalendarApp />,
    },
  ],
};

export default CalendarAppConfig;
