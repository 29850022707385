const query = {
    getCounters: `
         query getCounters($agentId: String) {
            getCounters(agentId: $agentId) {
                followers
                reach
                missions
            }
        }
    `,
};

export { query };