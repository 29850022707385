import React from 'react';
import { Card, CardContent, CardHeader, Skeleton, Stack } from "@mui/material";
import { Box } from '@mui/system';

const AgentSkeletonLoader = () => (
    <>
        <CardHeader
            className='pt-16 pb-8 px-16'
            avatar={<Skeleton animation="wave" variant="circular" width={120} height={120} />}

        />
        <CardContent className="p-0  px-16">
            <Skeleton sx={{ height: 28, width: '50%' }} animation="wave" variant="text" />
            <Skeleton sx={{ height: 16, width: '30%' }} animation="wave" variant="text" />
            <Box className="mt-8" />
            <Skeleton sx={{ height: 16, }} animation="wave" variant="text" />
            <Skeleton sx={{ height: 16, }} animation="wave" variant="text" />
            <Stack direction={'row'} gap={2} className=" w-full mt-8" justifyContent={'space-between'}>
                <Skeleton sx={{ height: 65, width: '100%' }} animation="wave" variant="rectangular" className=' rounded-4' />
                <Skeleton sx={{ height: 65, width: '100%' }} animation="wave" variant="rectangular" className=' rounded-4' />
                <Skeleton sx={{ height: 65, width: '100%' }} animation="wave" variant="rectangular" className=' rounded-4' />
            </Stack>
        </CardContent>
    </>
);

export default AgentSkeletonLoader;
