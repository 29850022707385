import { authRoles } from 'app/auth';
import ManageAgents from './manage-agent/ManageAgents';

const AgentCmsAppConfig = {
    settings: {
        theme: {
            main: "default",
            navbar: "default",
            toolbar: "default",
            footer: "default"
        },
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {
            path: 'apps/agents/manage',
            element: <ManageAgents />,
            auth: ['agents-list']
        },
    ],
};

export default AgentCmsAppConfig;
