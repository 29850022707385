import FusePageCarded from "@fuse/core/FusePageCarded";
import withReducer from "app/store/withReducer";
import _ from "@lodash";
import { useForm, FormProvider } from "react-hook-form";
import { styled } from "@mui/material/styles";
import reducer from "app/main/apps/settings/store";
import SystemLogsHeader from "./SystemLogsHeader";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

const Root = styled(FusePageCarded)(({ theme }) => ({
    '& .FusePageCarded-header': {
        minHeight: 72,
        height: 72,
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            minHeight: 120,
            height: 136,
        },
    },
    "& .FusePageCarded-contentCard": {
        marginTop: "2rem",
        marginBottom: "2rem",
        borderRadius: "20px"
    },
    "& .FusePageCarded-content": {
        paddingBottom: ".5rem"
    },

    "& .FusePageCarded-toolbar": {
        border: "none"
    },
}));

import JwtService from "app/services/jwtService";
import SystemLogsFilter from "./content/SystemLogsFilter";
import SystemLogsTable from "./content/SystemLogsTable";
import { padding } from "@mui/system";
const user = JwtService.getDecodedAccessToken();

const defaultValues = {
    items: [],
    keyword: '',
    dateFrom: null,
    dateTo: null
}

function ManageSysLogs(props) {

    const dispatch = useDispatch();

    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues
    });

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);

    return (
        <FormProvider  {...methods}>
            <Root
                header={
                    <div className="flex flex-col flex-1 p-8 sm:p-12 relative">
                        <SystemLogsHeader />
                    </div>
                }
                contentToolbar={
                    <SystemLogsFilter data={data} setData={setData} setLoading={setLoading} loading={loading} page={page} setPage={setPage} />
                }
                content={
                    <SystemLogsTable data={data} setData={setData} setLoading={setLoading} loading={loading} page={page} setPage={setPage} />
                }
            />
        </FormProvider>
    );
}

export default withReducer("settingsApp", reducer)(ManageSysLogs);
