import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { query, mutation } from "../graphql/search_graphql";

export const updateSearchHistory = createAsyncThunk(
    "searchApp/search/updateSearchHistory",
    async (data) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.updateSearchHistory,
            variables: { data: JSON.stringify(data) }
        });
        return response.data.data.updateSearchHistory;
    }
);

export const getSearchById = createAsyncThunk(
    "searchApp/search/getSearchById",
    async (agentId) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getSearchById,
            variables: {
                agentId: agentId
            }
        });

        if (response.data.data.getSearchById) {
            const data = response.data.data.getSearchById;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const getSearchList = createAsyncThunk(
    "searchApp/search/getSearchList",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getSearchList,
            variables: {
                agentId: params.agentId,
                searchKey: params.searchKey
            }
        });

        if (response.data.data.getSearchList) {
            const data = response.data.data.getSearchList;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

const searchSlice = createSlice({
    name: "search",
    initialState: null,
    reducers: null,
    extraReducers: {
        [updateSearchHistory.fulfilled]: (state, action) => action.payload,
        [getSearchList.fulfilled]: (state, action) => action.payload,
        [getSearchById.fulfilled]: (state, action) => action.payload,
    },
});

export default searchSlice.reducer;
