import { combineReducers } from '@reduxjs/toolkit';
import agentlogin from './agentLoginSlice';
import agentuser from './agentUserSlice';

const authReducers = combineReducers({
  agentuser,
  agentlogin,
});

export default authReducers;
