// cooments nakadepende yung profile ne agent kung nkajoined na means with pin yung profile, kapag hindi naka join at comment lang is no pin,
import {
    Card,
    CardContent,
    Stack,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Avatar,
    Badge,
    CardHeader,
    IconButton,
    Container,
    Button,
    Divider,
    Icon,
    Tab,
    TextField,
    InputAdornment,
    Typography
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import * as React from "react";

import { ToastContainer, toast } from "react-toastify";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { showMessage } from "app/store/fuse/messageSlice";
import { Provider, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useMemo } from "react";
import {
    BrowserRouter as Router,
    useParams,
} from "react-router-dom";
import CommentsContent from "./CommentsContent"
import UploadFile from "../../../shared/Upload/UploadFile";
import { createComment, getSingleMissionById } from "../../store/missionSlice"
import { styled } from "@mui/material/styles";
import { inputLabelClasses } from "@mui/material/InputLabel";
import MIssionDetailsCommentImageModal from "../modal/MIssionDetailsCommentImageModal";
import AgentJwtService from 'app/services/agent/AgentJwtService';

const defaultValues = {
    comment: "",
    images: [],
};

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 34,
    height: 34,
}));

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        fontFamily: "Nunito, sans-serif !important",
        paddingTop: "8px !important",
        backgroundColor: "#F8FAFB",
        border: "1px solid #E9E9E9",
        fontSize: '14px',
        borderRadius: '23px',
        [theme.breakpoints.up("lg")]: {
            height: 'unset',
            fontSize: '14px',
        },
    },
    "& .MuiInputLabel-root": {
        fontSize: '14px',
        [theme.breakpoints.up("lg")]: {
            fontSize: '14px',
        },
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },

}));

const BackgroundAvatar = styled(Avatar)(({ theme }) => ({
    borderRadius: "unset",
    width: 60,
    height: 60,
    "& img": {
        objectFit: "contain",
    },
}));

function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    return color;
}

function stringAvatar(name) {
    var username = name.split(" "),
        firstName = username[0].split(" ")[0][0],
        lastName = username[username.length - 1].split(" ")[0][0];
    if (firstName == lastName) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}`,
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}${lastName}`,
        };
    }
}

export default function CommentForm(props) {

    const token = AgentJwtService.getDecodedAccessToken();
    const { mission_id } = props
    const [openDialog, setOpenDialog] = useState(false);
    const [imagesData, setImagesData] = useState([]);
    const [mapData, setMapData] = useState([]);
    const [commentData, setCommentData] = useState([]);
    const [replyData, setReplyData] = useState([]);
    const { id } = useParams();
    const dispatch = useDispatch();

    function reloadData() {
        dispatch(
            getSingleMissionById({
                id: mission_id,
            })
        ).then((action) => {
            setMapData(action.payload)
            setCommentData(action.payload.comments);
            setReplyData(action.payload.comments.replies);
        });
    }

    const schema = yup.object().shape({
        comment: yup.string().required("Comment is required"),
    });

    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState,
        handleSubmit,
        reset,
        getValues,
        setValue,
        watch,
        trigger
    } = methods;

    const { isValid, dirtyFields, errors } = formState;

    async function handleSave() {
        const result = await trigger(["comment"]);

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                    variant: "error",
                })
            );
        }

        var data = getValues();
        data.mission_id = mission_id;
        data.agent = token.id
        dispatch(createComment(data)).then(function (action) {
            reloadData();
            reset(defaultValues);
        },);
    }
    return (
        <FormProvider {...methods}>
            <div>

                <ToastContainer />
                <form name="commentForm" noValidate>

                    <CardHeader
                        className=" !p-0"
                        sx={{
                            "& .MuiCardHeader-avatar": {
                                marginRight: "5px",
                                alignSelf: "start",
                            },

                        }}
                        avatar={
                            <>
                                <Avatar {...stringAvatar(`Super Agent`)} className="!mt-4" />
                            </>
                        }
                        subheader={
                            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                <Controller
                                    name="comment"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldStyled
                                            {...field}
                                            id="comment"
                                            className="w-full"
                                            size="medium"
                                            placeholder="Write a comment..."
                                            variant="filled"
                                            InputLabelProps={{
                                                sx: {
                                                    color: "#48525C",
                                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                        fontSize: '14px',
                                                        color: !!errors.username ? "red" : "#141F2C"
                                                    }
                                                }
                                            }}
                                            fullWidth
                                            multiline
                                            InputProps={{
                                                disableUnderline: true,
                                                endAdornment: (
                                                    <InputAdornment
                                                        position="end"
                                                        className="!h-full !max-h-max !justify-between !pr-4"
                                                    >
                                                        <MIssionDetailsCommentImageModal module="comments" ></MIssionDetailsCommentImageModal>
                                                    </InputAdornment>
                                                ),
                                            }}

                                        />
                                    )}
                                />
                                <IconButton
                                    onClick={handleSave}
                                    aria-label="toggle password visibility"
                                    title="Submit"
                                    edge="end"
                                    className="  !p-5"
                                >
                                    <Icon className=" !text-20" size="large">send</Icon>
                                </IconButton>
                            </Stack>

                        }
                    />
                </form>
                <CommentsContent mapData={mapData} commentData={commentData} replyData={replyData} reloadData={reloadData} mission_id={mission_id} />
            </div>

        </FormProvider>
    );
};
