import React from 'react';
import { Card, CardContent, CardHeader, Skeleton, Stack } from "@mui/material";

const SkeletonLoader = () => (

    <>
        <Stack direction={"row"} gap={1} className=" px-16 py-8">
            <Skeleton variant="rectangular" width={75} height={75} />
            <CardHeader
                className="p-0 w-x cursor-pointer flex-1"
                title={
                    <Skeleton />
                }
                subheader={
                    <>
                        <Skeleton />
                        <Skeleton width="60%" />
                    </>
                }
            />
        </Stack>
    </>
);

export default SkeletonLoader;
