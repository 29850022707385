const mutation = {
    savePublicUpload: `
        mutation savePublicUpload($data: JSONString!) {
            savePublicUpload (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    deletePublicAttachment: `
        mutation deletePublicAttachment($id: String!) {
            deletePublicAttachment (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
};

const query = {
    getPublicUploads: `
        query getPublicUploads($id: String){
            getPublicUploads(id: $id){
                id
                attachmentId
                fileId
                fileName
                fileType
                fileId
                path
                link
                srcUrl
                dateCreated
                dateUpdated
            }
        }
    `,
    getAllPublicUploads: `
        query getAllPublicUploads($id: String){
            getAllPublicUploads(id: $id){
                id
                attachmentId
                fileId
                fileName
                fileType
                fileId
                path
                link
                srcUrl
                dateCreated
                dateUpdated
            }
        }
    `,
    getSignedUrl: `
        query getSignedUrl($path: String){
            getSignedUrl(path: $path)
        }
    `,
};
export { mutation, query };