import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from 'app/config';
import { query, mutation } from "../graphql/public_uploads_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import { mutation as mutationUpload } from "app/graphql/upload_graphql";
import format from 'date-fns/format';
import JwtService from "app/services/jwtService";
import { isEmpty } from "lodash";



export const savePublicUpload = createAsyncThunk(
    "uploadApp/savePublicUpload",
    async (params, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.savePublicUpload,
            variables: { data: JSON.stringify(params) },
        });

        if (response.data.data.savePublicUpload) {
            dispatch(
                showMessage({
                    message: "Attachment has been successfully uploaded.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );
        } else {
            dispatch(
                showMessage({
                    message: "Something went wrong. Please try again later.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }
        return response.data.data.savePublicUpload.res;
    }
);

export const getPublicUploads = createAsyncThunk(
    "uploadApp/getPublicUploads",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getPublicUploads,
            variables: {
                id: params.id ? params.id : null,
            }
        });

        if (response.data.data.getPublicUploads) {
            const data = response.data.data.getPublicUploads;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const getAllPublicUploads = createAsyncThunk(
    "uploadApp/getAllPublicUploads",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getAllPublicUploads,
            variables: {
                id: params.id ? params.id : null,
            }
        });

        if (response.data.data.getAllPublicUploads) {
            const data = response.data.data.getAllPublicUploads;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const deletePublicAttachment = createAsyncThunk(
    "uploadApp/deletePublicAttachment",
    async (params, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.deletePublicAttachment,
            variables: { id: params.id },
        });

        if (response.data.data.deletePublicAttachment) {
            dispatch(
                showMessage({
                    message: "Attachment has been successfully deleted.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );
        } else {
            dispatch(
                showMessage({
                    message: "Something went wrong. Please try again later.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }
    }
);

const publicUploadsSlice = createSlice({
    name: 'uploadApp',
    initialState: null,
    reducers: null,
    extraReducers: {
        [savePublicUpload.fulfilled]: (state, action) => action.payload,
        [getPublicUploads.fulfilled]: (state, action) => action.payload,
        [deletePublicAttachment.fulfilled]: (state, action) => action.payload,
    },
});

export default publicUploadsSlice.reducer;
