import CONFIG from "app/config";
import axios from "axios";

const uploadSvc = (data) => {

  // if (!data.file) {
  //   return {
  //     status: "error",
  //     data: {
  //       status: "error",
  //       msg: "file not found"
  //     }
  //   };
  // }

  const formData = new FormData();

  formData.append('file', data['file']);

  formData.append('destination_file_name', data['destination_file_name']);

  return axios.post(CONFIG.API + "/external/uploads/gcloud", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });

};


export { uploadSvc };