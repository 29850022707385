import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller, FormProvider } from "react-hook-form";
import * as yup from "yup";
import reducer from 'app/main/apps/blogs/store';
import withReducer from "app/store/withReducer";
import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { styled, lighten } from '@mui/material/styles';
import { showMessage } from "app/store/fuse/messageSlice";
import { Divider, ListItemButton, ListItemIcon, ListItemText, inputLabelClasses } from "@mui/material";
// import ManageMissionMaps from "../maps/ManageMissionMaps";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import CONFIG from "app/config";
// import ManageMissionImages from "./ManageMissionImages";
import Geocode from "react-geocode";
import MissionManagerimages from "./MissionManagerimages";
// import { updateMissionContent } from "../../store/MissionsManageSlice";
import { DialogTitle } from "@mui/material";

Geocode.setApiKey(CONFIG.MAPS);
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

const defaultValues = {
    id: null,
    title: "",
    description: "",
    mission_briefing: "",
    address: "",
    lattitude: "",
    longtitude: "",
    content_marker: "",
    featuredImageId: "",
    images: [],
};


const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
};

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        backgroundColor: "#ffff",
        border: "1px solid #E9E9E9",
        // height: 48,
        fontSize: '16px',
        borderRadius: '8px',
        [theme.breakpoints.up("lg")]: {
            height: 'unset',
            fontSize: '18px',
        },
        // borderRadius:"8px"
    },
    "& .MuiInputLabel-root": {
        // height: 48,
        fontSize: '16px',
        [theme.breakpoints.up("lg")]: {
            fontSize: '16px',
        },
        // borderRadius:"8px"
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
}));

const Root = styled('div')(({ theme }) => ({
    background: theme.palette.primary,
    color: theme.palette.primary.contrastText,

    '& .bg-icon': {
        background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${lighten(
            theme.palette.primary.dark,
            0.1
        )} 100%)`,
        color: theme.palette.primary.contrastText,
    },

}));

const mapOptions = (maps) => {
    return {
        mapTypeId: maps.MapTypeId.HYBRID,
        mapTypeControl: true,
        mapTypeControlOptions: {
            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: maps.ControlPosition.TOP_CENTER,
        },
        zoomControl: true,
        zoomControlOptions: {
            position: maps.ControlPosition.LEFT_CENTER,
        },
        scaleControl: true,
        streetViewControl: true,
        streetViewControlOptions: {
            position: maps.ControlPosition.LEFT_TOP,
        },
        fullscreenControl: false,
    };
};

function MissionManagerEditModal(props) {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);  // visibility state
    const [openDialog, setOpenDialog] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [origData, setOrigData] = useState({});
    const [markerLocation, setMarkerLocation] = useState({});
    const [newMarkerLocation, setNewMarkerLocation] = useState({});
    useEffect(() => {
        if (openDialog) {
            if (props.module === 'edit') {
                if (props.item) {
                    setValue("id", props.item["id"])
                    setValue("title", props.item["title"])
                    setValue("description", props.item["description"])
                    setMarkerLocation(
                        {
                            lat: props.item["lat"],
                            lng: props.item["lng"]
                        }
                    )
                    setValue("lattitude", props.item["lat"], { shouldDirty: true });
                    setValue("longtitude", props.item["lng"], { shouldDirty: true });
                    setValue("featuredImageId", props.item["missionsFile"])
                    setValue("featuredImagePath", props.item["missionsFile"])
                    setValue("images", props.item["missionsFile"] ? props.item["missionsFile"] : [])

                }
            }
        } else {
            setTimeout(() => {
                reset(defaultValues);
            }, 500);
        }

    }, [openDialog]);

    function handleOpenDialog() {
        setOpenDialog(true);
    }

    function handleCloseDialog() {
        setOpenDialog(false);
    }

    const schema = yup.object().shape({
        title: yup.string().required("Field is required").min(8).max(32),
        description: yup.string().required("Field is required"),
        mission_briefing: yup.string(),
        address: yup.string(),
        lattitude: yup.string(),
        longtitude: yup.string(),
        content_marker: yup.string(),
    });


    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState,
        handleSubmit,
        reset,
        getValues,
        setValue,
        watch,
        trigger
    } = methods;

    const { isValid, dirtyFields, errors } = formState;

    const handleMarkerDragend = (props, marker, e) => {
        const markerData = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        };
        setValue("lattitude", e.latLng.lat(), { shouldDirty: true });
        setValue("longtitude", e.latLng.lng(), { shouldDirty: true });

        Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
            (response) => {
                const address = response.results[0].formatted_address;

                setValue("address", address, { shouldDirty: true });
            },
            (error) => {
                console.error(error);
            }
        );
    };
    async function handleSave() {

        const result = await trigger([
            "title",
            "description",
            "mission_briefing",
            "address",
            "lattitude",
            "longtitude",
        ]);

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                    variant: "error",
                })
            );
        }

        var data = getValues();
        data.mission_id = props.item['id']
        data.files_id = props.item['filesId']

        // dispatch(updateMissionContent(data)).then(function (action) {
        //     props.loadData();
        //     handleCloseDialog()
        // });

    }



    return (
        <FormProvider {...methods}>

            <Root>
                {props.module == "edit" && (
                    <Tooltip title={"edit"} arrow>
                        <ListItemButton className=" text-pi-blue-1" onClick={handleOpenDialog}>
                            <ListItemIcon sx={{ minWidth: '36px' }}>
                                <Icon className=" text-pi-blue-1">edit</Icon>
                            </ListItemIcon>

                            <ListItemText primary="Edit" />
                        </ListItemButton>
                    </Tooltip>
                )}

                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="form-dialog-title"
                    scroll="body"
                    sx={{

                        '& .MuiPaper-root': {
                            width: "100%",
                        },
                    }}
                >
                    <DialogTitle className="flex w-full">
                        <Typography className="font-bold text-18" color="inherit">
                            Confirm Edit  Action
                        </Typography>
                    </DialogTitle>
                    <Divider />
                    <DialogContent classes={{ root: 'p-16 pb-0 lg:p-24 lg:pb-0' }}>
                        <div>
                            <Controller
                                name="title"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldStyled
                                        {...field}
                                        className=" w-full"
                                        error={!!errors.title}
                                        required
                                        helperText={errors?.title?.message}
                                        label="Mission Title"
                                        id="title"
                                        variant="filled"
                                        InputLabelProps={{
                                            sx: {
                                                color: "#48525C",
                                                fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                [`&.${inputLabelClasses.shrink}`]: {
                                                    fontSize: '14px',
                                                    color: !!errors.username ? "red" : "#141F2C"
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        fullWidth
                                    />
                                )}
                            />
                            <Controller
                                name="description"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldStyled
                                        {...field}
                                        className="mt-16 w-full"
                                        error={!!errors.description}
                                        required
                                        helperText={errors?.description?.message}
                                        label="Description"
                                        id="description"
                                        variant="filled"
                                        InputLabelProps={{
                                            sx: {
                                                color: "#48525C",
                                                fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                [`&.${inputLabelClasses.shrink}`]: {
                                                    fontSize: '14px',
                                                    color: !!errors.username ? "red" : "#141F2C"
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        fullWidth
                                        multiline
                                        rows={3}
                                    />
                                )}
                            />
                            <div className="mt-16" style={{ width: "100%", height: "450px" }}>
                                <Map
                                    key={props}
                                    options={mapOptions}
                                    containerStyle={containerStyle}
                                    google={window.google}
                                    zoom={14}
                                    initialCenter={markerLocation}
                                    fullscreenControl={false}
                                >
                                    <Marker
                                        key={props}
                                        onDragend={handleMarkerDragend}
                                        position={markerLocation}
                                        draggable={true}
                                        icon={{
                                            url: "assets/images/pin-background.png",
                                        }}
                                    ></Marker>

                                </Map>
                            </div>

                            {visible &&
                                <Controller
                                    name="address"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField


                                            {...field}
                                            className="mt-16 w-full"
                                            error={!!errors.address}
                                            helperText={errors?.address?.message}
                                            label="Address"
                                            readOnly
                                            id="address"
                                            variant="filled"
                                            InputLabelProps={{
                                                sx: {
                                                    color: "#48525C",
                                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                        fontSize: '14px',
                                                        color: !!errors.username ? "red" : "#141F2C"
                                                    }
                                                }
                                            }}
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />
                                    )}
                                />}

                            <Controller
                                name="lattitude"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mt-16 w-full"
                                        error={!!errors.lattitude}
                                        helperText={errors?.lattitude?.message}
                                        label="Latitude"
                                        id="lattitude"
                                        variant="filled"
                                        InputLabelProps={{
                                            sx: {
                                                color: "#48525C",
                                                fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                [`&.${inputLabelClasses.shrink}`]: {
                                                    fontSize: '14px',
                                                    color: !!errors.username ? "red" : "#141F2C"
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        fullWidth
                                    />
                                )}
                            />

                            <Controller
                                name="longtitude"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        // value={longtitude}
                                        className="mt-16 w-full"
                                        error={!!errors.longtitude}
                                        // required
                                        helperText={errors?.longtitude?.message}
                                        label="Longtitude"
                                        id="longtitude"
                                        variant="filled"
                                        InputLabelProps={{
                                            sx: {
                                                color: "#48525C",
                                                fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                [`&.${inputLabelClasses.shrink}`]: {
                                                    fontSize: '14px',
                                                    color: !!errors.username ? "red" : "#141F2C"
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        fullWidth
                                    />
                                )}
                            />
                            <MissionManagerimages className="mt-16" />
                        </div>

                    </DialogContent>
                    <DialogActions className="justify-center px-8 py-16">
                        <div className="px-16">
                            <LoadingButton
                                className="rounded-md mr-5"
                                variant="contained"
                                color="primary"
                                type="button"
                                disabled={isSaving}
                            // onClick={handleSave}
                            >
                                Save
                            </LoadingButton>
                            <Button
                                className="rounded-md ml-5"
                                variant="outlined"
                                type="button"
                                onClick={handleCloseDialog}
                            >
                                Cancel
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
            </Root>
        </FormProvider>
    );
}

export default GoogleApiWrapper({
    apiKey: CONFIG.MAPS,
})(MissionManagerEditModal);

