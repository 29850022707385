import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import history from '@history';
import _ from '@lodash';
import { setInitialSettings, setDefaultSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import auth0Service from 'app/services/auth0Service';
import firebaseService from 'app/services/firebaseService';
import jwtService from 'app/services/jwtService';
import settingsConfig from 'app/fuse-configs/settingsConfig';
import { usePrompt } from "app/services/leaveRoute";

export const setUserDataAuth0 = (tokenData) => async (dispatch) => {
    const user = {
        role: ['admin'],
        from: 'auth0',
        data: {
            displayName: tokenData.username || tokenData.name,
            photoURL: tokenData.picture,
            email: tokenData.email,
            settings:
                tokenData.user_metadata && tokenData.user_metadata.settings
                    ? tokenData.user_metadata.settings
                    : {},
            shortcuts:
                tokenData.user_metadata && tokenData.user_metadata.shortcuts
                    ? tokenData.user_metadata.shortcuts
                    : [],
        },
    };

    return dispatch(setUserData(user));
};

export const setUserDataFirebase = (user, authUser) => async (dispatch) => {
    if (
        user &&
        user.data &&
        user.data.settings &&
        user.data.settings.theme &&
        user.data.settings.layout &&
        user.data.settings.layout.style
    ) {
        // Set user data but do not update
        return dispatch(setUserData(user));
    }

    // Create missing user settings
    return dispatch(createUserSettingsFirebase(authUser));
};

export const createUserSettingsFirebase = (authUser) => async (dispatch, getState) => {
    const guestUser = getState().auth.user;
    const fuseDefaultSettings = getState().fuse.settings.defaults;
    const { currentUser } = firebase.auth();

    /**
     * Merge with current Settings
     */
    const user = _.merge({}, guestUser, {
        uid: authUser.uid,
        from: 'firebase',
        role: ['admin'],
        data: {
            displayName: authUser.displayName,
            email: authUser.email,
            settings: { ...fuseDefaultSettings },
        },
    });
    currentUser.updateProfile(user.data);

    dispatch(updateUserData(user));

    return dispatch(setUserData(user));
};

export const setUserData = (user) => async (dispatch, getState) => {
    /*
    You can redirect the logged-in user to a specific route depending on his role
    */
    if (user.loginRedirectUrl) {
        settingsConfig.loginRedirectUrl = user.loginRedirectUrl; // for example 'apps/academy'
    }
    /*
    Set User Settings
    */
    dispatch(setDefaultSettings(user.data.settings));

    dispatch(setUser(user));
};

export const updateUserSettings = (settings) => async (dispatch, getState) => {
    const oldUser = getState().auth.user;
    const user = _.merge({}, oldUser, { data: { settings } });

    dispatch(updateUserDataSettings(user));

    return dispatch(setUserData(user));
};

export const updateUserShortcuts = (shortcuts) => async (dispatch, getState) => {
    const { user } = getState().auth;
    const newUser = {
        ...user,
        data: {
            ...user.data,
            shortcuts,
        },
    };

    dispatch(updateUserData(newUser));

    return dispatch(setUserData(newUser));
};

export const logoutUser = () => async (dispatch, getState) => {

    const { user } = getState().auth;

    if ((!user.role || user.role.length === 0)) {
        // is guest
        return null;
    }

    history.push({
        pathname: '/login',
    });

    jwtService.logout();

    return dispatch(setInitialSettings());
};

export const updateUserData = (user) => async (dispatch, getState) => {
    jwtService
        .updateUserData(user)
        .then(() => {
            dispatch(showMessage({ message: 'User data saved with api' }));
        })
        .catch((error) => {
            dispatch(showMessage({ message: error.message }));
        });
};

export const updateUserDataSettings = (user) => async (dispatch, getState) => {
    jwtService
        .updateUserDataSettings(user)
        .then(() => {
            dispatch(showMessage({ message: 'User data saved with api' }));
        })
        .catch((error) => {
            dispatch(showMessage({ message: error.message }));
        });
};

const initialState = {
    role: [], // guest
    data: {
        displayName: 'John Doe',
        photoURL: 'assets/images/avatars/Velazquez.jpg',
        email: 'johndoe@withinpixels.com',
        shortcuts: ['calendar', 'mail', 'contacts', 'todo'],
    },
};

const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (state, action) => action.payload,
        userLoggedOut: (state, action) => initialState,
    },
    extraReducers: {},
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
