import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PublicSidebarLeftLogo from 'app/main/apps/public/public-sidebar-left/PublicSidebarLeftLogo';

const Root = styled('div')(({ theme }) => ({
  '& > .logo-icon': {
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  '& > .badge, & > .logo-text': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

function Logo() {
  return (
    <PublicSidebarLeftLogo />
  );
}

export default Logo;
