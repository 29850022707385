// comment on Marker, try code for map marker pin with profile picture kaso dko mai align reference lang sguro para sa dev in the future
import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import CONFIG from "app/config";

import {
    Dialog,
    DialogTitle,
    IconButton,
    ImageList,
    Box,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import AgentJwtService from 'app/services/agent/AgentJwtService';
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardHeader, Container, Stack } from "@mui/material";
import { TextField, Button, Item } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";

//maps
import InfoWindowEx from "../infoWindowEx";
import { Map, Marker, InfoWindow, GoogleApiWrapper } from "google-maps-react";

//Redux
import { useDispatch } from "react-redux";

//Service
import { getSingleMissionById } from "../store/missionSlice"; //from store

//Hooks
import React, { useState, useRef, useEffect, useMemo, createRef, useReducer } from "react";

//Router
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useParams,
} from "react-router-dom";
import { red } from "@mui/material/colors";
import MissionDetailsContent from "./MissionDetailsContent";
import Provider from "react-redux/es/components/Provider";
import store from "app/store";
import { PushPin } from "@mui/icons-material";
import UpdateMarkerContent from "./marker/UpdateMarkerContent";
import DeleteMarkerContent from "./marker/DeleteMarkerContent";
import JoinMarkerContent from "./marker/JoinMarkerContent";
import UpdateMarkerLocationContent from "./marker/UpdateMarkerLocationContent";
import MissionDetailsJoinMissionModal from "./modal/MissionDetailsJoinMissionModal";
import MissionDetailsMovePinModal from "./modal/MissionDetailsMovePinModal";
import { format } from "date-fns";
import { followAgent } from "../../friends-manager/store/FriendsManagerSlice";
import AgentFollowUnfollow from "app/services/AgentFollowUnfollow";
import AgentJoinMission from "app/services/maps/AgentJoinMission";
import AgentMovePinLocation from "app/services/maps/AgentMovePinLocation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import { map } from "lodash";
import MissionDetailsUpdateContent from "./modal/MissionDetailsUpdateContent";
import { getAllPublicUploads } from "app/services/store/PublicUploadsSlice";
import AttachmentContainer from "app/services/AttachmentContainer";
import AttachmentSvs from "app/services/AttachmentSvs";
import JoinButtonSvs from "app/services/JoinButtonSvs";

const Root = styled(FusePageSimple)(() => ({
    "& .FusePageCarded-topBg": {
        background: "unset",
    },
    "& .flex.flex-auto.flex-col.container.z-10.h-full": {
        width: "100%",
        maxWidth: "unset",
    },
    "& .FusePageSimple-toolbar": {
        background: "white",
        minHeight: "unset",
        height: "unset",
    },
    minHeight: "unset",
    "& .FusePageSimple-contentCard, .FusePageSimple-header": {
        backgroundColor: "unset",
        background: "unset",
        boxShadow: "unset",
    },
}));
const PopupStyled = styled('div')(() => ({
    filter: "drop-shadow(rgba(149, 157, 165, 0.3) 0px 5px 8px)",
    width: "100%",
    margin: "auto",
    position: "relative",
    "& #box-content": {
        background: "white",
        width: "100%",
        height: "auto",
        position: "relative",
        borderRadius: "8px",
        zIndex: '1',
    },
    "& #box-profile": {
        background: "white",
        width: " 121px",
        height: "121px",
        margin: "auto",
        position: "relative",
        borderRadius: "999px",
        top: "-30px",
        left: "-30px",
        zIndex: "1",
        position: "absolute",
    },
    "& #arrow-down": {
        width: "0",
        height: "0",
        borderLeft: "25px solid transparent",
        borderRight: "25px solid transparent",
        borderTop: "25px solid white",
        position: "absolute",
        left: "40%",
        zIndex: "1",
        bottom: "-18px",
    },

}));
const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 26,
    height: 26,
}));
const BackgroundAvatar = styled(Avatar)(({ theme }) => ({
    borderRadius: "unset",
    width: 46,
    height: 46,
    "& img": {
        objectFit: "contain",
    },
}));

function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    return color;
}

function stringAvatar(name) {
    var username = name.split(" "),
        firstName = username[0].split(" ")[0][0],
        lastName = username[username.length - 1].split(" ")[0][0];
    if (firstName == lastName) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}`,
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}${lastName}`,
        };
    }
}

//for dialog
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    //dialog
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    edge={false}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};
const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
};
function MapContent(props) {

    const { id } = useParams();
    const token = AgentJwtService.getDecodedAccessToken();
    const dispatch = useDispatch();
    const [visibleMovePin, setVisibleMovePin] = useState(false);  // visibility state
    const [hideJoinMission, setHideJoinMission] = useState(true);  // visibility state
    const [attachmentData, setAttachmentData] = useState([])
    const mapRef = createRef();
    const [ishowingInfoWindow, setShowingInfoWindow] = useState(true);
    const [isKingPin, setKingPin] = useState("");
    const [mapData, setMapData] = useState([]);
    const [mapDataAgents, setMapDataAgents] = useState([]);
    const [totalComment, setTotalComment] = useState([]);
    const [totalPinned, setTotalPinned] = useState([]);
    const [updateMarkerData, setUpdateMarkerData] = useState([]);
    const [mapMarkersData, setMapMarkersData] = useState([]);
    const [activeMarker, setActiveMarker] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [menuElement, setMenuElement] = useState(null);
    const openElement = Boolean(menuElement);
    const [openViewFullDialog, setViewFullDialog] = useState(false); //View full Details Dialog
    const [openUpdateContentDialog, setUpdateContentDialog] = useState(false); //Update Details Dialog
    const [openDeleteContentDialog, setDeleteContentDialog] = useState(false); //Delete Details Dialog
    const [openUpdateMarkerLocation, setOpenUpdateMarkerLocation] = useState(false); //Join Mission  Details Dialog
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [profilePicPath, setProfilePicPath] = useState("");
    const [profilePicPathTemp, setProfilePicPathTemp] = useState("");
    const [missionPathUrl, setMissionPathUrl] = useState("");
    const [missionPathUrlTemp, setMissionPathUrlTemp] = useState("");
    const [infoData, setInfoData] = useState([]);
    const [mapCenter, setMapCenter] = useState([]);

    function getTimeAgo(dateTime) {

        const customDate = new Date(dateTime);
        const currentDate = new Date();
        const timeDifference = currentDate - customDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);

        if (weeks > 0) {
            return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
            return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
        }
    }

    function reloadData() {
        dispatch(
            getSingleMissionById({
                id: id,
            })
        ).then((action) => {
            setMapData(action.payload.totalMissionViews);
            setTotalComment(action.payload.totalMissionComments);
            setTotalPinned(action.payload.totalMissionPinned);
            setMapMarkersData(action.payload.totalMissionViews.markers);

        });
    }

    useEffect(() => {
        dispatch(
            getSingleMissionById({
                id: id,
            })
        ).then((action) => {
            setMapData(action.payload.totalMissionViews);
            setMapDataAgents(action.payload.totalMissionViews.agents)
            setTotalComment(action.payload.totalMissionComments);
            setTotalPinned(action.payload.totalMissionPinned);
            setMapMarkersData(action.payload.totalMissionViews.markers);

            action.payload.totalMissionViews.markers.filter(agent => agent.agent == token.id).map((map) => {
                if (map.agent === token.id) {
                    setHideJoinMission(false)
                } else {
                    setHideJoinMission(true)
                }
            })
        });
    }, [dispatch]);

    const clickMenuOpen = (event) => {
        setMenuElement(event.currentTarget);
    }

    const clickMenuClose = () => {
        setMenuElement(null)
    }

    const closeDeleteContentDialog = () => {
        setDeleteContentDialog(false)
        setMenuElement(null)
    }

    const clickViewFullDetails = () => {
        setViewFullDialog(true)
    };

    const clickCloseViewFullDetails = () => {
        setViewFullDialog(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const joinedMarkerDragEnd = (id) => (props, marker, e) => {
        const data = {
            marker_id: id,
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        };

        localStorage.setItem('dataKey', JSON.stringify(data));
        const map = mapRef.current.map;
        if (map) {
            const newPosition = { lat: e.latLng.lat(), lng: e.latLng.lng(), };
            map.panTo(newPosition);
        }
    };

    const clickUpdateContent = (pin) => (props, marker, e) => {

        setMissionPathUrl("")
        setProfilePicPath("")
        setFirstName(pin.agents[0].firstName)
        setLastName(pin.agents[0].lastName)
        setProfilePicPathTemp(pin.agents[0].profilePicPath)
        setMissionPathUrlTemp(pin.filesId)
        setActiveMarker(marker);
        setShowingInfoWindow(ishowingInfoWindow);
        setInfoData(pin);


    };

    useEffect(() => {
        if (profilePicPath == "" && profilePicPathTemp != "") {
            setProfilePicPath(profilePicPathTemp)
            setProfilePicPathTemp("")
        }
    }, [profilePicPath, profilePicPathTemp])

    useEffect(() => {
        if (missionPathUrl == "" && missionPathUrlTemp != "") {
            setMissionPathUrl(missionPathUrlTemp)
            setMissionPathUrlTemp("")
        }
    }, [missionPathUrl, missionPathUrlTemp])

    const clickContentPin = (pin) => (props, marker, e) => {

        const map = mapRef.current.map;
        const latValue = parseFloat(pin.lat);
        const lngValue = parseFloat(pin.lng);

        if (map) {
            const newPosition = { lat: latValue, lng: lngValue };
            map.panTo(newPosition);
        }
    }

    const clickMyPin = (pin) => (props, marker, e) => {
        const map = mapRef.current.map;
        const latValue = parseFloat(mapData.lat);
        const lngValue = parseFloat(mapData.lng);

        if (map) {
            const newPosition = { lat: latValue, lng: lngValue };
            map.panTo(newPosition);
        }
    };

    const clickMovePin = (props, marker, e) => {
        setVisibleMovePin(true)

    };

    return (
        <div style={{ width: "100%", height: "calc(100vh - 76px)" }}>
            <Map
                key={props}
                ref={mapRef}
                containerStyle={containerStyle}
                google={window.google}
                zoom={14}
                initialCenter={{ lat: 40.714081847094675, lng: -74.00243802642824 }}
                fullscreenControl={false}
                yesIWantToUseGoogleMapApiInternals
            >
                {mapMarkersData.map((item) => (
                    <Marker
                        key={`pin-${item.id}`}
                        position={{ lat: item.lat, lng: item.lng }}
                        onClick={clickUpdateContent(item)}
                        onDragend={joinedMarkerDragEnd(item.id)}
                        draggable={item.agent == token.id ? true : false}
                        icon={{
                            url: item.pin == 1 ? 'assets/images/pi-icons/pin-with-shadow-yellow.svg' : 'https://toppng.com/uploads/preview/eat-play-do-icon-map-marker-115548254600u9yjx6qhj.png',
                            scaledSize: new window.google.maps.Size(40, 40),
                        }}
                    />
                ))}
                <InfoWindowEx
                    key={props}
                    className="scroll-unset"
                    disableAutoPan={true}
                    marker={activeMarker}
                    visible={ishowingInfoWindow}
                    sx={{

                    }}
                >
                    <PopupStyled>
                        <div id="box-content">
                            <CardHeader
                                className=" !pl-92 !pt-10 !pr-48"
                                action={
                                    <>
                                        <IconButton
                                            className="!p-5"
                                            onClick={clickMenuOpen}
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={openElement ? "account-menu" : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openElement ? "true" : undefined}
                                            edge={false}
                                        >
                                            <SettingsIcon className=" !w-20 !h-20 text-pi-grey" />
                                        </IconButton>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={menuElement}
                                            open={openElement}
                                            onClose={clickMenuClose}
                                            PaperProps={{
                                                elevation: 0,
                                                sx: {
                                                    overflow: "visible",
                                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                    mt: 1.5,
                                                    "& .MuiAvatar-root": {
                                                        width: 32,
                                                        height: 32,
                                                        ml: -0.5,
                                                        mr: 1,
                                                    },
                                                    "&:before": {
                                                        content: '""',
                                                        display: "block",
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 14,
                                                        width: 10,
                                                        height: 10,
                                                        bgcolor: "background.paper",
                                                        transform: "translateY(-50%) rotate(45deg)",
                                                        zIndex: 0,
                                                    },
                                                },
                                            }}
                                            transformOrigin={{
                                                horizontal: "right",
                                                vertical: "top",
                                            }}
                                            anchorOrigin={{
                                                horizontal: "right",
                                                vertical: "bottom",
                                            }}
                                        >
                                            <MenuItem >
                                                <Provider store={store}>
                                                    <MissionDetailsUpdateContent item={mapData} module="update"></MissionDetailsUpdateContent>
                                                </Provider>
                                            </MenuItem>
                                            <MenuItem >
                                                <Typography className="!text-14"> Delete Pin</Typography>
                                                <DeleteMarkerContent
                                                    data={mapData}
                                                    maxWidth="md"
                                                    open={openDeleteContentDialog}
                                                    onClose={closeDeleteContentDialog}>
                                                </DeleteMarkerContent>
                                            </MenuItem>
                                        </Menu>
                                    </>
                                }
                                title={
                                    <>
                                        {infoData.pin == 1 ? (
                                            <>
                                                {firstName} {lastName}
                                                <FontAwesomeIcon className="ml-5 text-14" icon={faCrown} />
                                            </>
                                        ) : (
                                            <>
                                                {firstName} {lastName}
                                            </>
                                        )}
                                    </>
                                }
                                subheader={
                                    <>
                                        {mapMarkersData.filter(filter => filter.filesId == infoData.filesId).map((item) => (
                                            <>
                                                <Typography className=" !text-14 "> {format(new Date(item.dateCreated), 'MM/dd/yyyy hh:mm a')} - Created this mission - {getTimeAgo(item.dateCreated)}</Typography>
                                            </>

                                        ))}
                                    </>
                                }

                            />
                            <Container className="mt-16">
                                <Typography className="TITLE !text-16  !leading-5 !font-bold !line-clamp-2 text-pi-dark-blue-text">
                                    {infoData.title}
                                </Typography>
                                <Typography className=" !text-14  !leading-5 !font-normal !line-clamp-2 text-pi-secondary">
                                    {infoData.description}
                                </Typography>
                                <Provider store={store}>
                                    {missionPathUrl && (
                                        <AttachmentContainer id={missionPathUrl} />
                                    )}
                                </Provider>
                                <Stack direction={'row'} className=" pb-4" gap={1} justifyContent={'space-between'}>
                                    <Typography className="capitalize !text-12 "> {mapData.views} Views</Typography>
                                    <Typography className="capitalize !text-12 ">{totalComment} Comments</Typography>
                                    <Typography className="capitalize !text-12 ">{totalPinned} Pinned</Typography>
                                    <Button variant="Text" className=" !p-0" onClick={clickViewFullDetails}><p className="capitalize !text-12 !text-pi-blue-1">View full details</p></Button>
                                </Stack>
                                <Provider store={store}>
                                    <MissionDetailsContent
                                        comments={totalComment}
                                        pinned={totalPinned}
                                        data={mapData}
                                        missionUrl={missionPathUrl}
                                        open={openViewFullDialog}
                                        onClose={clickCloseViewFullDetails}>
                                    </MissionDetailsContent>
                                </Provider>
                            </Container>
                        </div>
                        <div id="box-profile" className=" flex justify-center items-center">
                            <Provider store={store}>
                                {profilePicPath && (
                                    <AttachmentSvs module={"at-4"} path={profilePicPath} />
                                )}
                            </Provider>
                        </div>
                    </PopupStyled>
                </InfoWindowEx>
                <div className="map-menu !shadow-6 !p-16">
                    {mapMarkersData.map((item) => (
                        <Card elevation={0} className=" bg-pi-yellow-orange-1 border-2 rounded-md border-pi-yellow-orange p-10 " sx={{ maxWidth: '366px !important' }}>
                            <Stack direction={"column"} spacing={0.5}>
                                <Stack direction={"row"} >
                                    <>
                                        <CardHeader
                                            onClick={clickContentPin(item)}
                                            className="p-0 w-x cursor-pointer"
                                            title={
                                                <>
                                                    <p className=" !text-16 !font-bold !text-pi-dark-blue-text line-clamp-2">{item.description}</p>
                                                </>
                                            }
                                            subheader={
                                                <Stack direction={'row'} gap={1}>
                                                    <Provider store={store}>
                                                        <AttachmentSvs module={"at-5"} path={item.agent?.profilePicPath} />
                                                    </Provider>
                                                    <Box>
                                                        <Typography className=" !text-12 !text-pi-black !font-bold">{item.agent?.firstName} {item.agent?.lastName} </Typography>
                                                        <Typography className=" line-clamp-1 !text-pi-dark-blue-text">
                                                            Created this mission {getTimeAgo(item.dateCreated)}
                                                        </Typography>
                                                    </Box>
                                                </Stack>
                                            }
                                        />
                                        <div
                                            style={{ maxWidth: 85, maxHeight: 85 }}
                                            cols={1}
                                            className="overflow-hidden bg-default w-full rounded-md"
                                        >
                                            <AttachmentContainer id={item.filesId} />
                                        </div>

                                    </>
                                </Stack>
                            </Stack >
                        </Card>
                    ))}
                </div>
                <div className="map-menu-controls ">
                    <IconButton
                        onClick={handleClick}
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        edge={false}
                    >
                        <SettingsIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: "visible",
                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                        }}
                        anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                        }}
                    >
                        <MenuItem onClick={clickMyPin()}>
                            <Avatar /> Find My Pin
                        </MenuItem>
                        <MenuItem onClick={clickMovePin}>
                            <Avatar /> Move My Pin
                        </MenuItem>
                    </Menu>
                </div>
                <JoinButtonSvs mission_id={id} module={"at-1"} />
                {
                    visibleMovePin &&
                    <>
                        <div className="map-button-container ">
                            <AgentMovePinLocation reloadData={reloadData} module="movepin" item={mapMarkersData}></AgentMovePinLocation>
                        </div>
                    </>
                }
            </Map >
        </div >
    );
}
export default GoogleApiWrapper({
    apiKey: CONFIG.MAPS,
})(MapContent);
