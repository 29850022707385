import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { GlobalStyles, height } from "@mui/system";
import FuseScrollbars from "@fuse/core/FuseScrollbars/FuseScrollbars";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { getPageInfo } from "../store/PageSlice";

const Root = styled(FusePageSimple)(() => ({
    minHeight: "unset",
    "& .FusePageSimple-header.FusePageSimple-topBg": {
        display: "none",
    },
    "& .flex.flex-auto.flex-col.container.z-10.h-full": {
        width: "100%",
        maxWidth: "unset",
    },
    "& .FusePageSimple-header": {
        background: "transparent",
        backgroundSize: "cover",

        height: 152.99,
    },
    "& .FusePageSimple-wrapper": {
        background: "transparent",
        height: "unset",
        width: "100%",
    },
    "& .MuiDrawer-root.MuiDrawer-docked": {
        "& .MuiPaper-root": {
            width: "17.708333333333332vw",
            minWidth: "290px"
        }
    },
    "& .max-h-screen-content-100 .ps__rail-y": {
        display: "none !important",
    },
    "& .FusePageSimple-sidebarContent": {
        height: "calc(100vh - 7.6rem)",
    },
    "& .FusePageSimple-toolbar": {
        height: "76px",
    },
    "& div, span": {
        backgroundColor: "unset !important"
    },
}));

function PublicAbout(props) {
    const dispatch = useDispatch();
    const routeParams = useParams();
    const [data, setData] = useState([]);

    useEffect(() => {
        dispatch(getPageInfo({ slugs: routeParams.slugs })).then((action) => {
            setData(action.payload);
        })
    }, [dispatch, routeParams.slugs])


    class BodyContent extends React.Component {
        render() {
            return (
                <div dangerouslySetInnerHTML={{ __html: data.bodyContent }} ></div>
            )
        }
    }

    return (
        <>
            <GlobalStyles
                styles={(theme) => ({
                    "#fuse-main": {
                        height: "100% !important",
                    },
                    " .FusePageSimple-sidebar, .FusePageSimple-sidebarHeader":
                    {
                        background: "white !important",
                    },
                    " .absolute-important":
                    {
                        position: "absolute !important",
                    },
                    " div.FusePageSimple-sidebarHeader.permanent": {
                        minHeight: 0,
                        height: "unset"
                    }
                })}
            />

            <Root
                content={
                    <FuseScrollbars className=" h-full max-h-screen-content-100 overflow-auto overscroll-auto">
                        <Box className='relative mx-16 my-16 sm:my-56px-vw sm:mx-72px-vw min-h-screen-70' sx={{ backgroundImage: 'url("assets/images/pi-images/global-map.svg")', backgroundSize: 'contain', backgroundRepeat: "no-repeat", backgroundPosition: 'center' }}>
                            <Box className=" w-full">
                                {data && (
                                    <BodyContent />
                                )}

                            </Box>
                        </Box>
                    </FuseScrollbars>
                }
            />
        </>
    );
}

export default PublicAbout;
